<template>
	<div class="createLable-box" v-if="modalVisible" @click="(showCloudFolder = false), (showCloudDataList = false), close()">
		<div class="content-box" @click.stop="">
			<CloseOutlined class="create-close-btn" @click="close()" />
			<div class="title" v-if="labelStatus == 'add'">{{ $t(`button.createLabel`) }}</div>
			<div class="title" v-else>{{ $t(`button.labelset`) }}</div>
			<div class="main-create">
				<div class="left-box" ref="leftBox">
					<div class="drawBox" :style="'width: ' + boxData.boxWidth + 'px;height:' + boxData.boxHeight + 'px;top:' + boxData.boxTop + 'px;left:' + boxData.boxLeft + 'px;'">
						<div
							v-for="(item, index) in boxData.boxCount"
							:key="index"
							:ref="el => (itemDrawBox[index] = el)"
							class="itemDrawBox"
							:style="{
								width: boxData.itemWidth + 'px',
								height: boxData.itemHeight + 'px',
								marginRight: labelAttr.columnSpacing * UnitConversion + 'px',
								marginBottom: labelAttr.rowSpacing * UnitConversion + 'px'
							}">
							<img v-if="labelAttr.background" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%" :src="imgHeader + labelAttr.background" alt="" />
						</div>
					</div>
				</div>
				<div class="right-box">
					<div class="right-nav">
						<a-form :model="labelAttr" labelAlign="left" ref="formRef" style="width: 100%">
							<div class="item-title" style="margin-top: 0">
								{{ $t(`label.label16`) }}
							</div>
							<a-form-item class="title-input" name="name">
								<a-input v-model:value="labelAttr.name" style="border-radius: 5px" />
							</a-form-item>
							<div class="item-title">
								{{ $t(`label.label20`) }}
								<a-select v-model:value="labelAttr.unit" :bordered="false" @change="unitChange">
									<a-select-option value="Mm">mm</a-select-option>
									<a-select-option value="Inch">inch</a-select-option>
								</a-select>
							</div>
							<a-row class="size-box">
								<a-col :span="12">
									<a-form-item :label="$t(`label.label23`)" name="width">
										<a-input-number @change="handleChange($event, 1)" id="inputNumber" v-model:value="labelAttr.width" :step="0.001" :min="0.001" :max="400" />
									</a-form-item>
								</a-col>
								<a-col :span="12">
									<a-form-item :label="$t(`label.label24`)" name="height">
										<a-input-number @change="handleChange($event, 2)" id="inputNumber" v-model:value="labelAttr.height" :min="0.001" :max="1800" :step="0.001" />
									</a-form-item>
								</a-col>
							</a-row>
							<div class="item-title">{{ $t(`label.label25`) }}</div>
							<a-row class="size-box">
								<a-col :span="12">
									<a-form-item :label="$t(`label.label26`)" name="columns">
										<a-input-number @change="handleChange($event, 3)" id="inputNumber" v-model:value="labelAttr.columns" :min="1" :step="1" :precision="0" :max="20" />
									</a-form-item>
								</a-col>
								<a-col :span="12">
									<a-form-item :label="$t(`label.label28`)" name="columnSpacing">
										<a-input-number @change="handleChange($event, 4)" id="inputNumber" v-model:value="labelAttr.columnSpacing" :min="0" :max="100" :precision="3" :step="1" />
									</a-form-item>
								</a-col>
							</a-row>
							<a-row class="size-box">
								<a-col :span="12">
									<a-form-item :label="$t(`label.label27`)" name="rows">
										<a-input-number @change="handleChange($event, 5)" id="inputNumber" v-model:value="labelAttr.rows" :min="1" :max="20" :precision="0" :step="1" />
									</a-form-item>
								</a-col>
								<a-col :span="12">
									<a-form-item :label="$t(`label.label29`)" name="rowSpacing">
										<a-input-number @change="handleChange($event, 6)" id="inputNumber" v-model:value="labelAttr.rowSpacing" :min="0" :max="100" :precision="3" :step="1" />
									</a-form-item>
								</a-col>
							</a-row>
							<div class="item-title">{{ $t(`label.label30`) }}</div>
							<a-row class="size-box">
								<a-col :span="12">
									<a-form-item :label="$t(`label.label31`)" name="paddingLeft">
										<a-input-number @change="handleChange($event, 7)" id="inputNumber" v-model:value="labelAttr.paddingLeft" :min="0" :max="100" :precision="3" :step="1" />
									</a-form-item>
								</a-col>
								<a-col :span="12">
									<a-form-item :label="$t(`label.label32`)" name="paddingRight">
										<a-input-number @change="handleChange($event, 8)" id="inputNumber" v-model:value="labelAttr.paddingRight" :min="0" :max="100" :precision="3" :step="1" />
									</a-form-item>
								</a-col>
							</a-row>
							<a-row class="size-box">
								<a-col :span="12">
									<a-form-item :label="$t(`label.label33`)" name="paddingTop">
										<a-input-number @change="handleChange($event, 9)" id="inputNumber" v-model:value="labelAttr.paddingTop" :min="0" :max="100" :precision="3" :step="1" />
									</a-form-item>
								</a-col>
								<a-col :span="12">
									<a-form-item :label="$t(`label.label34`)" name="paddingBottom">
										<a-input-number @change="handleChange($event, 10)" id="inputNumber" v-model:value="labelAttr.paddingBottom" :min="0" :max="100" :precision="3" :step="1" />
									</a-form-item>
								</a-col>
							</a-row>
							<div class="item-title">{{ $t(`editAttr.attrText97`) }}</div>
							<div class="create-add-img" v-if="!labelAttr.background">
								<a-upload class="head-btn" accept=".jpeg,.jpg,.png" action="" :showUploadList="false" :beforeUpload="beforeUpload">
									<a-button>{{ $t(`editAttr.attrText30`) }}</a-button>
								</a-upload>
								<div style="position: relative">
									<a-button @click.stop="handleOpenCloudImg">{{ $t(`editAttr.attrText31`) }}</a-button>
								</div>
							</div>
							<!-- 背景图片 -->
							<div class="create-background-img" v-else>
								<img class="background-img" :src="imgHeader + labelAttr.background" alt="" />
								<div class="iconfont icon-delete" @click="handleDelete"></div>
							</div>
						</a-form>
						<div class="create-btn-group">
							<a-button @click="close()">{{ $t(`button.cancel`) }}</a-button>
							<a-button type="primary" @click="handleSave()">{{ $t(`button.confirm`) }}</a-button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<CloudImgDia ref="cloudImgDiaRef" @update="handleUpdateImg" />
	</div>
</template>
<script>
	import { message } from "ant-design-vue";
	import { useRouter } from "vue-router";
	import { CloseOutlined } from "@ant-design/icons-vue";
	import { defineComponent, reactive, getCurrentInstance, ref, toRefs, nextTick, onMounted, onUnmounted } from "vue";
	import CloudImgDia from "@/components/CloudImgDia.vue";
	export default defineComponent({
		components: {
			CloseOutlined,
			CloudImgDia
		},
		setup(props, { emit }) {
			const router = useRouter();
			const { proxy } = getCurrentInstance();
			let globalProperties = getCurrentInstance().appContext.config.globalProperties;
			const userInfo = JSON.parse(localStorage.getItem("userInfo"));
			const state = reactive({
				modalVisible: false,
				labelAttr: {
					name: 'New Label',
					unit: "Mm", //单位
					width: 100.0, //宽度
					height: 80.0, //高度
					background: "",
					printBackground: false,
					columns: 1,
					rows: 1,
					rowSpacing: 0,
					columnSpacing: 0,
					paddingTop: 0,
					paddingBottom: 0,
					paddingLeft: 0,
					paddingRight: 0,
          type: 'add',
				},
				boxData: {
					boxWidth: 100.0,
					boxHeight: 80.0,
					itemWidth: 100.0,
					itemHeight: 80.0,
					boxCount: 0,
					multiple: 0,
					boxTop: 0,
					boxLeft: 0
				},
				itemDrawBox: [],
				leftBox: null,
				cloudImgDiaRef: null,
				formRef: null,
				ImageList: [],
				UnitConversion: 1,
				imgHeader: "data:image/png;base64,",
				cloudFileList: [],
				cloudDataList: [],
				showCloudDataList: false,
				showCloudFolder: false,
				queryList: {
					id: "",
					user_id: userInfo.userid,
					type: 1,
					page: 1,
					size: 999,
					order: ""
				},
				labelStatus: "create" // create 创建模板   add 编辑新创建模板的信息  edit 编辑已有模板的信息
			});

			// 显示弹窗
			const showModal = (labelStatus, labelAttr) => {
				state.labelStatus = labelStatus;
				if (state.labelStatus == "create") {
					localStorage.removeItem("labelBgImgInfo");
					state.labelAttr.unit = globalProperties.$unit;
          state.labelAttr.name = proxy.$i18n.locale == 'zh' ? "新建标签" : proxy.$i18n.locale == 'pt' ? 'Novo Rótulo' : proxy.$i18n.locale == 'es' ? 'Nueva etiqueta' : proxy.$i18n.locale == 'ru' ? 'Создать метку' : 'New Label'
					if (state.labelAttr.unit == "Inch") {
						state.UnitConversion = 25.4;
					} else {
						state.UnitConversion = 1;
					}
					switch (state.labelAttr.unit) {
						case "Mm":
							state.labelAttr.width = 100;
							state.labelAttr.height = 80;
							state.boxData.boxWidth = 100;
							state.boxData.boxHeight = 80;
							break;
						case "Inch":
							state.labelAttr.width = 3.937;
							state.labelAttr.height = 3.15;
							state.boxData.boxWidth = 3.937;
							state.boxData.boxHeight = 3.15;
					}
				} else {
					state.labelAttr = labelAttr;
				}
				// console.log(state.labelAttr);
				state.modalVisible = true;
				nextTick(() => {
					setLeftBox();
					window.onresize = () => {
						setLeftBox();
					};
				});
			};

			//计算左侧盒子的大小
			const setLeftBox = () => {
				state.boxData.boxCount = state.labelAttr.columns * state.labelAttr.rows;
				let width = state.labelAttr.width * state.UnitConversion * state.labelAttr.columns + (state.labelAttr.columns - 1) * (state.labelAttr.columnSpacing * state.UnitConversion);
				let height = state.labelAttr.height * state.UnitConversion * state.labelAttr.rows + (state.labelAttr.rows - 1) * (state.labelAttr.rowSpacing * state.UnitConversion);

				if (width > height) {
					state.boxData.multiple = (state.leftBox.offsetWidth - 20) / width;
					state.boxData.boxWidth = state.leftBox.offsetWidth - 20;
					state.boxData.boxHeight = height * state.boxData.multiple;
					state.boxData.boxTop = (state.leftBox.offsetHeight - state.boxData.boxHeight + state.labelAttr.rowSpacing * state.UnitConversion) / 2;
					state.boxData.boxLeft = (state.leftBox.offsetWidth - state.boxData.boxWidth + state.labelAttr.columnSpacing * state.UnitConversion) / 2;
					if (state.boxData.boxHeight > state.leftBox.offsetHeight) {
						state.boxData.boxHeight = state.leftBox.offsetHeight - 20;
						state.boxData.boxWidth = width * state.boxData.multiple;
						state.boxData.boxTop = (state.leftBox.offsetHeight - state.boxData.boxHeight + state.labelAttr.rowSpacing * state.UnitConversion) / 2;
						state.boxData.boxLeft = (state.leftBox.offsetWidth - state.boxData.boxWidth + state.labelAttr.columnSpacing * state.UnitConversion) / 2;
						state.boxData.itemWidth = state.boxData.boxWidth / state.labelAttr.columns - state.labelAttr.columnSpacing * state.UnitConversion;
						state.boxData.itemHeight = state.boxData.boxHeight / state.labelAttr.rows - state.labelAttr.rowSpacing * state.UnitConversion;
					} else {
						state.boxData.itemWidth = state.boxData.boxWidth / state.labelAttr.columns - state.labelAttr.columnSpacing * state.UnitConversion;
						state.boxData.itemHeight = state.boxData.boxHeight / state.labelAttr.rows - state.labelAttr.rowSpacing * state.UnitConversion;
					}
				} else {
					state.boxData.multiple = (state.leftBox.offsetHeight - 20) / height;
					state.boxData.boxWidth = width * state.boxData.multiple;
					state.boxData.boxHeight = state.leftBox.offsetHeight - 20;
					state.boxData.boxTop = (state.leftBox.offsetHeight - state.boxData.boxHeight + state.labelAttr.rowSpacing * state.UnitConversion) / 2;
					state.boxData.boxLeft = (state.leftBox.offsetWidth - state.boxData.boxWidth + state.labelAttr.columnSpacing * state.UnitConversion) / 2;
					state.boxData.itemWidth = state.boxData.boxWidth / state.labelAttr.columns - state.labelAttr.columnSpacing * state.UnitConversion;
					state.boxData.itemHeight = state.boxData.boxHeight / state.labelAttr.rows - state.labelAttr.rowSpacing * state.UnitConversion;
				}
			};

			//单位切换 数值转换
			const unitChange = e => {
				globalProperties.$unit = e;
				switch (state.labelAttr.unit) {
					case "Mm":
						state.UnitConversion = 1;
						state.labelAttr.width = (state.labelAttr.width * 25.4).toFixed(3);
						state.labelAttr.height = (state.labelAttr.height * 25.4).toFixed(3);
						state.labelAttr.columnSpacing = (state.labelAttr.columnSpacing * 25.4).toFixed(3);
						state.labelAttr.rowSpacing = (state.labelAttr.rowSpacing * 25.4).toFixed(3);
						state.labelAttr.paddingLeft = (state.labelAttr.paddingLeft * 25.4).toFixed(3);
						state.labelAttr.paddingRight = (state.labelAttr.paddingRight * 25.4).toFixed(3);
						state.labelAttr.paddingTop = (state.labelAttr.paddingTop * 25.4).toFixed(3);
						state.labelAttr.paddingBottom = (state.labelAttr.paddingBottom * 25.4).toFixed(3);
						break;
					case "Inch":
						state.UnitConversion = 25.4;
						state.labelAttr.width = (state.labelAttr.width / 25.4).toFixed(3);
						state.labelAttr.height = (state.labelAttr.height / 25.4).toFixed(3);
						state.labelAttr.columnSpacing = (state.labelAttr.columnSpacing / 25.4).toFixed(3);
						state.labelAttr.rowSpacing = (state.labelAttr.rowSpacing / 25.4).toFixed(3);
						state.labelAttr.paddingLeft = (state.labelAttr.paddingLeft / 25.4).toFixed(3);
						state.labelAttr.paddingRight = (state.labelAttr.paddingRight / 25.4).toFixed(3);
						state.labelAttr.paddingTop = (state.labelAttr.paddingTop / 25.4).toFixed(3);
						state.labelAttr.paddingBottom = (state.labelAttr.paddingBottom / 25.4).toFixed(3);
				}
				setLeftBox();
			};

			// 确定
			const handleSave = () => {
				var reg = new RegExp('[\\\\/:*?"<>|]');
				if (state.labelAttr.name == "") {
					message.error(proxy.$t(`tips.tip41`));
					return;
				} else if (reg.test(state.labelAttr.name)) {
					message.error(proxy.$t(`tips.tip42`) + '\\/:*?"<>|');
					return;
				}
        
				if (state.labelStatus == "create") {
					//新建
					let params = JSON.parse(JSON.stringify(state.labelAttr));
					//将背景图片存到缓存里， 解决谷歌分析上报事件请求太长的问题
					if (params.background) {
						let data = { name: params.name, background: params.background };
						localStorage.setItem("labelBgImgInfo", JSON.stringify(data));
					}
					delete params.background;
					router.push({
						path: "/editLabel",
						query: {
							labelAttr: encodeURIComponent(JSON.stringify(params)),
							labelStatus: "add"
						}
					});
					close();
				} else {
					//编辑
					emit("set", JSON.parse(JSON.stringify(state.labelAttr)));
					close();
				}
			};

			// 输入框内容为空时，置入默认值
			const handleChange = (e, type) => {
				nextTick(() => {
					if (!e) {
						switch (type) {
							case 1:
								state.labelAttr.width = state.labelAttr.unit == "Inch" ? "3.937" : 100;
								break;
							case 2:
								state.labelAttr.height = state.labelAttr.unit == "Inch" ? "3.15" : 80;
								break;
							case 3:
								state.labelAttr.columns = 1;
								break;
							case 4:
								state.labelAttr.columnSpacing = 0;
								break;
							case 5:
								state.labelAttr.rows = 1;
								break;
							case 6:
								state.labelAttr.rowSpacing = 0;
								break;
							case 7:
								state.labelAttr.paddingLeft = 0;
								break;
							case 8:
								state.labelAttr.paddingRight = 0;
								break;
							case 9:
								state.labelAttr.paddingTop = 0;
								break;
							case 10:
								state.labelAttr.paddingBottom = 0;
								break;
						}
					}
					setLeftBox();
				});
			};

			//关闭
			const close = () => {
				state.formRef.resetFields();
				state.modalVisible = false;
				window.onresize = null;
			};

			//获取本地添加的图片
			const beforeUpload = file => {
				var testMsg = /^image\/(jpeg|png|jpg)$/.test(file.type);
				if (!testMsg) {
					message.error(proxy.$t(`tips.tip39`));
					return;
				}
				var configuration = JSON.parse(localStorage.getItem("configuration"));
				if (file.size / 1024 > configuration.image_size) {
					message.error(proxy.$t(`tips.tip30`) + configuration.image_size + proxy.$t(`tips.tip31`));
					return;
				}
				getBase64(file).then(res => {
					state.labelAttr.background = res.split(",")[1];
					state.labelAttr.printBackground = true;
				});
				return false;
			};

			//本地路径转base64
			const getBase64 = file => {
				return new Promise(function (resolve, reject) {
					let reader = new FileReader();
					let imgResult = "";
					reader.readAsDataURL(file);
					reader.onload = function () {
						imgResult = reader.result;
					};
					reader.onerror = function (error) {
						reject(error);
					};
					reader.onloadend = function () {
						resolve(imgResult);
					};
				});
			};

			//打开云端图片
			const handleOpenCloudImg = () => {
				state.cloudImgDiaRef.show();
			};

			//插入云端图片
			const handleUpdateImg = imgUrl => {
				URLgetBase64(imgUrl, dataURL => {
					state.labelAttr.background = dataURL.split(",")[1];
					state.labelAttr.printBackground = true;
				});
			};

			//七牛云路径转base64
			const URLgetBase64 = (url, callback) => {
				const Img = new Image();
				let dataURL = "";
				Img.src = url + "?v=" + Math.random();
				Img.setAttribute("crossOrigin", "Anonymous");
				Img.onload = function () {
					const canvas = document.createElement("canvas");
					canvas.width = Img.width;
					canvas.height = Img.height;
					canvas.getContext("2d").drawImage(Img, 0, 0, Img.width, Img.height);
					dataURL = canvas.toDataURL("image/png", 0.5);
					return callback ? callback(dataURL) : null;
				};
			};

			//删除背景
			const handleDelete = () => {
				state.labelAttr.background = "";
				state.labelAttr.printBackground = false;
			};

			return {
				...toRefs(state),
				handleSave,
				unitChange,
				handleChange,
				showModal,
				close,
				handleDelete,
				beforeUpload,
				handleOpenCloudImg,
				handleUpdateImg
			};
		}
	});
</script>
<style>
 .layout-content .anticon{
    font-size: 14px;
  }
</style>
<style scoped>
	.drawBox {
		position: absolute;
		line-height: 0;
	}
	.itemDrawBox {
		background: #ffffff;
		display: inline-block;
		position: relative;
	}

	.createLable-box {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 1000;
		background-color: rgba(0, 0, 0, 0.4);
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
 
	.content-box {
		width: 1395px;
		height: 802px;
		background: #ffffff;
		border-radius: 4px;
		position: relative;
	}
	.create-close-btn {
		position: absolute;
		top: 30px;
		right: 30px;
	}
	.content-box .title {
		height: 28px;
		font-size: 20px;
		font-weight: 500;
		color: #1a1d21;
		line-height: 28px;
		margin-top: 30px;
		margin-left: 32px;
	}
	.main-create {
		width: 100%;
		display: flex;
		flex-direction: row;
		align-content: center;
	}
	.main-create .left-box {
		width: 768px;
		height: 680px;
		background: rgba(0, 53, 145, 0.1);
		border-radius: 4px;
		margin-left: 32px;
		position: relative;
		margin-top: 24px;
	}
	.main-create .right-box {
		margin-top: 24px;
		width: 532px;
		margin-left: 31px;
	}
	.main-create .right-nav {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}
	.right-box .item-title {
		height: 24px;
		font-size: 16px;
		color: #535d6c;
		line-height: 24px;
		margin-bottom: 14px;
		margin-top: 16px;
	}

	.size-box {
		width: 100%;
	}
	.right-box ::v-deep(.ant-form-item) {
		height: 40px;
		margin-bottom: 8px;
		margin-top: 8px;
	}
	.size-box ::v-deep(.ant-form-item-label > label) {
		max-width: 150px;
		width: 100%;
		min-width: 85px;
		height: 22px;
		font-size: 16px;
		color: #1a1d21;
		line-height: 22px;
		margin-top: 9px;
		margin-right: 9px;
		white-space: nowrap;
		word-break: break-all;
		overflow: hidden;
		text-overflow: ellipsis;
		display: block;
	}
	.size-box ::v-deep(.ant-input-number) {
		width: 100px;
		height: 40px;
		line-height: 40px;
	}
	.create-add-img {
		display: flex;
	}
	.create-add-img ::v-deep(.ant-btn) {
		min-width: 204px;
		height: 40px;
		line-height: 24px;
		border-radius: 4px;
		color: #1a1d21;
		border: 1px solid #d6dce6;
		margin-right: 16px;
		background: none;
		font-size: 16px;
	}
	.create-btn-group {
		display: inline-block;
		margin-top: 26px;
	}
	.create-btn-group ::v-deep(.ant-btn:first-child) {
		color: #535d6c;
		border-color: #535d6c;
		margin-right: 16px;
		background: none;
	}
	.create-background-img {
		display: flex;
	}
	.background-img {
		width: 40px;
		height: 40px;
		border: 1px solid #d6dce6;
		margin-right: 8px;
	}
	.create-background-img ::v-deep(.iconfont) {
		font-size: 26px;
	}
	.create-cloudFolder {
		position: absolute;
		width: calc(100% - 16px);
		max-height: 250px;
		overflow: hidden;
		overflow-y: auto;
		background: #fff;
		z-index: 999;
		bottom: 40px;
		left: 0px;
		padding: 8px 16px;
		border-radius: 4px;
		box-shadow: 0px 0px 8px 0px rgba(145, 152, 165, 0.24);
	}

	.create-cloudFolder-box {
		height: 40px;
		font-size: 16px;
		color: #535d6c;
		line-height: 40px;
		text-align: center;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.create-cloudFolder-box img {
		object-fit: contain;
		width: 30px;
		height: 30px;
	}

	.create-cloudFolder-box:not(:last-child) {
		border-bottom: 1px solid #d6dce6;
	}

	.create-cloudFolder-box:hover {
		background: #f6f6f6;
	}
	@media only screen and (max-width: 1280px) {
		.content-box {
			width: 930px;
			height: 540px;
		}
		.create-close-btn {
			position: absolute;
			top: 20px;
			right: 20px;
		}
		.content-box .title {
			height: 18px;
			font-size: 14px;
			line-height: 18px;
			margin-top: 20px;
			margin-left: 21px;
		}
		.main-create .left-box {
			height: 450px;
			margin-left: 21px;
		}
		.main-create .right-box {
			width: 610px;
			margin-left: 21px;
		}
		.right-box .item-title {
			height: 16px;
			font-size: 10px;
			line-height: 16px;
			margin-bottom: 10px;
			margin-top: 10px;
		}
		.right-box .title-input ::v-deep(.ant-input) {
			width: 354px;
			height: 30px;
		}
		.right-box ::v-deep(.ant-form-item) {
			height: 26px;
			margin-bottom: 5px;
			margin-top: 5px;
		}
		.size-box ::v-deep(.ant-form-item-label > label) {
			max-width: 78px;
			min-width: 57px;
			height: 15px;
			font-size: 10px;
			line-height: 15px;
			margin-top: 8px;
			margin-right: 8px;
		}
		.size-box ::v-deep(.ant-input-number) {
			width: 70px;
			height: 30px;
			font-size: 10px;
			line-height: 24px;
		}
		.create-add-img ::v-deep(.ant-btn) {
			min-width: 136px;
			height: 26px;
			line-height: 16px;
			font-size: 10px;
			margin-right: 16px;
		}
		.create-btn-group {
			margin-top: 17px;
		}
		.create-btn-group ::v-deep(.ant-btn:first-child) {
			margin-right: 10px;
		}
		.item-title ::v-deep(.ant-select) {
			font-size: 10px !important;
		}
		.background-img {
			width: 26px;
			height: 26px;
		}
		.create-background-img ::v-deep(.iconfont) {
			font-size: 17px;
		}
		.create-cloudFolder {
			width: calc(100% - 16px);
			max-height: 200px;
			bottom: 26px;
			padding: 5px 10px;
		}

		.create-cloudFolder-box {
			height: 28px;
			font-size: 10px;
			line-height: 28px;
		}
		.create-cloudFolder-box img {
			width: 20px;
			height: 20px;
		}
	}
</style>
