<template>
	<div>
		<a-modal
			class="select-box"
			:title="$t(`editAttr.attrText100`)"
			centered
			:visible="selectVisible"
			:cancelText="$t(`button.cancel`)"
			:okText="$t(`button.confirm`)"
			:width="738"
			@cancel="handleCancel"
			@ok="handleOk">
			<a-input-search v-model:value="searchValue" :placeholder="$t(`tips.tip53`)" @input="handleSearch" />
			<div class="select-nav">
				<a-table
					:bordered="true"
					:row-selection="{
						selectedRowKeys: selectedRowKeys,
						onChange: onSelectChange
					}"
					:columns="columns"
					:data-source="dataSource"
					:pagination="false"
					size="small">
				</a-table>
			</div>
		</a-modal>
	</div>
</template>
<script>
	import { message } from 'ant-design-vue';
	import { defineComponent, getCurrentInstance, reactive, toRefs } from 'vue';
	export default defineComponent({
		setup(props, {emit}) {
			const { proxy } = getCurrentInstance();

			const state = reactive({
				selectVisible: false,
				columns: [],
				dataSource: [],
				originDataSource: [],
				list: [],
				selectedRowKeys: [],
				searchValue: ''
			});

			const show = (list, minCount, select) => {
				state.selectVisible = true;
				state.list = JSON.parse(JSON.stringify(list));
        console.log(select)

				state.list.forEach((item) => {
          item.data.forEach((data,index) => {
            let column = {
              title: item.name,
              dataIndex: `${item.name}_${index}`,
              key: `${item.name}_${index}`,
              align: 'center',
            };
            state.columns.push(column);
          })
					
				});

        for(let i = 0;i < minCount;i++){
          let row = {
            key: i + 1,
          }
          state.list.forEach((item)=>{
            item.data.forEach((data,index)=>{
              row[`${item.name}_${index}`] = data.data[i]
            })
          })
          state.dataSource.push(row)
        }

        console.log(state.dataSource)

				//设置勾选状态
				state.selectedRowKeys = select;

				//存储原始的表格数据  用来做关键字搜索
				state.originDataSource = state.dataSource;
				if (state.columns.length > 0) {
					state.columns.unshift({
						title: '序号',
						dataIndex: 'key',
						key: 'key',
						align: 'center',
						width: 50
					});
				}
			};

      const onSelectChange = selectedRowKeys => {
				state.selectedRowKeys = selectedRowKeys;
			};


			const handleSearch = () => {
				if (state.searchValue == '') {
					state.dataSource = state.originDataSource;
				} else {
					state.dataSource = state.originDataSource.filter(row => {
						for (let key in row) {
							if (String(row[key]).indexOf(state.searchValue) > -1) {
								return row;
							}
						}
					});
				}
			};

			const handleOk = () => {
				if (state.selectedRowKeys.length == 0) {
					message.error(proxy.$t(`tips.tip52`));
					return;
				}
				state.selectedRowKeys.sort(function (a, b) {
					return a - b;
				});
        let newList = []
        newList = state.list.filter(item => {
          item.data = item.data.filter((data) => {
            data.data = data.data.filter((child,index) => {
              let isSelect = state.selectedRowKeys.some(key => key == index + 1)
              if(isSelect){
                return child
              }
            })
            return data
          })
          return item
        })
        console.log(newList)

				emit('confirm', newList, state.selectedRowKeys);
				handleCancel();
			};

			const handleCancel = () => {
				state.selectVisible = false;
				state.list = [];
				state.columns = [];
				state.dataSource = [];
				state.selectedRowKeys = [];
				state.searchValue = '';
				state.selectedRowKeys = [];
			};

			return {
				...toRefs(state),
				show,
				handleCancel,
				handleOk,
				handleSearch,
				onSelectChange
			};
		}
	});
</script>
<style>
	.select-box .ant-modal-content {
		height: 635px;
		background: #ffffff;
		border-radius: 4px;
	}
	.select-box .ant-modal-body {
		margin-top: 24px;
	}
	.select-box .ant-input-affix-wrapper {
		width: 320px;
		height: 40px;
	}
	.select-nav {
		margin-top: 24px;
		width: 100%;
		height: 393px;
		border-radius: 2px;
		border: 1px solid #d6dce6;
		padding: 16px;
	}
	.select-nav .ant-table-wrapper {
		width: 100%;
		height: 100%;
		overflow: hidden;
		overflow-y: auto;
		overflow-x: auto;
	}
	.select-nav .ant-table-thead > tr > th {
		background: #fff;
		color: #7b89a1;
		font-size: 14px;
		font-weight: 500;
	}
	.select-nav .ant-table-tbody > tr > td {
		font-size: 14px;
		color: #535d6c;
	}
	@media only screen and (max-width: 1280px) {
		.select-box .ant-modal-content {
			height: 440px;
		}
		.select-box .ant-modal-title {
			height: 18px;
			font-size: 14px;
			line-height: 18px;
		}
		.select-box .ant-input-affix-wrapper {
			width: 213px;
			height: 26px;
		}
		.select-nav {
			margin-top: 16px;
			width: 100%;
			height: 262px;
			padding: 10px;
		}
		.select-nav .ant-table-wrapper {
			width: 100%;
			height: 100%;
		}
	}
</style>
