import { createI18n } from 'vue-i18n'; //引入vue-i18n组件
import messages from './index';
let langList = ['zh', 'en', 'es', 'pt', 'ru'];
var localeLanguage = localStorage.getItem('language');
localeLanguage = localeLanguage ? (langList.indexOf(localeLanguage) > -1 ? localeLanguage : 'en') : null;
// console.log(localeLanguage)
const language = (
	(navigator.language
		? navigator.language.split('-')[0] != 'zh' &&
		  navigator.language.split('-')[0] != 'en' &&
		  navigator.language.split('-')[0] != 'pt' &&
		  navigator.language.split('-')[0] != 'es' &&
		  navigator.language.split('-')[0] != 'ru'
			? 'en'
			: navigator.language
		: navigator.userLanguage) || 'zh'
).toLowerCase();
const i18n = createI18n({
	fallbackLocale: { 'zh-Hant': ['zh'] },
	globalInjection: true,
	legacy: false, // you must specify 'legacy: false' option
	locale: (localeLanguage ? localeLanguage : language.split('-')[0]) || 'zh',
	messages,
	warnHtmlMessage: false
});

export default i18n;
