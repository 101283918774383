<template>
	<div v-if="showModal" class="printLabel-box" @click="handleCancel()">
		<div class="print-content" element-loading-background="rgba(0, 0, 0, 0.2)" @click.stop="">
			<CloseOutlined class="print-close-btn" @click="handleCancel()" />
			<p class="title">{{ $t(`label.label19`) }}</p>
			<a-row class="print-box">
				<a-col :span="14">
					<div class="print-left">
						<div class="print-left-header">
							<div class="print-left-header-item">
								<span class="item-title">{{ $t(`label.label16`) }}</span>
								<span class="title-span">{{ labelAttr.name }}</span>
							</div>
							<div class="print-left-header-item">
								<span class="item-title">{{ $t(`label.label20`) }}</span>
								<span class="title-span">
									{{ labelAttr.width }}
									<span>*</span>
									{{ labelAttr.height }}{{ MeasureUnit }}
								</span>
							</div>
						</div>
						<div class="left-nav" v-loading="loading">
							<img style="max-width: 100%; max-height: 100%" v-if="previewImgList.length > 0" :src="previewImgList[currentPage - 1]" />
						</div>
						<div class="left-btn">
							<DoubleLeftOutlined @click="handlePage(1)" class="pagebtn" />
							<LeftOutlined class="pagebtn" @click="handlePage(2)" />
							<div>
								<span v-if="previewImgList.length > 0">{{ currentPage }}/{{ totalPage }}</span>
							</div>
							<RightOutlined class="pagebtn" @click="handlePage(3)" />
							<DoubleRightOutlined class="pagebtn" @click="handlePage(4)" />
						</div>
					</div>
				</a-col>
				<a-col :span="10">
					<div class="print-right">
						<div class="right-item">
							<span class="item-title" style="display: block">{{ $t(`editAttr.attrText98`) }}</span>
							<div class="select-print">
								<div>
									<a-input disabled v-model:value="selectKeysPage"></a-input>
								</div>
								<div v-if="minCount > 1" class="set-print" @click="selectData()">
									{{ $t(`button.choose`) }}
								</div>
							</div>
						</div>
						<div class="right-item">
							<a-checkbox size="large" class="item-title" :disabled="!canSetBackground" v-model:checked="printBackground" @change="handlePrintBackground">{{ $t(`editAttr.attrText99`) }}</a-checkbox>
						</div>
						<div v-if="system == 'Windows'">
							<div v-if="showPrintDevice">
								<div class="right-item">
									<div class="item-title" style="display: block">
										{{ $t(`label.label64`) }}
										<span @click="handleOpenHelp()" class="iconfont icon-bangzhu"></span>
									</div>
									<div class="print-device">
										<a-select
											allowClear
											@change="printDeviceChange"
											v-if="printDeviceList.length > 0"
											v-model:value="printDevice"
											:placeholder="$t(`tips.tip84`)"
											:options="printDeviceList"></a-select>
									</div>
								</div>
								<div class="right-item">
									<div class="item-title">
										{{ $t(`label.label65`) }}
									</div>
									<div class="print-device">
										<el-input-number v-model="printNum" controls-position="right" :step="1" :precision="0" :min="1" @blur="printNum = printNum ? printNum : 1"> </el-input-number>
									</div>
								</div>
							</div>

							<div class="right-item" v-else>
								<div class="item-title" style="height: auto">
									{{ $t(`tips.tip85`) }}
									<span @click="handleDownLoad()" style="text-decoration: underline; color: #003591; cursor: pointer">{{ $t(`tips.tip86`) }}</span>
									{{ $t(`tips.tip87`) }}
									<span @click="handleOpenHelp()" class="iconfont icon-bangzhu"></span>
								</div>
								<div class="item-title printer-tip">
									({{ $t(`tips.tip88`) }}<span style="color: #003591; cursor: pointer; text-decoration: underline" @click="getWebSocket('Manual_link')">{{ $t(`tips.tip89`) }}</span
									>)
								</div>
							</div>
						</div>
						<div class="btn-group">
							<a-button @click="handleCancel()">{{ $t(`button.cancel`) }}</a-button>
							<a-button type="primary" @click="handlePrint()">{{ $t(`button.confirm`) }}</a-button>
						</div>
					</div>
				</a-col>
			</a-row>
		</div>
		<div style="position: absolute; margin: 0; padding: 0; left: -200%; top: -200%; pointer-events: none; z-index: -10; display: none">
			<div v-if="showPrint" id="printDemo" style="position: absolute; margin: 0; padding: 0; left: 0; top: 0; pointer-events: none">
				<div
					v-for="(img, index) in printImgList"
					:key="index"
					:style="{
						width: printWidth + 'mm',
						height: printHeight + 'mm',
						paddingLeft: labelAttr.paddingLeft * UnitConversion + 'mm',
						paddingRight: labelAttr.paddingRight * UnitConversion + 'mm',
						paddingTop: labelAttr.paddingTop * UnitConversion + 'mm',
						paddingBottom: labelAttr.paddingBottom * UnitConversion + 'mm'
					}">
					<img style="width: 100%; height: 100%" :src="img" alt="" />
				</div>
			</div>
		</div>

		<CanvasGenerateImg ref="canvasGenerateImgRef" @getInfo="handleGetInfo" @ready="handleReady" />
		<SelectData ref="selectDataRef" @confirm="confirmSelect" />
	</div>
</template>
<script>
	import { getExplorer, getSystem } from '../utils/device';
	import { randomNumberStr, getBase64 } from '@/utils/util';
	import { mmToPx } from '@/views/editLabel/js/common';
	import { changeDpiDataUrl } from '../views/editLabel/js/changeDpi.js';
	import CanvasGenerateImg from './CanvasGenerateImg.vue';
	import SelectData from './SelectData.vue';
	import { message } from 'ant-design-vue';
	import { DoubleLeftOutlined, LeftOutlined, RightOutlined, DoubleRightOutlined, CloseOutlined } from '@ant-design/icons-vue';
	import { defineComponent, onMounted, reactive, ref, nextTick, toRefs, getCurrentInstance, onBeforeMount } from 'vue';
	export default defineComponent({
		components: {
			SelectData,
			DoubleLeftOutlined,
			LeftOutlined,
			RightOutlined,
			DoubleRightOutlined,
			CanvasGenerateImg,
			CloseOutlined
		},
		setup(props, context) {
			const { proxy } = getCurrentInstance();
			let globalProperties = getCurrentInstance().appContext.config.globalProperties;
			const Version = globalProperties.$Version;
			const userInfo = JSON.parse(localStorage.getItem('userInfo'));
			const axios = globalProperties.$axioshanma;
			const axiosnet = globalProperties.$axiosnet;

			const state = reactive({
				showPrintDevice: false,
				loading: true,
				MeasureUnit: '',
				printBackground: false,
				canSetBackground: true,
				selectDataRef: null,
				selectKeys: [],
				selectKeysPage: '',
				explorer: '',
				system: '',
				UnitConversion: 1, //单位毫米时为1，1英寸等于25.4毫米，英寸时为25.4, 将英寸换算成毫米
				printDevice: null, //打印机
				printDeviceList: [],
				printNum: 1, //打印份数
				webSocket: null,
				showModal: false,
				labelInfo: '', //标签信息（带标签链接）
				labelAttr: {}, //标签尺寸信息
				previewImgList: [], //预览图片数组
				totalPage: 0, //总页数
				currentPage: 1, //当前页
				canvasGenerateImgRef: null,
				labelList: [],
				multipleNodeData: [], //多数据
				realMultipleNodeData: [], //实际要打印的数据量
				minCount: '', //多数据的最小条数
				printCount: '', //实际要打印的条数
				showPrint: false,
				printWidth: 0,
				printHeight: 0,
				printImgList: [], //本地打印的图片数据（用来生成dom）
				canvasImg: '' //耗材背景图片
			});
			onMounted(() => {
				state.explorer = getExplorer();
				state.system = getSystem(); //获取操作系统
			});

			const show = obj => {
				// console.log(obj);
				if (state.system == 'Windows') {
					getWebSocket();
				}
				state.currentPage = 1;
				state.showPrint = false;
				if (obj.type == 'print') {
					//从列表立即打印进入
					state.labelInfo = obj.labelInfo;
					openLabel();
				} else {
					//从编辑器打印进入
					state.labelAttr = JSON.parse(JSON.stringify(obj.labelAttr));
					state.MeasureUnit = state.labelAttr.unit.toLowerCase();
					if (state.MeasureUnit == 'inch') {
						state.UnitConversion = 25.4;
					} else {
						state.UnitConversion = 1;
					}
					state.canvasImg = obj.canvasImg;
					state.multipleNodeData = obj.multipleNodeData;
					state.realMultipleNodeData = obj.multipleNodeData;
					state.minCount = obj.minCount;
					state.printCount = obj.minCount;
					let length = Math.ceil(state.printCount / (Number(state.labelAttr.rows) * Number(state.labelAttr.columns)));
					state.previewImgList = Array.apply([], { length });
					state.selectKeys = [];
					for (let i = 0; i < state.printCount; i++) {
						state.selectKeys.push(i + 1);
					}
					setDataNumber();

					state.totalPage = length;

					state.canSetBackground = state.labelAttr.background ? true : false;
					state.printBackground = state.labelAttr.background ? true : false;

					state.showModal = true;
					nextTick(() => {
						state.canvasGenerateImgRef.createCanvas(obj);
					});
				}
			};

			//webSocket通信
			const getWebSocket = val => {
				state.webSocket = new WebSocket('ws://127.0.0.1:9004');
				state.webSocket.onopen = () => {
					// console.log("webSocket已连接");
					state.showPrintDevice = true;
					//获取打印机列表
					state.webSocket.send("hmarkwebclient {fun:'getComputerPrinters'}");
				};
				state.webSocket.onmessage = evt => {
					//数据接收
					var received_msg = JSON.parse(evt.data);
					// console.log(received_msg);

					switch (received_msg.fun) {
						case 'getComputerPrinters':
							//打印机列表
							if (received_msg.code == 200) {
								let list = JSON.parse(received_msg.data);
								state.printDeviceList = [];
								state.printDevice = localStorage.getItem('printDevice');
								let isDevice = list.find(item => item == state.printDevice);
								if (!isDevice) {
									state.printDevice = null;
									localStorage.removeItem('printDevice');
								}
								list.forEach(item => {
									state.printDeviceList.push({
										value: item,
										label: item
									});
								});
							} else {
								message.error(proxy.$t(`tips.tip90`));
								state.showPrintDevice = true;
							}
							break;
						case 'outputPrintting':
							//打印下发回调
							if (received_msg.code == 200) {
								message.success(proxy.$t(`tips.tip91`));
								state.webSocket.close();
								state.showPrintDevice = false;
								handleCancel();
							} else {
								message.success(proxy.$t(`tips.tip92`));
							}
					}
				};

				state.webSocket.onclose = () => {
					// 关闭 websocket
					// console.log("webSocket关闭");
					state.showPrintDevice = false;
				};

				state.webSocket.onerror = () => {
					//通信失败
					// console.log("webSocket通信失败");
					state.showPrintDevice = false;
					if (val == 'Manual_link') {
						message.error(proxy.$t(`tips.tip93`));
					}
				};
			};

			//打开标签，转成json数据
			const openLabel = () => {
				var form = new FormData();
				form.append('url', state.labelInfo.file_pc);
				axiosnet('/LoadXml/writeFile', form, {
					custom: { loading: false }
				}).then(res => {
					let labelJsonData = '';
					let regx = /^[\s\S]*\.tprts$/;
					if (regx.test(state.labelInfo.file_pc)) {
						//解密
						let jsonString = decodeURIComponent(escape(atob(res)));
						labelJsonData = JSON.parse(jsonString);
					} else {
						labelJsonData = JSON.parse(res);
					}

					let DrawObject = labelJsonData.PrtLable.ObjectList.DrawObject;
					DrawObject = Array.isArray(DrawObject) ? DrawObject : [DrawObject];
					let LabelPage = labelJsonData.PrtLable.PictureArea.LabelPage;
					let obj = {
						id: state.labelInfo.id,
						name: state.labelInfo.name,
						width: LabelPage.LabelWidth,
						height: LabelPage.LabelHeight,
						unit: LabelPage['@MeasureUnit'],
						background: LabelPage.Background,
						printBackground: LabelPage.PrintBackground == 'True' ? true : false,
						columns: LabelPage.Columns,
						rows: LabelPage.Rows,
						rowSpacing: LabelPage.RowSpacing,
						columnSpacing: LabelPage.ColumnSpacing,
						paddingTop: LabelPage.UpperMargin,
						paddingBottom: LabelPage.LowerMargin,
						paddingLeft: LabelPage.LeftMargin,
						paddingRight: LabelPage.RightMargin,
						type: state.labelInfo.type
					};
					state.labelAttr = Object.assign(state.labelAttr, obj);
					state.canSetBackground = state.labelAttr.background ? true : false;
					state.printBackground = state.labelAttr.background ? true : false;

					state.MeasureUnit = LabelPage['@MeasureUnit'].toLowerCase();
					if (state.MeasureUnit == 'inch') {
						state.UnitConversion = 25.4;
					} else {
						state.UnitConversion = 1;
					}
          if(state.labelInfo.type == 'common'){
            state.labelAttr.canvas_img = state.labelInfo.canvas_img
            LabelPage.Canvas = state.labelInfo.canvas_img;
          }else{
            LabelPage.Canvas = LabelPage.Canvas ? LabelPage.Canvas.split(',') : [];
          }
					let canvasImg = LabelPage.Canvas.length > 0 ? LabelPage.Canvas[0] : '';
					state.canvasImg = canvasImg;
					let val = {
						type: 'print',
						labelAttr: state.labelAttr,
						labelList: DrawObject,
						canvasImg: canvasImg
					};
					state.showModal = true;
					state.loading = true;
					nextTick(() => {
						state.canvasGenerateImgRef.createCanvas(val);
					});
				});
			};

			//获取标签初始化信息
			const handleGetInfo = ({ multipleNodeData, minCount }) => {
				state.multipleNodeData = JSON.parse(JSON.stringify(multipleNodeData));
				state.realMultipleNodeData = JSON.parse(JSON.stringify(multipleNodeData));
				state.minCount = minCount;
				state.printCount = minCount;

				let length = Math.ceil(state.printCount / (Number(state.labelAttr.rows) * Number(state.labelAttr.columns)));
				state.previewImgList = Array.apply([], { length });
				state.selectKeys = [];
				for (let i = 0; i < state.printCount; i++) {
					state.selectKeys.push(i + 1);
				}
				setDataNumber();

				state.totalPage = length;
				state.currentPage = 1;
			};

			//画布初始化完毕，可以开始生成图片
			const handleReady = () => {
				getDrawImg();
			};

			// 控制是否打印背景图片
			const handlePrintBackground = () => {
				state.canvasGenerateImgRef.showBackground(state.printBackground);
				getDrawImg();
			};

			//切换页码
			const handlePage = e => {
				switch (e) {
					case 1:
						if (state.totalPage == 1) return;
						state.currentPage = 1;
						break;
					case 2:
						if (state.currentPage == 1) return;
						state.currentPage--;
						break;
					case 3:
						if (state.currentPage == state.totalPage) return;
						state.currentPage++;
						break;
					case 4:
						if (state.currentPage == state.totalPage) return;
						state.currentPage = state.totalPage;
						break;
				}
				getDrawImg();
			};

			//选择数据
			const selectData = () => {
				state.selectDataRef.show(state.multipleNodeData, state.minCount, state.selectKeys);
			};

			//设置数据的条数显示
			const setDataNumber = () => {
				let tempArr = [];
				const num = i => {
					if (state.selectKeys[i] + 1 == state.selectKeys[i + 1]) {
						if (i + 1 < state.selectKeys.length) {
							num(i + 1);
						}
					} else {
						let endVal = state.selectKeys[i];
						let str = '';
						if (endVal == startVal) {
							str = startVal;
						} else {
							str = startVal + '-' + endVal;
						}
						tempArr.push(str);
						if (i + 1 < state.selectKeys.length) {
							startVal = state.selectKeys[i + 1];
							num(i + 1);
						}
					}
				};
				let startVal = state.selectKeys[0];
				num(0);
				state.selectKeysPage = tempArr.join(',');
			};

			//选择的数据回调
			const confirmSelect = async (list, keys) => {
				state.selectKeys = keys;
				setDataNumber();
				state.currentPage = 1;
				state.printCount = state.selectKeys.length;
				state.totalPage = Math.ceil(state.printCount / (Number(state.labelAttr.rows) * Number(state.labelAttr.columns)));
				state.canvasGenerateImgRef.setMultipleNodeData(list);
				state.realMultipleNodeData = JSON.parse(JSON.stringify(list));
				getDrawImg();
			};

			const getDrawImg = async () => {
				state.loading = true;
				state.previewImgList[state.currentPage - 1] = await drawImg(state.currentPage);
				state.loading = false;
			};

			// 绘制图片
			const drawImg = (currentPage, drawType) => {
				return new Promise(async resolve => {
					state.loading = true;
					let pageCount = state.labelAttr.rows * state.labelAttr.columns;
					let startNum = (currentPage - 1) * pageCount;
					let endNum = startNum + pageCount - 1;
					endNum = endNum > state.printCount - 1 ? state.printCount - 1 : endNum;
					let imgList = [];
					for (let i = startNum; i <= endNum; i++) {
						let obj = {
							index: i
						};
						if (drawType) [(obj['drawType'] = drawType)];
						let img = await state.canvasGenerateImgRef.draw(obj);
						imgList.push(img);
					}

					if (pageCount > 1) {
						let canvas = document.createElement('canvas');
						let ctx = canvas.getContext('2d');
						//转成毫米单位
						let canvasWidth =
							(state.labelAttr.width * state.labelAttr.columns +
								Number(state.labelAttr.paddingLeft) +
								Number(state.labelAttr.paddingRight) +
								state.labelAttr.columnSpacing * (Number(state.labelAttr.columns) - 1)) *
							state.UnitConversion;
						let canvasHeight =
							(state.labelAttr.height * state.labelAttr.rows +
								Number(state.labelAttr.paddingTop) +
								Number(state.labelAttr.paddingBottom) +
								state.labelAttr.rowSpacing * (Number(state.labelAttr.rows) - 1)) *
							state.UnitConversion;

						// 放大的倍率 为了使生成出来的图片更清晰
						let pixelRatio = 3;

						canvas.width = mmToPx(canvasWidth) * pixelRatio;
						canvas.height = mmToPx(canvasHeight) * pixelRatio;
						if (!state.labelAttr.background && !state.canvasImg) {
							ctx.fillStyle = '#fff';
							ctx.fillRect(0, 0, canvas.width, canvas.height);
						}
						let labelWidth = mmToPx(state.labelAttr.width * state.UnitConversion);
						let labelHeight = mmToPx(state.labelAttr.height * state.UnitConversion);
						let rowSpacing = mmToPx(state.labelAttr.rowSpacing * state.UnitConversion);
						let columnSpacing = mmToPx(state.labelAttr.columnSpacing * state.UnitConversion);
						let paddingLeft = mmToPx(state.labelAttr.paddingLeft * state.UnitConversion);
						let paddingTop = mmToPx(state.labelAttr.paddingTop * state.UnitConversion);

						let x = paddingLeft;
						let y = paddingTop;
						const createImg = (url, x, y) => {
							return new Promise(resolve => {
								let img = new Image();
								img.onload = () => {
									ctx.drawImage(img, x * pixelRatio, y * pixelRatio, labelWidth * pixelRatio, labelHeight * pixelRatio);
									resolve();
								};
								img.src = url;
							});
						};
						for (let j = 0; j < imgList.length; j++) {
							let num = j % state.labelAttr.columns;
							if (num == 0) {
								x = paddingLeft;
								if (j > 0) {
									y = y + labelHeight + rowSpacing;
								}
							} else {
								x = x + labelWidth + columnSpacing;
							}
							await createImg(imgList[j], x, y);
						}
						let image = canvas.toDataURL('image/png', 1);
						let highPixelImg = changeDpiDataUrl(image, 300);
						resolve(highPixelImg);
					} else {
						resolve(imgList[0]);
					}
					state.loading = false;
				});
			};

			//切换打印设备
			const printDeviceChange = () => {
				localStorage.setItem('printDevice', state.printDevice);
			};

			//下载云打印助手
			const handleDownLoad = () => {
				axios(
					'/api/upgrade.download/appSoftwareDownload?language=ul&app_type=1&app_package_name=HPRT_HMark_Service_exe&type=1',
					{},
					{
						custom: { loading: true }
					},
					'get'
				).then(res => {
					let a = document.createElement('a');
					a.href = res.url;
					a.click();
				});
			};

			// 打印
			const handlePrint = async () => {
				if (state.showPrintDevice) {
					if (state.printDevice) {
						dealCloudData();
					} else {
						message.error(proxy.$t(`tips.tip84`));
					}
					recordUseTemp();
				} else {
					//确定
					state.loading = true;
					state.printWidth =
						(state.labelAttr.width * state.labelAttr.columns +
							Number(state.labelAttr.paddingLeft) +
							Number(state.labelAttr.paddingRight) +
							state.labelAttr.columnSpacing * (Number(state.labelAttr.columns) - 1) +
							Number(state.labelAttr.paddingLeft) +
							Number(state.labelAttr.paddingRight)) *
						state.UnitConversion;
					state.printHeight =
						(state.labelAttr.height * state.labelAttr.rows +
							Number(state.labelAttr.paddingTop) +
							Number(state.labelAttr.paddingBottom) +
							state.labelAttr.rowSpacing * (Number(state.labelAttr.rows) - 1) +
							Number(state.labelAttr.paddingTop) +
							Number(state.labelAttr.paddingBottom)) *
						state.UnitConversion;

					let imgList = [];
					for (let i = 1; i <= state.totalPage; i++) {
						let highPixelImg = await drawImg(i, 'print');
						imgList.push(highPixelImg);
					}
					state.printImgList = imgList;
					state.loading = false;
					state.showPrint = true;
					nextTick(() => {
						$('#printDemo').jqprint({
							debug: false, //如果是true则可以显示iframe查看效果（iframe默认高和宽都很小，可以再源码中调大），默认是false
							importCSS: true, //true表示引进原来的页面的css，默认是true。（如果是true，先会找$("link[media=print]")，若没有会去找$("link")中的css文件）
							printContainer: true, //表示如果原来选择的对象必须被纳入打印（注意：设置为false可能会打破你的CSS规则）。
							operaSupport: true //表示如果插件也必须支持歌opera浏览器，在这种情况下，它提供了建立一个临时的打印选项卡。默认是true
						});
					});
					recordUseTemp();
					setTimeout(() => {
						handleCancel();
					}, 1000);
				}
			};

			//记录模板使用情况
			const recordUseTemp = () => {
				if (state.labelAttr.type == 'common') {
					//公共模板下载记录添加
					axios('/api/tem.app_public/addDowTem', {
						id: state.labelAttr.id
					}).then(res => {});
					//公共模板使用记录添加
					axios('/api/tem.app_public/userUseTem', {
						id: state.labelAttr.id,
						user_id: userInfo.userid
					}).then(res => {});
				} else if (state.labelAttr.type == 'cloud') {
					//云端模板使用情况记录
					axios('/api/tem.app_public/userPrivateUserTem', {
						id: state.labelAttr.id,
						user_id: userInfo.userid
					}).then(res => {
						//首页刷新最近使用列表
						context.emit('refresh');
					});
				}
			};

			//处理数据
			const dealCloudData = async () => {
				//数据精度转换（四舍五入）mm转点
				const mmToPoint = 3.96; // 1mm约等于3.96点 (31.7/8)  (203dpi(pc端采用203dpi进行计算)) 用于与pc端的数据转换
				const mmToPointPrecision = (data, precision = 0) => {
					let value = (Math.round(Math.abs(data) * mmToPoint * Math.pow(10, precision)) / Math.pow(10, precision)).toFixed(precision);
					return Number(value);
				};
				let imgList = [];
				let cloudData = [];
				for (let i = 0; i < state.printCount; i++) {
					let highPixelImg = await state.canvasGenerateImgRef.draw({
						index: i,
						drawType: 'print'
					});
					imgList.push(highPixelImg);
				}

				let currentRow = 1;
				let currentColumn = 1;
				let pageCount = state.labelAttr.rows * state.labelAttr.columns;
				let len = state.printCount > 1 ? imgList.length : pageCount;
				for (let i = 0; i < len; i++) {
					let base64 = state.printCount > 1 ? imgList[i] : imgList[0];

					let page = Math.ceil((i + 1) / pageCount);
					let row = currentRow;
					let cloumn = currentColumn;
					if ((i + 1) % pageCount == 0) {
						currentRow = 1;
						currentColumn = 1;
					} else {
						if (currentColumn == state.labelAttr.columns) {
							currentRow += 1;
							currentColumn = 1;
						} else {
							currentColumn += 1;
						}
					}

					let obj = {
						Id: randomNumberStr(8),
						zOrder: i,
						Name: `Image_${row}_${cloumn}`,
						OriginalImage: '',
						Mirror: 'None',
						Inverse: 'False',
						Halftone: 'None',
						ISParticipating: 'True',
						ImageFilePath: '',
						Image: base64.split(',')[1],
						StartX: 0,
						StartY: 0,
						Width: mmToPointPrecision(state.labelAttr.width * state.UnitConversion),
						Height: mmToPointPrecision(state.labelAttr.height * state.UnitConversion),
						AngleRound: 0,
						Data: null,
						Type: 'Image',
						Color: '-16777216',
						PenWidth: 0,
						DashStyle: 0,
						FillColor: '-16777216',
						Lock: 'False'
					};

					cloudData.push({
						'@Count': 1, //元素长度
						page, //页码
						row, //所在行
						cloumn, //所在列
						DrawObject: [obj] //元素数组
					});
				}

				const Version = globalProperties.$Version;

				var cloudPrintJson = {
					'?xml': {
						'@version': '1.0',
						'@encoding': 'utf-8'
					},
					PrtLable: {
						'#comment': [],
						FileInfo: {
							Creator: {
								'@Platform': 'Web',
								'@Version': Version
							}
						},
						PictureArea: {
							AreaSize: {
								'@Width': Math.round(state.labelAttr.width * (31.7 / 8) * state.UnitConversion).toString(),
								'@Height': Math.round(state.labelAttr.height * (31.7 / 8) * state.UnitConversion).toString()
							},
							LabelPage: {
								'@MeasureUnit': state.labelAttr.unit,
								'@LabelShape': 'Rectangle',
								'@Height': state.labelAttr.height,
								'@Width': state.labelAttr.width,
								Rows: state.labelAttr.rows,
								Columns: state.labelAttr.columns,
								RowSpacing: state.labelAttr.rowSpacing,
								ColumnSpacing: state.labelAttr.columnSpacing,
								LeftMargin: state.labelAttr.paddingLeft,
								RightMargin: state.labelAttr.paddingRight,
								UpperMargin: state.labelAttr.paddingTop,
								LowerMargin: state.labelAttr.paddingBottom,
								LabelWidth: state.labelAttr.width,
								LabelHeight: state.labelAttr.height,
								Background: state.labelAttr.Background,
								PrintBackground: state.printBackground ? 'True' : 'False',
								PrinterName: state.printDevice, //选择的打印机
								PrintNum: state.printNum //打印份数
							}
						},
						ObjectList: cloudData
					}
				};
				// console.log(cloudPrintJson);
				if (state.showPrintDevice) {
					//下发打印命令
					state.webSocket.send('hmarkwebclient {fun:"outputPrintting",data:' + JSON.stringify(cloudPrintJson) + '}');
				}
			};

			//关闭弹窗
			const handleCancel = () => {
				if (state.showPrintDevice) {
					state.webSocket.close();
				}
				state.showModal = false;
				state.previewImgList = [];
				state.currentPage = 1;
			};

			//跳转帮助中心
			const handleOpenHelp = () => {
				if (process.env.VUE_APP_VERSION == 'hprt') {
					// hprt 版本
					if (proxy.$i18n.locale == 'zh') {
						window.open('/help/124/', '_blank');
					} else {
						window.open('/en/help/125/', '_blank');
					}
				} else if (process.env.VUE_APP_VERSION == 'hereLabelTop') {
					window.open('/help/125', '_blank');
				} else {
					// HereLabel 版本
					if (proxy.$i18n.locale == 'zh') {
						window.open('/helpDetail?id=124', '_blank');
					} else {
						window.open('/helpDetail?id=125', '_blank');
					}
				}
			};

			return {
				...toRefs(state),
				selectData,
				handlePrint,
				handlePage,
				handlePrintBackground,
				confirmSelect,
				getWebSocket,
				printDeviceChange,
				handleDownLoad,
				handleOpenHelp,
				show,
				handleCancel,
				handleGetInfo,
				handleReady
			};
		}
	});
</script>
<style scoped>
	.printLabel-box .icon-bangzhu {
		font-size: 18px;
		color: #7c8ca4;
		cursor: pointer;
	}
	.printLabel-box .printer-tip {
		font-size: 14px;
		height: auto;
	}
	.printLabel-box {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 999;
		background-color: rgba(0, 0, 0, 0.4);
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.print-content {
		width: 888px;
		height: 582px;
		background: #ffffff;
		border-radius: 4px;
		position: relative;
	}
	.print-close-btn {
		position: absolute;
		top: 30px;
		right: 30px;
	}
	.print-content .title {
		width: 100%;
		height: 28px;
		font-size: 20px;
		font-weight: 500;
		color: #1a1d21;
		line-height: 28px;
		margin-top: 30px;
		margin-left: 32px;
	}
	.print-box {
		width: 100%;
		display: flex;
		flex-direction: row;
		align-content: center;
		margin-top: 24px;
		position: relative;
	}
	.print-box .print-left {
		width: 480px;
		display: flex;
		align-content: center;
		flex-direction: column;
		margin-left: 32px;
	}
	.print-box .print-left-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 12px;
	}
	.print-box .print-left-header-item {
		display: flex;
		align-items: center;
		flex-shrink: 0;
		max-width: 46%;
	}
	.print-left-header .item-title {
		height: 24px;
		font-size: 16px;
		color: #535d6c;
		line-height: 24px;
		margin-right: 16px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	.print-left-header .title-span {
		height: 24px;
		font-size: 16px;
		font-weight: 500;
		color: #1a1d21;
		line-height: 24px;
		max-width: 60%;
		display: inline-block;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		word-break: break-all;
		flex-shrink: 0;
	}
	.left-nav {
		width: 480px;
		height: 360px;
		background: #f5f8fc;
		border-radius: 4px;
		display: flex;
		align-content: center;
		justify-content: center;
		flex-wrap: wrap;
		padding: 10px;
	}
	.left-nav > img {
		object-fit: scale-down;
	}
	.left-btn {
		margin-top: 24px;
		margin-left: 110px;
		display: flex;
		align-items: center;
	}
	.left-btn ::v-deep(.anticon) {
		font-size: 16px;
		margin: 13px 30px 13px 0px;
	}
	.left-btn ::v-deep(.anticon:nth-child(2)) {
		margin-right: 19px;
	}
	.left-btn .pagebtn {
		cursor: pointer;
	}
	.left-btn ::v-deep(.anticon:hover) {
		color: #003591;
	}

	.left-btn > div {
		display: inline-block;
		font-size: 16px;
		font-weight: 500;
		color: #1a1d21;
		line-height: 24px;
		width: 80px;
		height: 40px;
		background: #ffffff;
		border-radius: 4px;
		border: 1px solid #d6dce6;
		text-align: center;
		padding-top: 8px;
		margin-right: 19px;
	}

	.print-box .print-right {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-left: 32px;
	}

	.print-right .right-item {
		margin-bottom: 24px;
	}
	.print-right .item-title {
		height: 24px;
		font-size: 16px;
		color: #535d6c;
		line-height: 24px;
		margin-right: 16px;
	}
	.print-right .title-span {
		height: 24px;
		font-size: 16px;
		font-weight: 500;
		color: #1a1d21;
		line-height: 24px;
	}
	.select-print {
		display: flex;
		align-items: center;
		margin-top: 16px;
	}
	.select-print ::v-deep(.ant-input) {
		width: 240px;
		height: 40px;
	}
	.print-device {
		margin-top: 16px;
	}
	.print-device ::v-deep(.ant-select) {
		width: 240px;
		height: 40px;
	}
	.print-device ::v-deep(.ant-select-single:not(.ant-select-customize-input) .ant-select-selector) {
		height: 40px;
		border-radius: 4px;
	}
	.print-device ::v-deep(.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input) {
		height: 40px;
	}
	.print-device ::v-deep(.ant-select-selection-placeholder) {
		line-height: 38px !important;
	}
	.print-device ::v-deep(.ant-select-selection-item) {
		line-height: 38px !important;
	}
	.print-device ::v-deep(.el-input-number) {
		text-align: left;
		width: 240px;
		height: 40px;
	}
	.print-device ::v-deep(.el-input-number.is-controls-right .el-input__inner) {
		padding-left: 11px;
	}
	.set-print {
		height: 40px;
		font-size: 16px;
		color: #003591;
		line-height: 40px;
		margin-left: 16px;
		cursor: pointer;
	}
	.btn-group {
		position: absolute;
		display: inline-block;
		bottom: 2px;
	}
	.btn-group ::v-deep(.ant-btn) {
		margin-right: 16px;
	}
	.btn-group ::v-deep(.ant-btn:first-child) {
		color: #535d6c;
		border-color: #535d6c;
		background: none;
	}
	@media only screen and (max-width: 1280px) {
		.printLabel-box .icon-bangzhu {
			font-size: 14px;
		}
		.printLabel-box .printer-tip {
			font-size: 10px;
		}
		.print-content {
			width: 592px;
			height: 385px;
		}
		.print-close-btn {
			position: absolute;
			top: 20px;
			right: 20px;
		}
		.print-content .title {
			height: 18px;
			font-size: 14px;
			line-height: 18px;
			margin-top: 20px;
			margin-left: 21px;
		}
		.print-box {
			margin-top: 16px;
		}
		.print-box .print-left {
			width: 320px;
			margin-left: 21px;
		}
		.print-box .print-left-header {
			margin-bottom: 10px;
		}
		.print-left-header .item-title {
			height: 16px;
			font-size: 10px;
			line-height: 16px;
			margin-right: 10px;
		}
		.print-left-header .title-span {
			height: 16px;
			font-size: 10px;
			line-height: 16px;
		}
		.left-nav {
			width: 320px;
			height: 240px;
			padding: 6px;
		}
		.left-btn {
			margin-top: 16px;
			margin-left: 73px;
		}
		.left-btn ::v-deep(.anticon) {
			font-size: 10px;
			margin: 8px 20px 8px 0px;
		}
		.left-btn ::v-deep(.anticon:nth-child(2)) {
			margin-right: 14px;
		}

		.left-btn > div {
			font-size: 10px;
			line-height: 16px;
			width: 53px;
			height: 28px;
			padding-top: 5px;
			margin-right: 14px;
		}

		.print-box .print-right {
			margin-left: 21px;
		}

		.print-right .right-item {
			margin-bottom: 16px;
		}
		.print-right .item-title {
			height: 16px;
			font-size: 10px;
			line-height: 16px;
			margin-right: 10px;
		}
		.print-right .title-span {
			height: 16px;
			font-size: 10px;
			line-height: 16px;
		}
		.select-print {
			margin-top: 10px;
		}
		.select-print ::v-deep(.ant-input) {
			width: 160px;
			height: 30px;
		}

		.print-device {
			margin-top: 10px;
		}
		.print-device ::v-deep(.ant-select) {
			width: 160px;
			height: 30px;
		}

		.print-device ::v-deep(.ant-select-single:not(.ant-select-customize-input) .ant-select-selector) {
			height: 30px;
			border-radius: 4px;
		}
		.print-device ::v-deep(.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input) {
			height: 30px;
		}
		.print-device ::v-deep(.ant-select-selection-placeholder) {
			line-height: 28px !important;
		}
		.print-device ::v-deep(.ant-select-selection-item) {
			line-height: 28px !important;
			font-size: 12px;
		}
		.print-device ::v-deep(.el-input) {
			line-height: 30px;
		}
		.print-device ::v-deep(.el-input-number) {
			width: 160px;
			height: 30px;
			line-height: 30px !important;
		}

		.print-device ::v-deep(.el-input-number.is-controls-right .el-input__inner) {
			height: 30px !important;
			line-height: 30px !important;
		}

		.print-device ::v-deep(.el-input-number.is-controls-right .el-input-number__increase) {
			height: 15px !important;
			line-height: 15px !important;
		}
		.print-device ::v-deep(.el-input-number.is-controls-right .el-input-number__decrease) {
			height: 15px !important;
			line-height: 15px !important;
		}

		.set-print {
			display: inline-block;
			height: 26px;
			font-size: 10px;
			line-height: 26px;
			margin-left: 10px;
		}
		.btn-group ::v-deep(.ant-btn) {
			margin-right: 10px;
			min-width: 30px;
		}
	}
</style>
