import bootstrap from "@/bootstrap";
import { BluetoothSDK } from "@/utils/BluetoothSDK.js";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import { createApp } from "vue";
import VueWechatTitle from "vue-wechat-title"; //动态修改title
import "../static/css/animation.css";
import "../static/css/common.css";
import "../static/css/font.css";
import "../static/css/style.css";
import App from "./App.vue";
import i18n from "./language/i18n";
import router from "./router";
import {
  axios,
  axioshanma,
  axiosnet,
  qiniuApi
} from "./services/axios";
import store from "./store";
const bluetoothSDK = new BluetoothSDK();
if (BluetoothSDK.isBluetoothAvailable()) {
  console.log("当前支持");
}
const app = createApp(App);
app.config.globalProperties.$bluetoothSDK = bluetoothSDK;
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$axioshanma = axioshanma;
app.config.globalProperties.$axiosnet = axiosnet;
app.config.globalProperties.$qiniuApi = qiniuApi;
app.config.globalProperties.$unit = "Mm";
app.config.globalProperties.$largerSize = 5.5; //文件可打开编辑的最大size
app.config.globalProperties.$Version = "Label Designer V2.0.0"; //版本号
app.config.globalProperties.$picupload = `${process.env.VUE_APP_VERSION == 'hprt' ? 'https://upload-z2.qiniup.com/' : 'https://up-na0.qiniup.com/'}putb64/-1/key/`

bootstrap({ router, store });
app.use(store);
app.use(ElementPlus);
app.use(router);
app.use(Antd);
app.use(i18n);

app.use(VueWechatTitle);
app.mount("#app");
