<template>
  <div class="cover">
    <a-modal
      class="cover-tip"
      style="height: 203px"
      :width="400"
      centered
      :visible="coverVisible"
      :cancelText="$t(`button.cancel`)"
      :okText="$t(`button.confirm`)"
      @cancel="handCancel"
      @ok="handleCover"
    >
      <div class="cover-text" style="text-align: center; padding: 10px 24px">
        {{ $t(`tips.tip45`) }}
      </div>
    </a-modal>
  </div>
</template>
<script>
import { defineComponent, reactive, getCurrentInstance, toRefs } from "vue";
import { message } from "ant-design-vue";

export default defineComponent({
  setup(props, {emit}) {
    const { proxy } = getCurrentInstance();
    let globalProperties =
      getCurrentInstance().appContext.config.globalProperties;
    const axios = globalProperties.$axioshanma;
    const state = reactive({
      coverVisible: false,
      data: {},
      MemberDiaRef: null,
    });
    const show = (value, MemberDiaRef) => {
      state.data = value;
      state.coverVisible = true;
      state.MemberDiaRef = MemberDiaRef;
    };
    const handleCover = () => {
      axios("/api/tem.app_public/coverUserTemplate", state.data)
        .then((res) => {
          message.success(proxy.$t(`tips.tip56`));
          state.coverVisible = false;
          setTimeout(()=>{
            emit("refreshFile", res);
          },0)
          
        })
        .catch((err) => {
          if (err.code == 402) {
            handCancel();
            if (process.env.VUE_APP_VERSION == "hprt") {
              setTimeout(() => {
                state.MemberDiaRef.show(err.message);
              }, 500);
            }
          }
        });
    };
    const handCancel = () => {
      state.coverVisible = false;
    };
    return {
      ...toRefs(state),
      show,
      handleCover,
      handCancel,
    };
  },
});
</script>
<style>
.cover-tip .ant-modal-header {
  padding-top: 0;
  margin-left: 0;
  height: 0;
}

.cover-tip .ant-modal-body {
  padding: 0;
  padding-top: 71px;
  font-size: 20px;
  font-weight: 500;
  color: #1a1d21;
  line-height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
}
@media only screen and (max-width: 1280px) {
  .cover-tip .ant-modal-body {
    padding: 0;
    padding-top: 47px;
    font-size: 14px;
    line-height: 28px;
  }
}
</style>
