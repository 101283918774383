<template>
	<div class="node-container-box">
		<div id="node_container" @click.stop=""></div>
	</div>
</template>
<script>
	import {
		getTextAlignValue,
		booleanChange,
		setFontStyle,
		getCodeType,
		getBarCodeTypeList,
		getQrCodeTypeList,
		getFontFamily,
		getGenerateCodeImg,
		resetNodeAttr,
		changeType,
		getCellRang,
		sumData,
		pxToMm,
		getEclevelList,
		getNowDateOrTime,
		getTimeFormatList,
		dealTimeOffset,
		formatDate,
		dataPrecision,
		combinationData,
		setBeforeZero,
		convertToTwentySix,
		convertToTen,
		drawVerticalText
	} from '../views/editLabel/js/common.js';

	import { changeDpiDataUrl } from '../views/editLabel/js/changeDpi.js';
	import { resolveTransitionHooks } from '@vue/runtime-core';
	export default {
		data() {
			return {
				mmToPoint: 3.96, // 1mm约等于3.96点 (31.7/8)  (203dpi(pc端采用203dpi进行计算)) 用于与pc端的数据转换
				magnification: 3.78, //1mm 约等于3.78px（96dpi）   像素 = 毫米 * ( 分辨率 / 25.4)
				UnitConversion: 1, //单位毫米时为1，1英寸等于25.4毫米，英寸时为25.4, 将英寸换算成毫米
				designStage: '',
				designLayer: '',
				designArea: '',
				elementGroup: '',
				imgHeader: 'data:image/png;base64,',
				labelAttr: '', //标签数据
				type: '', //类型
				labelList: '', //元素数据
				canvasImg: '', //画布背景图片
				multipleNodeData: [], //多数据
				count: 1, //数量
				isShowBackground: true //是否显示背景
			};
		},
		methods: {
			createCanvas(obj) {
				this.type = obj.type;
				this.labelAttr = obj.labelAttr;
				this.labelList = obj.labelList;
				this.count = obj.minCount;
				if (obj.multipleNodeData) {
					this.multipleNodeData = obj.multipleNodeData;
				}
				if (obj.canvasImg) {
					this.canvasImg = obj.canvasImg;
				}
				this.editInit();
			},
			//画布初始化
			async editInit() {
				let width = this.labelAttr.width * this.UnitConversion * this.magnification;
				let height = this.labelAttr.height * this.UnitConversion * this.magnification;

				this.canvasWidth = width;
				this.canvasHeight = height;
				this.designStage = new Konva.Stage({
					id: 'stage',
					container: 'node_container',
					width: this.canvasWidth,
					height: this.canvasHeight
				});

				this.designLayer = new Konva.Layer();
				this.designStage.add(this.designLayer);
				this.designLayer.draw();

				this.designArea = new Konva.Group({
					id: 'designArea',
					x: 0,
					y: 0,
					width: this.canvasWidth,
					height: this.canvasHeight,
					scaleX: 1,
					scaleY: 1
				});

				let background = new Konva.Rect({
					id: 'background',
					x: 0,
					y: 0,
					width,
					height,
					fill: '#fff'
				});
				this.designArea.add(background);

				let backgroundCanvas = new Konva.Image({
					id: 'backgroundCanvas',
					x: 0,
					y: 0,
					width,
					height,
					image: ''
				});
				this.designArea.add(backgroundCanvas);

				let backgroundImg = new Konva.Image({
					id: 'backgroundImg',
					x: 0,
					y: 0,
					width,
					height,
					image: ''
				});
				this.designArea.add(backgroundImg);

				this.elementGroup = new Konva.Group({
					id: 'elementGroup',
					x: 0,
					y: 0,
					width,
					height
				});
				this.designArea.add(this.elementGroup);
				this.designLayer.add(this.designArea);
				const drawCanvasImg = (url, node) => {
					return new Promise(resolve => {
						let image = new Image();
						image.setAttribute('crossOrigin', 'Anonymous');
						image.onload = e => {
							node.image(image);
							background.fill('');
							resolve();
						};
						image.src = url;
					});
				};

				if (this.canvasImg) {
					await drawCanvasImg(this.canvasImg, backgroundCanvas);
				}
				if (this.labelAttr.background) {
					await drawCanvasImg(this.imgHeader + this.labelAttr.background, backgroundImg);
				}
				this.designLayer.draw();
				this.generateNode();
			},
			//元素处理
			async generateNode() {
				for (let i = 0; i < this.labelList.length; i++) {
					let item = this.labelList[i];
					let Tag;
					let text;
					let fontObj;
					let fontFamily;
					let BoxWidth;
					let Data = '';
					if (item.Font) {
						var { font_style, text_decoration } = setFontStyle(item.Font['@FontStyle']);
					}

					let x = 0;
					let y = 0;
					let width = 0;
					let height = 0;
					let lineHeight = 1;
					item.StartX = Number(item.StartX);
					item.StartY = Number(item.StartY);
					item.Width = item.Width ? Number(item.Width) : '';
					item.Height = item.Height ? Number(item.Height) : '';

					switch (item.Type) {
						case 'WinText':
							x = (item.StartX / this.mmToPoint) * this.magnification;
							y = (item.StartY / this.mmToPoint) * this.magnification;

							let textNode = new Konva.Label({
								id: item.Id,
								type: item.Type,
								x,
								y,
								rotation: Number(item.AngleRound) == 270 ? -90 : Number(item.AngleRound),
								scaleX: 1,
								scaleY: 1,
								draggable: false
							});
							Tag = new Konva.Tag({
								id: textNode.id(),
								fill: booleanChange(item.AntiBlack) ? '#000000' : ''
							});
							textNode.add(Tag);

							item.DataSourceList = Array.isArray(item.DataSourceList) ? item.DataSourceList : [item.DataSourceList];
							item.DataSourceList.map(value => {
								Data += value.FirstValue;
								value.InitialValue = value.DataSourceType == 2 ? value.InitialValue : '';
								value.AddedValue = value.DataSourceType == 2 ? Number(value.AddedValue) : '';
								value.Quantity = value.DataSourceType == 2 ? Number(value.Quantity) : '';

								value.ColumnName = value.DataSourceType == 1 ? value.ColumnName : '';
								value.QuerySql = value.DataSourceType == 1 ? value.QuerySql : '';
								value.TableName = value.DataSourceType == 1 ? value.TableName : '';
								value.SheetIndex = [-1, -1];
								value.DataSourceType = Number(value.DataSourceType);
								value.SequenceType = Number(value.SequenceType);
								value.DataType = Number(value.DataType);
								value.SequenceType = Number(value.SequenceType);
							});

							if (item.TextArrange == 1) {
								this.drawVerticalText(textNode, item, Data);
							} else {
								this.drawHorizontalText(textNode, item, Data);
							}

							width = textNode.width();
							height = textNode.height();
							//根据左上角坐标计算出实际点坐标
							textNode.x(item.AngleRound == 90 ? x + height : item.AngleRound == 180 ? x + width : x);
							textNode.y(item.AngleRound == 180 ? y + height : item.AngleRound == 270 ? y + width : y);

							this.elementGroup.add(textNode);

							BoxWidth = item.TextArrange == 1 ? pxToMm(textNode.height() * textNode.scaleY(), 2) : pxToMm(textNode.width() * textNode.scaleX(), 2);

							textNode.setAttr('parameter', {
								Id: textNode.id(),
								Type: item.Type,
								Name: item.Name,
								StartX: pxToMm(x, 2),
								StartY: pxToMm(y, 2),
								Width: pxToMm(textNode.width() * textNode.scaleX(), 2),
								Height: pxToMm(textNode.height() * textNode.scaleY(), 2),
								BoxWidth,
								Lock: booleanChange(item.Lock),
								Rotate: textNode.rotation(),
								AntiBlack: booleanChange(item.AntiBlack),
								MirrorImage: booleanChange(item.MirrorImage),
								FontFamily: item.Font['@FontFamily'],
								FontSize: dataPrecision((item.Font['@FontSize'] / this.mmToPoint) * this.magnification, 1),
								Bold: font_style.indexOf('bold') > -1 ? true : false,
								Italic: font_style.indexOf('italic') > -1 ? true : false,
								UnderLine: text_decoration.indexOf('underline') > -1 ? true : false,
								DeleteLine: text_decoration.indexOf('line-through') > -1 ? true : false,
								TextAlign: item.TextAlign,
								LineHeight: Math.round((item.RowSpacing1 / this.mmToPoint) * 100) / 100,
								LetterSpacing: Math.round((item.WordSpacing / this.mmToPoint) * 100) / 100,
								AutoWrap: booleanChange(item.AutoWrap),
								Data,
								TextPrefix: item.TextPrefix,
								TextSuffix: item.TextSuffix,
								TextArrange: item.TextArrange,
								DataSourceList: item.DataSourceList,
								dataSourceIndex: 0
							});
							if (this.type == 'print') {
								await this.getExcelCloudData(textNode);
							}
							break;
						case 'TimeText':
							x = (item.StartX / this.mmToPoint) * this.magnification;
							y = (item.StartY / this.mmToPoint) * this.magnification;
							let timeDateNode = new Konva.Label({
								id: item.Id,
								type: item.Type,
								x,
								y,
								width,
								height,
								rotation: Number(item.AngleRound) == 270 ? -90 : Number(item.AngleRound),
								scaleX: 1,
								scaleY: 1,
								draggable: false
							});

							Tag = new Konva.Tag({
								id: timeDateNode.id(),
								fill: booleanChange(item.AntiBlack) ? '#000000' : ''
							});

							timeDateNode.add(Tag);

							let SelectDate = '';
							let SelectTime = '';
							let date = '';
							if (item.TimeDate == 0) {
								//系统时间
								SelectDate = getNowDateOrTime('date');
								SelectTime = getNowDateOrTime('time');
							} else {
								//指定时间
								let fixedTime = '';
								if (item.TimeStamp != '' && item.TimeStamp != 0) {
									fixedTime = new Date(Number(item.TimeStamp));
								} else {
									//TimeStamp不存在的话，根据data反推出时间
									let time = new Date(item.Data).getTime();
									let time1 = time - item.DayOffset * 24 * 60 * 60 * 1000 - item.MinuteOffset * 60 * 1000;
									fixedTime = new Date(time1);
								}
								let year = fixedTime.getFullYear();
								let month = fixedTime.getMonth() + 1;
								let date = fixedTime.getDate();
								SelectDate = `${year}-${month}-${date}`;

								let hours = fixedTime.getHours();
								let minutes = fixedTime.getMinutes();
								let seconds = fixedTime.getSeconds();
								SelectTime = `${hours}:${minutes}:${seconds}`;
							}
							date = dealTimeOffset(SelectDate, SelectTime, item.DayOffset, item.MinuteOffset);

							let { dateFormatList, timeFormatList, allDateFormatList } = getTimeFormatList();
							let splitIndex = item.DataFormat.indexOf(' ');
							let index_d = -1;
							let index_t = -1;
							let format1 = '';
							let format2 = '';
							if (splitIndex > -1) {
								//存在空格
								format1 = item.DataFormat.substring(0, splitIndex);
								format2 = item.DataFormat.substring(splitIndex + 1);
								index_d = dateFormatList.findIndex(val => val == format1);
								index_t = timeFormatList.findIndex(val => val == format2);
							} else {
								index_d = dateFormatList.findIndex(val => val == item.DataFormat);
								//处理非中文状态下打开模板，部分日期格式不显示的问题
								if (index_d == -1) {
									let all_index_d = allDateFormatList.findIndex(val => val == item.DataFormat);
									if (all_index_d > -1) {
										format1 = item.DataFormat;
									}
								}
								index_t = timeFormatList.findIndex(val => val == item.DataFormat);
							}

							let DataFormat = '';
							let TimeFormat = '';
							let DateFormatChecked = [];

							if (index_d > -1) {
								DataFormat = dateFormatList[index_d];
								DateFormatChecked.push('date');
							} else {
								if (format1) {
									DataFormat = format1;
									DateFormatChecked.push('date');
								}
							}
							if (index_t > -1) {
								TimeFormat = timeFormatList[index_t];
								DateFormatChecked.push('time');
							}

							Data = formatDate(date, DataFormat, TimeFormat);
							if (item.TextArrange == 1) {
								this.drawVerticalText(timeDateNode, item, Data);
							} else {
								this.drawHorizontalText(timeDateNode, item, Data);
							}

							width = timeDateNode.width();
							height = timeDateNode.height();
							//根据左上角坐标计算出实际点坐标
							timeDateNode.x(item.AngleRound == 90 ? x + height : item.AngleRound == 180 ? x + width : x);
							timeDateNode.y(item.AngleRound == 180 ? y + height : item.AngleRound == 270 ? y + width : y);

							this.elementGroup.add(timeDateNode);

							BoxWidth = item.TextArrange == 1 ? pxToMm(timeDateNode.height() * timeDateNode.scaleY(), 2) : pxToMm(timeDateNode.width() * timeDateNode.scaleX(), 2);
							timeDateNode.setAttr('parameter', {
								Id: timeDateNode.id(),
								Type: item.Type,
								Name: item.Name,
								StartX: pxToMm(x, 2),
								StartY: pxToMm(y, 2),
								Width: pxToMm(timeDateNode.width() * timeDateNode.scaleX(), 2),
								Height: pxToMm(timeDateNode.height() * timeDateNode.scaleY(), 2),
								BoxWidth,
								Lock: booleanChange(item.Lock),
								Data,
								DataSourceType: 0,
								Rotate: timeDateNode.rotation(),
								AntiBlack: booleanChange(item.AntiBlack),
								MirrorImage: booleanChange(item.MirrorImage),
								FontFamily: item.Font['@FontFamily'],
								FontSize: dataPrecision((item.Font['@FontSize'] / this.mmToPoint) * this.magnification, 1),
								Bold: font_style.indexOf('bold') > -1 ? true : false,
								Italic: font_style.indexOf('italic') > -1 ? true : false,
								UnderLine: text_decoration.indexOf('underline') > -1 ? true : false,
								DeleteLine: text_decoration.indexOf('line-through') > -1 ? true : false,
								TextAlign: item.TextAlign,
								LineHeight: Math.round((item.RowSpacing1 / this.mmToPoint) * 100) / 100,
								LetterSpacing: Math.round((item.WordSpacing / this.mmToPoint) * 100) / 100,
								AutoWrap: booleanChange(item.AutoWrap),
								TextArrange: item.TextArrange,
								TimeType: Number(item.TimeDate),
								DataFormat,
								TimeFormat,
								DayOffset: Number(item.DayOffset),
								MinuteOffset: Number(item.MinuteOffset),
								SelectDate,
								SelectTime,
								DateFormatChecked
							});
							break;
						case 'GeneralBarcode':
						case 'QRCode':
							try {
								let Localtype =
									item.Localtype == 'BOTTOMLEFT' || item.Localtype == 'TOPLEFT'
										? 0
										: item.Localtype == 'BOTTOMRIGHT' || item.Localtype == 'TOPRIGHT'
										? 2
										: item.Localtype == 'BOTTOMJUSTIFY' || item.Localtype == 'TOPJUSTIFY'
										? 3
										: 1;
								let fontSize = item.Type == 'GeneralBarcode' ? (item.Font['@FontSize'] / this.mmToPoint) * this.magnification : 12;
								item.DataSourceList = Array.isArray(item.DataSourceList) ? item.DataSourceList : [item.DataSourceList];
								item.DataSourceList.map(value => {
									Data += value.FirstValue;
									value.InitialValue = value.DataSourceType == 2 ? value.InitialValue : '';
									value.AddedValue = value.DataSourceType == 2 ? Number(value.AddedValue) : '';
									value.Quantity = value.DataSourceType == 2 ? Number(value.Quantity) : '';

									value.ColumnName = value.DataSourceType == 1 ? value.ColumnName : '';
									value.QuerySql = value.DataSourceType == 1 ? value.QuerySql : '';
									value.TableName = value.DataSourceType == 1 ? value.TableName : '';
									value.SheetIndex = [-1, -1];
									value.DataSourceType = Number(value.DataSourceType);
									value.SequenceType = Number(value.SequenceType);
									value.DataType = Number(value.DataType);
									value.SequenceType = Number(value.SequenceType);
								});
								let codeList = item.Type == 'GeneralBarcode' ? getBarCodeTypeList() : item.Type == 'QRCode' ? getQrCodeTypeList() : [];
								let data = codeList.find(val => val.value == item.BarcodeType);
								let options = {
									bcid: data ? data.bcid : item.Type == 'GeneralBarcode' ? 'code128' : 'qrcode',
									text: Data,
									scale: 12,
									includetext: booleanChange(item.HasLabel), //是否显示文本
									textxalign: getTextAlignValue(Localtype), //left center right justify
									textsize: fontSize,
									textyalign: item.DisplaPosition == 5 || item.DisplaPosition == 6 || item.DisplaPosition == 7 || item.DisplaPosition == 8 ? 'above' : 'bottom' //above bottom
								};
								if (item.Type == 'GeneralBarcode') {
									options.height = 8;
								} else {
									if (options.bcid == 'qrcode' || options.bcid == 'pdf417') {
										let eclevelList = getEclevelList(item.BarcodeType);
										if (eclevelList.length > 0) {
											let eclevel = eclevelList.find(val => val.label == item.ECLevelBits);
											options.eclevel = eclevel ? eclevel.value : eclevelList[2].value;
										}
									}
								}
								let barcodeImg = new Konva.Image({
									id: item.Id,
									type: item.Type,
									x: (item.StartX / this.mmToPoint) * this.magnification,
									y: (item.StartY / this.mmToPoint) * this.magnification,
									rotation: Number(item.AngleRound) == 270 ? -90 : Number(item.AngleRound),
									draggable: false
								});
								this.elementGroup.add(barcodeImg);

								barcodeImg.setAttr('options', options);
								barcodeImg.setAttr('parameter', {
									Id: barcodeImg.id(),
									Type: barcodeImg.attrs.type,
									Name: item.Name,
									Lock: booleanChange(item.Lock),
									Data: options.text,
									Rotate: barcodeImg.rotation(),
									FontSize: dataPrecision(options.textsize, 1),
									BarcodeType: item.BarcodeType,
									TextAlign: Localtype,
									BarcodeNumPosition: options.includetext ? (options.textyalign == 'above' ? 2 : 1) : 0,
									QRCodeWidth: barcodeImg.width() / item.Zoom,
									ECLevelBits: options.eclevel,
									DataSourceList: item.DataSourceList,
									dataSourceIndex: 0,
								});

								let codeImg = await getGenerateCodeImg(barcodeImg);
								barcodeImg.setAttrs({
									image: codeImg,
									imageSrc: codeImg.src,
									width: codeImg.width,
									height: codeImg.height,
									scaleX: ((item.Width / this.mmToPoint) * this.magnification) / codeImg.width,
									scaleY: ((item.Height / this.mmToPoint) * this.magnification) / codeImg.height
								});
								x = barcodeImg.x();
								y = barcodeImg.y();
								width = barcodeImg.width() * barcodeImg.scaleX();
								height = barcodeImg.height() * barcodeImg.scaleY();
								//根据左上角坐标计算出实际点坐标
								barcodeImg.x(item.AngleRound == 90 ? x + height : item.AngleRound == 180 ? x + width : x);
								barcodeImg.y(item.AngleRound == 180 ? y + height : item.AngleRound == 270 ? y + width : y);
								barcodeImg.attrs.parameter.StartX = pxToMm(x, 2);
								barcodeImg.attrs.parameter.StartY = pxToMm(y, 2);
								barcodeImg.attrs.parameter.Width = pxToMm(barcodeImg.width() * barcodeImg.scaleX(), 2);
								barcodeImg.attrs.parameter.Height = pxToMm(barcodeImg.height() * barcodeImg.scaleY(), 2);
								if (this.type == 'print') {
									await this.getExcelCloudData(barcodeImg);
								}
								this.designLayer.draw();
							} catch (e) {
								console.log(e);
							}
							break;
						case 'Image':
							x = (item.StartX / this.mmToPoint) * this.magnification;
							y = (item.StartY / this.mmToPoint) * this.magnification;
							width = (item.Width / this.mmToPoint) * this.magnification;
							height = (item.Height / this.mmToPoint) * this.magnification;
							const drawImg = () => {
								return new Promise((resolve, reject) => {
									let image = new Image();
									image.setAttribute('crossOrigin', 'Anonymous');
									image.onload = async () => {
										let imageObj = new Konva.Image({
											id: item.Id,
											type: item.Type,
											x,
											y,
											width,
											height,
											rotation: Number(item.AngleRound) == 270 ? -90 : Number(item.AngleRound),
											image,
											imageSrc: image.src,
											scaleX: 1,
											scaleY: 1,
											draggable: false
										});
										this.elementGroup.add(imageObj);
										//根据左上角坐标计算出实际点坐标
										imageObj.x(item.AngleRound == 90 ? x + height : item.AngleRound == 180 ? x + width : x);
										imageObj.y(item.AngleRound == 180 ? y + height : item.AngleRound == 270 ? y + width : y);
										imageObj.setAttr('parameter', {
											Id: imageObj.id(),
											Type: imageObj.attrs.type,
											Name: item.Name,
											StartX: pxToMm(x, 2),
											StartY: pxToMm(y, 2),
											Width: pxToMm(imageObj.width() * imageObj.scaleX(), 2),
											Height: pxToMm(imageObj.height() * imageObj.scaleY(), 2),
											Lock: booleanChange(item.Lock),
											Rotate: imageObj.rotation(),
											imageMode: 0,
											ISParticipating: booleanChange(item.ISParticipating)
										});
										this.designLayer.draw();
										resolve();
									};
									image.src = this.imgHeader + item.OriginalImage;
								});
							};
							await drawImg();
							break;
						case 'LineHorizontal':
						case 'LineVertical':
						case 'Line':
							let StartX = (item.StartX / this.mmToPoint) * this.magnification;
							let StartY = (item.StartY / this.mmToPoint) * this.magnification;
							let EndX = (item.EndX / this.mmToPoint) * this.magnification;
							let EndY = (item.EndY / this.mmToPoint) * this.magnification;
							x = StartX < EndX ? StartX : EndX;
							y = StartY < EndY ? StartY : EndY;
							let lineNode = new Konva.Line({
								id: item.Id,
								type: item.Type,
								x,
								y,
								points: [StartX - x, StartY - y, EndX - x, EndY - y],
								stroke: '#000000',
								strokeWidth: (item.PenWidth / this.mmToPoint) * this.magnification,
								dash: [3,1],
								dashEnabled: item.DashStyle == 0 ? false : true,
								hitStrokeWidth: 10,
								draggable: false
							});
							this.elementGroup.add(lineNode);

							let LineDirection = item.Type;
							if (item.Type == 'Line') {
								if (item.StartX < item.EndX && item.StartY < item.EndY) {
									LineDirection = 'RightLine';
								} else {
									LineDirection = 'LeftLine';
								}
							}
							lineNode.setAttr('parameter', {
								Id: lineNode.id(),
								Type: lineNode.attrs.type,
								Lock: booleanChange(item.Lock),
								StartX: pxToMm(lineNode.x(), 2),
								StartY: pxToMm(lineNode.y(), 2),
								EndX: pxToMm(EndX, 2),
								EndY: pxToMm(EndY, 2),
								Width: pxToMm(lineNode.width() * lineNode.scaleX(), 2),
								Height: pxToMm(lineNode.height() * lineNode.scaleY(), 2),
								PenWidth: Math.round((lineNode.strokeWidth() / this.magnification) * 10) / 10,
								Dash: lineNode.dashEnabled(),
								DashStyle: item.DashStyle,
								LineDirection //LineHorizontal水平 LineVertical垂直  LeftLine左斜线 RightLine右斜线
							});
							break;
						case 'Rectangle':
            case 'SolidRectangle':
						case 'RoundRectangle':
							let rectNode = new Konva.Rect({
								id: item.Id,
								type: item.Type == 'SolidRectangle' ? 'Rectangle' : item.Type,
								x: ((item.StartX + item.PenWidth / 2) / this.mmToPoint) * this.magnification,
								y: ((item.StartY + item.PenWidth / 2) / this.mmToPoint) * this.magnification,
								width: ((item.Width - item.PenWidth) / this.mmToPoint) * this.magnification,
								height: ((item.Height - item.PenWidth) / this.mmToPoint) * this.magnification,
								fill: booleanChange(item.Fill) || item.Type == 'SolidRectangle' ? '#000000' : '',
								stroke: 'black',
								strokeWidth: (item.PenWidth / this.mmToPoint) * this.magnification,
								dash: [3,1],
								dashEnabled: item.DashStyle == 0 ? false : true,
								rotation: 0,
								cornerRadius: item.Type == 'RoundRectangle' ? (item.CornerRadius / this.mmToPoint) * this.magnification : 0,
								draggable: false
							});
							this.elementGroup.add(rectNode);
							rectNode.setAttr('parameter', {
								Id: rectNode.id(),
								Type: rectNode.attrs.type,
								Name: item.Name,
								StartX: pxToMm(rectNode.x(), 2),
								StartY: pxToMm(rectNode.y(), 2),
								Width: pxToMm(rectNode.width() * rectNode.scaleX(), 2),
								Height: pxToMm(rectNode.height() * rectNode.scaleY(), 2),
								Lock: booleanChange(item.Lock),
								Rotate: rectNode.rotation(),
								PenWidth: Math.round((rectNode.strokeWidth() / this.magnification) * 10) / 10,
								CornerRadius: Math.round((rectNode.cornerRadius() / this.magnification) * 10) / 10,
								Dash: rectNode.dashEnabled(),
								DashStyle: item.DashStyle, //0实线 1虚线
								Fill: item.Type == 'SolidRectangle' ? true : booleanChange(item.Fill)
							});
							break;
						case 'Ellipse':
							x = ((item.StartX + item.Width / 2) / this.mmToPoint) * this.magnification;
							y = ((item.StartY + item.Height / 2) / this.mmToPoint) * this.magnification;

							let ellipseNode = new Konva.Ellipse({
								id: item.Id,
								type: item.Type,
								x,
								y,
								width: (item.Width / this.mmToPoint) * this.magnification,
								height: (item.Height / this.mmToPoint) * this.magnification,
								radiusX: ((item.Width / this.mmToPoint) * this.magnification) / 2,
								radiusY: ((item.Height / this.mmToPoint) * this.magnification) / 2,
								fill: booleanChange(item.Fill) ? '#000000' : '',
								stroke: 'black',
								strokeWidth: (item.PenWidth / this.mmToPoint) * this.magnification,
								dash: [3,1],
								dashEnabled: item.DashStyle == 0 ? false : true,
								rotation: 0,
								draggable: false
							});
							this.elementGroup.add(ellipseNode);
							ellipseNode.setAttr('parameter', {
								Id: ellipseNode.id(),
								Type: ellipseNode.attrs.type,
								Name: item.Name,
								StartX: pxToMm(ellipseNode.x() - ellipseNode.radiusX() * ellipseNode.scaleX(), 2),
								StartY: pxToMm(ellipseNode.y() - ellipseNode.radiusY() * ellipseNode.scaleY(), 2),
								Width: pxToMm(ellipseNode.width() * ellipseNode.scaleX(), 2),
								Height: pxToMm(ellipseNode.height() * ellipseNode.scaleY(), 2),
								Lock: booleanChange(item.Lock),
								Rotate: ellipseNode.rotation(),
								PenWidth: Math.round((ellipseNode.strokeWidth() / this.magnification) * 10) / 10,
								Dash: ellipseNode.dashEnabled(),
								DashStyle: item.DashStyle,
								Fill: booleanChange(item.Fill)
							});
							break;
						case 'Diamond':
							x = (item.StartX / this.mmToPoint) * this.magnification;
							y = (item.StartY / this.mmToPoint) * this.magnification;

							let diamond_width = (item.Width / this.mmToPoint) * this.magnification;
							let diamond_height = (item.Height / this.mmToPoint) * this.magnification;

							let diamondNode = new Konva.Line({
								id: item.Id,
								type: item.Type,
								x,
								y,
								width: diamond_width,
								height: diamond_height,
								points: [0, diamond_height / 2, diamond_width / 2, 0, diamond_width, diamond_height / 2, diamond_width / 2, diamond_height],
								closed: true,
								fill: booleanChange(item.Fill) ? '#000000' : '',
								stroke: 'black',
								strokeWidth: (item.PenWidth / this.mmToPoint) * this.magnification,
								dash: [3,1],
								dashEnabled: item.DashStyle == 0 ? false : true,
								rotation: 0,
								scaleX: 1,
								scaleY: 1,
								draggable: false
							});
							this.elementGroup.add(diamondNode);
							diamondNode.setAttr('parameter', {
								Id: diamondNode.id(),
								Type: diamondNode.attrs.type,
								Name: item.Name,
								StartX: pxToMm(diamondNode.x(), 2),
								StartY: pxToMm(diamondNode.y(), 2),
								Width: pxToMm(diamondNode.width() * diamondNode.scaleX(), 2),
								Height: pxToMm(diamondNode.height() * diamondNode.scaleY(), 2),
								Lock: booleanChange(item.Lock),
								Rotate: diamondNode.rotation(),
								PenWidth: Math.round((diamondNode.strokeWidth() / this.magnification) * 10) / 10,
								Dash: diamondNode.dashEnabled(),
								DashStyle: item.DashStyle,
								Fill: booleanChange(item.Fill)
							});
							break;
						case 'Triangle':
							x = (item.StartX / this.mmToPoint) * this.magnification;
							y = (item.StartY / this.mmToPoint) * this.magnification;

							let triangle_width = (item.Width / this.mmToPoint) * this.magnification;
							let triangle_height = (item.Height / this.mmToPoint) * this.magnification;

							let triangleNode = new Konva.Line({
								id: item.Id,
								type: item.Type,
								x,
								y,
								width: triangle_width,
								height: triangle_height,
								points: [0, triangle_height, triangle_width / 2, 0, triangle_width, triangle_height],
								closed: true,
								fill: booleanChange(item.Fill) ? '#000000' : '',
								stroke: 'black',
								strokeWidth: (item.PenWidth / this.mmToPoint) * this.magnification,
								dash: [3,1],
								dashEnabled: item.DashStyle == 0 ? false : true,
								rotation: 0,
								scaleX: 1,
								scaleY: 1,
								draggable: false
							});
							this.elementGroup.add(triangleNode);

							triangleNode.setAttr('parameter', {
								Id: triangleNode.id(),
								Type: triangleNode.attrs.type,
								Name: item.Name,
								StartX: pxToMm(triangleNode.x(), 2),
								StartY: pxToMm(triangleNode.y(), 2),
								Width: pxToMm(triangleNode.width() * triangleNode.scaleX(), 2),
								Height: pxToMm(triangleNode.height() * triangleNode.scaleY(), 2),
								Lock: booleanChange(item.Lock),
								StartX: triangleNode.x(),
								StartY: triangleNode.y(),
								Rotate: triangleNode.rotation(),
								PenWidth: Math.round((triangleNode.strokeWidth() / this.magnification) * 10) / 10,
								Dash: triangleNode.dashEnabled(),
								DashStyle: item.DashStyle,
								Fill: booleanChange(item.Fill)
							});
							break;
						case 'Table':
							x = (item.StartX / this.mmToPoint) * this.magnification;
							y = (item.StartY / this.mmToPoint) * this.magnification;
							let tableWidth = (item.Width / this.mmToPoint) * this.magnification;
							let tableHeight = (item.Height / this.mmToPoint) * this.magnification;
							let strokeWidth = (item.PenWidth / this.mmToPoint) * this.magnification;
							let tableNode = new Konva.Group({
								id: item.Id,
								type: item.Type,
								x,
								y,
								width: tableWidth,
								height: tableHeight,
								rotation: Number(item.AngleRound) == 270 ? -90 : Number(item.AngleRound),
								scaleX: 1,
								scaleY: 1,
								draggable: true
							});
							let DicLineLocW = item.DicLineLocW ? item.DicLineLocW.split(',') : [];
							let DicLineLocH = item.DicLineLocH ? item.DicLineLocH.split(',') : [];
							let row = DicLineLocH.length + 1;
							let column = DicLineLocW.length + 1;

							let CellWidth = []; //记录每一列的宽度
							let CellHeight = []; //记录每一行的高度
							DicLineLocW = DicLineLocW.map(val => {
								return (val / this.mmToPoint) * this.magnification;
							});
							DicLineLocW.unshift(0);
							DicLineLocW.push(tableWidth - strokeWidth);
							DicLineLocH = DicLineLocH.map(val => {
								return (val / this.mmToPoint) * this.magnification;
							});
							DicLineLocH.unshift(0);
							DicLineLocH.push(tableHeight - strokeWidth);

							for (let i = 1; i < DicLineLocW.length; i++) {
								let val = DicLineLocW[i] - DicLineLocW[i - 1] - strokeWidth;
								CellWidth.push(val);
							}
							for (let i = 1; i < DicLineLocH.length; i++) {
								let val = DicLineLocH[i] - DicLineLocH[i - 1] - strokeWidth;
								CellHeight.push(val);
							}

							//绘制横线
							let tableRowLine = new Konva.Group({
								name: 'tableRowLine'
							});
							tableNode.add(tableRowLine);
							for (let i = 0; i <= row; i++) {
								let y = DicLineLocH[i];
								let rowLine = new Konva.Rect({
									name: `rowLine_${i}`,
									x: 0,
									y,
									width: tableWidth,
									height: strokeWidth,
									fill: '#000000',
									hitFunc: context => {
										context.beginPath();
										context.rect(0, y, tableWidth, strokeWidth);
										context.closePath();
									}
								});
								tableRowLine.add(rowLine);
							}
							//绘制竖线
							let tableColumnLine = new Konva.Group({
								name: 'tableColumnLine'
							});
							tableNode.add(tableColumnLine);
							for (let i = 0; i <= column; i++) {
								let x = DicLineLocW[i];
								let columnLine = new Konva.Rect({
									name: `columnLine_${i}`,
									x,
									y: 0,
									width: strokeWidth,
									height: tableHeight,
									fill: '#000000',
									hitFunc: context => {
										context.beginPath();
										context.rect(x, 0, strokeWidth, tableHeight);
										context.closePath();
									}
								});
								tableColumnLine.add(columnLine);
							}
							//绘制单元格
							let cellGroup = new Konva.Group({
								name: 'cellGroup',
								x: strokeWidth,
								y: strokeWidth
							});
							tableNode.add(cellGroup);

							item.DataSourceList = Array.isArray(item.DataSourceList) ? item.DataSourceList : [item.DataSourceList];
							let DataSourceList = item.DataSourceList.map(data => {
								let allPos = data.Key.split('|');
								for (let i = 0; i < allPos.length; i++) {
									allPos[i] = changeType(allPos[i].split(','));
								}
								let pos = allPos[0];

								let cell_x = DicLineLocW[pos[1]];
								let cell_y = DicLineLocH[pos[0]];
								let cellWidth = CellWidth[pos[1]];
								let cellHeight = CellHeight[pos[0]];

								if (allPos.length > 1) {
									//合并的单元格
									const { minRow, maxRow, minColumn, maxColumn } = getCellRang(allPos);
									cellWidth = sumData(CellWidth.slice(minColumn, maxColumn + 1)) + strokeWidth * (maxColumn - minColumn);
									cellHeight = sumData(CellHeight.slice(minRow, maxRow + 1)) + strokeWidth * (maxRow - minRow);
								}

								let cell = new Konva.Group({
									name: `cell_${pos[0]}_${pos[1]}`,
									x: cell_x,
									y: cell_y,
									width: cellWidth,
									height: cellHeight,
									pos,
									allPos,
									clipWidth: cellWidth,
									clipHeight: cellHeight
								});
								let cellBg = new Konva.Rect({
									name: 'cellBg',
									pos,
									x: 0,
									y: 0,
									width: cellWidth,
									height: cellHeight,
									fill: booleanChange(data.AntiBlack) ? '#000000' : '#FFFFFF',
									antiBlack: booleanChange(data.AntiBlack)
								});
								cell.add(cellBg);

								let { font_style, text_decoration } = setFontStyle(data.Font['@FontStyle']);

								lineHeight = ((data.RowSpacing1 / this.mmToPoint) * this.magnification) / ((data.Font['@FontSize'] / this.mmToPoint) * this.magnification) + 1;

								let cellText = new Konva.Text({
									name: 'cellText',
									pos,
									x: booleanChange(data.MirrorImage) ? cellWidth : 0,
									y: 0,
									width: cellWidth,
									height: cellHeight,
									text: data.Data,
									fontFamily: data.Font['@FontFamily'],
									fontSize: (data.Font['@FontSize'] / this.mmToPoint) * this.magnification,
									fontStyle: font_style,
									textDecoration: text_decoration,
									align: getTextAlignValue(data.TextAlign),
									verticalAlign: 'middle',
									lineHeight,
									letterSpacing: (data.WordSpacing / this.mmToPoint) * this.magnification,
									fill: booleanChange(data.AntiBlack) ? '#FFFFFF' : '#000000',
									scaleX: booleanChange(data.MirrorImage) ? -1 : 1,
									scaleY: 1,
									wrap: 'char'
								});
								cell.add(cellText);

								let cellSelect = new Konva.Rect({
									name: 'cellSelect',
									pos,
									x: 0,
									y: 0,
									width: cellWidth,
									height: cellHeight,
									fill: '#3F80F5',
									opacity: 0.1,
									visible: pos[0] == 0 && pos[1] == 0 ? true : false
								});
								cellSelect.hide();
								cell.add(cellSelect);
								cellSelect.moveToTop();

								cell.setAttr('parameter', {
									AllKeys: cell.attrs.allPos, //存放合并的单元格坐标
									Key: cell.attrs.pos,
									StartX: Math.round((cell.x() / this.magnification) * 100) / 100,
									StartY: Math.round((cell.y() / this.magnification) * 100) / 100,
									Width: Math.round((cell.width() / this.magnification) * 100) / 100,
									Height: Math.round((cell.height() / this.magnification) * 100) / 100,
									FirstValue: cellText.text() ? cellText.text() : '',
									SequenceType: Number(data.SequenceType),
									InitialValue: data.InitialValue,
									AddedValue: Number(data.AddedValue),
									Quantity: Number(data.Quantity),
									ColumnName: data.ColumnName,
									QuerySql: data.QuerySql,
									TableName: data.TableName,
									ConnectionStr: data.ConnectionStr,
									DataSourceType: Number(data.DataSourceType),
									AutoWrap: booleanChange(data.AutoWrap),
									AntiBlack: booleanChange(data.AntiBlack),
									MirrorImage: booleanChange(data.MirrorImage),
									FontFamily: cellText.fontFamily(),
									FontSize: dataPrecision(cellText.fontSize(), 1),
									Bold: cellText.fontStyle().indexOf('bold') > -1 ? true : false,
									Italic: cellText.fontStyle().indexOf('italic') > -1 ? true : false,
									UnderLine: cellText.textDecoration().indexOf('underline') > -1 ? true : false,
									DeleteLine: cellText.textDecoration().indexOf('line-through') > -1 ? true : false,
									TextAlign: data.TextAlign,
									LineHeight: Math.round((data.RowSpacing1 / this.mmToPoint) * 100) / 100,
									LetterSpacing: Math.round((cellText.letterSpacing() / this.magnification) * 100) / 100,
									SheetIndex: [-1, -1],
									DataBase: []
								});

								cellGroup.add(cell);
								return cell.attrs.parameter;
							});

							tableNode.setAttr('parameter', {
								Id: tableNode.id(),
								Type: tableNode.attrs.type,
								Name: item.Name,
								StartX: pxToMm(tableNode.x(), 2),
								StartY: pxToMm(tableNode.y(), 2),
								Width: pxToMm(tableNode.width() * tableNode.scaleX(), 2),
								Height: pxToMm(tableNode.height() * tableNode.scaleY(), 2),
								Lock: booleanChange(item.Lock),
								Rotate: tableNode.rotation(),
								DataSourceList, //记录单元格数据
								DicLineLocW, //记录列线
								DicLineLocH, //记录行线
								Row: row, //行数
								Column: column, //列数
								CellWidth, //记录每列单元格的宽度
								CellHeight, //记录每行单元格的高度
								cellMultiple: false, //是否开启多选
								mulCellPos: [], //记录多选的单元格的坐标
								currentPos: [0, 0], //当前选中单元格的坐标
								dataSourceIndex: 0, //当前选中单元格的下标
								PenWidth: Math.round((strokeWidth / this.magnification) * 10) / 10 //边框粗细
							});

							this.elementGroup.add(tableNode);
							if (this.type == 'print') {
								await this.getExcelCloudData(tableNode);
							}
							break;
					}
				}
				this.designLayer.draw();

				if (this.type == 'print') {
					let { multipleNodeData, minCount } = this.getMultipleNode();
					this.multipleNodeData = multipleNodeData;
					this.$emit('getInfo', {
						multipleNodeData,
						minCount
					});
				}
				this.$emit('ready');
			},

			//绘制横向文本
			drawHorizontalText(node, item, Data) {
				let lineHeight = ((item.RowSpacing1 / this.mmToPoint) * this.magnification) / ((item.Font['@FontSize'] / this.mmToPoint) * this.magnification) + 1;

				item.TextPrefix = item.TextPrefix ? item.TextPrefix : '';
				item.TextSuffix = item.TextSuffix ? item.TextSuffix : '';

				let textStr = Data;
				if (node.attrs.type == 'WinText') {
					textStr = item.TextPrefix + Data + item.TextSuffix;
				}

				const { font_style, text_decoration } = setFontStyle(item.Font['@FontStyle']);

				let text = new Konva.Text({
					id: node.id(),
					text: textStr,
					fontFamily: item.Font['@FontFamily'],
					fontSize: (item.Font['@FontSize'] / this.mmToPoint) * this.magnification,
					fontStyle: font_style,
					textDecoration: text_decoration,
					align: getTextAlignValue(item.TextAlign),
					lineHeight,
					letterSpacing: (item.WordSpacing / this.mmToPoint) * this.magnification,
					fill: booleanChange(item.AntiBlack) ? '#FFFFFF' : '#000000',
					scaleX: booleanChange(item.MirrorImage) ? -1 : 1,
					scaleY: 1,
					wrap: 'char'
				});
				node.add(text);
				let textWidth = text.width();
        let oldWidth = booleanChange(item.AutoWrap) ? item.BoxWidth : item.Width
				let width = (oldWidth / this.mmToPoint) * this.magnification;
				let scale = width / textWidth;
				if (booleanChange(item.AutoWrap)) {
					if (item.TextAlign == 4) {
						text.width(textWidth);
						node.width(textWidth);
						node.scaleX(scale);
					} else {
						text.width(width);
						node.width(width);
					}
				} else {
					if (item.TextAlign == 4) {
						node.scaleX(scale);
					}
					text.width(textWidth);
					node.width(textWidth);
				}
				//翻转重置文本坐标
				if (booleanChange(item.MirrorImage)) {
					text.x(width);
				}
			},
			//绘制竖向文本
			drawVerticalText(node, item, Data) {
				item.TextPrefix = item.TextPrefix ? item.TextPrefix : '';
				item.TextSuffix = item.TextSuffix ? item.TextSuffix : '';
				let textStr = Data;
				if (node.attrs.type == 'WinText') {
					textStr = item.TextPrefix + Data + item.TextSuffix;
				}
				let textArr = textStr.split(/\r?\n/);

				const { font_style, text_decoration } = setFontStyle(item.Font['@FontStyle']);

				let fontSize = (item.Font['@FontSize'] / this.mmToPoint) * this.magnification;
				let letterSpacing = (item.WordSpacing / this.mmToPoint) * this.magnification;
				let lineHeight = ((item.RowSpacing1 / this.mmToPoint) * this.magnification) / ((item.Font['@FontSize'] / this.mmToPoint) * this.magnification) + 1;

				let wordHeight = fontSize + letterSpacing; //每个字的高度+字间距
				let columnText = []; //每列的内容

				let maxWordNum = 0;
				textArr.forEach(item => {
					maxWordNum = Math.max(maxWordNum, item.length);
				});
				let textHeight = maxWordNum * fontSize + letterSpacing * maxWordNum;

				let oldHeight = booleanChange(item.AutoWrap) ? item.BoxWidth : item.Height
				let height = (oldHeight / this.mmToPoint) * this.magnification;
				let scale = height / textHeight;

				if (booleanChange(item.AutoWrap) && item.TextAlign != 4) {
					//自动换行
					height = (item.Height / this.mmToPoint) * this.magnification;
					let charsPerColumn = Math.floor(height / wordHeight); // 每列可以容纳的字符数
					for (let i = 0; i < textArr.length; i++) {
						let count = Math.ceil(textArr[i].length / charsPerColumn);
						if (count > 1) {
							for (let j = 0; j < textArr[i].length; j += charsPerColumn) {
								columnText.push(textArr[i].slice(j, j + charsPerColumn));
							}
						} else {
							columnText.push(textArr[i]);
						}
					}
				} else {
					columnText = textArr;
					height = textHeight;
					if (item.TextAlign == 4) {
						node.scaleY(scale);
					}
				}

				let columnCount = columnText.length; //总列数
				// 计算总宽度
				let width = columnCount * fontSize + lineHeight * (columnCount - 1);
				node.width(width);
				node.height(height);
				node.getTag().width(width);
				node.getTag().height(height);
				//text1 用来撑开高度，label会取第一个text的高度作为整体的高度
				let text1 = new Konva.Text({
					name: 'tempText',
					x: 0,
					y: 0,
					width,
					height
				});
				text1.hide();
				node.add(text1);
				let textGroup = new Konva.Group({
					name: 'textGroup',
					x: booleanChange(item.MirrorImage) ? width : 0,
					y: 0,
					scaleX: booleanChange(item.MirrorImage) ? -1 : 1,
					scaleY: 1,
					width: width,
					height
				});
				node.add(textGroup);
				let currentX = width - fontSize;
				let regex1 = /^[0-9a-zA-Z]*$/;
				let regex2 = /[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·#（）“”‘|《》、【】[\]]/;
				for (let i = 0; i < columnText.length; i++) {
					let currentY = item.TextAlign == 1 ? (height - wordHeight * columnText[i].length) / 2 : item.TextAlign == 2 ? height - wordHeight * columnText[i].length : 0;
					for (let j = 0; j < columnText[i].length; j++) {
						let text = new Konva.Text({
							x: currentX + Math.ceil(fontSize) / 2,
							y: currentY + Math.ceil(fontSize) / 2,
							offsetX: Math.ceil(fontSize) / 2,
							offsetY: Math.ceil(fontSize) / 2,
							width: Math.ceil(fontSize), //向上取整，防止字体实际大小超出我们所预设的宽高导致文字无法显示的问题
							height: Math.ceil(fontSize),
							text: columnText[i][j],
							fontSize: fontSize,
							fontFamily: item.Font['@FontFamily'],
							fontStyle: font_style,
							textDecoration: text_decoration,
							rotation: regex1.test(columnText[i][j]) || regex2.test(columnText[i][j]) ? 90 : 0,
							align: 'center',
							lineHeight: 1,
							letterSpacing: 0,
							fill: booleanChange(item.AntiBlack) ? '#ffffff' : '#000000',
							scaleX: 1,
							scaleY: 1
						});
						currentY += fontSize + letterSpacing;
						if (item.TextAlign == 3) {
							//平铺
							let space = (height - wordHeight * columnText[i].length) / (columnText[i].length - 1);
							currentY += space;
						}
						textGroup.add(text);
					}
					currentX -= fontSize + lineHeight;
				}
			},

			//处理初始化excel表格数据
			getExcelCloudData(node) {
				return new Promise(async (resolve, reject) => {
					let parameter = node.attrs.parameter;
					let DataSourceList = parameter.DataSourceList;
					for (let i = 0; i < DataSourceList.length; i++) {
						let list = DataSourceList[i];
						let DataSourceType = list.DataSourceType;
						let ColumnName = list.ColumnName;
						let TableName = list.TableName;
						let ConnectionStr = list.ConnectionStr;
						if (DataSourceType != 1) {
							continue;
						}
						if (!ColumnName) {
							list.DataSourceType = 0;
							continue;
						}
						const reg = new RegExp('^(http|https)://', 'i');
						var match = reg.test(ConnectionStr);
						//云端数据
						if (match) {
							let sheetList = [];
							let ConnectionData = [];
							let res = await this.getSheet(ConnectionStr);
							TableName = TableName ? TableName : res[0].sheetName; //TableName为空时默认取第一张表的数据
							res.forEach((item, index) => {
								let obj = {
									text: item.sheetName,
									index,
									children: []
								};
								if (item.data.length > 0) {
									item.data.forEach((column, c_index) => {
										obj.children.push({
											text: column[0] ? column[0] : `col_`,
											index: c_index,
											column: column.slice(1)
										});
										if (item.sheetName == TableName && column[0] == ColumnName) {
											ConnectionData = column.slice(1);
											list.SheetIndex = [index, c_index];
										}
									});
								} else {
									obj.children.push({
										text: '暂无数据',
										index: 0,
										column: []
									});
								}
								sheetList.push(obj);
							});

							list.DataBase = sheetList;
							list.ConnectionData = ConnectionData;
						} else {
							//非云端数据,转成普通文本
							list.DataSourceType = 0;
							list.ColumnName = '';
							list.QuerySql = '';
							list.TableName = '';
							list.ConnectionStr = '';
						}
					}

					node.attrs.parameter.DataSourceList = DataSourceList;
					resolve();
				});
			},
			//请求初始化云端数据表内容
			getSheet(url) {
				return new Promise((resolve, reject) => {
					this.$axioshanma(
						'/api/tem.new_class/import',
						{
							FileUrl: url,
							RowIndex: 0,
							TableIndex: -1 //传-1可拿到所有的表数据
						},
						{
							custom: { loading: false }
						}
					)
						.then(res => {
							resolve(res);
						})
						.catch(err => {
							reject();
						});
				});
			},

			//处理递变数据和excel表格数据
			getMultipleNode() {
				let multipleNodeData = [];
				let countArr = [];
				let minCount = 1;
				let nodeInfo = [];
				//获取最小长度
				this.elementGroup.children.forEach(node => {
					let type = node.attrs.type;
					if (type == 'WinText' || type == 'GeneralBarcode' || type == 'QRCode' || type == 'Table') {
						let DataSourceList = node.attrs.parameter.DataSourceList;
						let arr = [];
						let isMult = false;
						DataSourceList.forEach((item, index) => {
							let DataSourceType = item.DataSourceType;
							if (DataSourceType == 1 || DataSourceType == 2) {
								isMult = true;
								if (DataSourceType == 1) {
									//数据库
									countArr.push(item.ConnectionData.length);
								} else {
									countArr.push(Number(item.Quantity));
								}
								arr.push(index); //DataSourceList下标
							}
						});
						if (isMult) {
							nodeInfo.push({
								id: node.id(), //元素id
								indexArr: arr
							});
						}
					}
				});

				if (countArr.length > 0) {
					//获取最小长度
					minCount = Math.min(...countArr);
					// console.log('minCount', minCount);

					//单元格排序
					const dealTableCell = (cell_1, cell_2) => {
						if (cell_1.Key[0] !== cell_2.Key[0]) return cell_1.Key[0] < cell_2.Key[0] ? -1 : 1;
						else if (cell_1.Key[1] !== cell_2.Key[1]) return cell_1.Key[1] < cell_2.Key[1] ? -1 : 1;
					};

					nodeInfo.forEach(info => {
						let node = this.elementGroup.find(`#${info.id}`)[0];
						if (node) {
							let parameter = node.attrs.parameter;
							let DataSourceList = node.attrs.parameter.DataSourceList;
							if (node.attrs.type == 'Table') {
								DataSourceList.sort(dealTableCell);
							}
							let arr = [];
							for (let i = 0; i < info.indexArr.length; i++) {
								let dataSourceIndex = info.indexArr[i];
								let data = DataSourceList[dataSourceIndex];
								let DataSourceType = data.DataSourceType;
								if (DataSourceType == 1) {
									arr.push({
										dataSourceIndex,
										data: data.ConnectionData.slice(0, minCount)
									});
								} else if (DataSourceType == 2) {
									let SequenceType = data.SequenceType;
									let valArr = [];
									for (let i = 0; i < minCount; i++) {
										let sequenceValue = '';
										let value = '';
										if (SequenceType == 1) {
											//十六进制
											sequenceValue = (parseInt(data.FirstValue, 16) + data.AddedValue * i).toString(16);
											value = setBeforeZero(16, sequenceValue, data.FirstValue);
										} else if (SequenceType == 2) {
											//二十六进制
											sequenceValue = convertToTwentySix(convertToTen(data.FirstValue) + data.AddedValue * i);
											value = setBeforeZero(26, sequenceValue, data.FirstValue);
										} else if (SequenceType == 3) {
											//三十六进制
											sequenceValue = (parseInt(data.FirstValue, 36) + data.AddedValue * i).toString(36);
											value = setBeforeZero(36, sequenceValue, data.FirstValue);
										} else {
											//十进制
											sequenceValue = (data.FirstValue * 1 + data.AddedValue * i).toString();
											value = setBeforeZero(10, sequenceValue, data.FirstValue);
										}
										valArr.push(value);
									}
									arr.push({
										dataSourceIndex,
										data: valArr
									});
								}
							}
							multipleNodeData.push({
								id: info.id,
								name: parameter.Name,
								data: arr
							});
						}
					});
          multipleNodeData = this.dealReferenceData(multipleNodeData);
				}
				return { multipleNodeData, minCount };
			},
      //处理数据库序列化关联元素打印
			dealReferenceData(multipleNodeData) {
				const traverseArray = (dataArray,dataSourceList) => {
					let result = [];
					for (let i = 0; i < dataArray[0].data.length; i++) {
						let combinedValue = '';
						for (let j = 0; j < dataSourceList.length;j++) {
              let source = dataSourceList[j]
              if(source.DataSourceType == 0){
                //普通文本
                combinedValue += source.FirstValue;
              }else if(source.DataSourceType == 1 || source.DataSourceType == 2){
                let data = dataArray.find(val => val.dataSourceIndex == j)
                combinedValue += data.data[i];
              }
						}
						result.push(combinedValue);
					}

					return result;
				};
				this.elementGroup.children.forEach(node => {
					if (node.attrs.type == 'WinText' || node.attrs.type == 'GeneralBarcode' || node.attrs.type == 'QRCode') {
						let parameter = node.attrs.parameter;
						let arr = [];
						parameter.DataSourceList.forEach((val, dataSourceIndex) => {
							if (val.DataSourceType == 3) {
								//存在元素值，且元素值绑定的值为递变数据或数据库数据
								let reference_index = multipleNodeData.findIndex(item => item.id == val.ReferenceId);
                let reference_node = this.elementGroup.find(`#${multipleNodeData[reference_index].id}`)[0]
                let reference_DataSourceList = reference_node.attrs.parameter.DataSourceList

								if (reference_index > -1) {
									arr.push({
										dataSourceIndex,
										data: traverseArray(multipleNodeData[reference_index].data,reference_DataSourceList)
									});
								}
							}
						});
						if (arr.length > 0) {
							multipleNodeData.push({
								id: node.id(),
								name: parameter.Name,
								data: arr
							});
						}
					}
				});
				return multipleNodeData;
			},
			//是否显示背景
			showBackground(value) {
				this.isShowBackground = value;
			},
			setMultipleNodeData(list) {
				this.multipleNodeData = JSON.parse(JSON.stringify(list));
			},
			//绘制当前的图片
			draw({ index, drawType = 'preview' }) {
				return new Promise(async (resolve, reject) => {
					for (let i = 0; i < this.multipleNodeData.length; i++) {
						let item = this.multipleNodeData[i];
						let node = this.elementGroup.find(`#${item.id}`)[0];
						if (node) {
							let type = node.attrs.type;
							let value = '';
							for (let j = 0; j < item.data.length; j++) {
								let dataSourceIndex = item.data[j].dataSourceIndex;
								let data = item.data[j].data;
								node.attrs.parameter.DataSourceList[dataSourceIndex].FirstValue = data[index];
								if (type == 'Table') {
									let key = node.attrs.parameter.DataSourceList[dataSourceIndex].Key;
									let cell = node.find(`.cell_${key[0]}_${key[1]}`)[0];
									if (cell) {
										let cellText = cell.find('.cellText')[0];
										cellText.text(data[index]);
									}
								}
							}

							switch (type) {
								case 'WinText':
									value = combinationData(node);
									node.attrs.parameter.Data = value;
									if (node.attrs.parameter.TextArrange == 1) {
										drawVerticalText(node, this.designLayer);
									} else {
										value = node.attrs.parameter.TextPrefix + value + node.attrs.parameter.TextSuffix;
										node.getText().text(value);
									}
									break;
								case 'GeneralBarcode':
								case 'QRCode':
									value = combinationData(node);
									node.attrs.parameter.Data = value;
									node.attrs.options.text = value;
									try {
										let codeImg = await getGenerateCodeImg(node);
										node.setAttrs({
											image: codeImg,
											imageSrc: codeImg.src,
											width: codeImg.width,
											height: codeImg.height
										});
									} catch (err) {}
									break;
							}
							this.designLayer.draw();
						}
					}
					let background = this.designLayer.find('#background')[0];
					let backgroundImg = this.designLayer.find('#backgroundImg')[0];
					let backgroundCanvas = this.designLayer.find('#backgroundCanvas')[0];
					if (this.labelAttr.background && this.isShowBackground) {
						backgroundImg.show();
						background.fill('');
					} else {
						backgroundImg.hide();
						background.fill('#fff');
					}
					if (drawType == 'print') {
						//隐藏耗材底图
						backgroundCanvas.hide();
					} else {
						backgroundCanvas.show();
					}
					let dataURL = this.designStage.toDataURL({
						x: 0,
						y: 0,
						width: this.designArea.width() * this.designArea.scaleX(),
						height: this.designArea.height() * this.designArea.scaleY(),
						quality: 1,
						pixelRatio: 3,
						mimeType: 'image/png'
					});
					let highPixelImg = changeDpiDataUrl(dataURL, 300);
					// console.log(highPixelImg)
					resolve(highPixelImg);
				});
			}
		}
	};
</script>
<style scoped>
	.node-container-box {
		position: fixed;
		left: -200%;
		top: -200%;
		pointer-events: none;
		z-index: -10;
		display: none;
	}
</style>
