<template>
  <div class="memberDia" v-show="showMemberDia" @click="showMemberDia = false">
    <div class="main-box" @click.stop="">
      <CloseOutlined class="close-btn" @click.stop="showMemberDia = false" />
      <img src="../../static/icon/member.png" alt="" />
      <div class="text">{{ text }}</div>
      <el-button
        @click.stop="router.push('member')"
        v-if="VUE_APP_VERSION == 'hprt'"
        >{{ $t(`button.upgradeMember`) }}</el-button>
      <el-button @click.stop="showMemberDia = false" v-else>{{
        $t(`button.confirm`)
      }}</el-button>
    </div>
  </div>
</template>
<script>
import { useRouter } from "vue-router";
import { CloseOutlined } from "@ant-design/icons-vue";
import {
  defineComponent,
  watch,
  getCurrentInstance,
  toRefs,
  reactive,
} from "vue";

export default defineComponent({
  components: {
    CloseOutlined,
  },
  setup(props, context) {
    const router = useRouter();
    const { proxy } = getCurrentInstance();
    let globalProperties =
      getCurrentInstance().appContext.config.globalProperties;

    const state = reactive({
      showMemberDia: false,
      text: "",
      VUE_APP_VERSION: process.env.VUE_APP_VERSION,
    });

    const show = (value) => {
      state.showMemberDia = true;
      state.text = value;
    };

    return {
      router,
      ...toRefs(state),
      show,
    };
  },
});
</script>
<style scoped>
.memberDia {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-box {
  width: 400px;
  min-height: 400px;
  background: #ffffff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
}
.close-btn {
  position: absolute;
  right: 30px;
  top: 30px;
  font-size: 20px;
  color: #fccf8a;
}
.text {
  padding: 0 28px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: #1a1d21;
  line-height: 28px;
}
.main-box ::v-deep(.el-button) {
  margin-bottom: 24px;
  width: 340px;
  height: 40px;
  background: linear-gradient(97deg, #fce8c9 0%, #fccf8a 100%);
  border-radius: 4px;
  border-color: #fce8c9;
  font-size: 16px;
  font-weight: 500;
  color: #864510;
}
@media only screen and (max-width: 1280px) {
}
</style>
