<template>
	<a-config-provider :locale="locale">
		<div id="app">
			<router-view v-slot="{ Component, route }">
				<transition :name="route.meta.transition">
					<component :is="Component" />
				</transition>
			</router-view>
			<!-- <router-view v-slot="{ Component }">
        <keep-alive>
          <component :is="Component" v-if="keepAlive" />
        </keep-alive>
        <component :is="Component" />
      </router-view> -->

			<!-- <transition v-on:before-enter="beforeEnter" v-on:before-leave="beforeLeave" :name="fold">
      <router-view v-wechat-title="$route.meta.title" v-if="isRouterAlive" />
    </transition> -->
			<p class="p" style="position: absolute; top: -200%; left: -200%">获取最小字号</p>
		</div>
	</a-config-provider>
</template>

<script>
	import { defineComponent, nextTick, ref, getCurrentInstance, watch, reactive, onMounted } from 'vue';
	import zh from 'ant-design-vue/lib/locale-provider/zh_CN';
	import en from 'ant-design-vue/lib/locale-provider/en_GB';
	import pt from 'ant-design-vue/lib/locale-provider/pt_PT';
	import es from 'ant-design-vue/lib/locale-provider/es_ES';
	import EditLabelVue from './views/editLabel/EditLabel.vue';
	// import { getFontFamily } from '@/utils/fontFamily'
	export default defineComponent({
		name: 'App',
		setup() {
			const { proxy } = getCurrentInstance();
			onMounted(() => {
				// window.document.title = i18n.global.t(label)
				nextTick(() => {
					setTimeout(() => {
						// getMinFontsize();
						let label = proxy.$route.meta.label;
						window.document.title = proxy.$t(label);
						// console.log(proxy.$route.meta.label);
					}, 2000);
				});
			});

			const getLanguage = () => {
				switch (proxy.$i18n.locale) {
					case 'zh':
						proxy.locale = zh;
						var label = proxy.$route.meta.label;
						window.document.title = proxy.$t(label);
						break;
					case 'en':
						proxy.locale = en;
						var label = proxy.$route.meta.label;
						window.document.title = proxy.$t(label);
						break;
					case 'pt':
						proxy.locale = pt;
						var label = proxy.$route.meta.label;
						window.document.title = proxy.$t(label);
						break;
					case 'es':
						proxy.locale = es;
						var label = proxy.$route.meta.label;
						window.document.title = proxy.$t(label);
						break;
					default:
						proxy.locale = zh;
				}
			};

			//获取浏览器最小字号
			// const getMinFontsize = () => {
			//   let el = document.querySelector(".p");
			//   let heightRecord = "";
			//   let fontSizeRecord = "";

			//   // 循环迭代到最小字体
			//   for (let i = 20; i > 5; i--) {
			//     el.style.fontSize = `${i}px`;
			//     const height = window
			//       .getComputedStyle(el, null)
			//       .getPropertyValue("height");
			//     const font = window
			//       .getComputedStyle(el, null)
			//       .getPropertyValue("font-size");

			//     if (i === 20) {
			//       // 第一次执行不操作后面
			//       heightRecord = height;
			//       fontSizeRecord = font;
			//       continue;
			//     }
			//     // console.log(heightRecord + `-` + height, fontSizeRecord + `-` + font);
			//     // 如果上次的元素大小、字体大小一致  即  表明这次就为最小值
			//     if (heightRecord !== height && font !== fontSizeRecord) {
			//       heightRecord = height;
			//       fontSizeRecord = font;
			//       // 为了验证这里我没有添加break
			//       // break; 性能~
			//     }
			//   }
			//   let minFontSize = fontSizeRecord.replace("px", "");
			//   localStorage.setItem("minFontSize", minFontSize);
			// };

			return {
				getLanguage,
				locale: ref(en)
			};
		}
	});
</script>
<style>
	html,
	body,
	#app {
		height: 100%;
	}
</style>
