<template>
	<div class="edit-print" id="editLabel" @mousedown="handleEditMousedown">
		<ToolArea
			ref="toolAreaRef"
			:designStage="designStage"
			:designArea="designArea"
			:designLayer="designLayer"
			:elementGroup="elementGroup"
			:labelAttr="labelAttr"
			:elementAttr="elementAttr"
			:historyList="historyList"
			:historyIndex="historyIndex"
			@click="$refs.toolAreaRef.closeRightMenu()" />

		<ElementArea ref="elementAreaRef" :designStage="designStage" :designArea="designArea" :designLayer="designLayer" :elementGroup="elementGroup" @click="$refs.toolAreaRef.closeRightMenu()" @mousedown.stop.prevent=""/>

		<!-- 画布操作区域 -->
		<div class="edit-print-box" id="editBox" @contextmenu.prevent="">
			<div id="desgin_container" @click.stop=""></div>
		</div>

		<AttrsDia v-if="showAttrPanel" ref="attrsDiaRef" :elementAttr="elementAttr" :designArea="designArea" :designLayer="designLayer" @click="$refs.toolAreaRef.closeRightMenu()" />

		<PrintLabel ref="PrintLabelRef" />
		<EditGuide v-if="showEditGuide" @close="closeEditGuide" />
		<MemberDia ref="MemberDiaRef" />
		<CloudImgDia ref="cloudImgDiaRef" @update="handleUpdateImg" />
	</div>
</template>
<script>
	import MemberDia from '@/components/MemberDia.vue';
	import { message } from 'ant-design-vue';
	import { ElMessage, ElMessageBox, ElLoading } from 'element-plus';
	import axios from 'axios/dist/axios.min.js';
	import PrintLabel from '@/components/PrintLabel.vue';
	import EditGuide from '@/components/EditGuide.vue';
	import CloudImgDia from '@/components/CloudImgDia.vue';
	import './css/edit.css';
	import './css/edit-small.css';
	import {
		getTextAlignValue,
		booleanChange,
		setFontStyle,
		getCodeType,
		getBarCodeTypeList,
		getQrCodeTypeList,
		getFontFamily,
		getGenerateCodeImg,
		changeType,
		getCellRang,
		sumData,
		pxToMm,
		getEclevelList,
		getNowDateOrTime,
		getTimeFormatList,
		dealTimeOffset,
		formatDate,
		dataPrecision,
    setSystemTime
	} from './js/common.js';
	import { TransformerListening, destroyTransformer, updateTransformer } from './js/transformerStyle';
	import { drawSketchRuler, sketchRulerMove, sketchRulerReset } from './js/sketchRuler.js';

	import AttrsDia from './components/AttrsDia.vue';
	import ElementArea from './components/ElementArea.vue';
	import ToolArea from './components/ToolArea.vue';

	export default {
		components: {
			PrintLabel,
			EditGuide,
			MemberDia,
			AttrsDia,
			ElementArea,
			CloudImgDia,
			ToolArea
		},
		data() {
			return {
				userMemberInfo: '', //记录用户会员信息
				VUE_APP_VERSION: process.env.VUE_APP_VERSION,
				origionCodeImage: '', //用来存储错误二维码的底图
				showEditGuide: true, //显示新手指导
				configuration: [], //配置文件大小
				loadingInstance: '',
				//七牛云token
				postData: {
					token: '',
					url: ''
				},
				imgHeader: 'data:image/png;base64,',
				renameLabel: {
					renameVisible: false,
					renameType: 'label',
					renameId: '',
					renameValue: ''
				},
				userInfo: '',
				language: this.$i18n.locale,
				userClassList: [], //个人模板文件夹
				designStage: {},
				designLayer: {},
				designArea: {},
				elementGroup: {},
				sketchRulerLayer: {},
				mmToPoint: 3.96, // 1mm约等于3.96点 (31.7/8)  (203dpi(pc端采用203dpi进行计算)) 用于与pc端的数据转换
				magnification: 3.78, //1mm 约等于3.78px（96dpi）   像素 = 毫米 * ( 分辨率 / 25.4)
				UnitConversion: 1, //单位毫米时为1，1英寸等于25.4毫米，英寸时为25.4, 将英寸换算成毫米
				MeasureUnit: 'Mm', //标签单位
				multiple: 1,
				canvasWidth: 0,
				canvasHeight: 0,
				labelAttr: [],
				labelStatus: 'add', //标签状态：新建/编辑
				selectNode: '',
				flag: true,
				isRestSize: true, //是否允许编辑标签尺寸
				historyIndex: -1,
				historyList: [],
				elementAttr: '',
				showAttrPanel: false,
				canvasArr: [], //标签画布数组
				canvasIndex: 0,
				labelJsonData: '',
				isDraw: false,
        systemTimer: null, //系统时间定时器
			};
		},
		created() {
			this.configuration = JSON.parse(localStorage.getItem('configuration'));
			this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
		},

		mounted() {
			window.addEventListener('beforeunload', this.beforeunloadTip);
			this.getQiniuToken();
			this.getMemberUser();
			this.getUserClassList();
     

			//第一次使用编辑器，显示新手引导
			let firstEdit = localStorage.getItem('firstEdit');
			if (firstEdit == 'true') {
				this.showEditGuide = true;
			} else {
				this.showEditGuide = false;
			}

			let editBox = document.getElementById('editBox');
			this.$nextTick(() => {
				this.canvasWidth = editBox.offsetWidth;
				this.canvasHeight = editBox.offsetHeight;
				this.designStage = new Konva.Stage({
					id: 'stage',
					container: 'desgin_container',
					width: this.canvasWidth,
					height: this.canvasHeight
				});

				this.designLayer = new Konva.Layer();
				this.designStage.add(this.designLayer);
				this.designLayer.draw();

				if (this.$route.query.labelAttr) {
					this.labelStatus = this.$route.query.labelStatus;
					this.labelAttr = JSON.parse(decodeURIComponent(this.$route.query.labelAttr));
					console.log('画布信息：', this.labelAttr);

					if (this.labelStatus == 'edit') {
						//编辑标签
						localStorage.removeItem('labelBgImgInfo');
						this.OpenLabel();
					} else {
						//新建标签
						this.MeasureUnit = this.labelAttr.unit;
						if (this.MeasureUnit.toLowerCase() == 'inch') {
							this.UnitConversion = 25.4;
						} else {
							this.UnitConversion = 1;
						}
						//获取背景图片
						let labelBgImgInfo = localStorage.getItem('labelBgImgInfo');
						if (labelBgImgInfo) {
							labelBgImgInfo = JSON.parse(labelBgImgInfo);
							if (labelBgImgInfo.name == this.labelAttr.name) {
								this.labelAttr.background = labelBgImgInfo.background;
							} else {
								localStorage.removeItem('labelBgImgInfo');
							}
						}
						//创建画布
						this.editInit();
					}
				}
			});
		},

		watch: {
			elementAttr() {
				if (JSON.stringify(this.elementAttr) === '{}') {
					this.showAttrPanel = false;
				} else {
					this.showAttrPanel = true;
				}
			}
		},

		methods: {
			beforeunloadTip(e) {
				if (this.elementGroup.children.length > 0 && this.isDraw) {
					e = e || window.event;
					e.returnValue = '内容未保存';
					return e;
				}
			},
      handleEditMousedown(){
        if (this.$refs.toolAreaRef) {
					this.$refs.toolAreaRef.showShortcutKey = false;
				}
        if(this.$refs.elementAreaRef){
          this.$refs.elementAreaRef.showIcons = false
          this.$refs.elementAreaRef.showRects = false
        }
      },
			//获取用户会员信息
			getMemberUser() {
				this.$axioshanma('/api/tem.app_member/getMemberUser', {
					user_id: this.userInfo.userid
				}).then(res => {
					this.userMemberInfo = res;
				});
			},
			//获取模板默认文件夹的ID
			getUserClassList() {
				this.$axioshanma('/api/tem.app_public/getUserClassList', {
					user_id: this.userInfo.userid
				}).then(res => {
					this.userClassList = res;
					if (this.labelStatus == 'add' || this.labelStatus == 'common') {
						this.labelAttr.parent_id = this.userClassList[0].id;
					}
				});
			},
			//获取七牛云token
			getQiniuToken() {
				//获取七牛云token
				let data = {
					bucket: this.VUE_APP_VERSION == 'hprt' ? 'hm-hprt' : 'na-hprt'
				};
				this.$axioshanma('/api/tem.app_shared/getToken', data)
					.then(res => {
						this.postData.token = res.data;
						this.postData.url = res.url;
					})
					.catch(() => {});
			},
			//画布初始化
			editInit() {
				let width = this.labelAttr.width * this.UnitConversion * this.magnification;
				let height = this.labelAttr.height * this.UnitConversion * this.magnification;
				let scaleX = (this.canvasWidth * 0.6) / width;
				let scaleY = (this.canvasHeight * 0.6) / height;
				let multiple = scaleX < scaleY ? scaleX : scaleY;
				this.multiple = Math.round(multiple * 10) / 10;

				//元素属性框的宽度+边距
				let clientWidth = document.body.clientWidth;
				let attrBoxWidth = clientWidth <= 1280 ? 320 : 476;
				let x = -(width - (this.canvasWidth - attrBoxWidth)) / 2 + width / 2;
				let y = -(height - this.canvasHeight) / 2 + height / 2;

				x = x < 50 + (width * this.multiple) / 2 ? 50 + (width * this.multiple) / 2 : x;

				this.designArea = new Konva.Group({
					id: 'designArea',
					x,
					y,
					offsetX: width / 2,
					offsetY: height / 2,
					width,
					height,
					scaleX: this.multiple,
					scaleY: this.multiple,
					draggable: true
				});

				let background = new Konva.Rect({
					id: 'background',
					x: 0,
					y: 0,
					width,
					height,
					fill: '#fff'
				});
				this.designArea.add(background);

				let backgroundCanvas = new Konva.Image({
					id: 'backgroundCanvas',
					x: 0,
					y: 0,
					width,
					height,
					image: '',
					imageSrc: ''
				});
				this.designArea.add(backgroundCanvas);

				let backgroundImg = new Konva.Image({
					id: 'backgroundImg',
					x: 0,
					y: 0,
					width,
					height,
					image: '',
					imageSrc: ''
				});
				this.designArea.add(backgroundImg);

				if (this.labelAttr.background) {
					let image = new Image();
					image.setAttribute('crossOrigin', 'Anonymous');
					image.onload = e => {
						backgroundImg.image(image);
						backgroundImg.attrs.imageSrc = this.imgHeader + this.labelAttr.background;
						background.fill('');
						this.designLayer.draw();
					};
					image.src = this.imgHeader + this.labelAttr.background;
				}

				this.elementGroup = new Konva.Group({
					id: 'elementGroup',
					x: 0,
					y: 0,
					width,
					height
				});
				this.designArea.add(this.elementGroup);

				this.designLayer.add(this.designArea);
				//绘制标尺
				drawSketchRuler(this);
				this.designLayer.draw();
				// console.log(this.designLayer);
        setSystemTime(this)
				this.layerListening();
				//监听窗口变化，自适应
				window.addEventListener('resize', this.fitStageIntoParentContainer);
			},
			//元素监听，事件处理
			layerListening() {
				let isTouchMove = false;
				let startX, startY;

				const handleMouseStart = evt => {
					// console.log(evt)
					// buttons 1为鼠标左键，2为鼠标右键
					let buttons = evt.evt.buttons;
					let id = evt.target.id();
					if (buttons == 2) {
						//显示右键菜单
						this.$refs.toolAreaRef.isShowMenu = true;
						this.$refs.toolAreaRef.isShowAlign = false;
						this.$refs.toolAreaRef.isShowMenu = true;
						//计算右键菜单的显示位置
						let clientWidth = document.body.clientWidth;
						let attrBoxWidth = clientWidth <= 1280 ? 320 : 480;
						clientWidth = this.selectNode ? clientWidth - attrBoxWidth : clientWidth;
						let clientHeight = this.canvasHeight;

						this.$nextTick(() => {
							let rightMenuBox = document.getElementById('rightMenu');
							let boxWidth = rightMenuBox.offsetWidth;
							let boxHeight = rightMenuBox.offsetHeight;

							let clientX = evt.evt.clientX;
							let clientY = evt.evt.clientY;

							if (clientY + boxHeight > clientHeight) {
								this.$refs.toolAreaRef.rightMenu.posY = clientY - boxHeight - 5;
							} else {
								this.$refs.toolAreaRef.rightMenu.posY = clientY + 5;
							}

							if (clientX + boxWidth > clientWidth) {
								this.$refs.toolAreaRef.rightMenu.posX = clientX - boxWidth - 5;
							} else {
								this.$refs.toolAreaRef.rightMenu.posX = clientX + 5;
							}
						});

						return;
					}
					if (this.$refs.toolAreaRef.isShowMenu) {
						this.$refs.toolAreaRef.isShowMenu = false;
					}
					if (id == 'background' || id == 'backgroundImg' || id == 'backgroundCanvas' || id == 'stage') {
						if (!this.$refs.toolAreaRef.multipleSelection) {
							//非多选情况下
							destroyTransformer(this.designLayer);
							if (this.selectNode) {
								this.selectNode.draggable(false);
								if (this.selectNode.attrs.type == 'Table') {
									this.selectNode.attrs.parameter.mulCellPos = [];
									this.selectNode.attrs.parameter.cellMultiple = false;
								}
							}
							this.selectNode = '';
							this.elementAttr = {};
							let cellSelectArr = this.designLayer.find('.cellSelect');
							cellSelectArr.map(item => {
								item.hide();
							});
							this.designLayer.draw();
						} else {
							destroyTransformer(this.designLayer);
							this.$refs.toolAreaRef.multipleSelection = false;
							this.$refs.toolAreaRef.selectionElement = [];
						}

						isTouchMove = true;
						startX = evt.evt.clientX - this.designArea.x();
						startY = evt.evt.clientY - this.designArea.y();
					}
				};

				const handleMouseMove = evt => {
					if (!isTouchMove) return;
					this.designArea.x(evt.evt.clientX - startX);
					this.designArea.y(evt.evt.clientY - startY);
					sketchRulerMove(this);
					this.designLayer.batchDraw();
				};

				const handleMouseEnd = evt => {
					if (!isTouchMove) return;
					isTouchMove = false;
				};

				// 绑定事件监听器
				this.designStage.on('mousedown', handleMouseStart);
				this.designStage.on('mousemove', handleMouseMove);
				this.designStage.on('mouseup', handleMouseEnd);
				this.designArea.on('dragmove', evt => {
					sketchRulerMove(this);
				});
			},
			//监听窗口缩放，自适应
			fitStageIntoParentContainer() {
				if (this.$refs.toolAreaRef) {
					this.$refs.toolAreaRef.closeRightMenu();
				}
				let editBox = document.getElementById('editBox');
				this.canvasWidth = editBox.offsetWidth;
				this.canvasHeight = editBox.offsetHeight;

				let scaleX = (this.canvasWidth * 0.6) / this.designArea.width();
				let scaleY = (this.canvasHeight * 0.6) / this.designArea.height();
				let multiple = scaleX < scaleY ? scaleX : scaleY;
				this.multiple = Math.round(multiple * 10) / 10;
				//元素属性框的宽度+边距
				let clientWidth = document.body.clientWidth;
				let attrBoxWidth = clientWidth <= 1280 ? 320 : 476;
				let x = -(this.designArea.width() - (this.canvasWidth - attrBoxWidth)) / 2 + this.designArea.width() / 2;
				let y = -(this.designArea.height() - this.canvasHeight) / 2 + this.designArea.height() / 2;
				x = x < 50 + (this.designArea.width() * this.multiple) / 2 ? 50 + (this.designArea.width() * this.multiple) / 2 : x;

				this.designStage.width(this.canvasWidth);
				this.designStage.height(this.canvasHeight);
				this.designArea.x(x);
				this.designArea.y(y);
				this.designArea.scaleX(this.multiple);
				this.designArea.scaleY(this.multiple);
				this.designStage.draw();
				sketchRulerReset(this);
			},
			//打开标签，转成json数据
			OpenLabel() {
				var form = new FormData();
				form.append('url', this.labelAttr.file_pc);
				this.$axiosnet('/LoadXml/writeFile', form).then(res => {
					let regx = /^[\s\S]*\.tprts$/;
					if (regx.test(this.labelAttr.file_pc)) {
						//加密文件需要解密
						let jsonString = decodeURIComponent(escape(atob(res)));
						this.labelJsonData = JSON.parse(jsonString);
					} else {
						this.labelJsonData = JSON.parse(res);
					}

					console.log('标签数据：', this.labelJsonData);

					let DrawObject = this.labelJsonData.PrtLable.ObjectList.DrawObject;
					DrawObject = Array.isArray(DrawObject) ? DrawObject : [DrawObject];
					// console.log('初始元素数据：', DrawObject);
					let LabelPage = this.labelJsonData.PrtLable.PictureArea.LabelPage;

					let obj = {
						unit: LabelPage['@MeasureUnit'].toLowerCase(),
						background: LabelPage.Background,
						printBackground: booleanChange(LabelPage.PrintBackground),
						columns: LabelPage.Columns,
						rows: LabelPage.Rows,
						rowSpacing: LabelPage.RowSpacing,
						columnSpacing: LabelPage.ColumnSpacing,
						paddingTop: LabelPage.UpperMargin,
						paddingBottom: LabelPage.LowerMargin,
						paddingLeft: LabelPage.LeftMargin,
						paddingRight: LabelPage.RightMargin
					};
					this.labelAttr = Object.assign(this.labelAttr, obj);

					if (LabelPage['@MeasureUnit'].toLowerCase() == 'inch') {
						this.MeasureUnit = 'Inch';
						this.UnitConversion = 25.4;
					} else {
						this.MeasureUnit = 'Mm';
						this.UnitConversion = 1;
					}
					this.editInit();
					setTimeout(() => {
            //行业模板需要从外部带canvas_img数据进来
						if (this.labelAttr.type == "common") {
							LabelPage.Canvas = this.labelAttr.canvas_img;
						} else {
							LabelPage.Canvas = LabelPage.Canvas ? LabelPage.Canvas.split(',') : [];
						}
						this.canvasArr = LabelPage.Canvas;
						this.canvasIndex = 0;
						let background = this.designLayer.find('#background')[0];
						if (LabelPage.Canvas.length > 0) {
							//绘制耗材背景
							this.isRestSize = false;
							let backgroundCanvas = this.designLayer.find('#backgroundCanvas')[0];
							let image = new Image();
							image.setAttribute('crossOrigin', 'Anonymous');
							image.onload = e => {
								background.fill('');
								backgroundCanvas.image(image);
								backgroundCanvas.attrs.imageSrc = LabelPage.Canvas[0];
								this.designLayer.draw();
							};
							image.src = LabelPage.Canvas[this.canvasIndex];
						} else {
							this.isRestSize = true;
							background.fill('#FFFFFF');
						}
						this.generateNode(JSON.parse(JSON.stringify(DrawObject)));
					}, 10);
				});
			},
			//元素处理
			async generateNode(nodeList) {
				for (let i = 0; i < nodeList.length; i++) {
					let item = nodeList[i];
					let Tag;
					let text;
					let fontObj;
					let fontFamily;
					let BoxWidth;
					let Data = '';
					if (item.Font) {
						var { font_style, text_decoration } = setFontStyle(item.Font['@FontStyle']);
					}

					let x = 0;
					let y = 0;
					let width = 0;
					let height = 0;
					let lineHeight = 1;
					item.StartX = Number(item.StartX);
					item.StartY = Number(item.StartY);
					item.Width = item.Width ? Number(item.Width) : '';
					item.Height = item.Height ? Number(item.Height) : '';

					switch (item.Type) {
						case 'WinText':
							x = (item.StartX / this.mmToPoint) * this.magnification;
							y = (item.StartY / this.mmToPoint) * this.magnification;

							let textNode = new Konva.Label({
								id: item.Id,
								type: item.Type,
								x,
								y,
								rotation: Number(item.AngleRound) == 270 ? -90 : Number(item.AngleRound),
								scaleX: 1,
								scaleY: 1,
								draggable: false
							});
							Tag = new Konva.Tag({
								id: textNode.id(),
								fill: booleanChange(item.AntiBlack) ? '#000000' : ''
							});
							textNode.add(Tag);

							item.DataSourceList = Array.isArray(item.DataSourceList) ? item.DataSourceList : [item.DataSourceList];
							item.DataSourceList.forEach(value => {
								Data += value.FirstValue;
								value.InitialValue = value.DataSourceType == 2 ? value.InitialValue : '1';
								value.AddedValue = value.DataSourceType == 2 ? Number(value.AddedValue) : '';
								value.Quantity = value.DataSourceType == 2 ? Number(value.Quantity) : '';

								value.ColumnName = value.DataSourceType == 1 ? value.ColumnName : '';
								value.QuerySql = value.DataSourceType == 1 ? value.QuerySql : '';
								value.TableName = value.DataSourceType == 1 ? value.TableName : '';
								value.SheetIndex = [-1, -1];
								value.DataBase = [];
								value.DataSourceType = Number(value.DataSourceType);
								value.SequenceType = Number(value.SequenceType);
								value.DataType = Number(value.DataType);
								value.SequenceType = Number(value.SequenceType);
								value.NormalText = value.FirstValue;
							});

							if (item.TextArrange == 1) {
								this.drawVerticalText(textNode, item, Data);
							} else {
								this.drawHorizontalText(textNode, item, Data);
							}
							width = textNode.width();
							height = textNode.height();
							//根据左上角坐标计算出实际点坐标
							textNode.x(item.AngleRound == 90 ? x + height : item.AngleRound == 180 ? x + width : x);
							textNode.y(item.AngleRound == 180 ? y + height : item.AngleRound == 270 ? y + width : y);

							this.elementGroup.add(textNode);
							BoxWidth = item.TextArrange == 1 ? pxToMm(textNode.height() * textNode.scaleY(), 2) : pxToMm(textNode.width() * textNode.scaleX(), 2);
							textNode.setAttr('parameter', {
								Id: textNode.id(),
								Type: item.Type,
								Name: item.Name,
								StartX: pxToMm(x, 2),
								StartY: pxToMm(y, 2),
								Width: pxToMm(textNode.width() * textNode.scaleX(), 2),
								Height: pxToMm(textNode.height() * textNode.scaleY(), 2),
								BoxWidth,
								Lock: booleanChange(item.Lock),
								Rotate: textNode.rotation(),
								AntiBlack: booleanChange(item.AntiBlack),
								MirrorImage: booleanChange(item.MirrorImage),
								FontFamily: item.Font['@FontFamily'],
								FontSize: dataPrecision((item.Font['@FontSize'] / this.mmToPoint) * this.magnification, 1),
								Bold: font_style.indexOf('bold') > -1 ? true : false,
								Italic: font_style.indexOf('italic') > -1 ? true : false,
								UnderLine: text_decoration.indexOf('underline') > -1 ? true : false,
								DeleteLine: text_decoration.indexOf('line-through') > -1 ? true : false,
								TextAlign: item.TextAlign,
								LineHeight: Math.round((item.RowSpacing1 / this.mmToPoint) * 100) / 100,
								LetterSpacing: Math.round((item.WordSpacing / this.mmToPoint) * 100) / 100,
								AutoWrap: booleanChange(item.AutoWrap),
								Data,
								TextPrefix: item.TextPrefix,
								TextSuffix: item.TextSuffix,
								TextArrange: item.TextArrange,
								DataSourceList: item.DataSourceList,
								dataSourceIndex: 0
							});
							this.getExcelCloudData(textNode);
							TransformerListening(this, textNode);
							break;
						case 'TimeText':
							x = (item.StartX / this.mmToPoint) * this.magnification;
							y = (item.StartY / this.mmToPoint) * this.magnification;
							let timeDateNode = new Konva.Label({
								id: item.Id,
								type: item.Type,
								x,
								y,
								width,
								height,
								rotation: Number(item.AngleRound) == 270 ? -90 : Number(item.AngleRound),
								scaleX: 1,
								scaleY: 1,
								draggable: false
							});

							Tag = new Konva.Tag({
								id: timeDateNode.id(),
								fill: booleanChange(item.AntiBlack) ? '#000000' : ''
							});

							timeDateNode.add(Tag);

							let SelectDate = '';
							let SelectTime = '';
							let date = '';
							if (item.TimeDate == 0) {
								//系统时间
								SelectDate = getNowDateOrTime('date');
								SelectTime = getNowDateOrTime('time');
							} else {
								//指定时间
								let fixedTime = '';
								if (item.TimeStamp != '' && item.TimeStamp != 0) {
									fixedTime = new Date(Number(item.TimeStamp));
								} else {
									//TimeStamp不存在的话，根据data反推出时间
									let time = new Date(item.Data).getTime();
									let time1 = time - item.DayOffset * 24 * 60 * 60 * 1000 - item.MinuteOffset * 60 * 1000;
									fixedTime = new Date(time1);
								}
								let year = fixedTime.getFullYear();
								let month = fixedTime.getMonth() + 1;
								let date = fixedTime.getDate();
								SelectDate = `${year}-${month}-${date}`;

								let hours = fixedTime.getHours();
								let minutes = fixedTime.getMinutes();
								let seconds = fixedTime.getSeconds();
								SelectTime = `${hours}:${minutes}:${seconds}`;
							}
							date = dealTimeOffset(SelectDate, SelectTime, item.DayOffset, item.MinuteOffset);

							let { dateFormatList, timeFormatList, allDateFormatList } = getTimeFormatList();
							let splitIndex = item.DataFormat.indexOf(' ');
							let index_d = -1;
							let index_t = -1;
							let format1 = '';
							let format2 = '';
							if (splitIndex > -1) {
								//存在空格
								format1 = item.DataFormat.substring(0, splitIndex);
								format2 = item.DataFormat.substring(splitIndex + 1);
								index_d = dateFormatList.findIndex(val => val == format1);
								index_t = timeFormatList.findIndex(val => val == format2);
							} else {
								index_d = dateFormatList.findIndex(val => val == item.DataFormat);
								//处理非中文状态下打开模板，部分日期格式不显示的问题
								if (index_d == -1) {
									let all_index_d = allDateFormatList.findIndex(val => val == item.DataFormat);
									if (all_index_d > -1) {
										format1 = item.DataFormat;
									}
								}
								index_t = timeFormatList.findIndex(val => val == item.DataFormat);
							}

							let DataFormat = '';
							let TimeFormat = '';
							let DateFormatChecked = [];

							if (index_d > -1) {
								DataFormat = dateFormatList[index_d];
								DateFormatChecked.push('date');
							} else {
								if (format1) {
									DataFormat = format1;
									DateFormatChecked.push('date');
								}
							}
							if (index_t > -1) {
								TimeFormat = timeFormatList[index_t];
								DateFormatChecked.push('time');
							}
							Data = formatDate(date, DataFormat, TimeFormat);
							if (item.TextArrange == 1) {
								this.drawVerticalText(timeDateNode, item, Data);
							} else {
								this.drawHorizontalText(timeDateNode, item, Data);
							}

							width = timeDateNode.width();
							height = timeDateNode.height();
							//根据左上角坐标计算出实际点坐标
							timeDateNode.x(item.AngleRound == 90 ? x + height : item.AngleRound == 180 ? x + width : x);
							timeDateNode.y(item.AngleRound == 180 ? y + height : item.AngleRound == 270 ? y + width : y);

							this.elementGroup.add(timeDateNode);

							BoxWidth = item.TextArrange == 1 ? pxToMm(timeDateNode.height() * timeDateNode.scaleY(), 2) : pxToMm(timeDateNode.width() * timeDateNode.scaleX(), 2);
							timeDateNode.setAttr('parameter', {
								Id: timeDateNode.id(),
								Type: item.Type,
								Name: item.Name,
								StartX: pxToMm(x, 2),
								StartY: pxToMm(y, 2),
								Width: pxToMm(timeDateNode.width() * timeDateNode.scaleX(), 2),
								Height: pxToMm(timeDateNode.height() * timeDateNode.scaleY(), 2),
								BoxWidth,
								Lock: booleanChange(item.Lock),
								Data,
								DataSourceType: 0,
								Rotate: timeDateNode.rotation(),
								AntiBlack: booleanChange(item.AntiBlack),
								MirrorImage: booleanChange(item.MirrorImage),
								FontFamily: item.Font['@FontFamily'],
								FontSize: dataPrecision((item.Font['@FontSize'] / this.mmToPoint) * this.magnification, 1),
								Bold: font_style.indexOf('bold') > -1 ? true : false,
								Italic: font_style.indexOf('italic') > -1 ? true : false,
								UnderLine: text_decoration.indexOf('underline') > -1 ? true : false,
								DeleteLine: text_decoration.indexOf('line-through') > -1 ? true : false,
								TextAlign: item.TextAlign,
								LineHeight: Math.round((item.RowSpacing1 / this.mmToPoint) * 100) / 100,
								LetterSpacing: Math.round((item.WordSpacing / this.mmToPoint) * 100) / 100,
								AutoWrap: booleanChange(item.AutoWrap),
								TextArrange: item.TextArrange,
								TimeType: Number(item.TimeDate),
								DataFormat,
								TimeFormat,
								DayOffset: Number(item.DayOffset),
								MinuteOffset: Number(item.MinuteOffset),
								SelectDate,
								SelectTime,
								DateFormatChecked
							});
							TransformerListening(this, timeDateNode);
							break;
						case 'GeneralBarcode':
						case 'QRCode':
							try {
								let Localtype =
									item.Localtype == 'BOTTOMLEFT' || item.Localtype == 'TOPLEFT'
										? 0
										: item.Localtype == 'BOTTOMRIGHT' || item.Localtype == 'TOPRIGHT'
										? 2
										: item.Localtype == 'BOTTOMJUSTIFY' || item.Localtype == 'TOPJUSTIFY'
										? 3
										: 1;
								let fontSize = item.Type == 'GeneralBarcode' ? (item.Font['@FontSize'] / this.mmToPoint) * this.magnification : 12;
								item.DataSourceList = Array.isArray(item.DataSourceList) ? item.DataSourceList : [item.DataSourceList];
								item.DataSourceList.map(value => {
									Data += value.FirstValue;
									value.InitialValue = value.DataSourceType == 2 ? value.InitialValue : '1';
									value.AddedValue = value.DataSourceType == 2 ? Number(value.AddedValue) : '';
									value.Quantity = value.DataSourceType == 2 ? Number(value.Quantity) : '';

									value.ColumnName = value.DataSourceType == 1 ? value.ColumnName : '';
									value.QuerySql = value.DataSourceType == 1 ? value.QuerySql : '';
									value.TableName = value.DataSourceType == 1 ? value.TableName : '';
									value.DataBase = [];
									value.SheetIndex = [-1, -1];
									value.DataSourceType = Number(value.DataSourceType);
									value.SequenceType = Number(value.SequenceType);
									value.DataType = Number(value.DataType);
									value.SequenceType = Number(value.SequenceType);
									value.NormalText = value.FirstValue;
								});
								let codeList = item.Type == 'GeneralBarcode' ? getBarCodeTypeList() : item.Type == 'QRCode' ? getQrCodeTypeList() : [];
								let data = codeList.find(val => val.value == item.BarcodeType);
								let options = {
									bcid: data ? data.bcid : item.Type == 'GeneralBarcode' ? 'code128' : 'qrcode',
									text: Data,
									scale: 12,
									includetext: booleanChange(item.HasLabel), //是否显示文本
									textxalign: getTextAlignValue(Localtype), //left center right justify
									textsize: fontSize,
									textyalign: item.DisplaPosition == 5 || item.DisplaPosition == 6 || item.DisplaPosition == 7 || item.DisplaPosition == 8 ? 'above' : 'bottom' //above bottom
								};
								if (item.Type == 'GeneralBarcode') {
									options.height = 8;
								} else {
									if (options.bcid == 'qrcode' || options.bcid == 'pdf417') {
										let eclevelList = getEclevelList(item.BarcodeType);
										if (eclevelList.length > 0) {
											let eclevel = eclevelList.find(val => val.label == item.ECLevelBits);
											options.eclevel = eclevel ? eclevel.value : eclevelList[2].value;
										}
									}
								}

								let barcodeImg = new Konva.Image({
									id: item.Id,
									type: item.Type,
									x: (item.StartX / this.mmToPoint) * this.magnification,
									y: (item.StartY / this.mmToPoint) * this.magnification,
									rotation: Number(item.AngleRound) == 270 ? -90 : Number(item.AngleRound),
									draggable: false
								});
								this.elementGroup.add(barcodeImg);

								barcodeImg.setAttr('options', options);
								barcodeImg.setAttr('parameter', {
									Id: barcodeImg.id(),
									Type: barcodeImg.attrs.type,
									Name: item.Name,
									Lock: booleanChange(item.Lock),
									Data: options.text,
									Rotate: barcodeImg.rotation(),
									FontSize: dataPrecision(options.textsize, 1),
									BarcodeType: item.BarcodeType,
									TextAlign: Localtype,
									BarcodeNumPosition: options.includetext ? (options.textyalign == 'above' ? 2 : 1) : 0,
									QRCodeWidth: barcodeImg.width() / item.Zoom,
									ECLevelBits: options.eclevel,
									DataSourceList: item.DataSourceList,
									dataSourceIndex: 0,
									isEdit: false //用来判断条码内容是否编辑过
								});

								let codeImg = await getGenerateCodeImg(barcodeImg);
								barcodeImg.setAttrs({
									image: codeImg,
									imageSrc: codeImg.src,
									width: codeImg.width,
									height: codeImg.height,
									scaleX: ((item.Width / this.mmToPoint) * this.magnification) / codeImg.width,
									scaleY: ((item.Height / this.mmToPoint) * this.magnification) / codeImg.height
								});
								x = barcodeImg.x();
								y = barcodeImg.y();
								width = barcodeImg.width() * barcodeImg.scaleX();
								height = barcodeImg.height() * barcodeImg.scaleY();
								//根据左上角坐标计算出实际点坐标
								barcodeImg.x(item.AngleRound == 90 ? x + height : item.AngleRound == 180 ? x + width : x);
								barcodeImg.y(item.AngleRound == 180 ? y + height : item.AngleRound == 270 ? y + width : y);
								barcodeImg.attrs.parameter.StartX = pxToMm(x, 2);
								barcodeImg.attrs.parameter.StartY = pxToMm(y, 2);
								barcodeImg.attrs.parameter.Width = pxToMm(barcodeImg.width() * barcodeImg.scaleX(), 2);
								barcodeImg.attrs.parameter.Height = pxToMm(barcodeImg.height() * barcodeImg.scaleY(), 2);

								this.getExcelCloudData(barcodeImg);
								TransformerListening(this, barcodeImg);
								this.designLayer.draw();
							} catch (e) {
								console.log(e);
							}
							break;
						case 'Image':
							x = (item.StartX / this.mmToPoint) * this.magnification;
							y = (item.StartY / this.mmToPoint) * this.magnification;
							width = (item.Width / this.mmToPoint) * this.magnification;
							height = (item.Height / this.mmToPoint) * this.magnification;
							let image = new Image();
							image.setAttribute('crossOrigin', 'Anonymous');
							image.onload = async () => {
								let imageObj = new Konva.Image({
									id: item.Id,
									type: item.Type,
									x,
									y,
									width,
									height,
									rotation: Number(item.AngleRound) == 270 ? -90 : Number(item.AngleRound),
									image,
									imageSrc: image.src,
									scaleX: 1,
									scaleY: 1,
									draggable: false
								});
								this.elementGroup.add(imageObj);
								//根据左上角坐标计算出实际点坐标
								imageObj.x(item.AngleRound == 90 ? x + height : item.AngleRound == 180 ? x + width : x);
								imageObj.y(item.AngleRound == 180 ? y + height : item.AngleRound == 270 ? y + width : y);
								imageObj.setAttr('parameter', {
									Id: imageObj.id(),
									Type: imageObj.attrs.type,
									Name: item.Name,
									StartX: pxToMm(x, 2),
									StartY: pxToMm(y, 2),
									Width: pxToMm(imageObj.width() * imageObj.scaleX(), 2),
									Height: pxToMm(imageObj.height() * imageObj.scaleY(), 2),
									Lock: booleanChange(item.Lock),
									Rotate: imageObj.rotation(),
									imageMode: 0,
									ISParticipating: booleanChange(item.ISParticipating)
								});
								TransformerListening(this, imageObj);
								this.designLayer.draw();
							};
							image.src = this.imgHeader + item.OriginalImage;
							break;
						case 'LineHorizontal':
						case 'LineVertical':
						case 'Line':
							let StartX = (item.StartX / this.mmToPoint) * this.magnification;
							let StartY = (item.StartY / this.mmToPoint) * this.magnification;
							let EndX = (item.EndX / this.mmToPoint) * this.magnification;
							let EndY = (item.EndY / this.mmToPoint) * this.magnification;
							x = StartX < EndX ? StartX : EndX;
							y = StartY < EndY ? StartY : EndY;
							let lineNode = new Konva.Line({
								id: item.Id,
								type: item.Type,
								x,
								y,
								points: [StartX - x, StartY - y, EndX - x, EndY - y],
								stroke: '#000000',
								strokeWidth: (item.PenWidth / this.mmToPoint) * this.magnification,
								dash: [3,1],
								dashEnabled: item.DashStyle == 0 ? false : true,
								hitStrokeWidth: 10,
								draggable: false
							});
							this.elementGroup.add(lineNode);

							let LineDirection = item.Type;
							if (item.Type == 'Line') {
								if (item.StartX < item.EndX && item.StartY < item.EndY) {
									LineDirection = 'RightLine';
								} else {
									LineDirection = 'LeftLine';
								}
							}
							lineNode.setAttr('parameter', {
								Id: lineNode.id(),
								Type: lineNode.attrs.type,
								Lock: booleanChange(item.Lock),
								StartX: pxToMm(lineNode.x(), 2),
								StartY: pxToMm(lineNode.y(), 2),
								EndX: pxToMm(EndX, 2),
								EndY: pxToMm(EndY, 2),
								Width: pxToMm(lineNode.width() * lineNode.scaleX(), 2),
								Height: pxToMm(lineNode.height() * lineNode.scaleY(), 2),
								PenWidth: Math.round((lineNode.strokeWidth() / this.magnification) * 10) / 10,
								Dash: lineNode.dashEnabled(),
								DashStyle: item.DashStyle,
								LineDirection //LineHorizontal水平 LineVertical垂直  LeftLine左斜线 RightLine右斜线
							});
							TransformerListening(this, lineNode);
							break;
						case 'Rectangle':
            case 'SolidRectangle':
            case 'RoundRectangle':
							let rectNode = new Konva.Rect({
								id: item.Id,
								type: item.Type == 'SolidRectangle' ? 'Rectangle' : item.Type,
								x: ((item.StartX + item.PenWidth / 2) / this.mmToPoint) * this.magnification,
								y: ((item.StartY + item.PenWidth / 2) / this.mmToPoint) * this.magnification,
								width: ((item.Width - item.PenWidth) / this.mmToPoint) * this.magnification,
								height: ((item.Height - item.PenWidth) / this.mmToPoint) * this.magnification,
								fill: booleanChange(item.Fill) || item.Type == 'SolidRectangle' ? '#000000' : '',
								stroke: 'black',
								strokeWidth: (item.PenWidth / this.mmToPoint) * this.magnification,
								dash: [3,1],
								dashEnabled: item.DashStyle == 0 ? false : true,
								rotation: 0,
								cornerRadius: item.Type == 'RoundRectangle' ? (item.CornerRadius / this.mmToPoint) * this.magnification : 0,
								draggable: false
							});
							this.elementGroup.add(rectNode);
							rectNode.setAttr('parameter', {
								Id: rectNode.id(),
								Type: rectNode.attrs.type,
								Name: item.Name,
								StartX: pxToMm(rectNode.x(), 2),
								StartY: pxToMm(rectNode.y(), 2),
								Width: pxToMm(rectNode.width() * rectNode.scaleX(), 2),
								Height: pxToMm(rectNode.height() * rectNode.scaleY(), 2),
								Lock: booleanChange(item.Lock),
								Rotate: rectNode.rotation(),
								PenWidth: Math.round((rectNode.strokeWidth() / this.magnification) * 10) / 10,
								CornerRadius: Math.round((rectNode.cornerRadius() / this.magnification) * 10) / 10,
								Dash: rectNode.dashEnabled(),
								DashStyle: item.DashStyle, //0实线 1虚线
								Fill: item.Type == 'SolidRectangle' ? true : booleanChange(item.Fill)
							});
							TransformerListening(this, rectNode);
							break;
						case 'Ellipse':
							x = ((item.StartX + item.Width / 2) / this.mmToPoint) * this.magnification;
							y = ((item.StartY + item.Height / 2) / this.mmToPoint) * this.magnification;

							let ellipseNode = new Konva.Ellipse({
								id: item.Id,
								type: item.Type,
								x,
								y,
								width: (item.Width / this.mmToPoint) * this.magnification,
								height: (item.Height / this.mmToPoint) * this.magnification,
								radiusX: ((item.Width / this.mmToPoint) * this.magnification) / 2,
								radiusY: ((item.Height / this.mmToPoint) * this.magnification) / 2,
								fill: booleanChange(item.Fill) ? '#000000' : '',
								stroke: 'black',
								strokeWidth: (item.PenWidth / this.mmToPoint) * this.magnification,
								dash: [3,1],
								dashEnabled: item.DashStyle == 0 ? false : true,
								rotation: 0,
								draggable: false
							});
							this.elementGroup.add(ellipseNode);
							ellipseNode.setAttr('parameter', {
								Id: ellipseNode.id(),
								Type: ellipseNode.attrs.type,
								Name: item.Name,
								StartX: pxToMm(ellipseNode.x() - ellipseNode.radiusX() * ellipseNode.scaleX(), 2),
								StartY: pxToMm(ellipseNode.y() - ellipseNode.radiusY() * ellipseNode.scaleY(), 2),
								Width: pxToMm(ellipseNode.width() * ellipseNode.scaleX(), 2),
								Height: pxToMm(ellipseNode.height() * ellipseNode.scaleY(), 2),
								Lock: booleanChange(item.Lock),
								Rotate: ellipseNode.rotation(),
								PenWidth: Math.round((ellipseNode.strokeWidth() / this.magnification) * 10) / 10,
								Dash: ellipseNode.dashEnabled(),
								DashStyle: item.DashStyle,
								Fill: booleanChange(item.Fill)
							});
							TransformerListening(this, ellipseNode);
							break;
						case 'Diamond':
							x = (item.StartX / this.mmToPoint) * this.magnification;
							y = (item.StartY / this.mmToPoint) * this.magnification;

							let diamond_width = (item.Width / this.mmToPoint) * this.magnification;
							let diamond_height = (item.Height / this.mmToPoint) * this.magnification;

							let diamondNode = new Konva.Line({
								id: item.Id,
								type: item.Type,
								x,
								y,
								width: diamond_width,
								height: diamond_height,
								points: [0, diamond_height / 2, diamond_width / 2, 0, diamond_width, diamond_height / 2, diamond_width / 2, diamond_height],
								closed: true,
								fill: booleanChange(item.Fill) ? '#000000' : '',
								stroke: 'black',
								strokeWidth: (item.PenWidth / this.mmToPoint) * this.magnification,
								dash: [3,1],
								dashEnabled: item.DashStyle == 0 ? false : true,
								rotation: 0,
								scaleX: 1,
								scaleY: 1,
								draggable: false
							});
							this.elementGroup.add(diamondNode);
							diamondNode.setAttr('parameter', {
								Id: diamondNode.id(),
								Type: diamondNode.attrs.type,
								Name: item.Name,
								StartX: pxToMm(diamondNode.x(), 2),
								StartY: pxToMm(diamondNode.y(), 2),
								Width: pxToMm(diamondNode.width() * diamondNode.scaleX(), 2),
								Height: pxToMm(diamondNode.height() * diamondNode.scaleY(), 2),
								Lock: booleanChange(item.Lock),
								Rotate: diamondNode.rotation(),
								PenWidth: Math.round((diamondNode.strokeWidth() / this.magnification) * 10) / 10,
								Dash: diamondNode.dashEnabled(),
								DashStyle: item.DashStyle,
								Fill: booleanChange(item.Fill)
							});
							TransformerListening(this, diamondNode);
							break;
						case 'Triangle':
							x = (item.StartX / this.mmToPoint) * this.magnification;
							y = (item.StartY / this.mmToPoint) * this.magnification;

							let triangle_width = (item.Width / this.mmToPoint) * this.magnification;
							let triangle_height = (item.Height / this.mmToPoint) * this.magnification;

							let triangleNode = new Konva.Line({
								id: item.Id,
								type: item.Type,
								x,
								y,
								width: triangle_width,
								height: triangle_height,
								points: [0, triangle_height, triangle_width / 2, 0, triangle_width, triangle_height],
								closed: true,
								fill: booleanChange(item.Fill) ? '#000000' : '',
								stroke: 'black',
								strokeWidth: (item.PenWidth / this.mmToPoint) * this.magnification,
								dash: [3,1],
								dashEnabled: item.DashStyle == 0 ? false : true,
								rotation: 0,
								scaleX: 1,
								scaleY: 1,
								draggable: false
							});
							this.elementGroup.add(triangleNode);

							triangleNode.setAttr('parameter', {
								Id: triangleNode.id(),
								Type: triangleNode.attrs.type,
								Name: item.Name,
								StartX: pxToMm(triangleNode.x(), 2),
								StartY: pxToMm(triangleNode.y(), 2),
								Width: pxToMm(triangleNode.width() * triangleNode.scaleX(), 2),
								Height: pxToMm(triangleNode.height() * triangleNode.scaleY(), 2),
								Lock: booleanChange(item.Lock),
								StartX: triangleNode.x(),
								StartY: triangleNode.y(),
								Rotate: triangleNode.rotation(),
								PenWidth: Math.round((triangleNode.strokeWidth() / this.magnification) * 10) / 10,
								Dash: triangleNode.dashEnabled(),
								DashStyle: item.DashStyle,
								Fill: booleanChange(item.Fill)
							});
							TransformerListening(this, triangleNode);
							break;
						case 'Table':
							x = (item.StartX / this.mmToPoint) * this.magnification;
							y = (item.StartY / this.mmToPoint) * this.magnification;
							let tableWidth = (item.Width / this.mmToPoint) * this.magnification;
							let tableHeight = (item.Height / this.mmToPoint) * this.magnification;
							let strokeWidth = (item.PenWidth / this.mmToPoint) * this.magnification;
							let tableNode = new Konva.Group({
								id: item.Id,
								type: item.Type,
								x,
								y,
								width: tableWidth,
								height: tableHeight,
								rotation: Number(item.AngleRound) == 270 ? -90 : Number(item.AngleRound),
								scaleX: 1,
								scaleY: 1,
								draggable: true
							});
							let DicLineLocW = item.DicLineLocW ? item.DicLineLocW.split(',') : [];
							let DicLineLocH = item.DicLineLocH ? item.DicLineLocH.split(',') : [];
							let row = DicLineLocH.length + 1;
							let column = DicLineLocW.length + 1;

							let CellWidth = []; //记录每一列的宽度
							let CellHeight = []; //记录每一行的高度
							DicLineLocW = DicLineLocW.map(val => {
								return (val / this.mmToPoint) * this.magnification;
							});
							DicLineLocW.unshift(0);
							DicLineLocW.push(tableWidth - strokeWidth);
							DicLineLocH = DicLineLocH.map(val => {
								return (val / this.mmToPoint) * this.magnification;
							});
							DicLineLocH.unshift(0);
							DicLineLocH.push(tableHeight - strokeWidth);

							for (let i = 1; i < DicLineLocW.length; i++) {
								let val = DicLineLocW[i] - DicLineLocW[i - 1] - strokeWidth;
								CellWidth.push(val);
							}
							for (let i = 1; i < DicLineLocH.length; i++) {
								let val = DicLineLocH[i] - DicLineLocH[i - 1] - strokeWidth;
								CellHeight.push(val);
							}

							//绘制横线
							let tableRowLine = new Konva.Group({
								name: 'tableRowLine'
							});
							tableNode.add(tableRowLine);
							for (let i = 0; i <= row; i++) {
								let y = DicLineLocH[i];
								let rowLine = new Konva.Rect({
									name: `rowLine_${i}`,
									x: 0,
									y,
									width: tableWidth,
									height: strokeWidth,
									fill: '#000000',
									hitFunc: context => {
										context.beginPath();
										context.rect(0, y, tableWidth, strokeWidth);
										context.closePath();
									}
								});
								tableRowLine.add(rowLine);
							}
							//绘制竖线
							let tableColumnLine = new Konva.Group({
								name: 'tableColumnLine'
							});
							tableNode.add(tableColumnLine);
							for (let i = 0; i <= column; i++) {
								let x = DicLineLocW[i];
								let columnLine = new Konva.Rect({
									name: `columnLine_${i}`,
									x,
									y: 0,
									width: strokeWidth,
									height: tableHeight,
									fill: '#000000',
									hitFunc: context => {
										context.beginPath();
										context.rect(x, 0, strokeWidth, tableHeight);
										context.closePath();
									}
								});
								tableColumnLine.add(columnLine);
							}
							//绘制单元格
							let cellGroup = new Konva.Group({
								name: 'cellGroup',
								x: strokeWidth,
								y: strokeWidth
							});
							tableNode.add(cellGroup);

							item.DataSourceList = Array.isArray(item.DataSourceList) ? item.DataSourceList : [item.DataSourceList];
							let DataSourceList = item.DataSourceList.map(data => {
								let allPos = data.Key.split('|');
								for (let i = 0; i < allPos.length; i++) {
									allPos[i] = changeType(allPos[i].split(','));
								}
								let pos = allPos[0];

								let cell_x = DicLineLocW[pos[1]];
								let cell_y = DicLineLocH[pos[0]];
								let cellWidth = CellWidth[pos[1]];
								let cellHeight = CellHeight[pos[0]];

								if (allPos.length > 1) {
									//合并的单元格
									const { minRow, maxRow, minColumn, maxColumn } = getCellRang(allPos);
									cellWidth = sumData(CellWidth.slice(minColumn, maxColumn + 1)) + strokeWidth * (maxColumn - minColumn);
									cellHeight = sumData(CellHeight.slice(minRow, maxRow + 1)) + strokeWidth * (maxRow - minRow);
								}

								let cell = new Konva.Group({
									name: `cell_${pos[0]}_${pos[1]}`,
									x: cell_x,
									y: cell_y,
									width: cellWidth,
									height: cellHeight,
									pos,
									allPos,
									clipWidth: cellWidth,
									clipHeight: cellHeight
								});
								let cellBg = new Konva.Rect({
									name: 'cellBg',
									pos,
									x: 0,
									y: 0,
									width: cellWidth,
									height: cellHeight,
									fill: booleanChange(data.AntiBlack) ? '#000000' : '#FFFFFF',
									antiBlack: booleanChange(data.AntiBlack)
								});
								cell.add(cellBg);

								let { font_style, text_decoration } = setFontStyle(data.Font['@FontStyle']);

								lineHeight = ((data.RowSpacing1 / this.mmToPoint) * this.magnification) / ((data.Font['@FontSize'] / this.mmToPoint) * this.magnification) + 1;

								let cellText = new Konva.Text({
									name: 'cellText',
									pos,
									x: booleanChange(data.MirrorImage) ? cellWidth : 0,
									y: 0,
									width: cellWidth,
									height: cellHeight,
									text: data.Data,
									fontFamily: data.Font['@FontFamily'],
									fontSize: (data.Font['@FontSize'] / this.mmToPoint) * this.magnification,
									fontStyle: font_style,
									textDecoration: text_decoration,
									align: getTextAlignValue(data.TextAlign),
									verticalAlign: 'middle',
									lineHeight,
									letterSpacing: (data.WordSpacing / this.mmToPoint) * this.magnification,
									fill: booleanChange(data.AntiBlack) ? '#FFFFFF' : '#000000',
									scaleX: booleanChange(data.MirrorImage) ? -1 : 1,
									scaleY: 1,
									wrap: 'char'
								});
								cell.add(cellText);

								let cellSelect = new Konva.Rect({
									name: 'cellSelect',
									pos,
									x: 0,
									y: 0,
									width: cellWidth,
									height: cellHeight,
									fill: '#3F80F5',
									opacity: 0.1,
									visible: pos[0] == 0 && pos[1] == 0 ? true : false
								});
								cellSelect.hide();
								cell.add(cellSelect);
								cellSelect.moveToTop();

								cell.setAttr('parameter', {
									AllKeys: cell.attrs.allPos, //存放合并的单元格坐标
									Key: cell.attrs.pos,
									StartX: Math.round((cell.x() / this.magnification) * 100) / 100,
									StartY: Math.round((cell.y() / this.magnification) * 100) / 100,
									Width: Math.round((cell.width() / this.magnification) * 100) / 100,
									Height: Math.round((cell.height() / this.magnification) * 100) / 100,
									FirstValue: cellText.text() ? cellText.text() : '',
									NormalText: cellText.text() ? cellText.text() : '',
									SequenceType: Number(data.SequenceType),
									InitialValue: Number(data.DataSourceType) == 2 ? data.InitialValue : '1',
									AddedValue: Number(data.AddedValue),
									Quantity: Number(data.Quantity),
									ColumnName: data.ColumnName,
									QuerySql: data.QuerySql,
									TableName: data.TableName,
									ConnectionStr: data.ConnectionStr,
									DataSourceType: Number(data.DataSourceType),
									AutoWrap: booleanChange(data.AutoWrap),
									AntiBlack: booleanChange(data.AntiBlack),
									MirrorImage: booleanChange(data.MirrorImage),
									FontFamily: cellText.fontFamily(),
									FontSize: dataPrecision(cellText.fontSize(), 1),
									Bold: cellText.fontStyle().indexOf('bold') > -1 ? true : false,
									Italic: cellText.fontStyle().indexOf('italic') > -1 ? true : false,
									UnderLine: cellText.textDecoration().indexOf('underline') > -1 ? true : false,
									DeleteLine: cellText.textDecoration().indexOf('line-through') > -1 ? true : false,
									TextAlign: data.TextAlign,
									LineHeight: Math.round((data.RowSpacing1 / this.mmToPoint) * 100) / 100,
									LetterSpacing: Math.round((cellText.letterSpacing() / this.magnification) * 100) / 100,
									SheetIndex: [-1, -1],
									DataBase: []
								});

								cellGroup.add(cell);
								return cell.attrs.parameter;
							});

							tableNode.setAttr('parameter', {
								Id: tableNode.id(),
								Type: tableNode.attrs.type,
								Name: item.Name,
								StartX: pxToMm(tableNode.x(), 2),
								StartY: pxToMm(tableNode.y(), 2),
								Width: pxToMm(tableNode.width() * tableNode.scaleX(), 2),
								Height: pxToMm(tableNode.height() * tableNode.scaleY(), 2),
								Lock: booleanChange(item.Lock),
								Rotate: tableNode.rotation(),
								DataSourceList, //记录单元格数据
								DicLineLocW, //记录列线
								DicLineLocH, //记录行线
								Row: row, //行数
								Column: column, //列数
								CellWidth, //记录每列单元格的宽度
								CellHeight, //记录每行单元格的高度
								cellMultiple: false, //是否开启多选
								mulCellPos: [], //记录多选的单元格的坐标
								currentPos: [0, 0], //当前选中单元格的坐标
								dataSourceIndex: 0, //当前选中单元格的下标
								PenWidth: Math.round((strokeWidth / this.magnification) * 10) / 10 //边框粗细
							});

							this.elementGroup.add(tableNode);
							this.getExcelCloudData(tableNode);
							TransformerListening(this, tableNode);

							break;
					}
				}
				this.designLayer.draw();
			},
			//绘制横向文本
			drawHorizontalText(node, item, Data) {
				let lineHeight = ((item.RowSpacing1 / this.mmToPoint) * this.magnification) / ((item.Font['@FontSize'] / this.mmToPoint) * this.magnification) + 1;

				item.TextPrefix = item.TextPrefix ? item.TextPrefix : '';
				item.TextSuffix = item.TextSuffix ? item.TextSuffix : '';

				let textStr = Data;
				if (node.attrs.type == 'WinText') {
					textStr = item.TextPrefix + Data + item.TextSuffix;
				}

				const { font_style, text_decoration } = setFontStyle(item.Font['@FontStyle']);

				let text = new Konva.Text({
					id: node.id(),
					text: textStr,
					fontFamily: item.Font['@FontFamily'],
					fontSize: (item.Font['@FontSize'] / this.mmToPoint) * this.magnification,
					fontStyle: font_style,
					textDecoration: text_decoration,
					align: getTextAlignValue(item.TextAlign),
					lineHeight,
					letterSpacing: (item.WordSpacing / this.mmToPoint) * this.magnification,
					fill: booleanChange(item.AntiBlack) ? '#FFFFFF' : '#000000',
					scaleX: booleanChange(item.MirrorImage) ? -1 : 1,
					scaleY: 1,
					wrap: 'char'
				});
				node.add(text);
				let textWidth = text.width();
        let oldWidth = booleanChange(item.AutoWrap) ? item.BoxWidth : item.Width
				let width = (oldWidth / this.mmToPoint) * this.magnification;
				let scale = width / textWidth;
				if (booleanChange(item.AutoWrap)) {
					if (item.TextAlign == 4) {
						text.width(textWidth);
						node.width(textWidth);
						node.scaleX(scale);
					} else {
						text.width(width);
						node.width(width);
					}
				} else {
					if (item.TextAlign == 4) {
						node.scaleX(scale);
					}
					text.width(textWidth);
					node.width(textWidth);
				}
				//翻转重置文本坐标
				if (booleanChange(item.MirrorImage)) {
					text.x(width);
				}
			},
			//绘制竖向文本
			drawVerticalText(node, item, Data) {
				item.TextPrefix = item.TextPrefix ? item.TextPrefix : '';
				item.TextSuffix = item.TextSuffix ? item.TextSuffix : '';
				let textStr = Data;
				if (node.attrs.type == 'WinText') {
					textStr = item.TextPrefix + Data + item.TextSuffix;
				}
				let textArr = textStr.split(/\r?\n/);

				const { font_style, text_decoration } = setFontStyle(item.Font['@FontStyle']);

				let fontSize = (item.Font['@FontSize'] / this.mmToPoint) * this.magnification;
				let letterSpacing = (item.WordSpacing / this.mmToPoint) * this.magnification;
				let lineHeight = ((item.RowSpacing1 / this.mmToPoint) * this.magnification) / ((item.Font['@FontSize'] / this.mmToPoint) * this.magnification) + 1;

				let wordHeight = fontSize + letterSpacing; //每个字的高度+字间距
				let columnText = []; //每列的内容

				let maxWordNum = 0;
				textArr.forEach(item => {
					maxWordNum = Math.max(maxWordNum, item.length);
				});
				let textHeight = maxWordNum * fontSize + letterSpacing * maxWordNum;

        let oldHeight = booleanChange(item.AutoWrap) ? item.BoxWidth : item.Height
				let height = (oldHeight / this.mmToPoint) * this.magnification;
				let scale = height / textHeight;

				if (booleanChange(item.AutoWrap) && item.TextAlign != 4) {
					//自动换行
					height = (item.Height / this.mmToPoint) * this.magnification;
					let charsPerColumn = Math.floor(height / wordHeight); // 每列可以容纳的字符数
					for (let i = 0; i < textArr.length; i++) {
						let count = Math.ceil(textArr[i].length / charsPerColumn);
						if (count > 1) {
							for (let j = 0; j < textArr[i].length; j += charsPerColumn) {
								columnText.push(textArr[i].slice(j, j + charsPerColumn));
							}
						} else {
							columnText.push(textArr[i]);
						}
					}
				} else {
					columnText = textArr;
					height = textHeight;
					if (item.TextAlign == 4) {
						node.scaleY(scale);
					}
				}

				let columnCount = columnText.length; //总列数
				// 计算总宽度
				let width = columnCount * fontSize + lineHeight * (columnCount - 1);
				node.width(width);
				node.height(height);
				node.getTag().width(width);
				node.getTag().height(height);
				//text1 用来撑开高度，label会取第一个text的高度作为整体的高度
				let text1 = new Konva.Text({
					name: 'tempText',
					x: 0,
					y: 0,
					width,
					height
				});
				text1.hide();
				node.add(text1);
				let textGroup = new Konva.Group({
					name: 'textGroup',
					x: booleanChange(item.MirrorImage) ? width : 0,
					y: 0,
					scaleX: booleanChange(item.MirrorImage) ? -1 : 1,
					scaleY: 1,
					width: width,
					height
				});
				node.add(textGroup);
				let currentX = width - fontSize;
				let regex1 = /^[0-9a-zA-Z]*$/;
				let regex2 = /[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·#（）“”‘|《》、【】[\]]/;
				for (let i = 0; i < columnText.length; i++) {
					let currentY = item.TextAlign == 1 ? (height - wordHeight * columnText[i].length) / 2 : item.TextAlign == 2 ? height - wordHeight * columnText[i].length : 0;
					for (let j = 0; j < columnText[i].length; j++) {
						let text = new Konva.Text({
							x: currentX + Math.ceil(fontSize) / 2,
							y: currentY + Math.ceil(fontSize) / 2,
							offsetX: Math.ceil(fontSize) / 2,
							offsetY: Math.ceil(fontSize) / 2,
							width: Math.ceil(fontSize), //向上取整，防止字体实际大小超出我们所预设的宽高导致文字无法显示的问题
							height: Math.ceil(fontSize),
							text: columnText[i][j],
							fontSize: fontSize,
							fontFamily: item.Font['@FontFamily'],
							fontStyle: font_style,
							textDecoration: text_decoration,
							rotation: regex1.test(columnText[i][j]) || regex2.test(columnText[i][j]) ? 90 : 0,
							align: 'center',
							lineHeight: 1,
							letterSpacing: 0,
							fill: booleanChange(item.AntiBlack) ? '#ffffff' : '#000000',
							scaleX: 1,
							scaleY: 1
						});
						currentY += fontSize + letterSpacing;
						if (item.TextAlign == 3) {
							//平铺
							let space = (height - wordHeight * columnText[i].length) / (columnText[i].length - 1);
							currentY += space;
						}
						textGroup.add(text);
					}
					currentX -= fontSize + lineHeight;
				}
			},
			//处理初始化excel表格数据
			async getExcelCloudData(node) {
				let parameter = node.attrs.parameter;
				let DataSourceList = parameter.DataSourceList;
				for (let i = 0; i < DataSourceList.length; i++) {
					let list = DataSourceList[i];
					let DataSourceType = list.DataSourceType;
					let ColumnName = list.ColumnName;
					let TableName = list.TableName;
					let ConnectionStr = list.ConnectionStr;
					if (DataSourceType != 1) continue;
					if (!ColumnName) {
						list.DataSourceType = 0;
						continue;
					}
					const reg = new RegExp('^(http|https)://', 'i');
					var match = reg.test(ConnectionStr);
					//云端数据
					if (match) {
						let sheetList = [];
						let ConnectionData = [];
						await this.getSheet(ConnectionStr).then(res => {
							TableName = TableName ? TableName : res[0].sheetName; //TableName为空时默认取第一张表的数据
							res.forEach((item, index) => {
								let obj = {
									text: item.sheetName,
									index,
									children: []
								};
								if (item.data.length > 0) {
									item.data.forEach((column, c_index) => {
										obj.children.push({
											text: column[0] ? column[0] : `col_`,
											index: c_index,
											column: column.slice(1)
										});
										if (item.sheetName == TableName && column[0] == ColumnName) {
											ConnectionData = column.slice(1);
											list.SheetIndex = [index, c_index];
										}
									});
								} else {
									obj.children.push({
										text: this.$t(`tips.tip107`),
										index: 0,
										column: []
									});
								}
								sheetList.push(obj);
							});

							list.DataBase = sheetList;
							list.ConnectionData = ConnectionData;
						});
					} else {
						//非云端数据,转成普通文本
						list.DataSourceType = 0;
						list.ColumnName = '';
						list.QuerySql = '';
						list.TableName = '';
						list.ConnectionStr = '';
					}
				}
				parameter.DataSourceList = DataSourceList;
			},
			//请求初始化云端数据表内容
			getSheet(url) {
				return new Promise((resolve, reject) => {
					this.$axioshanma('/api/tem.new_class/import', {
						FileUrl: url,
						RowIndex: 0,
						TableIndex: -1 //传-1可拿到所有的表数据
					})
						.then(res => {
							resolve(res);
						})
						.catch(err => {
							reject();
						});
				});
			},
			//设置云端图片
			handleUpdateImg(imgUrl) {
				this.$refs.attrsDiaRef.handleUpdateImg(imgUrl);
			},

			//关闭新手引导
			closeEditGuide() {
				this.showEditGuide = false;
				localStorage.setItem('firstEdit', 'false');
			}
		},
		unmounted() {
			clearInterval(this.systemTimer);
			this.systemTimer = null;
			document.onkeydown = null;
			window.removeEventListener('resize', this.fitStageIntoParentContainer);
			window.removeEventListener('beforeunload', this.beforeunloadTip);
		},
		destroyed() {
			clearInterval(this.systemTimer);
			this.systemTimer = null;
			message.destroy();
			document.onkeydown = null;
			document.removeEventListener('mousewheel');
			document.removeEventListener('DOMMouseScroll');
		}
	};
</script>
