import { fontUrlArr } from '@/utils/fontFamily';
import i18n from '../../../language/i18n';
const magnification = 3.78; //1mm 约等于3.78px（96dpi）   像素 = 毫米 * ( 分辨率 / 25.4)
const mmToPoint = 3.96; // 1mm约等于3.96点 (31.7/8)  (203dpi(pc端采用203dpi进行计算)) 用于与pc端的数据转换

//生成随机数
const randomString = length => {
	var chars = '0123456789';
	var result = '';
	for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
	return result;
};

const getTextAlignValue = value => {
	let TextAlign = 'left';
	if (value == 1) {
		TextAlign = 'center';
	} else if (value == 2) {
		TextAlign = 'right';
	} else if (value == 3) {
		TextAlign = 'justify';
	} else {
		TextAlign = 'left';
	}
	return TextAlign;
};

const getBarCodeTypeList = () => {
	let list = [
		{
			bcid: 'code128',
			label: 'Code 128',
			value: 'CODE128',
			defaultValue: '0123456789'
		},
		{
			bcid: 'code39',
			label: 'Code 39',
			value: 'CODE39',
			defaultValue: '0123456789'
		},
		{
			bcid: 'code11',
			label: 'Code 11',
			value: 'CODE11',
			defaultValue: '0123456789'
		},
		{
			bcid: 'code93',
			label: 'Code 93',
			value: 'CODE93',
			defaultValue: '0123456789'
		},
		{
			bcid: 'ean8',
			label: 'EAN-8',
			value: 'ean8',
			defaultValue: '0123456'
		},
		{
			bcid: 'ean13',
			label: 'EAN-13',
			value: 'ean13',
			defaultValue: '211234567891'
		},
		{
			bcid: 'upca',
			label: 'UPC-A',
			value: 'upc',
			defaultValue: '61414199899'
		},
		{
			bcid: 'msi',
			label: 'MSI-10',
			value: 'msi10',
			defaultValue: '0123456789'
		},
		{
			bcid: 'pharmacode',
			label: 'Pharmacode',
			value: 'pharmacode',
			defaultValue: '012345'
		},
		{
			bcid: 'telepen',
			label: 'Telepen',
			value: 'Telepen',
			defaultValue: '0123456789'
		},
		{
			bcid: 'postnet',
			label: 'PostNet',
			value: 'PostNet',
			defaultValue: '12345'
		},
		{
			bcid: 'gs1-128',
			label: 'GS1_128',
			value: 'GS1_128',
			defaultValue: '(01)09521234543213(3103)000123'
		},
		{
			bcid: 'itf14',
			label: 'ITF-14',
			value: 'ITF-14',
			defaultValue: '0123456789101'
		},
		{
			bcid: 'interleaved2of5',
			label: 'Interleaved 2 of 5',
			value: 'Interleaved2of5',
			defaultValue: '0123456789'
		}
	];
	return list;
};

const getQrCodeTypeList = () => {
	let list = [
		{
			bcid: 'qrcode',
			label: 'QR_CODE',
			value: 'QR_CODE'
		},
		{
			bcid: 'pdf417',
			label: 'PDF_417',
			value: 'PDF_417'
		},
		{
			bcid: 'datamatrix',
			label: 'DATA_MATRIX',
			value: 'DATA_MATRIX'
		},
		{
			bcid: 'azteccode',
			label: 'AZTEC',
			value: 'AZTEC'
		}
	];
	return list;
};

//获取二维码纠错率类型
const getEclevelList = type => {
	let list = [];
	switch (type) {
		case 'QR_CODE':
			list = [
				{
					value: 'L',
					label: 'Low'
				},
				{
					value: 'M',
					label: 'Medium'
				},
				{
					value: 'Q',
					label: 'Medium High'
				},
				{
					value: 'H',
					label: 'High'
				}
			];
			break;
		case 'PDF_417':
			list = [
				{
					value: 0,
					label: 'L0'
				},
				{
					value: 1,
					label: 'L1'
				},
				{
					value: 2,
					label: 'L2'
				},
				{
					value: 3,
					label: 'L3'
				},
				{
					value: 4,
					label: 'L4'
				},
				{
					value: 5,
					label: 'L5'
				},
				{
					value: 6,
					label: 'L6'
				},
				{
					value: 7,
					label: 'L7'
				}
			];
			break;
	}
	return list;
};

//获取字体
const getFontFamily = () => {
	// return new Promise(resolve => {
	// axios('/api/tem.app_shared/getNewFontList').then((res) => {
	//   resolve(res);
	// }).catch((err) => {
	//   resolve(list);
	// })
	let defaultFamily = [
		{
			value: '@Malgun Gothic',
			label: '@Malgun Gothic'
		},
		{
			value: 'Arial',
			label: 'Arial'
		},
		{
			value: '阿里巴巴普惠体',
			label: '阿里巴巴普惠体'
		},
		{
			value: '黑体',
			label: '黑体'
		},
		{
			value: '微软雅黑',
			label: '微软雅黑'
		},
		{
			value: '宋体',
			label: '宋体'
		},
		{
			value: '楷体',
			label: '楷体'
		},
		{
			value: '仿宋',
			label: '仿宋'
		},
		{
			value: '等线',
			label: '等线'
		}
	];
	let familyList = defaultFamily.concat(fontUrlArr);
	return familyList;
	// });
};
// 加载字体
const loadFont = (name, url, progressCallback) => {
	return new Promise((resolve, reject) => {
		if (!url) {
			//本地引入的字体
			resolve();
		} else {
			// downAxios(url, { down: true }, (progressEvent) => {
			//   let progress = Number(((progressEvent.loaded / progressEvent.total) * 100).toFixed(2))
			//   // console.log(progress)
			//   if (progressCallback) {
			//     progressCallback(progress)
			//   }
			// }).then((res) => {
			// })

			let customFont = new FontFace(name, `url(${url})`, {
				display: 'auto'
			});
			customFont
				.load()
				.then(loadFace => {
					document.fonts.add(loadFace);
					resolve();
				})
				.catch(err => {
					console.log(err);
					reject();
				});
		}
	});
};

//时间格式列表
const getTimeFormatList = () => {
	let dateFormatList = [];
	if (i18n.global.locale.value == 'zh') {
		dateFormatList = ['yyyy年MM月dd日', 'yyyy年M月d日', 'yyyy年MM月', 'yyyy-MM', 'yyyy-MM-dd', 'yyyy-M-d', 'yy-M-d', 'yyyy/MM', 'yyyy/MM/dd', 'E', 'EEEE', 'yyyy.MM.dd', 'yyyy.MM', 'yyyy.M.d'];
	} else {
		dateFormatList = ['yyyy-MM', 'yyyy-MM-dd', 'yyyy-M-d', 'yy-M-d', 'yyyy/MM', 'yyyy/MM/dd', 'E', 'EEEE', 'yyyy.MM.dd', 'yyyy.MM', 'yyyy.M.d'];
	}

	let allDateFormatList = ['yyyy年MM月dd日', 'yyyy年M月d日', 'yyyy年MM月', 'yyyy-MM', 'yyyy-MM-dd', 'yyyy-M-d', 'yy-M-d', 'yyyy/MM', 'yyyy/MM/dd', 'E', 'EEEE', 'yyyy.MM.dd', 'yyyy.MM', 'yyyy.M.d'];

	let timeFormatList = ['HH:mm:ss', 'HH:mm a', 'HH:mm', 'H:m:s', 'a'];
	return { dateFormatList, timeFormatList, allDateFormatList };
};

const getCodeType = (value, type) => {
	let BarcodeType = '';
	let bcid = '';
	if (type == 'GeneralBarcode') {
		switch (value) {
			case 'CODE128':
				BarcodeType = 'Code 128';
				bcid = 'code128';
				break;
			case 'CODE128A':
				BarcodeType = 'Code 128';
				bcid = 'code128';
				break;
			case 'CODE128B':
				BarcodeType = 'Code 128';
				bcid = 'code128';
				break;
			case 'CODE128C':
				BarcodeType = 'Code 128';
				bcid = 'code128';
				break;
			case 'CODE39':
				BarcodeType = 'Code 39';
				bcid = 'code39';
				break;
			case 'CODE11':
				BarcodeType = 'CODE 11';
				bcid = 'code11';
				break;
			case 'CODE93':
				BarcodeType = 'CODE 93';
				bcid = 'code93';
				break;
			case 'upc':
				BarcodeType = 'UPC-A';
				bcid = 'upca';
				break;
			case 'ean8':
				BarcodeType = 'EAN-8';
				bcid = 'ean8';
				break;
			case 'ean13':
				BarcodeType = 'EAN-13';
				bcid = 'ean13';
				break;
			case 'msi10':
				BarcodeType = 'MSI-10';
				bcid = 'msi10';
				break;
			case 'pharmacode':
				BarcodeType = 'Pharmacode';
				bcid = 'pharmacode';
				break;
			case 'codabar':
				BarcodeType = 'Code 128';
				bcid = 'code128';
				break;
			case 'PostNet':
				BarcodeType = 'PostNet';
				bcid = 'postnet';
				break;
			case 'GS1_128':
				BarcodeType = 'GS1_128';
				bcid = 'gs1-128';
				break;
			case 'ITF-14':
				BarcodeType = 'ITF-14';
				bcid = 'itf14';
				break;
			case 'Interleaved2of5':
				BarcodeType = 'Interleaved 2 of 5';
				bcid = 'interleaved2of5';
				break;
			default:
				BarcodeType = 'Code 128';
				bcid = 'code128';
				break;
		}
	} else if (type == 'QRCode') {
		switch (value) {
			case 'QR_CODE':
				BarcodeType = 'QR_CODE';
				bcid = 'qrcode';
				break;
			case 'PDF_417':
				BarcodeType = 'PDF_417';
				bcid = 'pdf417';
				break;
			case 'DATA_MATRIX':
				BarcodeType = 'DATA_MATRIX';
				bcid = 'datamatrix';
				break;
			case 'AZTEC':
				BarcodeType = 'AZTEC';
				bcid = 'azteccode';
				break;
			default:
				BarcodeType = 'QR_CODE';
				bcid = 'qrcode';
				break;
		}
	}
	if (key == 'BarcodeType') {
		return BarcodeType;
	} else if (key == 'bcid') {
		return bcid;
	}
};

//生成条形码，二维码
const getGenerateCodeImg = node => {
	return new Promise((resolve, reject) => {
		let canvas = document.createElement('canvas');
		let options = JSON.parse(JSON.stringify(node.attrs.options));
		try {
			var reg = new RegExp('[\\u4E00-\\u9FFF]+', 'g');
			if (node.attrs.Type == 'GeneralBarcode' && reg.test(options.text)) {
				reject('条形码不能有汉字！');
			}
			//以下条码类型隐藏文本，只生成条码本身
			if (options.bcid == 'ean8' || options.bcid == 'ean13' || options.bcid == 'upca') {
				if (options.textyalign == 'above') {
					options.includetext = false;
				}
				delete options.textxalign;
				delete options.textyalign;
			}
			if (options.bcid == 'azteccode') {
				options.eclevel = 23;
			}

			bwipjs.toCanvas(canvas, options);

			let image = new Image();
			image.onload = () => {
				if (options.bcid == 'ean8' || options.bcid == 'ean13' || options.bcid == 'upca') {
					//重绘上方文字
					if (node.attrs.options.textyalign == 'above' && node.attrs.options.includetext) {
						const canvas_render = document.createElement('canvas');
						const ctx = canvas_render.getContext('2d');
						let fontSize = options.textsize * options.scale;
						ctx.font = `${fontSize}px OCRB`;
						const metrics = ctx.measureText(options.text);
						const textHeight = Math.ceil(metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent);
						const textWidth = metrics.width;
						const offsetY = 2 * options.scale;
						const offsetTop = 2 * options.scale;
						let canvasWidth = image.width;
						let canvasHeight = image.height + textHeight + offsetY + offsetTop;
						canvas_render.width = canvasWidth;
						canvas_render.height = canvasHeight;
						ctx.font = `${fontSize}px OCRB`;
						let textxalign = node.attrs.options.textxalign;
						ctx.textAlign = textxalign == 'justify' ? 'left' : textxalign;
						ctx.textBaseline = 'top';
						ctx.fillStyle = 'black';
						let textPosX = 0;
						if (textxalign != 'justify') {
							if (textxalign == 'right') {
								textPosX = canvasWidth;
							} else if (textxalign == 'center') {
								textPosX = canvasWidth / 2;
							}
							ctx.fillText(options.text, textPosX, offsetTop);
						} else {
							// 字间距
							let offsetX = Math.floor((canvasWidth - textWidth) / (options.text.length - 1));
							// 从左上角开始，每次向右移动文本的宽度
							let pos = (canvasWidth - (offsetX * (options.text.length - 1) + textWidth)) / 2;
							for (let i = 0; i < options.text.length; i++) {
								ctx.fillText(options.text[i], pos, offsetTop);
								pos = pos + offsetX + ctx.measureText(options.text[i]).width;
							}
						}
						ctx.drawImage(image, 0, textHeight + offsetY + offsetTop, canvasWidth, canvasHeight);
						let newImage = canvas_render.toDataURL('image/png');
						let image1 = new Image();
						image1.onload = () => {
							resolve(image1);
							return;
						};
						image1.src = newImage;
					} else {
						resolve(image);
					}
				} else {
					resolve(image);
				}
			};
			image.src = canvas.toDataURL('image/png');
		} catch (err) {
			console.log(err);
			reject(err.message);
		}
	});
};

//获取当前日期或时间
const getNowDateOrTime = type => {
	let time = new Date();

	if (type == 'date') {
		let year = time.getFullYear(); //年
		let month = time.getMonth() + 1; //月
		let date = time.getDate(); //日
		return `${year}/${month}/${date}`;
	} else if (type == 'time') {
		let hours = time.getHours(); //时
		let minutes = time.getMinutes(); //分
		let seconds = time.getSeconds(); //秒
		return `${hours}:${minutes}:${seconds}`;
	}
};

// 格式化日期函数
const formatDate = (date, dataFormat, timeFormat) => {
	// 判断输入是否为 Date 对象，如果不是，则尝试转换为 Date 对象
	let newDate = date;
	if (!(date instanceof Date)) {
		newDate = new Date(date);
	}

	const tf = i => {
		return (i < 10 ? '0' : '') + i;
	};

	let year = newDate.getFullYear(); //年
	let month = newDate.getMonth() + 1; //月
	let day = newDate.getDate(); //日
	let hours = newDate.getHours(); //时
	let minutes = newDate.getMinutes(); //分
	let seconds = newDate.getSeconds(); //秒
	let state = '';
	if (i18n.global.locale.value == 'zh') {
		state = hours > 12 ? '下午' : '上午';
	} else {
		state = hours > 12 ? 'PM' : 'AM';
	}
	let week = newDate.getDay();
	let weekDay = '';
	const getWeek = type => {
		if (i18n.global.locale.value == 'zh') {
			if (week == 0) {
				weekDay = '日';
			} else if (week == 1) {
				weekDay = '一';
			} else if (week == 2) {
				weekDay = '二';
			} else if (week == 3) {
				weekDay = '三';
			} else if (week == 4) {
				weekDay = '四';
			} else if (week == 5) {
				weekDay = '五';
			} else if (week == 6) {
				weekDay = '六';
			}
			return type == 'E' ? `周${weekDay}` : type == 'EEEE' ? `星期${weekDay}` : '';
		} else {
			if (week == 0) {
				weekDay = 'Sunday';
			} else if (week == 1) {
				weekDay = 'Monday';
			} else if (week == 2) {
				weekDay = 'Tuesday';
			} else if (week == 3) {
				weekDay = 'Wednesday';
			} else if (week == 4) {
				weekDay = 'Thursday';
			} else if (week == 5) {
				weekDay = 'Friday';
			} else if (week == 6) {
				weekDay = 'Saturday';
			}
			return type == 'E' ? `${weekDay.substring(0, 3)}` : type == 'EEEE' ? `${weekDay}` : '';
		}
	};

	let dataStr = '';
	let timeStr = '';

	switch (dataFormat) {
		case 'yyyy年MM月dd日':
			dataStr = `${year}年${tf(month)}月${tf(day)}日`;
			break;
		case 'yyyy年M月d日':
			dataStr = `${year}年${month}月${day}日`;
			break;
		case 'yyyy年MM月':
			dataStr = `${year}年${tf(month)}月`;
			break;
		case 'yyyy-MM':
			dataStr = `${year}-${tf(month)}`;
			break;
		case 'yyyy-MM-dd':
			dataStr = `${year}-${tf(month)}-${tf(day)}`;
			break;
		case 'yyyy-M-d':
			dataStr = `${year}-${month}-${day}`;
			break;
		case 'yy-M-d':
			dataStr = `${year}-${month}-${day}`;
			break;
		case 'yyyy/MM':
			dataStr = `${year}/${tf(month)}`;
			break;
		case 'yyyy/MM/dd':
			dataStr = `${year}/${tf(month)}/${tf(day)}`;
			break;
		case 'E':
			dataStr = getWeek('E');
			break;
		case 'EEEE':
			dataStr = getWeek('EEEE');
			break;
		case 'yyyy.MM.dd':
			dataStr = `${year}.${tf(month)}.${tf(day)}`;
			break;
		case 'yyyy.MM':
			dataStr = `${year}.${tf(month)}`;
			break;
		case 'yyyy.M.d':
			dataStr = `${year}.${month}.${day}`;
			break;
		default:
			dataStr = '';
			break;
	}
	switch (timeFormat) {
		case 'HH:mm:ss':
			timeStr = `${tf(hours)}:${tf(minutes)}:${tf(seconds)}`;
			break;
		case 'HH:mm a':
			timeStr = `${tf(hours)}:${tf(minutes)} ${state}`;
			break;
		case 'HH:mm':
			timeStr = `${tf(hours)}:${tf(minutes)}`;
			break;
		case 'H:m:s':
			timeStr = `${hours}:${minutes}:${seconds}`;
			break;
		case 'a':
			timeStr = `${state}`;
			break;
		default:
			timeStr = '';
			break;
	}
	if (dataStr && !timeStr) {
		return `${dataStr}`;
	} else if (!dataStr && timeStr) {
		return `${timeStr}`;
	} else if (dataStr && timeStr) {
		return `${dataStr} ${timeStr}`;
	} else {
		return `${year}/${tf(month)}/${tf(day)}/ ${tf(hours)}:${tf(minutes)}:${tf(seconds)}`;
	}
};

// 处理天数分钟偏移
const dealTimeOffset = (date, time, DayOffset, MinuteOffset) => {
	//将日期、时间拼接起来，固定格式为yyyy-m-d hh:m:s
	let dateStr = date;
	if (time) {
		dateStr = dateStr + ' ' + time;
	}
	//将日期转换成时间戳进行添加加减
	dateStr = new Date(dateStr).getTime();
	let newDate = dateStr + DayOffset * 24 * 60 * 60 * 1000 + MinuteOffset * 60 * 1000;
	return new Date(newDate);
};

// 组合数据
const combinationData = node => {
	let value = '';
	let DataSourceList = node.attrs.parameter.DataSourceList;
	DataSourceList.map(item => {
		value += item.FirstValue;
	});
	return value;
};

//判断当前选中元素的关联关系
const getAssociate = (designLayer, elementGroup, node) => {
	let DataSourceList = node.attrs.parameter.DataSourceList;
	//判断该条数据是否有绑定其他元素，若否则该条数据可能被其他数据绑定
	let isRefer = DataSourceList.some(item => item.DataSourceType == 3 && item.ReferenceId != '');
	if (!isRefer) {
		dealAssociateElements(designLayer, elementGroup, node);
	}
};

// 处理绑定元素值间的数据重载
const dealAssociateElements = async (designLayer, elementGroup, node) => {
	let Id = node.id();
	let currentData = node.attrs.parameter.Data;
	for (let i = 0; i < elementGroup.children.length; i++) {
		let node = elementGroup.children[i];
		if (node.attrs.type == 'WinText' || node.attrs.type == 'GeneralBarcode' || node.attrs.type == 'QRCode') {
			let getReference = false; //用来判断是否有处理元素绑定
			node.attrs.parameter.DataSourceList.forEach(val => {
				if (val.ReferenceId == Id) {
					val.FirstValue = currentData;
					getReference = true;
				}
			});
			if (getReference) {
				let data = '';
				switch (node.attrs.type) {
					case 'WinText':
						//将组合数据的文本内容拼接起来
						data = combinationData(node);
						node.attrs.parameter.Data = data;
						if (node.attrs.parameter.TextArrange == 1) {
							drawVerticalText(node, designLayer);
						} else {
							node.getText().text(data);
							resetNodeAttr(node);
						}
						designLayer.draw();
						break;
					case 'GeneralBarcode':
					case 'QRCode':
						//将组合数据的文本内容拼接起来
						data = combinationData(node);
						node.attrs.parameter.Data = data;
						node.attrs.options.text = data;

						try {
							let codeImg = await getGenerateCodeImg(node);
							node.setAttrs({
								image: codeImg,
								imageSrc: codeImg.src,
								width: codeImg.width,
								height: codeImg.height
							});
							designLayer.draw();
						} catch (err) {
							if (!data) {
								message.error('内容不能为空');
							} else {
								let errData = err.split(':');
								message.error('生成失败，' + errData[1]);
							}
						}
						break;
				}
			}
		}
	}
};

//绘制横向文本
const drawHorizontalText = (node, designLayer) => {
	let width = node.height();

	let textGroup = node.find('.textGroup')[0];
	let tempText = node.find('.tempText')[0];
	if (textGroup) {
		textGroup.destroy();
	}
	if (tempText) {
		tempText.destroy();
	}
	let parameter = node.attrs.parameter;
	let fontSize = Math.round((parameter.FontSize / mmToPoint) * magnification * 100) / 100;
	let fontStyle = [];
	if (parameter.Bold) {
		fontStyle.push('bold');
	}
	if (parameter.Italic) {
		fontStyle.push('italic');
	}

	let textDecoration = [];
	if (parameter.UnderLine) {
		textDecoration.push('underline');
	}
	if (parameter.DeleteLine) {
		textDecoration.push('line-through');
	}
	let letterSpacing = mmToPx(parameter.LetterSpacing, 2);
	let lineHeight = mmToPx(parameter.LineHeight);
	lineHeight = lineHeight / fontSize + 1;
	let data = parameter.Data;
	if (node.attrs.type == 'WinText') {
		data = parameter.TextPrefix + parameter.Data + parameter.TextSuffix;
	}
	let text = new Konva.Text({
		id: node.id(),
		text: data,
		fontFamily: parameter.FontFamily,
		fontSize,
		fontStyle: fontStyle.join(' '),
		textDecoration: textDecoration.join(' '),
		align: getTextAlignValue(parameter.TextAlign),
		lineHeight,
		letterSpacing,
		fill: parameter.AntiBlack ? '#ffffff' : '#000000',
		scaleX: parameter.MirrorImage ? -1 : 1,
		scaleY: 1,
		wrap: 'char'
	});
	node.add(text);
	if (parameter.TextAlign == 4) {
		let oldWidth = width * node.scaleY();
		let scale = oldWidth / text.textWidth;
		node.scaleX(scale);
		node.scaleY(1);
		width = text.textWidth;
	}

	if (parameter.AutoWrap) {
		text.width(width);
		node.width(width);
		node.getTag().width(width);
	} else {
		node.width(text.width());
		node.getTag().width(text.width());
	}

	if (parameter.MirrorImage) {
		let width = node.getText().width();
		node.getText().x(width);
	} else {
		node.getText().x(0);
	}

	node.height(null);
	node.height(text.height());
	node.getTag().height(text.height());
	setNodeSize(node);
	designLayer.draw();
};

//绘制竖向文本
const drawVerticalText = (node, designLayer, type = 'edit') => {
	let parameter = node.attrs.parameter;
	let height = node.height();
	if (type == 'create') {
		//取原宽度作为初始高度
		height = node.width();
		if (parameter.TextAlign == 4) {
			node.scaleY(node.scaleX());
			node.scaleX(1);
		}
	}

	let textGroup_temp = node.find('.textGroup')[0];
	let tempText_temp = node.find('.tempText')[0];
	if (textGroup_temp) {
		textGroup_temp.destroy();
	}
	if (tempText_temp) {
		tempText_temp.destroy();
	}

	if (node.getText()) {
		node.getText().destroy();
	}
	designLayer.draw();
	let text = parameter.Data;
	if (node.attrs.type == 'WinText') {
		text = parameter.TextPrefix + parameter.Data + parameter.TextSuffix;
	}

	let textArr = text.split(/\r?\n/);

	let fontSize = Math.round((parameter.FontSize / mmToPoint) * magnification * 100) / 100;
	let letterSpacing = mmToPx(parameter.LetterSpacing, 2);
	let lineHeight = mmToPx(parameter.LineHeight);

	let fontStyle = [];
	if (parameter.Bold) {
		fontStyle.push('bold');
	}
	if (parameter.Italic) {
		fontStyle.push('italic');
	}

	let textDecoration = [];
	if (parameter.UnderLine) {
		textDecoration.push('underline');
	}
	if (parameter.DeleteLine) {
		textDecoration.push('line-through');
	}

	let wordHeight = fontSize + letterSpacing; //每个字的高度+字间距
	let columnText = []; //每列的内容
	if (parameter.AutoWrap && parameter.TextAlign != 4) {
		//自动换行
		height = Math.max(mmToPx(parameter.BoxWidth), fontSize * 1.1);
		let charsPerColumn = Math.floor(height / wordHeight); // 每列可以容纳的字符数
		for (let i = 0; i < textArr.length; i++) {
			let count = Math.ceil(textArr[i].length / charsPerColumn);
			if (count > 1) {
				for (let j = 0; j < textArr[i].length; j += charsPerColumn) {
					columnText.push(textArr[i].slice(j, j + charsPerColumn));
				}
			} else {
				columnText.push(textArr[i]);
			}
		}
	} else {
		columnText = textArr;
		let maxWordNum = 0;
		textArr.forEach(item => {
			maxWordNum = Math.max(maxWordNum, item.length);
		});
		height = maxWordNum * fontSize + letterSpacing * maxWordNum + 1;
		if (parameter.TextAlign == 4) {
			let scale = mmToPx(parameter.BoxWidth) / height;
			node.scaleY(scale);
		}
	}

	let columnCount = columnText.length; //总列数
	// 计算总宽度
	let width = columnCount * fontSize + lineHeight * (columnCount - 1);
	node.width(width);
	node.height(height);
	node.getTag().width(width);
	node.getTag().height(height);
	//text1 用来撑开高度，label会取第一个text的高度作为整体的高度
	let text1 = new Konva.Text({
		name: 'tempText',
		x: 0,
		y: 0,
		width,
		height
	});
	text1.hide();
	node.add(text1);
	let textGroup = new Konva.Group({
		name: 'textGroup',
		x: parameter.MirrorImage ? width : 0,
		y: 0,
		scaleX: parameter.MirrorImage ? -1 : 1,
		scaleY: 1,
		width: width,
		height
	});
	node.add(textGroup);
	let currentX = width - fontSize;
	let regex1 = /^[0-9a-zA-Z]*$/;
	let regex2 = /[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·#（）“”‘|《》、【】[\]]/;
	for (let i = 0; i < columnText.length; i++) {
		let currentY = parameter.TextAlign == 1 ? (height - wordHeight * columnText[i].length) / 2 : parameter.TextAlign == 2 ? height - wordHeight * columnText[i].length : 0;

		for (let j = 0; j < columnText[i].length; j++) {
			let text = new Konva.Text({
				x: currentX + Math.ceil(fontSize) / 2,
				y: currentY + Math.ceil(fontSize) / 2,
				offsetX: Math.ceil(fontSize) / 2,
				offsetY: Math.ceil(fontSize) / 2,
				width: Math.ceil(fontSize), //向上取整，防止字体实际大小超出我们所预设的宽高导致文字无法显示的问题
				height: Math.ceil(fontSize),
				text: columnText[i][j],
				fontSize: fontSize,
				fontFamily: parameter.FontFamily,
				fontStyle: fontStyle.join(' '),
				textDecoration: textDecoration.join(' '),
				rotation: regex1.test(columnText[i][j]) || regex2.test(columnText[i][j]) ? 90 : 0,
				align: 'center',
				lineHeight: 1,
				letterSpacing: 0,
				fill: parameter.AntiBlack ? '#ffffff' : '#000000',
				scaleX: 1,
				scaleY: 1
			});

			currentY += fontSize + letterSpacing;
			if (parameter.TextAlign == 3) {
				//平铺
				let space = (height - wordHeight * columnText[i].length) / (columnText[i].length - 1);
				currentY += space;
			}
			textGroup.add(text);
		}
		currentX -= fontSize + lineHeight;
	}
	setNodeSize(node);
	designLayer.draw();
};

//实时渲染系统时间
const setSystemTime = event => {
	let _this = event;
	clearInterval(_this.systemTimer);
	_this.systemTimer = setInterval(() => {
		let isTimeNode = _this.elementGroup.children.some(node => node.attrs.type == 'TimeText' && node.attrs.parameter.TimeType == 0);
		if (isTimeNode) {
			_this.elementGroup.children.forEach(node => {
				if (node.attrs.type == 'TimeText') {
					let parameter = node.attrs.parameter;
					if (parameter.TimeType == 0) {
						//系统时间
						parameter.SelectDate = getNowDateOrTime('date');
						parameter.SelectTime = getNowDateOrTime('time');
						let date = dealTimeOffset(parameter.SelectDate, parameter.SelectTime, parameter.DayOffset, parameter.MinuteOffset);

						parameter.Data = formatDate(date, parameter.DataFormat, parameter.TimeFormat);
						if (parameter.TextArrange == 1) {
							drawVerticalText(node, _this.designLayer);
						} else {
							node.getText().text(parameter.Data);
							resetNodeAttr(node);
						}
						let { width, height } = setNodeSize(node);
						parameter.Width = width;
            parameter.Height = height;
            node.attrs.parameter = parameter
            if (_this.selectNode && node.id() == _this.selectNode.id()) {
              _this.elementAttr = JSON.parse(JSON.stringify(node.attrs.parameter));
            }
					}
				}
			});
		}
	},1000);
};

//计算同类型控件的数量
const getTypeNextNum = (elementGroup, type) => {
	let num = 0;
	elementGroup.children.forEach(node => {
		if (node.attrs.type == type) {
			num += 1;
		}
	});
	return num;
};

//七牛云路径转base64
const urlToBase64 = (url, callback) => {
	var Img = new Image(),
		dataURL = '';
	Img.src = url + '?v=' + Math.random();
	Img.setAttribute('crossOrigin', 'Anonymous');
	Img.onload = function () {
		var canvas = document.createElement('canvas');
		canvas.width = Img.width;
		canvas.height = Img.height;
		canvas.getContext('2d').drawImage(Img, 0, 0, Img.width, Img.height);
		dataURL = canvas.toDataURL('image/png');
		return callback ? callback(dataURL) : null;
	};
};

//本地路径转base64
const localToBase64 = file => {
	return new Promise(function (resolve, reject) {
		let reader = new FileReader();
		let imgResult = '';
		reader.readAsDataURL(file);
		reader.onload = function () {
			imgResult = reader.result;
		};
		reader.onerror = function (error) {
			reject(error);
		};
		reader.onloadend = function () {
			resolve(imgResult);
		};
	});
};

//字符串转布尔型，大写转小写
const booleanChange = value => {
	if (typeof value == 'boolean') {
		return value;
	}
	return value == 'True' || value == 'true' ? true : false;
};

//布尔型转字符串
const booleanToString = value => {
	return value ? 'True' : 'False';
};

//将数组中的值转成number类型
const changeType = arr => {
	for (let i = 0; i < arr.length; i++) {
		arr[i] = Number(arr[i]);
	}
	return arr;
};

//排序
const dealSort = (val1, val2) => {
	if (val1[0] !== val2[0]) return val1[0] < val2[0] ? -1 : 1;
	else if (val1[1] !== val2[1]) return val1[1] < val2[1] ? -1 : 1;
};

//单元格排序
const dealTableCell = (cell_1, cell_2) => {
	if (cell_1.attrs.pos[0] !== cell_2.attrs.pos[0]) return cell_1.attrs.pos[0] < cell_2.attrs.pos[0] ? -1 : 1;
	else if (cell_1.attrs.pos[1] !== cell_2.attrs.pos[1]) return cell_1.attrs.pos[1] < cell_2.attrs.pos[1] ? -1 : 1;
};

//数据精度转换（四舍五入）px转点
const dataPrecision = (data, precision = 0) => {
	let value = (Math.round((data / magnification) * mmToPoint * Math.pow(10, precision)) / Math.pow(10, precision)).toFixed(precision);
	return Number(value);
};

//数据精度转换（四舍五入）mm转点
const mmToPointPrecision = (data, precision = 0) => {
	let value = (Math.round(data * mmToPoint * Math.pow(10, precision)) / Math.pow(10, precision)).toFixed(precision);
	return Number(value);
};

//数据精度转换（四舍五入）px转mm
const pxToMm = (data, precision = 0) => {
	let value = (Math.round((data / magnification) * Math.pow(10, precision)) / Math.pow(10, precision)).toFixed(precision);
	return Number(value);
};
//数据精度转换（四舍五入）mm转px
const mmToPx = (data, precision = 0) => {
	let value = (Math.round(data * magnification * Math.pow(10, precision)) / Math.pow(10, precision)).toFixed(precision);
	return Number(value);
};

//设置字体样式
const setFontStyle = data => {
	let font_style = [];
	let text_decoration = [];
	if (data.indexOf('Bold') > -1) {
		font_style.push('bold');
	}
	if (data.indexOf('Italic') > -1) {
		font_style.push('italic');
	}
	if (data.indexOf('UnderLine') > -1) {
		text_decoration.push('underline');
	}
	if (data.indexOf('Strikeout') > -1) {
		text_decoration.push('line-through');
	}
	font_style = font_style.join(' ');
	text_decoration = text_decoration.join(' ');
	return {
		font_style,
		text_decoration
	};
};

//处理字体样式
const getFontStyle = parameter => {
	let styleArr = [];
	if (parameter.Bold) {
		styleArr.push('Bold');
	}
	if (parameter.Italic) {
		styleArr.push('Italic');
	}
	if (parameter.UnderLine) {
		styleArr.push('UnderLine');
	}
	if (parameter.DeleteLine) {
		styleArr.push('Strikeout');
	}
	if (!parameter.Bold && !parameter.Italic && !parameter.UnderLine && !parameter.DeleteLine) {
		styleArr.push('Regular');
	}

	let fontStyle = styleArr.join(',');
	return fontStyle;
};

//解决镜像情况下文本位置偏移的问题及自动换行、以及拉伸文本
const resetNodeAttr = node => {
	node.getTag().height(node.height());
	if (node.attrs.parameter.TextAlign == 4) {
		let width = node.width() * node.scaleX();
		node.getText().width(null);
		let textWidth = node.getText().width();
		let scale = width / textWidth;
		node.getText().width(textWidth);
		node.scaleX(scale);
	} else {
		if (!node.attrs.parameter.AutoWrap) {
			node.getText().width(null);
			let width = node.getText().width();
			node.getText().width(width);
			node.width(width);
		}
	}

	if (node.attrs.parameter.MirrorImage) {
		// console.log('MirrorImage');
		let width = node.getText().width();
		node.getText().x(width);
		node.width(width);
	} else {
		node.getText().x(0);
	}
	node.getTag().width(node.width());
	setNodeSize(node);
	node.attrs.parameter.Width = pxToMm(node.width() * node.scaleX(), 2);
	node.attrs.parameter.BoxWidth = pxToMm(node.width() * node.scaleX(), 2);
	node.attrs.parameter.Height = pxToMm(node.height() * node.scaleY(), 2);
	return node;
};

//计算元素的坐标
const setNodePos = node => {
	let x = node.x();
	let y = node.y();
	let scaleX = Math.abs(node.scaleX());
	let scaleY = Math.abs(node.scaleY());
	let width = node.width() * scaleX;
	let height = node.height() * scaleY;

	let type = node.attrs.type;
	if (type == 'WinText' || type == 'TimeText' || type == 'GeneralBarcode' || type == 'QRCode' || type == 'Image') {
		x = node.rotation() == 90 ? x - height : node.rotation() == 180 ? x - width : x;
		y = node.rotation() == 180 ? y - height : node.rotation() == -90 ? y - width : y;
	} else if (type == 'Ellipse') {
		x = x - width / 2;
		y = y - height / 2;
	}
	let StartX = pxToMm(x, 2);
	let StartY = pxToMm(y, 2);
	node.attrs.parameter.StartX = StartX;
	node.attrs.parameter.StartY = StartY;
};

//计算元素的尺寸
const setNodeSize = node => {
	let scaleX = Math.abs(node.scaleX());
	let scaleY = Math.abs(node.scaleY());
	let width = pxToMm(node.width() * scaleX, 2);
	let height = pxToMm(node.height() * scaleY, 2);
	node.attrs.parameter.Width = width;
	node.attrs.parameter.Height = height;
	if (node.attrs.parameter.TextArrange == 1) {
		node.attrs.parameter.BoxWidth = height;
	} else {
		node.attrs.parameter.BoxWidth = width;
	}

	return { width, height };
};

//根据多个单元格坐标，获取范围内的最小列、最小行、最大列、最大行
const getCellRang = mulCellPos => {
	let minRow = Math.min.apply(
		Math,
		mulCellPos.map(item => {
			return item[0];
		})
	);
	let maxRow = Math.max.apply(
		Math,
		mulCellPos.map(item => {
			return item[0];
		})
	);

	let minColumn = Math.min.apply(
		Math,
		mulCellPos.map(item => {
			return item[1];
		})
	);

	let maxColumn = Math.max.apply(
		Math,
		mulCellPos.map(item => {
			return item[1];
		})
	);
	return {
		minRow,
		maxRow,
		minColumn,
		maxColumn
	};
};

//求和
const sumData = arr => {
	let rusult = arr.reduce((prev, cur, index, arr) => {
		return prev + cur;
	}, 0);
	return rusult;
};

//(a-z)26进制转10进制
const convertToTen = str => {
	var num = 0,
		index = 1;
	for (let i = str.length - 1; i >= 0; i--) {
		if (str.charCodeAt(i)) num += ((str.toLowerCase().charCodeAt(i) - 97) % 26) * index;
		index = index * 26;
	}
	return num;
};

//10进制转(a-z)26进制
const convertToTwentySix = num => {
	var str = '';
	if (num == 0) {
		str = 'a';
	} else {
		while (num !== 0) {
			var temp = num % 26;
			str += String.fromCharCode(temp + 97);
			num = parseInt(num / 26);
		}
	}
	//从后往前存储，进行反转
	return str.split('').reverse().join('');
};

// 位数不够时前面补0
const setBeforeZero = (type, sequenceValue, InitialValue) => {
	let value = sequenceValue;
	let upperCase = false;
	//判断要转成大写还是小写
	for (let i = 0; i < InitialValue.length; i++) {
		let strCode = InitialValue.charCodeAt(i);
		if (strCode >= 65 && strCode <= 90) {
			upperCase = true;
			break;
		} else if (strCode >= 97 && strCode <= 122) {
			upperCase = false;
			break;
		}
	}
	if (upperCase) {
		//大写
		if (value.length < InitialValue.length) {
			//前面补位
			var length = InitialValue.length - value.length;
			for (let k = 0; k < length; k++) {
				if (type == 26) {
					value = 'A' + value.toUpperCase();
				} else {
					value = '0' + value.toUpperCase();
				}
			}
		} else {
			value = value.toUpperCase();
		}
	} else {
		//小写
		if (value.length < InitialValue.length) {
			//前面补位
			var length = InitialValue.length - value.length;
			for (let k = 0; k < length; k++) {
				if (type == 26) {
					value = 'a' + value;
				} else {
					value = '0' + value;
				}
			}
		}
	}
	return value;
};

//处理标签数据
const dealLabelData = list => {
	return new Promise((resolve, reject) => {
		let labelList = [];
		list.forEach((item, index) => {
			let attrs = JSON.parse(JSON.stringify(item.attrs));
			let parameter = attrs.parameter;
			let nodeObj;
			let bar_originalSize = 37;
			let qr_originalSize = 21;
			let Zoom = 2;
			let QRCodeWidth = 0;
			let QRCodeHeight = 0;
			let x = item.x();
			let y = item.y();
			let scaleX = Math.abs(item.scaleX());
			let scaleY = Math.abs(item.scaleY());
			let width = item.width() * scaleX;
			let height = item.height() * scaleY;
			let StartX = 0;
			let StartY = 0;
			let AngleRound = item.rotation() == -90 ? 270 : item.rotation();
			//计算出左上角的点坐标
			if (attrs.type == 'WinText' || attrs.type == 'TimeText' || attrs.type == 'GeneralBarcode' || attrs.type == 'QRCode' || attrs.type == 'Image') {
				x = AngleRound == 90 ? x - height : AngleRound == 180 ? x - width : x;
				y = AngleRound == 180 ? y - height : AngleRound == 270 ? y - width : y;
			}
			let DataSourceList = [];

			switch (attrs.type) {
				case 'WinText':
					parameter.DataSourceList.forEach(data => {
						let obj = {
							DataSourceType: data.DataSourceType,
							FirstValue: data.FirstValue,
							ColumnName: data.DataSourceType == 1 ? data.ColumnName : '',
							QuerySql: data.DataSourceType == 1 ? data.QuerySql : '',
							TableName: data.DataSourceType == 1 ? data.TableName : '',
							ConnectionStr: data.DataSourceType == 1 ? data.ConnectionStr : '',
							DataType: '2',
							DataFormat: 'yyyy-MM-dd HH:mm:ss',
							NumberFormat: '123456789',
							SequenceType: data.SequenceType,
							InitialValue: data.DataSourceType == 2 ? data.Data : '',
							AddedValue: data.DataSourceType == 2 ? data.AddedValue : '',
							Quantity: data.DataSourceType == 2 ? data.Quantity : '',
							ReferenceId: data.DataSourceType == 3 ? data.ReferenceId : ''
						};
						DataSourceList.push(obj);
					});
					nodeObj = {
						Id: item.id(),
						zOrder: index,
						Type: attrs.type,
						Name: `${attrs.type}_${item.id()}`,
						Lock: parameter.Lock,
						OriginalData: parameter.Data,
						Data: parameter.Data,
						Font: {
							'@FontFamily': parameter.FontFamily,
							'@FontSize': parameter.FontSize,
							'@FontStyle': getFontStyle(parameter)
						},
						Vert: false,
						TimeDate: 0,
						Key: '',
						RowSpacing1: mmToPointPrecision(parameter.LineHeight),
						WordSpacing: mmToPointPrecision(parameter.LetterSpacing),
						MigrationTime: 0,
						AutoWrap: parameter.AutoWrap,
						BoxWidth: dataPrecision(width),
						StartX: dataPrecision(x),
						StartY: dataPrecision(y),
						Width: dataPrecision(width),
						Height: dataPrecision(height),
						AngleRound,
						Color: '-16777216',
						PenWidth: 0,
						DashStyle: 0,
						FillColor: '-16777216',
						AntiBlack: parameter.AntiBlack,
						MirrorImage: parameter.MirrorImage,
						TextAlign: parameter.TextAlign,
						TextPrefix: parameter.TextPrefix,
						TextSuffix: parameter.TextSuffix,
						TextArrange: parameter.TextArrange,
						DataSourceList
					};
					break;
				case 'TimeText':
					let DataFormat = '';
					let TimeStamp = '';
					if (parameter.DataFormat != '' && parameter.TimeFormat == '') {
						DataFormat = `${parameter.DataFormat}`;
						TimeStamp = parameter.SelectDate;
					} else if (parameter.DataFormat == '' && parameter.TimeFormat != '') {
						DataFormat = `${parameter.TimeFormat}`;
						TimeStamp = parameter.SelectTime;
					} else {
						DataFormat = `${parameter.DataFormat} ${parameter.TimeFormat}`;
						TimeStamp = `${parameter.SelectDate} ${parameter.SelectTime}`;
					}
					TimeStamp = new Date(TimeStamp).getTime();

					nodeObj = {
						Id: item.id(),
						zOrder: index,
						Type: attrs.type,
						Name: `${attrs.type}_${item.id()}`,
						Lock: parameter.Lock,
						OriginalData: parameter.Data,
						Data: parameter.Data,
						Font: {
							'@FontFamily': parameter.FontFamily,
							'@FontSize': parameter.FontSize,
							'@FontStyle': getFontStyle(parameter)
						},
						Vert: false,
						DataType: '0',
						TimeDate: parameter.TimeType,
						DataFormat,
						DayOffset: parameter.DayOffset,
						MinuteOffset: parameter.MinuteOffset,
						TimeStamp,
						Key: '',
						NumberFormat: '123456789',
						RowSpacing1: mmToPointPrecision(parameter.LineHeight),
						WordSpacing: mmToPointPrecision(parameter.LetterSpacing),
						MigrationTime: 0,
						AutoWrap: parameter.AutoWrap,
						BoxWidth: dataPrecision(width),
						StartX: dataPrecision(x),
						StartY: dataPrecision(y),
						Width: dataPrecision(width),
						Height: dataPrecision(height),
						AngleRound,
						DataSourceType: parameter.DataSourceType,
						FirstValue: parameter.Data,
						Color: '-16777216',
						PenWidth: 0,
						DashStyle: 0,
						FillColor: '-16777216',
						AntiBlack: parameter.AntiBlack,
						MirrorImage: parameter.MirrorImage,
						TextAlign: parameter.TextAlign,
						TextArrange: parameter.TextArrange
					};
					break;
				case 'GeneralBarcode':
					let Localtype = 'BOTTOMCENTER';
					let DisplaPosition = 1;
					if (parameter.BarcodeNumPosition == 1) {
						//下方
						Localtype = parameter.TextAlign == 0 ? 'BOTTOMLEFT' : parameter.TextAlign == 2 ? 'BOTTOMRIGHT' : parameter.TextAlign == 3 ? 'BOTTOMJUSTIFY' : 'BOTTOMCENTER';
						DisplaPosition = parameter.TextAlign == 1 ? 2 : parameter.TextAlign == 2 ? 3 : parameter.TextAlign == 3 ? 4 : 1;
					} else if (parameter.BarcodeNumPosition == 2) {
						// 上方
						Localtype = parameter.TextAlign == 0 ? 'TOPLEFT' : parameter.TextAlign == 2 ? 'TOPRIGHT' : parameter.TextAlign == 3 ? 'TOPJUSTIFY' : 'TOPCENTER';
						DisplaPosition = parameter.TextAlign == 1 ? 6 : parameter.TextAlign == 2 ? 7 : parameter.TextAlign == 3 ? 8 : 5;
					}
					Zoom = Math.round(((width / magnification) * mmToPoint) / bar_originalSize);

					parameter.DataSourceList.forEach(data => {
						let obj = {
							DataSourceType: data.DataSourceType,
							FirstValue: data.FirstValue,
							ColumnName: data.DataSourceType == 1 ? data.ColumnName : '',
							QuerySql: data.DataSourceType == 1 ? data.QuerySql : '',
							TableName: data.DataSourceType == 1 ? data.TableName : '',
							ConnectionStr: data.DataSourceType == 1 ? data.ConnectionStr : '',
							DataType: '2',
							DataFormat: 'yyyy-MM-dd HH:mm:ss',
							NumberFormat: '123456789',
							SequenceType: data.SequenceType,
							InitialValue: data.DataSourceType == 2 ? data.Data : '',
							AddedValue: data.DataSourceType == 2 ? data.AddedValue : '',
							Quantity: data.DataSourceType == 2 ? data.Quantity : '',
							ReferenceId: data.DataSourceType == 3 ? data.ReferenceId : ''
						};
						DataSourceList.push(obj);
					});

					nodeObj = {
						Id: item.id(),
						zOrder: index,
						Type: attrs.type,
						Name: `${attrs.type}_${item.id()}`,
						Lock: parameter.Lock,
						BarcodeImage: attrs.imageSrc ? attrs.imageSrc.split('base64,')[1] : '',
						BarcodeType: parameter.BarcodeType,
						HasLabel: parameter.BarcodeNumPosition == 0 ? false : true,
						Localtype,
						DisplaPosition,
						Font: {
							'@FontFamily': 'Arial',
							'@FontSize': (Math.round(attrs.options.textsize * 10) / 10).toFixed(1),
							'@FontStyle': getFontStyle(parameter)
						},
						Zoom,
						OrgSize: dataPrecision(width),
						BarCodeHight: dataPrecision(height - attrs.options.textsize),
						Data: parameter.Data,
						Key: '',
						StartX: dataPrecision(x),
						StartY: dataPrecision(y),
						Width: dataPrecision(width),
						Height: dataPrecision(height),
						AngleRound,
						Color: '-16777216',
						PenWidth: 0,
						DashStyle: 0,
						FillColor: '-16777216',
						DataSourceList
					};
					break;
				case 'QRCode':
					Zoom = Math.round(((width / magnification) * mmToPoint) / qr_originalSize);
					QRCodeWidth = dataPrecision(width / Zoom);
					QRCodeHeight = dataPrecision(width / Zoom);

					parameter.DataSourceList.forEach(data => {
						let obj = {
							DataSourceType: data.DataSourceType,
							FirstValue: data.FirstValue,
							ColumnName: data.DataSourceType == 1 ? data.ColumnName : '',
							QuerySql: data.DataSourceType == 1 ? data.QuerySql : '',
							TableName: data.DataSourceType == 1 ? data.TableName : '',
							ConnectionStr: data.DataSourceType == 1 ? data.ConnectionStr : '',
							DataType: '2',
							DataFormat: 'yyyy-MM-dd HH:mm:ss',
							NumberFormat: '123456789',
							SequenceType: data.SequenceType,
							InitialValue: data.DataSourceType == 2 ? data.Data : '',
							AddedValue: data.DataSourceType == 2 ? data.AddedValue : '',
							Quantity: data.DataSourceType == 2 ? data.Quantity : '',
							ReferenceId: data.DataSourceType == 3 ? data.ReferenceId : ''
						};
						DataSourceList.push(obj);
					});

					nodeObj = {
						Id: item.id(),
						zOrder: index,
						Type: attrs.type,
						Name: `${attrs.type}_${item.id()}`,
						Lock: parameter.Lock,
						BarcodeImage: attrs.imageSrc ? attrs.imageSrc.split('base64,')[1] : '',
						BarcodeType: parameter.BarcodeType,
						ECLevelBits: 'Low',
						Zoom,
						OrgSize: dataPrecision(width),
						QRCodeWidth,
						QRCodeHeight,
						BarCodeHight: dataPrecision(height),
						Data: parameter.Data,
						Key: '',
						StartX: dataPrecision(x),
						StartY: dataPrecision(y),
						Width: dataPrecision(width),
						Height: dataPrecision(height),
						AngleRound,
						Color: '-16777216',
						PenWidth: 0,
						DashStyle: 0,
						FillColor: '-16777216',
						DataSourceList
					};
					break;
				case 'Image':
					nodeObj = {
						Id: item.id(),
						zOrder: index,
						Type: attrs.type,
						Name: `${attrs.type}_${item.id()}`,
						Lock: parameter.Lock,
						OriginalImage: attrs.imageSrc ? attrs.imageSrc.split(',')[1] : '',
						Mirror: 'None',
						Inverse: false,
						Halftone: 'None',
						ISParticipating: parameter.ISParticipating, //等比缩放
						ImageFilePath: '',
						Image: '',
						StartX: dataPrecision(x),
						StartY: dataPrecision(y),
						Width: dataPrecision(width),
						Height: dataPrecision(height),
						AngleRound,
						Data: null,
						Color: '-16777216',
						PenWidth: 0,
						DashStyle: 0,
						FillColor: '-16777216'
					};
					break;
				case 'Line':
				case 'LineHorizontal':
				case 'LineVertical':
					let lgx = Math.abs(item.points()[2] - item.points()[0]);
					let lgy = Math.abs(item.points()[3] - item.points()[1]);
					StartX = item.x();
					StartY = parameter.LineDirection == 'LeftLine' ? item.y() + lgy * scaleY : item.y();
					let EndX = item.x();
					if (attrs.type == 'Line' || attrs.type == 'LineHorizontal') {
						EndX = item.x() + lgx * scaleX;
					}
					let EndY = item.y();
					if ((attrs.type == 'Line' && parameter.LineDirection == 'RightLine') || attrs.type == 'LineVertical') {
						EndY = item.y() + lgy * scaleY;
					}

					nodeObj = {
						Id: item.id(),
						zOrder: index,
						Type: attrs.type,
						Name: `${parameter.LineDirection}_${item.id()}`,
						Lock: parameter.Lock,
						StartX: dataPrecision(StartX),
						StartY: dataPrecision(StartY),
						EndX: dataPrecision(EndX),
						EndY: dataPrecision(EndY),
						AngleRound,
						Color: '-16777216',
						PenWidth: dataPrecision(item.strokeWidth()),
						DashStyle: parameter.DashStyle,
						FillColor: '-16777216'
          };
					break;
				case 'Rectangle':
				case 'RoundRectangle':
					nodeObj = {
						Id: item.id(),
						zOrder: index,
						Type: attrs.type,
						Name: `${attrs.type}_${item.id()}`,
						Lock: parameter.Lock,
						StartX: dataPrecision(x - item.strokeWidth() / 2),
						StartY: dataPrecision(y - item.strokeWidth() / 2),
						Width: dataPrecision(width + item.strokeWidth()),
						Height: dataPrecision(height + item.strokeWidth()),
						AngleRound,
						CornerRadius: dataPrecision(item.cornerRadius()),
						Data: null,
						Color: '-16777216',
						PenWidth: dataPrecision(item.strokeWidth()),
						DashStyle: parameter.Dash ? 1 : 0,
						FillColor: '-16777216',
						Fill: parameter.Fill
					};
					break;
				case 'Ellipse':
					width = item.radiusX() * 2 * item.scaleX();
					height = item.radiusY() * 2 * item.scaleY();
					StartX = x - width / 2;
					StartY = y - height / 2;
					nodeObj = {
						Id: item.id(),
						zOrder: index,
						Type: attrs.type,
						Name: `${attrs.type}_${item.id()}`,
						Lock: parameter.Lock,
						StartX: dataPrecision(StartX),
						StartY: dataPrecision(StartY),
						Width: dataPrecision(width),
						Height: dataPrecision(height),
						AngleRound,
						Data: null,
						Color: '-16777216',
						PenWidth: dataPrecision(item.strokeWidth()),
						DashStyle: parameter.Dash ? 1 : 0,
						FillColor: '-16777216',
						Fill: parameter.Fill
					};
					break;
				case 'Diamond':
					nodeObj = {
						Id: item.id(),
						zOrder: index,
						Type: attrs.type,
						Name: `${attrs.type}_${item.id()}`,
						Lock: parameter.Lock,
						StartX: dataPrecision(x),
						StartY: dataPrecision(y),
						Width: dataPrecision(width),
						Height: dataPrecision(height),
						AngleRound,
						Data: null,
						Color: '-16777216',
						PenWidth: dataPrecision(item.strokeWidth()),
						DashStyle: parameter.Dash ? 1 : 0,
						FillColor: '-16777216',
						Fill: parameter.Fill,
						Vertice: [
							{
								'@X': dataPrecision(x + width / 2),
								'@Y': dataPrecision(y),
								'@order': '0'
							},
							{
								'@X': dataPrecision(x + width),
								'@Y': dataPrecision(y + height / 2),
								'@order': '1'
							},
							{
								'@X': dataPrecision(x + width / 2),
								'@Y': dataPrecision(y + height),
								'@order': '2'
							},
							{
								'@X': dataPrecision(x),
								'@Y': dataPrecision(y + height / 2),
								'@order': '3'
							}
						]
					};
					break;
				case 'Triangle':
					nodeObj = {
						Id: item.id(),
						zOrder: index,
						Type: attrs.type,
						Name: `${attrs.type}_${item.id()}`,
						Lock: parameter.Lock,
						StartX: dataPrecision(x),
						StartY: dataPrecision(y),
						Width: dataPrecision(width),
						Height: dataPrecision(height),
						AngleRound,
						Data: null,
						Color: '-16777216',
						PenWidth: dataPrecision(item.strokeWidth()),
						DashStyle: parameter.Dash ? 1 : 0,
						FillColor: '-16777216',
						Fill: parameter.Fill,
						Vertice: [
							{
								'@X': dataPrecision(x),
								'@Y': dataPrecision(y + height),
								'@order': '0'
							},
							{
								'@X': dataPrecision(x + width),
								'@Y': dataPrecision(y + height),
								'@order': '1'
							},
							{
								'@X': dataPrecision(x + width / 2),
								'@Y': dataPrecision(y),
								'@order': '2'
							}
						]
					};
					break;
				case 'Table':
					let cellGroup = item.find('.cellGroup')[0];
					parameter.DicLineLocH.sort(function (a, b) {
						return a - b;
					});
					parameter.DicLineLocH.shift();
					parameter.DicLineLocH.pop();
					let DicLineLocH = '';
					if (parameter.DicLineLocH.length > 0) {
						let lineArr = [];
						parameter.DicLineLocH.forEach(val => {
							lineArr.push(dataPrecision(val, 2));
						});
						DicLineLocH = lineArr.join(',');
					}
					parameter.DicLineLocW.sort(function (a, b) {
						return a - b;
					});
					parameter.DicLineLocW.shift();
					parameter.DicLineLocW.pop();
					let DicLineLocW = '';
					if (parameter.DicLineLocW.length > 0) {
						let lineArr = [];
						parameter.DicLineLocW.forEach(val => {
							lineArr.push(dataPrecision(val, 2));
						});
						DicLineLocW = lineArr.join(',');
					}

					let cellGroupArr = cellGroup.children;
					cellGroupArr.sort(dealTableCell);

					DataSourceList = cellGroupArr.map(cell => {
						let cellText = cell.find('.cellText')[0];
						return {
							Key: cell.attrs.allPos.join('|'),
							StartX: dataPrecision(cell.x() + cellGroup.x(), 2),
							StartY: dataPrecision(cell.y() + cellGroup.y(), 2),
							Width: dataPrecision(cell.width(), 2),
							Height: dataPrecision(cell.height(), 2),
							Data: cell.attrs.parameter.FirstValue ? cell.attrs.parameter.FirstValue : '',
							Font: {
								'@FontFamily': cell.attrs.parameter.FontFamily,
								'@FontSize': dataPrecision(cellText.fontSize(), 1),
								'@FontStyle': getFontStyle(cell.attrs.parameter)
							},
							RowSpacing1: dataPrecision((cellText.lineHeight() - 1) * cellText.fontSize()),
							WordSpacing: dataPrecision(cellText.letterSpacing()),
							TextAlign: cell.attrs.parameter.TextAlign,
							AutoWrap: booleanToString(cell.attrs.parameter.AutoWrap),
							AntiBlack: booleanToString(cell.attrs.parameter.AntiBlack),
							MirrorImage: booleanToString(cell.attrs.parameter.MirrorImage),
							DataSourceType: cell.attrs.parameter.DataSourceType,
							ColumnName: cell.attrs.parameter.DataSourceType == 1 ? cell.attrs.parameter.ColumnName : '',
							QuerySql: cell.attrs.parameter.DataSourceType == 1 ? cell.attrs.parameter.QuerySql : '',
							TableName: cell.attrs.parameter.DataSourceType == 1 ? cell.attrs.parameter.TableName : '',
							ConnectionStr: cell.attrs.parameter.DataSourceType == 1 ? cell.attrs.parameter.ConnectionStr : '',
							DataType: '2',
							DataFormat: 'yyyy-MM-dd HH:mm:ss',
							NumberFormat: '123456789',
							SequenceType: cell.attrs.parameter.SequenceType,
							InitialValue: cell.attrs.parameter.DataSourceType == 2 ? cell.attrs.parameter.Data : '',
							AddedValue: cell.attrs.parameter.DataSourceType == 2 ? cell.attrs.parameter.AddedValue : '',
							Quantity: cell.attrs.parameter.DataSourceType == 2 ? cell.attrs.parameter.Quantity : ''
						};
					});
					nodeObj = {
						Id: item.id(),
						zOrder: index,
						Type: attrs.type,
						Name: `${attrs.type}_${item.id()}`,
						Lock: parameter.Lock,
						StartX: dataPrecision(x),
						StartY: dataPrecision(y),
						Width: dataPrecision(width),
						Height: dataPrecision(height),
						AngleRound,
						PenWidth: mmToPointPrecision(parameter.PenWidth),
						DicLineLocH,
						DicLineLocW,
						DataSourceList
					};
					break;
			}
			labelList.push(nodeObj);
    });
		resolve(labelList);
	});
};

export {
  booleanChange,
  booleanToString,
  changeType,
  combinationData,
  convertToTen,
  convertToTwentySix,
  dataPrecision,
  dealAssociateElements,
  dealLabelData,
  dealSort,
  dealTableCell,
  dealTimeOffset,
  drawHorizontalText,
  drawVerticalText,
  formatDate,
  getAssociate,
  getBarCodeTypeList,
  getCellRang,
  getCodeType,
  getEclevelList,
  getFontFamily,
  getGenerateCodeImg,
  getNowDateOrTime,
  getQrCodeTypeList,
  getTextAlignValue,
  getTimeFormatList,
  getTypeNextNum,
  loadFont,
  localToBase64,
  mmToPointPrecision,
  mmToPx,
  pxToMm,
  randomString,
  resetNodeAttr,
  setBeforeZero,
  setFontStyle,
  setNodePos,
  setNodeSize,
  setSystemTime,
  sumData,
  urlToBase64
};

