<template>
  <div class="editguide">
    <div>
      <div class="editguide-box0 fadeCenter" v-if="step0">
        <div class="editguide-box0-icon" @click="closeGuide">
          <i class="el-icon-close"></i>
        </div>
        <img
          class="editguide-img1"
          :style="{ height: VUE_APP_VERSION == 'hprt' ? '60px' : '' }"
          :src="
            VUE_APP_VERSION == 'hprt'
              ? require('../assets/here/here-logo.png')
              : require('../assets/logo.png')
          "
          alt=""
        />
        <img class="editguide-img2" src="../assets/edit-welcome.png" alt="" />
        <div class="editguide-box0-title">{{ $t(`guide.guideText21`) }}</div>
        <div class="editguide-box0-description">
          {{ $t(`guide.guideText22`) }}
        </div>
        <div class="editguide-box0-btn">
          <el-button @click="closeGuide">{{
            $t(`guide.guideText7`)
          }}</el-button>
          <el-button @click="(step0 = false), (step1 = true)">{{
            $t(`guide.guideText8`)
          }}</el-button>
        </div>
      </div>
    </div>
    <div v-if="step1" class="fadeCenter">
      <div class="zujian-img1">
        <img
          class="zujian-img"
          :src="
            language == 'zh'
              ? require('../assets/guide/left-box-zh.png')
              : language == 'pt'
              ? require('../assets/guide/left-box-pt.png')
              : language == 'es'
              ? require('../assets/guide/left-box-es.png')
              : language == 'ru'
              ? require('../assets/guide/left-box-ru.png')
              : require('../assets/guide/left-box-en.png')
          "
          alt=""
        />
      </div>
      <div class="editguide-box1">
        <div class="animation-box1">
          <div class="editguide-box-shubiao1 subiao1"></div>
          <div class="editguide-box-gongjulan gongjulan"></div>
          <div class="editguide-box-wenzi wenzi"></div>
          <div class="editguide-box-huaban huaban"></div>
        </div>
        <div class="editguide-right1">
          <div class="editguide-title">{{ $t(`guide.guideText23`) }}</div>
          <div class="editguide-text">{{ $t(`guide.guideText24`) }}</div>
          <div class="editguide-button">
            <div>
              <div class="editguide-dot-select"></div>
              <div class="editguide-dot"></div>
              <div class="editguide-dot"></div>
              <div class="editguide-dot"></div>
            </div>
            <div>
              <el-button @click="closeGuide">{{
                $t(`guide.guideText7`)
              }}</el-button>
              <el-button el-button @click="(step1 = false), (step2 = true)">{{
                $t(`guide.guideText25`)
              }}</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="step2" class="fadeCenter">
      <div class="zujian-img2">
        <img class="zujian-img" src="../assets/tool-box.png" alt="" />
      </div>
      <div class="editguide-box2">
        <div class="animation-box2">
          <div class="editguide-box-shubiao2 subiao2"></div>
          <div class="editguide-box-gongneng gongneng"></div>
          <div class="editguide-box-youjian youjian"></div>
        </div>
        <div class="editguide-right2">
          <div class="editguide-title">{{ $t(`guide.guideText26`) }}</div>
          <div class="editguide-text">{{ $t(`guide.guideText27`) }}</div>
          <div class="editguide-button">
            <div>
              <div class="editguide-dot"></div>
              <div class="editguide-dot-select"></div>
              <div class="editguide-dot"></div>
              <div class="editguide-dot"></div>
            </div>
            <div>
              <el-button @click="closeGuide">{{
                $t(`guide.guideText7`)
              }}</el-button>
              <el-button @click="(step2 = false), (step3 = true)">{{
                $t(`guide.guideText25`)
              }}</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="step3" class="fadeCenter">
      <div class="zujian-img3">
        <img
          class="zujian-img"
          :src="
            language == 'zh'
              ? require('../assets/guide/right-box-zh.png')
              : language == 'pt'
              ? require('../assets/guide/right-box-pt.png')
              : language == 'es'
              ? require('../assets/guide/right-box-es.png')
              : language == 'ru'
              ? require('../assets/guide/right-box-ru.png')
              : require('../assets/guide/right-box-en.png')
          "
          alt=""
        />
      </div>
      <div class="editguide-box3">
        <div class="animation-box3">
          <div class="editguide-box-shubiao3 subiao3"></div>
          <div class="editguide-box-shuxing shuxing"></div>
          <div class="editguide-box-shuxingqu shuxingqu"></div>
        </div>
        <div class="editguide-right3">
          <div class="editguide-title">{{ $t(`guide.guideText29`) }}</div>
          <div class="editguide-text">{{ $t(`guide.guideText30`) }}</div>
          <div class="editguide-button">
            <div>
              <div class="editguide-dot"></div>
              <div class="editguide-dot"></div>
              <div class="editguide-dot-select"></div>
              <div class="editguide-dot"></div>
            </div>
            <div>
              <el-button @click="closeGuide">{{
                $t(`guide.guideText7`)
              }}</el-button>
              <el-button @click="(step3 = false), (step4 = true)">{{
                $t(`guide.guideText25`)
              }}</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="step4" class="fadeCenter">
      <div class="zujian-img4" :style="{ width: imgWidth + 'px' }">
        <img
          class="zujian-img"
          :src="
            language == 'zh'
              ? require('../assets/guide/save-print-box-zh.png')
              : language == 'pt'
              ? require('../assets/guide/save-print-box-pt.png')
              : language == 'es'
              ? require('../assets/guide/save-print-box-es.png')
              : language == 'ru'
              ? require('../assets/guide/save-print-box-ru.png')
              : require('../assets/guide/save-print-box-en.png')
          "
          alt=""
        />
      </div>
      <div class="editguide-box4">
        <div class="animation-box4">
          <div class="editguide-box-dayin dayin"></div>
        </div>
        <div class="editguide-right4">
          <div class="editguide-title">{{ $t(`guide.guideText31`) }}</div>
          <div class="editguide-text">{{ $t(`guide.guideText32`) }}</div>
          <div class="editguide-button">
            <div>
              <div class="editguide-dot"></div>
              <div class="editguide-dot"></div>
              <div class="editguide-dot"></div>
              <div class="editguide-dot-select"></div>
            </div>
            <div>
              <el-button @click="closeGuide">{{
                $t(`guide.guideText33`)
              }}</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      step0: true,
      step1: false,
      step2: false,
      step3: false,
      step4: false,
      language: "zh",
      sreenWidth: 0,
      imgWidth: 360,
      VUE_APP_VERSION: "hprt",
    };
  },
  mounted() {
    this.VUE_APP_VERSION = process.env.VUE_APP_VERSION;
    this.language = this.$i18n.locale;
    this.sreenWidth = document.body.clientWidth;
    if (this.sreenWidth > 1300) {
      switch (this.language) {
        case "zh":
          this.imgWidth = 365;
          break;
        case "en":
          this.imgWidth = 418;
          break;
        case "pt":
          this.imgWidth = 540;
          break;
        case "es":
          this.imgWidth = 560;
          break;
        case "ru":
          this.imgWidth = 537;
          break;
      }
    } else {
      switch (this.language) {
        case "zh":
          this.imgWidth = 250;
          break;
        case "en":
          this.imgWidth = 270;
          break;
        case "pt":
          this.imgWidth = 320;
          break;
        case "es":
          this.imgWidth = 334;
          break;
        case "ru":
          this.imgWidth = 320;
          break;
      }
    }
    window.addEventListener("resize", this.resize);
  },
  unmounted() {
    window.removeEventListener("resize", this.resize);
  },
  methods: {
    resize() {
      var that = this;
      that.sreenWidth = document.body.clientWidth;
      if (that.sreenWidth > 1300) {
        switch (that.language) {
          case "zh":
            that.imgWidth = 365;
            break;
          case "en":
            that.imgWidth = 418;
            break;
          case "pt":
            that.imgWidth = 540;
            break;
          case "es":
            that.imgWidth = 560;
            break;
          case "ru":
            this.imgWidth = 537;
            break;
        }
      } else {
        switch (that.language) {
          case "zh":
            that.imgWidth = 250;
            break;
          case "en":
            that.imgWidth = 270;
            break;
          case "pt":
            that.imgWidth = 320;
            break;
          case "es":
            that.imgWidth = 334;
            break;
          case "ru":
            this.imgWidth = 320;
            break;
        }
      }
    },
    closeGuide() {
      this.$emit("close");
      this.step0 = false;
      this.step1 = false;
      this.step2 = false;
      this.step3 = false;
      this.step4 = false;
    },
  },
};
</script>
<style scoped>
.editguide {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100vh;
  font-size: 16px;
}
.editguide-box0 {
  position: absolute;
  width: 720px;
  background: #ffffff;
  border-radius: 4px;
  left: calc(50% - 360px);
  top: calc(50% - 276px);
  padding: 42px 72px 40px 72px;
}
.editguide-box0-icon {
  position: absolute;
  right: 28px;
  top: 27px;
  font-size: 25px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.45);
}
.editguide-box0-icon:hover {
  color: #000;
}
.editguide-img1 {
  width: 200px;
  height: 50px;
  object-fit: contain;
}
.editguide-img2 {
  width: 100%;
  margin-top: 30px;
}
.editguide-box0-title {
  width: 100%;
  font-size: 28px;
  font-weight: 500;
  color: #1a1d21;
  line-height: 40px;
  text-align: center;
  margin-top: 25px;
}
.editguide-box0-description {
  margin-top: 8px;
  font-size: 18px;
  color: #535d6c;
  line-height: 24px;
  text-align: center;
}
.editguide-box0-btn {
  margin-top: 24px;
  display: flex;
  justify-content: center;
}
.editguide-box0-btn button {
  min-width: 130px;
  border: 1px solid #003591;
  color: #003591;
}
.editguide-box0-btn button:last-child {
  background-color: #003591;
  color: #fff;
  margin-left: 30px;
}
.editguide-box0-btn button:hover {
  background-color: #fafafa;
}
.editguide-box0-btn button:last-child:hover {
  background-color: #01296e;
}
.zujian-img1 {
  width: 240px;
  position: absolute;
  top: 97px;
  left: 0;
  border-radius: 4px;
}
.zujian-img {
  border-radius: 4px;
  object-fit: contain;
}
.editguide-box1 {
  width: 680px;
  height: 240px;
  border-radius: 4px;
  position: absolute;
  top: 250px;
  left: 250px;
  display: flex;
  justify-content: flex-start;
  background-image: url("../assets/yuanjianqipao.png");
  background-repeat: no-repeat;
  background-size: 680px 240px;
}
.animation-box1 {
  position: relative;
  width: 245px;
  height: 240px;
}
.editguide-box-shubiao1 {
  position: absolute;
  width: 30px;
  height: 30px;
  z-index: 1000;
  left: 65px;
  top: 75px;
  background-image: url("../assets/shubiao.png");
  background-size: 30px 30px;
}
.editguide-box-wenzi {
  position: absolute;
  width: 25px;
  height: 27px;
  top: 70px;
  left: 69px;
  background-image: url("../assets/wenzi.png");
  background-size: 25px 27px;
}
.editguide-box-gongjulan {
  position: absolute;
  width: 56px;
  height: 108px;
  top: 54px;
  left: 54px;
  background-image: url("../assets/gongjulan1.png");
  background-size: 56px 108px;
}
.editguide-box-huaban {
  position: absolute;
  width: 100px;
  height: 100px;
  bottom: 33px;
  right: 54px;
  background-image: url("../assets/huaban1.png");
  background-size: 100px 100px;
}
.zujian-img2 {
  width: 950px;
  height: 40px;
  position: absolute;
  top: 73px;
  left: 240px;
  border-radius: 4px;
}
.editguide-box2 {
  width: 724px;
  height: 210px;
  border-radius: 4px;
  position: absolute;
  top: 144px;
  left: 328px;
  display: flex;
  justify-content: flex-start;
  background-image: url("../assets/gongnengqipao.png");
  background-size: 724px 210px;
}
.animation-box2 {
  position: relative;
  width: 185px;
  height: 210px;
}
.editguide-box-shubiao2 {
  position: absolute;
  width: 30px;
  height: 30px;
  z-index: 1000;
  left: 60px;
  top: 65px;
  background-image: url("../assets/shubiao.png");
  background-size: 30px 30px;
}
.editguide-box-gongneng {
  position: absolute;
  width: 140px;
  height: 100px;
  top: 46px;
  left: 22px;
  background-image: url("../assets/gongneng1.png");
  background-size: 140px 100px;
}
.editguide-box-youjian {
  position: absolute;
  width: 80px;
  height: 110px;
  bottom: 18px;
  right: 28px;
  background-image: url("../assets/youjian.png");
  background-size: 80px 110px;
}
.zujian-img3 {
  width: auto;
  height: calc(100vh - 73px);
  background-color: #ffffff;
  position: fixed;
  top: 72px;
  right: 0;
  border-radius: 4px;
}
.zujian-img3 img {
  width: 356px;
  height: auto;
}
.editguide-box3 {
  width: 670px;
  height: 240px;
  border-radius: 4px;
  position: absolute;
  top: 179px;
  right: 390px;
  display: flex;
  justify-content: flex-start;
  background-image: url("../assets/shuxingqipao.png");
  background-repeat: no-repeat;
  background-size: 670px 240px;
}
.animation-box3 {
  position: relative;
  width: 230px;
  height: 240px;
}
.editguide-box-shubiao3 {
  position: absolute;
  width: 30px;
  height: 30px;
  z-index: 1000;
  left: 65px;
  top: 110px;
  background-image: url("../assets/shubiao.png");
  background-size: 30px 30px;
}
.editguide-box-shuxing {
  position: absolute;
  width: 140px;
  height: 100px;
  top: 66px;
  left: 42px;
  background-image: url("../assets/shuxing1.png");
  background-size: 140px 100px;
}
.editguide-box-shuxingqu {
  position: absolute;
  width: 88px;
  height: 84px;
  bottom: 55px;
  right: 45px;
  background-image: url("../assets/shuxingqu.png");
  background-size: 88px 84px;
}
.zujian-img4 {
  width: 430px;
  position: absolute;
  top: 1px;
  right: 24px;
  border-radius: 4px;
}
.editguide-box4 {
  width: 640px;
  height: 230px;
  border-radius: 4px;
  position: absolute;
  top: 92px;
  right: 20px;
  display: flex;
  justify-content: flex-start;
  background-image: url("../assets/dayinqipao.png");
  background-repeat: no-repeat;
  background-size: 640px 230px;
}
.animation-box4 {
  position: relative;
  width: 220px;
  height: 230px;
}
.editguide-box-dayin {
  position: absolute;
  width: 230px;
  height: 230px;
  top: 0;
  left: 0;
  background-image: url("../assets/dayin1.png");
  background-size: 230px 230px;
}

.editguide-right1 {
  height: 100%;
  width: calc(100% - 245px);
  padding: 24px 24px 24px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.editguide-right2 {
  height: 100%;
  width: calc(100% - 185px);
  padding: 24px 24px 24px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.editguide-right3 {
  height: 100%;
  width: calc(100% - 230px);
  padding: 24px 30px 24px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.editguide-right4 {
  height: 100%;
  width: calc(100% - 220px);
  padding: 24px 24px 24px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.editguide-title {
  height: 28px;
  font-size: 20px;
  font-weight: 500;
  color: #1a1d21;
  line-height: 28px;
}
.editguide-text {
  color: #535d6c;
  line-height: 22px;
  padding-top: 8px;
}
.editguide-button {
  padding-top: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.editguide-button button {
  border: none;
  color: #003591;
}
.editguide-button button:last-child {
  background-color: #003591;
  color: #fff;
  margin-left: 10px;
}
.editguide-button button:hover {
  border: none;
  background-color: #fff;
}
.editguide-button button:last-child:hover {
  background-color: #01296e;
}
.editguide-dot-select {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #003591;
  display: inline-block;
  margin-right: 3px;
}
.editguide-dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #d6dce6;
  display: inline-block;
  margin-right: 3px;
}
@media only screen and (max-width: 1280px) {
  .editguide {
    font-size: 10px;
  }
  .editguide-box0 {
    width: 480px;
    left: calc(50% - 240px);
    top: calc(50% - 184px);
    padding: 24px 48px 26px 48px;
  }
  .editguide-box0-icon {
    right: 18px;
    top: 17px;
    font-size: 20px;
  }
  .editguide-img1 {
    width: 140px;
    height: 35px;
  }
  .editguide-img2 {
    width: 100%;
    margin-top: 20px;
  }
  .editguide-box0-title {
    font-size: 18px;
    line-height: 26px;
    margin-top: 16px;
  }
  .editguide-box0-description {
    margin-top: 5px;
    font-size: 12px;
    line-height: 16px;
  }
  .editguide-box0-btn {
    margin-top: 16px;
  }
  .editguide-box0-btn button {
    min-width: 86px;
  }
  .zujian-img1 {
    width: 160px;
    top: 65px;
    left: 0px;
    border-radius: 4px;
  }
  .zujian-img {
    border-radius: 4px;
  }
  .editguide-box1 {
    width: 540px;
    height: 180px;
    top: 180px;
    left: 180px;
    background-size: 540px 180px;
  }
  .animation-box1 {
    width: 195px;
    height: 180px;
  }
  .editguide-box-shubiao1 {
    width: 20px;
    height: 20px;
    left: 55px;
    top: 40px;
    background-size: 20px 20px;
  }
  .editguide-box-wenzi {
    width: 22px;
    height: 24px;
    top: 37px;
    left: 55px;
    background-size: 22px 24px;
  }
  .editguide-box-gongjulan {
    width: 45px;
    height: 85px;
    top: 25px;
    left: 44px;
    background-size: 45px 85px;
  }
  .editguide-box-huaban {
    position: absolute;
    width: 90px;
    height: 90px;
    bottom: 25px;
    right: 30px;
    background-size: 90px 90px;
  }
  .zujian-img2 {
    width: 580px;
    height: 24px;
    top: 45px;
    left: 160px;
  }
  .editguide-box2 {
    width: 540px;
    height: 160px;
    top: 96px;
    left: 218px;
    background-size: 540px 160px;
  }
  .animation-box2 {
    width: 140px;
    height: 160px;
  }
  .editguide-box-shubiao2 {
    width: 20px;
    height: 20px;
    left: 60px;
    top: 65px;
    background-size: 20px 20px;
  }
  .editguide-box-gongneng {
    width: 100px;
    height: 75px;
    top: 50px;
    left: 22px;
    background-size: 100px 75px;
  }
  .editguide-box-youjian {
    width: 55px;
    height: 70px;
    bottom: 10px;
    right: 10px;
    background-size: 55px 70px;
  }
  .zujian-img3 {
    max-width: 236px;
    height: calc(100% - 49px);
    top: 48px;
  }
  .zujian-img3 img {
    width: 236px;
    height: auto;
  }
  .editguide-box3 {
    width: 520px;
    height: 180px;
    border-radius: 4px;
    position: absolute;
    top: 150px;
    right: 260px;
    background-size: 520px 180px;
  }
  .animation-box3 {
    width: 175px;
    height: 180px;
  }
  .editguide-box-shubiao3 {
    width: 20px;
    height: 20px;
    z-index: 1000;
    left: 45px;
    top: 90px;
    background-size: 20px 20px;
  }
  .editguide-box-shuxing {
    width: 115px;
    height: 80px;
    top: 45px;
    left: 25px;
    background-size: 115px 90px;
  }
  .editguide-box-shuxingqu {
    width: 75px;
    height: 71px;
    bottom: 30px;
    right: 25px;
    background-size: 75px 71px;
  }
  .zujian-img4 {
    width: 286px;
    top: 1px;
    right: 20px;
  }
  .editguide-box4 {
    width: 500px;
    height: 180px;
    top: 62px;
    right: 20px;
    background-size: 500px 180px;
  }
  .animation-box4 {
    width: 175px;
    height: 180px;
  }
  .editguide-box-dayin {
    width: 185px;
    height: 180px;
    background-size: 185px 180px;
    left: -5px;
  }
  .editguide-right1 {
    height: 100%;
    width: calc(100% - 195px);
    padding: 24px 16px 16px 16px;
  }
  .editguide-right2 {
    height: 100%;
    width: calc(100% - 140px);
    padding: 24px 16px 16px 16px;
  }
  .editguide-right3 {
    height: 100%;
    width: calc(100% - 175px);
    padding: 24px 24px 16px 16px;
  }
  .editguide-right4 {
    height: 100%;
    width: calc(100% - 175px);
    padding: 24px 16px 16px 16px;
  }
  .editguide-title {
    height: 18px;
    font-size: 14px;
    line-height: 18px;
  }
  .editguide-text {
    line-height: 14px;
    padding-top: 5px;
    height: 55px;
  }
  .editguide-button {
    padding-top: 0px;
  }
  .editguide-button .el-button {
    padding: 7px 15px;
    min-height: 30px;
    line-height: 16px;
  }
}
</style>
