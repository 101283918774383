var _this = "";
const sketchColor = "#7b89a1";
const thick = 25;
const fontSize = 11;
const shortLine = 5;
const longLine = 10;

const drawSketchRuler = (event) => {
  _this = event;
  _this.sketchRulerLayer = new Konva.Layer({
    id: "sketchRulerLayer",
  });
  _this.designStage.add(_this.sketchRulerLayer);
  _this.sketchRulerLayer.zIndex(1);
  drawAxisX();
  drawAxisY();
  _this.sketchRulerLayer.batchDraw();
};

const drawAxisX = () => {
  let width = _this.designArea.width() * _this.designArea.scaleX();
  let AxisXGroup = new Konva.Group({
    id: "AxisXGroup",
    x: _this.designArea.x(),
    y: 2,
    offsetX: width / 2,
    width,
    height: thick,
  });
  _this.sketchRulerLayer.add(AxisXGroup);

  let borderLine = new Konva.Line({
    points: [0, thick, width, thick],
    stroke: sketchColor,
    strokeWidth: 1,
  });
  AxisXGroup.add(borderLine);

  let xSpace = width / (_this.labelAttr.width * _this.UnitConversion);

  let length = Math.ceil(_this.labelAttr.width * _this.UnitConversion);
  for (let i = 0; i <= length; i++) {
    let lineLen = shortLine;
    if (i % 10 == 0 || i == 0) {
      lineLen = longLine;
      let num = String(i);
      let text = new Konva.Text({
        x: i * xSpace,
        y: 0,
        text: num,
        fontSize,
        fill: sketchColor,
        fontStyle: "bold",
      });
      text.x(text.x() - text.width() / 2);
      AxisXGroup.add(text);
    }
    let line = new Konva.Line({
      points: [i * xSpace, thick - lineLen, i * xSpace, thick],
      stroke: sketchColor,
      strokeWidth: 1,
    });
    AxisXGroup.add(line);
  }
};

const drawAxisY = () => {
  let height = _this.designArea.height() * _this.designArea.scaleY();
  let AxisYGroup = new Konva.Group({
    id: "AxisYGroup",
    x: 2,
    y: _this.designArea.y(),
    offsetY: height / 2,
    width: thick,
    height,
  });
  let borderLine = new Konva.Line({
    points: [thick, 0, thick, height],
    stroke: sketchColor,
    strokeWidth: 1,
  });
  AxisYGroup.add(borderLine);

  _this.sketchRulerLayer.add(AxisYGroup);

  let ySpace = height / (_this.labelAttr.height * _this.UnitConversion);
  let length = Math.round(_this.labelAttr.height * _this.UnitConversion);
  for (let i = 0; i <= length; i++) {
    let lineLen = shortLine;
    if (i % 10 == 0 || i == 0) {
      lineLen = longLine;
      let num = String(i);
      let text = new Konva.Text({
        x: 0,
        y: i * ySpace,
        text: num,
        fontSize,
        fill: sketchColor,
        fontStyle: "bold",
        rotation: -90,
      });
      text.y(text.y() + text.height() / 2);
      AxisYGroup.add(text);
    }
    let line = new Konva.Line({
      points: [thick - lineLen, i * ySpace, thick, i * ySpace],
      stroke: sketchColor,
      strokeWidth: 1,
    });
    AxisYGroup.add(line);
  }
};

const sketchRulerReset = (event) => {
  _this = event;
  //移除标尺
  if (_this.sketchRulerLayer) {
    _this.sketchRulerLayer.remove();
    _this.sketchRulerLayer = "";
  }
  drawSketchRuler(event)
};

//移动画布 ，重置标尺坐标
const sketchRulerMove = (event) => {
  _this = event;
  let AxisXGroup = _this.sketchRulerLayer.find("#AxisXGroup")[0];
  let width = _this.designArea.width()*_this.designArea.scaleX()
  AxisXGroup.setAttrs({
    x: _this.designArea.x(),
    offsetX: width / 2,
    width,
  });

  let AxisYGroup = _this.sketchRulerLayer.find("#AxisYGroup")[0];
  let height =  _this.designArea.height()* _this.designArea.scaleY()
  AxisYGroup.setAttrs({
    y: _this.designArea.y(),
    offsetY: height / 2,
    height,
  });
  _this.sketchRulerLayer.batchDraw();
}

export { drawSketchRuler, sketchRulerMove, sketchRulerReset };

