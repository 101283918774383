export default {
	navbar: {
		login: "Войти",
		home: "Домой",
		register: "Регистрация",
		shareTemplate: "Промышленный шаблон",
		cloudTemplate: "Облачный шаблон",
		cloudData: "Облачные данные",
		printSetting: "Настройки печати",
		moreSetting: "Дполнительные настройки",
		user: "Личный центр",
		unit: "Единица",
		logout: "Выйти",
		error404: "Ошибка 404",
		renamePassword: "Сменить пароль",
		editLabel: "Редактировать этикетку",
		member: "Центр участника",
		suggest: "Отзыв",
		download: "Загрузить программу",
		help: "Центр помощи",
		bind: "Завести учетную запись",
    onlinetool: 'Коммунальные услуги',
    expansion: 'Расширьте облачное пространство',
    pdfPrint: 'Печать документов',
	},
	button: {
		login: "Войти",
		register: "Регистрация",
		getCode: "Получить код проверки",
		print: "Распечатать сейчас",
		editLabel: "Редактировать этикетку",
		copyLabel: "Копировать этикетку",
		rename: "Переименовать",
		delete: "Удалить",
		createLabel: "Создать новую этикетку",
		move: "Переместить",
		createFile: "Создать новую папку",
		upload: "Загрузить",
		cancel: "Отменить",
		confirm: "Подтвердить",
		save: "Сохранить",
		create: "Сздать",
		saveToCloudTem: "Сохранить как облачный шаблон",
		download: "Скачать",
		updateAvatar: "Изменить",
		forgetPass: "Забыли пароль",
		set: "Настроить",
		goRegister: "Зарегистрироваться сейчас",
		goLogin: "Перейти, чтобы войти",
		backLogin: "Вернуться, чтобы войти",
		reset: "Сброс",
		useEmail: "Зарегистрировать по эл.почте",
		usePhone: "Зарегистрировать по номеру телефона",
		labelset: "Настройки этикетки",
		printLabel: "Печать",
		exitEdit: "Выход",
		choose: "Выбрать",
		help: "Помощь",
		downloadPC: "Скачать программу",
		Renewal: "Обновить",
		Upgrade: "Обновить участие",
		bingLogin: "Привязать и войти",
		upgradeMember: "Обновить участие",
		exchange: "Код погашения",
    exchangeNow: 'Выкупить сейчас',
    expansion: 'Расширение',
    abandon: 'Отказ',
    logout: 'Списание счетов',
	},
	lang: {
		chinese: "Китайский упрощенный",
		english: "Английский",
		portuguese: "Протугальский",
		Spanish: "Испанский",
		Russian: "Русский язык",
	},
	tips: {
		tip1: "Авторизация успешна",
		tip2: "Вы уверены, что хотите удалить все данные в папке и папке?",
		tip3: "Вы уверены, что хотите удалить эти данные?",
		tip4: "Вы уверены, что хотите удалить эту этикетку?",
		tip5: "Пожалуйста, введите название/размер этикетки",
		tip6: "Пожалуйста, введите отрасль",
		tip7: "Пожалуйста, введите номер вашего мобильного телефона/адрес электронной почты",
		tip8: "Пожалуйста, введите код проверки",
		tip9: "Пожалуйста, установите новый пароль",
		tip10: "Пожалуйста, подтвердите новый пароль",
		tip11: "Пожалуйста, введите Ваш пароль",
		tip12: "Пожалуйста, введите Ваш логин",
		tip13: "Пожалуйста, введите номер Вашего мобильного телефона",
		tip14: "Пожалуйста, установите новый пароль",
		tip15: "Пожалуйста, подтвердите Ваш пароль",
		tip16: "Пожалуйста, выберите отрасль",
		tip17: "Пожалуйста, введите почтовый адрес",
		tip18: "Пожалуйста, введите правильный номер мобильного телефона/адрес электронной почты",
		tip19: "Пожалуйста, прочитайте и проверьте соглашение",
		tip20: "Пожалуйста, введите правильный номер телефона",
		tip21: "Пожалуйста, введите правильный почтовый адрес",
		tip22: "Пароль не соответствует",
		tip23: "Нет результатов, попробуйте другие варианты",
		tip24: "На данный момент контента нет",
		tip25: "Создайте свой тег/ярлык",
		tip26: "Пожалуйста, выберите файл формата tprt/tprts!",
		tip27: "Загрузка не удалась",
		tip28: "Удалено успешно",
		tip29: " Выберите файлы форматов png, jpg, jpeg, xlsx, xls, csv!",
		tip30: "Файл слишком большой для загрузки, пожалуйста, выберите размер меньше чем",
		tip31: "Файл К-с!",
		tip32: "У вас еще нет аккаунта?",
		tip33: "Имется аккаунт,",
		tip34: "Изменено успешно",
		tip35: "Репликация прошла успешно",
		tip36: "Выберите слой",
		tip37: "Вставлено успешно",
		tip38: "Штрих-код не может содержать китайские символы!",
		tip39: "Пожалуйста, выбирайте изображения в формате JPG, JPEG, PNG!",
		tip40: "Нет облачного изображения",
		tip41: "Укажите имя",
		tip42: "Имя файла не может содержать ни один из следующих символов:",
		tip43: "Имя не может быть пустым",
		tip44: "Модификация прошла успешно, вы будете перенаправлены на страницу входа.",
		tip45: "Следует перезаписать существующий файл?",
		tip46: "Ошибка сети!",
		tip47: "Файл слишком большой, но может быть открыт с помощью клиента для ПК!",
		tip48: "Модификация прошла успешно",
		tip49: "Ошибка модификации",
		tip50: "Содержание шаблона не может быть пустым",
		tip51: "Выберите один слой",
		tip52: "Выберите хотя бы один фрагмент данных",
		tip53: "Пожалуйста, введите ключевые слова",
		tip54: "Код подтверждения успешно отправлен",
		tip55: "Регистрация прошла успешно",
		tip56: "Добавлено успешно",
		tip57: "Перемещено успешно",
		tip58: "Сохранено успешно",
		tip59: "Передано успешно",
		tip60: "Пожалуйста, загрузите изображения размером менее 2Mб",
		tip61: "Содержимое не может быть пустым",
		tip62: "В текущей папке нет загруженных данных",
		tip63: "В целях безопасности вашей учетной записи, пожалуйста, привяжите свой номер мобильного телефона/адрес электронной почты!",
		tip64: "Подключено успешно!",
		tip65: "Ошибка подключения!",
		tip66: "Связано успешно!",
		tip67: "После обновления участия, пожалуйста, воспользуйтесь разрешением для изменения псевдонима!",
		tip68: "Авторизация прошла успешно!",
		tip69: "Несоответствующий размер шрифта, настройте минимальный размер шрифта в браузере!",
		tip70: "Как модифицировать?",
		tip71: "Подсказки",
		tip72: "Связано с другим элементом!",
		tip73: "Пожалуйста, введите десятичное число от 0 до 9",
		tip74: "Введите шестнадцатеричное число от 0 до 9, от A до F",
		tip75: "Введите число с основанием 26, состоящее из букв от A до Z.",
		tip76: "Введите число с основанием 36, состоящее из цифр от 0 до 9 и букв от A до Z.",
		tip77: "Текущие данные используются другим элементом, удалить в любом случае?",
		tip78: "Эти данные используются другими элементами, удалить в любом случае?",
		tip79: "Для редактирования или печати этикеток рекомендуется использовать браузеры Chrome и Microsoft Edge!",
		tip80: "Неверный формат кодировки!",
		tip81: "Проверка безопасности",
		tip82: "Видно нечетко? Изменить один",
		tip83: "Отпечатки не могут быть пустыми!",
		tip84: "Выберите принтер",
		tip85: "Клик ",
		tip86: "Скачать для установки и запуска",
		tip87: " Помощник Печати",
		tip88: "Если установлено и работае, щелкните здесь, чтобы ",
		tip89: "соединить",
		tip90: "Не удалось подключить принтер. Переподключите Помощника Печати!",
		tip91: "Доставлено успешно!",
		tip92: "Ошибка доставки, попробуйте еще раз!",
		tip93: "Пожалуйста, установите и запустите Помощник Печати!",
		tip94: 'Оплата прошла успешно',
		tip95: 'Тайм-аут платежа',
		tip96: 'Пожалуйста, введите код погашения',
		tip97: 'Инструкции по использованию',
		tip98: 'Коды погашения можно получить, участвуя в официальных операционных мероприятиях, проводимых Hanma;',
		tip99: 'Процесс погашения: проверьте информацию об учетной записи, введите код погашения и нажмите «Погасить сейчас»;',
		tip100: 'Если вы являетесь участником, период участия будет продлен после выкупа;',
		tip101: 'Пожалуйста, обратите внимание на время действия и осуществите выкуп в течение этого времени.',
		tip102: 'Погашено успешно',
		tip103: 'Ошибка синтаксического анализа шаблона',
		tip104: 'Функция таблицы доступна только участникам, пожалуйста, подтвердите свое участие для ее использования!',
    tip105: 'Пожалуйста, откройте его с помощью браузера компьютера!',
    tip106: 'Выберите папку для удаления',
    tip107: 'Нет данных',
    tip108: 'Выберите элемент управления',
    tip109: 'Сначала разблокируйте блокируемый элемент управления.',
    tip110: 'Выберите данные',
    tip111: 'Ошибка генерации',
    tip112: 'Этот элемент управления заблокирован.',
    tip113: 'Выберите изображение',
    tip114: 'Пожалуйста, выберите модель',
    tip115: 'Вы уверены, что хотите удалить выбранную папку и все данные в папке?',
    tip116: 'Заведите хотя бы одну папку!',
    tip117: 'Сохранить текущее редактирование',
    tip118: 'Умные метки разделены на исключительные привилегии членов, пожалуйста, обновите членство после использования!',
    tip119: 'Пользовательское обрезание как исключительная привилегия члена, пожалуйста, обновите членство после использования!',
    tip120: 'Списание успешно!',
    tip121: 'Данные будут очищены после списания, будьте осторожны! Если вы хотите продолжать использовать код после отмены, вам нужно перерегистрировать свой аккаунт!',
	},
	label: {
		label1: "Папка",
		label2: "Недавно использованные этикетки",
		label3: "Имя",
		label4: "Редактировать имя папки",
		label5: "Редактировать имя файла",
		label6: "Редактировать название этикетки",
		label7: "Изменить псевдоним",
		label8: "Редактировать псевдоним",
		label9: "Изменить пароль",
		label10: "Псевдоним",
		label11: "Учетная запись",
		label12: "Пароль",
		label13: "Отрасль",
		label14: "Выбрать отрасль",
		label15: "Исправлять",
		label16: "Название этикетки",
		label17: "Сохранить этикетку в",
		label18: "Переместить этикетку в",
		label19: "Предпросмотр печати",
		label20: "Размер этикетки",
		label21: "Тип шаблона",
		label22: "Источник кода Shuo",
		label23: "Ширина",
		label24: "Высота",
		label25: "Многострочный многоколоночный",
		label26: "Столбцы",
		label27: "Строки",
		label28: "Расстояние между столбцами",
		label29: "Расстояние между строками",
		label30: "Отступ",
		label31: "Левый",
		label32: "Правый",
		label33: "Верх",
		label34: "Низ",
		label35: "Мои разработки",
		label36: "ИмяДанных",
		label37: "Добавить дату",
		label38: "По умолчанию",
		label39: "Имя от А до Z",
		label40: "Имя от Z до A",
		label41: "Дата ASC",
		label42: "Дата DESC",
		label43: "Размер ASC",
		label44: "Размер DESC",
		label45: "Вход в учетную запись",
		label46: "Сбросить пароль",
		label47: "Я прочитал(а) и согласен ",
		label48: "Сервисное соглашение ",
		label49: "И ",
		label50: "Политика конфиденциальности",
		label51: "Новая папка 1",
		label52: "Подтвердите участие, чтобы пользоваться эксклюзивными привилегиями",
		label53: "Платеж WeChat",
		label54: "WeChat",
		label55: "Подтвердите участие, чтобы пользоваться ",
		label63: " превелегиями",
		label56: "Рекомендовано",
		label57: "Истекает через ",
		label58: " дней",
		label59: "",
		label60: "Истекает сегодня",
		label61: " месяцев",
		label62: "Сторонний вход",
		label64: "Принтер",
		label65: "копировать",
    label66: "Дисконт",
    label67: "Все папки",
    label68: "Выбраны <span style='color: #003591'>{ total }</span> папки",
    label69: 'Управление',
    label70: 'Год',
	},
	privilege: {
		privilege1: "Увеличить облачное пространство",
		privilege2: "Новый скан-код",
		privilege3: "Неограниченное количество папок",
		privilege4: "Скан Печать",
		privilege5: "Поделиться шаблонами",
		privilege6: "Быстрая печать",
		privilege7: "Шаблоны переменных",
		privilege8: "Эксклюзивные шрифты",
		privilege9: "Функция таблицы",
		privilege10: "Печать записи",
		privilege11: "Изменить псевдоним",
    privilege12: "Почетный статус",
    privilege13: "Облачное пространство",
	},
	editAttr: {
		attrText1: "Свойства компонента",
		attrText2: "Источник данных",
		attrText3: "Обычный текст",
		attrText4: "Текст базы данных",
		attrText5: "Серийный текст",
		attrText6: "Шрифт",
		attrText7: "РазмерШрифта",
		attrText8: "СтильШрифта",
		attrText9: "Вращение",
		attrText10: "Расстояние между словами",
		attrText11: "Расстояние между строками",
		attrText12: "Перенос слова",
		attrText13: "Зеркало",
		attrText14: "АнтиЧерный",
		attrText15: "Положение",
		attrText16: "Данные",
		attrText17: "Цифра",
		attrText18: "Тип времени",
		attrText19: "Системное время",
		attrText20: "Указанное время",
		attrText21: "Тип штрих-кода",
		attrText22: "Размещение данных",
		attrText23: "Скрыть",
		attrText24: "Ниже",
		attrText25: "Увеличение масштаба",
		attrText26: "Левый",
		attrText27: "Центр",
		attrText28: "Правый",
		attrText29: "Скорость исправления ошибок",
		attrText30: "Добавить местную картинку",
		attrText31: "Добавить картинку на облако",
		attrText32: "Увеличить",
		attrText33: "Тип линии",
		attrText34: "Толщина линии",
		attrText35: "Тип данных",
		attrText36: "Пожалуйста, выберите",
		attrText37: "Соединение",
		attrText38: "Вставить данные",
		attrText39: "Начальное значение",
		attrText40: "Уведичение",
		attrText41: "Количество",
		attrText42: "Формат числа",
		attrText43: "Формат даты",
		attrText44: "Выбрать время",
		attrText45: "Радиус скругления",
		attrText46: "Адаптировать холст",
		attrText47: "Пожалуйста, введите любое значение",
		attrText48: "Сплошной",
		attrText49: "Пунктирная",
		attrText50: "Копировать",
		attrText51: "Вставить",
		attrText52: "Блокировка",
		attrText53: "Разблокировка",
		attrText54: "Выровнять",
		attrText55: "Выровнять по левому краю",
		attrText56: "По центру вертикально",
		attrText57: "Выровнять по правому краю",
		attrText58: "По центру горизонтально",
		attrText59: "Верхнее выравнивание",
		attrText60: "Нижнее выравнивание",
		attrText61: "Горизонтальное распределение",
		attrText62: "Вертикальное распределение",
		attrText63: "Порядок слоев",
		attrText64: "Переместить вверх",
		attrText65: "Переместить вниз",
		attrText66: "Удалить",
		attrText67: "Облачные данные",
		attrText68: "Местные данные",
		attrText69: "Отозвать",
		attrText70: "Восстановить",
		attrText71: "Сохранить",
		attrText72: "Сохранить как",
		attrText73: "Удалить",
		attrText74: "Поднять",
		attrText75: "Опустить",
		attrText76: "Сдвиг влево",
		attrText77: "Сдвиг вправо",
		attrText78: "Выбрать все",
		attrText79: "Печать",
		attrText80: "Масштабирование холста",
		attrText81: "Уменьшить хост",
		attrText82: "Выбрать несколько",
		attrText83: "Прокрутить вверх",
		attrText84: "Прокрутить вниз",
		attrText85: "Клик левой кнопкой",
		attrText86: "Руководство для начинающих",
		attrText87: "Просмотреть сочетания клавиш",
		attrText88: "Описание сочетаний клавиш",
		attrText89: "Адаптивное окно",
		attrText90: "Уменьшить",
		attrText91: "Увеличить",
		attrText92: "Тип сериализации",
		attrText93: "Десятичное",
		attrText94: "Выровнять",
		attrText95: "Смещение(d)",
		attrText96: "Смещение(m)",
		attrText97: "Фоновое изображение",
		attrText98: "Данные",
		attrText99: "Печать фонового изображения",
		attrText100: "Выбрать данные",
		attrText101: "Значение объекта",
		attrText102: "Добавить данные",
		attrText103: "Шестнадцатеричный",
		attrText104: "Буквы",
		attrText105: "Буквы и цифры",
		attrText106: "Высота строки",
		attrText107: "Ширина столбца",
		attrText108: "Содежимое",
		attrText109: "Вставить строку",
		attrText110: "Вставить столбец",
		attrText111: "Удалить строку",
		attrText112: "Удалить столбец",
		attrText113: "Объединить",
    attrText114: "Разделить",
    attrText115: "Формат времени",
    attrText116: "Ниже",
    attrText117: "наполните",
    attrText118: "Префикс",
    attrText119: "Суффикс",
    attrText120: 'Время',
    attrText121: 'Направление линии',
    attrText122: 'Облачные фотографии',
    attrText123: 'Пластинка',
    attrText124: 'Растяжка',
    attrText125: 'Горизонтальный текст',
    attrText126: 'Вертикальный текст',
    attrText127: 'Дугообразный текст',
    attrText128: 'Толщина',
    attrText129: 'Курсив',
    attrText130: 'Подчёркивание',
    attrText131: 'Удалить строку',
	},
	module: {
		editText1: "Текст",
		editText2: "Время",
		editText3: "Штрих - код",
		editText4: "Кёркоде",
		editText5: "Изображение",
		editText6: "Форма",
		editText7: "Прямоугольник",
		editText8: "Скругленный прямоугольник",
		editText9: "Ромб",
		editText10: "Эллиптический",
		editText11: "Треугольник",
		editText12: "Сплошной прямоугольник",
		editText13: "Иконка",
		editText14: "Слэш",
		editText15: "Горизонталь",
		editText16: "Вертикаль",
		editText17: "Таблица",
	},
	guide: {
		guideText1: "Добро пожаловать в ",
		guideText2: "Простой и эффективный",
		guideText3: "Сюжетно-ориентированный",
		guideText4: "Удобные ресурсы",
		guideText5: "Многозадачная совместимость",
		guideText6: "Программное обеспечение для дизайна этикеток iDRPT позволяет создавать этикетки так, как они будут выглядеть после печати. Дизайн редактируется простым перетаскиванием - это еще никогда не было таким простым и эффективным. Возьмите и попробуйте!",
		guideText7: "Пропустить",
		guideText8: "Начать обучение",
		guideText9: "Руководство: отраслевой шаблон",
		guideText10: "Шаблоны этикеток на основе сценариев предоставляют варианты дизайна для различных отраслей и сфер применений, таких как розничная торговля, промышленность и транспорт.",
		guideText11: "Руководство: облачный шаблон",
		guideText12: "Созданные этикетки автоматически сохраняются в облаке. Также можно создавать сценарии управления папками и использовать их даже на мобильных устройствах.",
		guideText13: "Руководство: облачные данные",
		guideText14: "Такие файлы, как изображения и таблицы, можно постоянно хранить в облаке и использовать в любое время и в любом месте в Интернете, на ПК и переносных терминалах.",
		guideText15: "Руководство: Персональный центр",
		guideText16: "Достаточно щелкнуть мышкой, чтобы изменить язык, переключить устройство или войти на страницу Персонального центра.",
		guideText17: "Руководство: Новая этикетка",
		guideText18: "Задайте имя, размер, количество строк и столбцов и начните создавать свою собственную этикетку!",
		guideText19: "Понятно",
		guideText20: "Начать создавать этикетки",
		guideText21: "Добро пожаловать на страницу редактирования",
		guideText22: "Учимся вместе, всего несколько простых шагов, чтобы завершить редактирование этикетки!",
		guideText23: "Библиотека компонентов",
		guideText24: "Различные компоненты, такие как текст, время или штрих-код можно добавить, перетащив их из библиотеки.",
		guideText25: "продолжаем учиться",
		guideText26: "Функциональная область",
		guideText27: "Функции управления компонентами включают перемещение, блокировку, отмену, восстановление, выравнивание, копирование и удаление.",
		guideText28: "Вы также можете щелкнуть правой кнопкой мыши любой компонент на холсте, чтобы открыть контекстное меню для работы с ним.",
		guideText29: "Область атрибутов",
		guideText30: "Выберите компонент, чтобы открыть область атрибутов и изменить источник данных, стиль, размер, положение и другие характеристики.",
		guideText31: "Сохранить Печать",
		guideText32: "Щелкните настройки этикетки, чтобы изменить текущий размер. <br />После редактирования этикетки, можно попробовать ее распечатать!",
		guideText33: "Я выучил",
		guideText34: "Следующее",
    guideText35: "Программное обеспечение для дизайна этикеток HereLabel позволяет создавать этикетки так, как они будут выглядеть после печати. Дизайн редактируется простым перетаскиванием - это еще никогда не было таким простым и эффективным. Возьмите и попробуйте!",
    guideText36: "Руководство: Печать документов",
    guideText37: "Поддержка Word, Excel、PPT、PDF、 Фотографии и другие форматы."
	},
	suggest: {
		suggest1: "Категория вопроса",
		suggest2: "Неправильная функция",
		suggest3: "Консультация по продукту",
		suggest4: "Описание проблемы",
		suggest5: "Пожалуйста, опишите подробно с чем Вы столкнулись, и мы решим задачу в кратчайшие сроки!",
		suggest6: "Контактная информация",
		suggest7: "Пожалуйста, введите ваши контактные данные",
		suggest8: "Копия экрана, демонстрирующая ошибку",
		suggest9: "Представить",
	},
	document: {
    text1: 'Откройте документ',
    text2: 'Нажмите или перетащите файл прямо в эту область, чтобы загрузить его',
    text3: 'Автообрезка',
    text4: 'Умная вырезка этикеток',
    text5: 'Настроить обрезку',
    text6: 'Прямая печать',
    text7: 'документация',
    text8: 'Один клик для вырезания файла',
    text9: 'Умное разделение меток',
    text10: 'Изменить файл на несколько меток',
    text11: 'Количество строк',
    text12: 'Количество столбцов',
    text13: 'Примеры',
    text14: 'Например: 2 * 2',
    text15: 'Следующий шаг',
    text16: 'Установить размер бумаги',
    text17: 'Настройка',
    text18: 'Ширина',
    text19: 'Высота',
    text20: 'Эффект печати',
    text21: 'Документация',
    text22: 'Фотографии',
    text23: 'Резолюция',
    text24: 'Порядок расположения',
    text25: 'Pастянуться',
    text26: 'Hаполнение',
    text27: 'Пропорция',
    text28: 'Количество распечаток',
    text29: 'Диапазон страниц',
    text30: 'Все.',
    text31: 'Текущая страница',
    text32: 'Диапазон страниц',
    text33: 'Диапазон номеров страниц (например: 1 - 3,5)',
    text34: 'Предыдущий шаг',
    text35: 'Обрезка',
    text36: 'Пожалуйста, загрузите txt, pptx, ppt, pdf, doc, docx, xls, xlsx, bp, jpg, png файлы',
    text37: 'Файл пуст',
    text38: 'Ошибка ввода диапазона страниц',
    text39: 'Не удалось провести парсинг',
    text40: 'Применить весь документ',
  },
  expandCloud: {
    text1: 'Используется',
    text2: 'Всего',
    text3: 'Учет покупок',
    text4: 'Купить пространство',
    text5: 'Дата покупки',
    text6: 'Срок действия до',
    text7: 'Нет записей о покупке',
    text8: 'Расширенный пакет',
    text9: 'Вступление в силу в течение 5 минут после успешной покупки; Приобретенное пространство возвращается после истечения срока действия, но загруженные файлы сохраняются.'
  },
  industryTemplate: {
    text1: 'Выбор типа самолета',
    text2: 'Больше',
    text3: 'Поиск содержимого',
    text4: 'Условия отбора',
    text5: 'Размер шаблона',
    text6: 'Ширина шаблона',
    text7: 'Высота шаблона',
    text8: '{tempCount} Шаблоны',
    text9: 'Модели переключателей',
    text10: 'Отбор содержимого',
	text11: 'Выберите серию',
  }
};