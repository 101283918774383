<template>
  <div class="exception-page">
    <div class="img">
      <img src="../assets/KpnpchXsobRgLElEozzI.svg" />
    </div>
    <div class="content">
      <h1>404</h1>
      <div class="desc">抱歉，你访问的页面不存在</div>
      <div class="action">
        <a-button type="primary" @click="handleClickUrl('/login')"
          >返回登录</a-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { defineComponent } from "vue";
export default defineComponent({
  setup() {
    const router = useRouter();
    const handleClickUrl = (url) => {
      router.push(url);
    };
    return {
      handleClickUrl,
    };
  },
});
</script>

<style>
.exception-page {
  padding-top: 30vh;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content h1 {
  color: #434e59;
  font-size: 72px;
  font-weight: 600;
  line-height: 72px;
  margin-bottom: 24px;
}
.content .desc {
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 16px;
}
</style>
