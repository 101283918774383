<template>
	<div>
		<a-modal class="cloud-folder-container" :title="$t(`editAttr.attrText122`)" centered :visible="visible" :cancelText="$t(`button.cancel`)" :okText="$t(`button.confirm`)" @ok="handleConfirm" @cancel="handCancel">
			<div class="folder-body">
				<a-tabs v-model:activeKey="folderIndex" :size="screenWidth > 1280 ? 'large' : 'small'" :tabBarStyle="{ color: '#7B89A1' }">
					<a-tab-pane v-for="(item, index) in folderList" :key="index" :tab="item.name">
						<div class="file-box" v-if="item.data.length > 0">
							<div class="file-item" v-for="(val, v_index) in item.data" :key="index" :style="{ borderColor: file && file.name == val.name ? '#003591' : '' }" @click="handleSelect(val)">
								<img :src="val.url" :alt="val.name" />
							</div>
						</div>
						<div class="file-none" v-if="item.data.length == 0">
							<img class="none-img" src="../assets/content.png" alt="" />
							<div>{{$t(`tips.tip107`)}}</div>
						</div>
					</a-tab-pane>
				</a-tabs>
			</div>
		</a-modal>
	</div>
</template>
<script>
	import { defineComponent, ref, watch, getCurrentInstance, reactive, toRefs, onMounted } from "vue";
	import { message } from "ant-design-vue";
	export default defineComponent({
		setup(props, { emit }) {
			const { proxy } = getCurrentInstance();
			let globalProperties = getCurrentInstance().appContext.config.globalProperties;
			const axios = globalProperties.$axioshanma;
			const state = reactive({
				visible: false,
				userInfo: "",
				folderList: [],
				folderIndex: 0,
				fileList: [],
				file: "",
				screenWidth: ""
			});

			onMounted(() => {
				state.userInfo = JSON.parse(localStorage.getItem("userInfo"));
				getFolderList();
				window.addEventListener("resize", resize);
			});

			const resize = () => {
				state.screenWidth = document.body.clientWidth;
			};

			const show = () => {
				state.screenWidth = document.body.clientWidth;
				state.visible = true;
			};
			//获取云端数据文件夹列表
			const getFolderList = () => {
				axios("/api/tem.app_public/getCloudDataList", {
					user_id: state.userInfo.userid,
					type: 1
				}).then(res => {
					state.folderList = res;
					state.fileList = state.folderList[state.folderIndex].data;
				});
			};

			//选择文件
			const handleSelect = value => {
				state.file = value;
			};

			const handleConfirm = () => {
				if (!state.file) {
					message.error(this,$t(`tips.tip113`));
					return;
				}
				emit("update", state.file.url);
				handCancel();
			};

			const handCancel = () => {
				state.visible = false;
				state.folderIndex = 0;
				state.fileList = state.folderList[state.folderIndex].data;
				state.file = "";
			};

			return {
				...toRefs(state),
				show,
				handleConfirm,
				handCancel,
				handleSelect
			};
		}
	});
</script>
<style>
	.cloud-folder-container {
		width: 726px !important;
	}
</style>
<style scoped>
	.folder-body {
		margin-top: 10px;
		position: relative;
	}
	.folder-body ::v-deep(.ant-tabs-nav .ant-tabs-tab-active) {
		color: #003591;
	}
	.folder-body ::v-deep(.ant-tabs-ink-bar) {
		background-color: #003591;
	}
	.folder-body ::v-deep(.ant-tabs-nav .ant-tabs-tab:hover) {
		color: #003591;
	}
	.folder-body ::v-deep(.ant-tabs-tab-prev-icon-target),
	.folder-body ::v-deep(.ant-tabs-tab-next-icon-target) {
		font-size: 18px !important;
	}
	.file-box {
		width: 100%;
		height: 350px;
		display: flex;
		align-items: flex-start;
		align-content: flex-start;
		flex-wrap: wrap;
		margin-left: -6px;
		width: calc(100% + 16px);
		overflow: hidden auto;
	}

	.file-item {
		width: 156px;
		height: 156px;
		margin: 6px;
		border: 1px solid #d6dce6;
		border-radius: 4px;
		cursor: pointer;
		padding: 1px;
	}
	.file-item img {
		width: 100%;
		height: 100%;
		object-fit: contain;
		border-radius: 4px;
	}
	.file-none {
		width: 100%;
		height: 350px;
		margin-left: -6px;
		width: calc(100% + 16px);
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		color: #7b89a1;
		font-size: 14px;
	}
	.file-none .none-img {
		width: 250px;
		margin-bottom: 16px;
	}
	@media only screen and (max-width: 1280px) {
		.folder-body {
			margin-top: 6px;
		}
		.file-item {
			width: 126px;
			height: 126px;
		}
	}
</style>
