<template>
	<!-- 右侧属性栏 -->
	<div class="edit-print-right" @click.stop="">
		<div class="edit-print-right-left" v-if="nodeType == 'WinText' || nodeType == 'TimeText' || nodeType == 'GeneralBarcode' || nodeType == 'Table'">
			<!-- 文字对齐 -->
			<div class="left-icon-box" v-for="(item, index) in textAlignList" :key="index" @click="handleSetTextAlign(item.value)">
				<i
					v-if="item.value != 4"
					:class="['iconfont', item.label]"
					:style="{
						color: attrObj.TextAlign == item.value || (nodeType == 'Table' && attrObj.DataSourceList[attrObj.dataSourceIndex].TextAlign == item.value) ? '#003591' : ''
					}"></i>
				<template v-if="item.value == 4">
					<i
						v-if="nodeType == 'WinText' || nodeType == 'TimeText'"
						:class="['iconfont', item.label]"
						:style="{
							color: attrObj.TextAlign == item.value || (nodeType == 'Table' && attrObj.DataSourceList[attrObj.dataSourceIndex].TextAlign == item.value) ? '#003591' : ''
						}"></i>
				</template>
				<div class="left-icon-hover">
					<div class="triangle_border_right"></div>
					<div class="left-icon-text">{{ item.text }}</div>
				</div>
			</div>
			<!-- 文字方向 -->
			<template v-if="nodeType == 'WinText' || nodeType == 'TimeText'">
				<div class="left-icon-box">
					<div
						class="iconfont icon-horizontal"
						:style="{
							color: attrObj.TextArrange == 0 ? '#003591' : ''
						}"
						@click="handleTextDirection('horizontal')"></div>
					<div class="left-icon-hover">
						<div class="triangle_border_right"></div>
						<div class="left-icon-text">{{$t(`editAttr.attrText125`)}}</div>
					</div>
				</div>
				<div class="left-icon-box">
					<div
						class="iconfont icon-vertical"
						:style="{
							color: attrObj.TextArrange == 1 ? '#003591' : ''
						}"
						@click="handleTextDirection('vertical')"></div>
					<div class="left-icon-hover">
						<div class="triangle_border_right"></div>
						<div class="left-icon-text">{{$t(`editAttr.attrText126`)}}</div>
					</div>
				</div>
				<div class="left-icon-box">
					<!-- <div
					class="iconfont icon-arc"
					:style="{
						color: attrObj.TextArrange == 2 ? '#003591' : ''
					}"
					@click="handleTextDirection('arc')"></div> -->
					<div class="left-icon-hover">
						<div class="triangle_border_right"></div>
						<div class="left-icon-text">{{$t(`editAttr.attrText127`)}}</div>
					</div>
				</div>
			</template>
			<!-- 文字效果 -->
			<template v-if="nodeType == 'WinText' || nodeType == 'TimeText' || nodeType == 'Table'">
				<div class="left-icon-box">
					<div
						class="iconfont icon-bold"
						:style="{
							color: attrObj.Bold || (nodeType == 'Table' && attrObj.DataSourceList[attrObj.dataSourceIndex].Bold) ? '#003591' : ''
						}"
						@click="handleFontStyle('Bold')"></div>
					<div class="left-icon-hover">
						<div class="triangle_border_right"></div>
						<div class="left-icon-text">{{$t(`editAttr.attrText128`)}}</div>
					</div>
				</div>
				<div class="left-icon-box">
					<div
						class="iconfont icon-italic"
						:style="{
							color: attrObj.Italic || (nodeType == 'Table' && attrObj.DataSourceList[attrObj.dataSourceIndex].Italic) ? '#003591' : ''
						}"
						@click="handleFontStyle('Italic')"></div>
					<div class="left-icon-hover">
						<div class="triangle_border_right"></div>
						<div class="left-icon-text">{{$t(`editAttr.attrText129`)}}</div>
					</div>
				</div>
				<div class="left-icon-box">
					<div
						class="iconfont icon-underLine"
						:style="{
							color: attrObj.UnderLine || (nodeType == 'Table' && attrObj.DataSourceList[attrObj.dataSourceIndex].UnderLine) ? '#003591' : ''
						}"
						@click="handleTextDecoration('UnderLine')"></div>
					<div class="left-icon-hover">
						<div class="triangle_border_right"></div>
						<div class="left-icon-text">{{$t(`editAttr.attrText130`)}}</div>
					</div>
				</div>
				<div class="left-icon-box">
					<div
						class="iconfont icon-deleteLine"
						:style="{
							color: attrObj.DeleteLine || (nodeType == 'Table' && attrObj.DataSourceList[attrObj.dataSourceIndex].DeleteLine) ? '#003591' : ''
						}"
						@click="handleTextDecoration('DeleteLine')"></div>
					<div class="left-icon-hover">
						<div class="triangle_border_right"></div>
						<div class="left-icon-text">{{$t(`editAttr.attrText131`)}}</div>
					</div>
				</div>
			</template>
		</div>
		<div class="edit-print-right-right">
			<div class="p-title">{{ attrObj.Name }}</div>
			<div class="edit-print-right-right-container">
				<!-- 文本、条形码、二维码数据类型 -->
				<div class="p-text" v-if="nodeType == 'WinText' || nodeType == 'GeneralBarcode' || nodeType == 'QRCode'">
					<div class="p-step-title">{{ $t(`editAttr.attrText2`) }}</div>
					<el-select
						class="p-text-select"
						:popper-append-to-body="false"
						v-model="attrObj.DataSourceList[attrObj.dataSourceIndex].DataSourceType"
						:placeholder="$t(`editAttr.attrText36`)"
						@change="handleDataSourceType()">
						<el-option v-for="item in dataSourceTypeList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
					</el-select>
				</div>
				<!-- 普通文本输入框 -->
				<div class="p-textarea" v-if="(nodeType == 'WinText' || nodeType == 'GeneralBarcode' || nodeType == 'QRCode') && attrObj.DataSourceList[attrObj.dataSourceIndex].DataSourceType == 0">
					<el-input type="textarea" resize="none" :rows="3" :placeholder="$t(`editAttr.attrText47`)" v-model="attrObj.DataSourceList[attrObj.dataSourceIndex].FirstValue" @input="handleTextData()">
					</el-input>
				</div>
				<!-- 数据库文本 -->
				<div class="p-textarea" v-if="(nodeType == 'WinText' || nodeType == 'GeneralBarcode' || nodeType == 'QRCode') && attrObj.DataSourceList[attrObj.dataSourceIndex].DataSourceType == 1">
					<!-- 连接数据库 -->
					<div style="display: flex; align-items: center; justify-content: space-between">
						<!-- 本地数据 -->
						<el-button class="p-textarea-half-btn" style="border: 1px solid #003591 !important" @click="getFileUpload()">
							<span class="iconfont icon-lianjie btn-icon" style="color: #003591"></span>
							<span class="btn-text" style="color: #003591">{{ $t(`editAttr.attrText68`) }}</span>
						</el-button>
						<!-- 云端数据 -->
						<el-button class="p-textarea-half-btn" style="border: 1px solid #003591 !important" @click="$refs.excelLinkRef.show()">
							<span class="iconfont icon-lianjie btn-icon" style="color: #003591"></span>
							<span class="btn-text" style="color: #003591">{{ $t(`editAttr.attrText67`) }}</span>
						</el-button>

						<input style="display: none" type="file" name="cover" accept=".xls,.xlsx" @change="getLocalData" id="selectBtn" />
					</div>
					<!-- 显示已连接的数据表表名 -->
					<div style="margin-top: 16px; width: 100%" v-if="attrObj.DataSourceList[attrObj.dataSourceIndex].ConnectionStr">
						<el-input
							:value="attrObj.DataSourceList[attrObj.dataSourceIndex].ConnectionStr.substr(attrObj.DataSourceList[attrObj.dataSourceIndex].ConnectionStr.lastIndexOf('/') + 1).length > 25 ? `${attrObj.DataSourceList[attrObj.dataSourceIndex].ConnectionStr.substr(attrObj.DataSourceList[attrObj.dataSourceIndex].ConnectionStr.lastIndexOf('/') + 1).substr(0, 10)}...${attrObj.DataSourceList[attrObj.dataSourceIndex].ConnectionStr.substr(attrObj.DataSourceList[attrObj.dataSourceIndex].ConnectionStr.lastIndexOf('/') + 1).substr(-12)}`: `${attrObj.DataSourceList[attrObj.dataSourceIndex].ConnectionStr.substr(attrObj.DataSourceList[attrObj.dataSourceIndex].ConnectionStr.lastIndexOf('/') + 1)}`"
							disabled
							style="width: 100%" />
					</div>
					<!-- 插入数据 -->
					<el-cascader
						v-if="attrObj.DataSourceList[attrObj.dataSourceIndex].ConnectionStr"
						style="margin-top: 16px; width: 100%"
						:popper-append-to-body="false"
						:options="attrObj.DataSourceList[attrObj.dataSourceIndex].DataBase"
						v-model="attrObj.DataSourceList[attrObj.dataSourceIndex].SheetIndex"
						:props="{
							label: 'text',
							value: 'index',
							children: 'children'
						}"
						:placeholder="$t(`editAttr.attrText38`)"
						@change="handleInsertSheet" />
				</div>
				<!-- 数据库文本的数据类型 -->
				<!-- <div v-if="(nodeType == 'WinText' || nodeType == 'GeneralBarcode' || nodeType == 'QRCode') && attrObj.DataSourceList[attrObj.dataSourceIndex].DataSourceType == 1"> -->
				<!-- 数据类型 -->
				<!-- <div class="p-text">
						<div class="p-step-title">{{ $t(`editAttr.attrText35`) }}</div>
						<el-select
							class="p-text-select"
							:popper-append-to-body="false"
							v-model="attrObj.DataSourceList[attrObj.dataSourceIndex].DataType"
							@change="dateBaseTypeChange($event)"
							:placeholder="$t(`editAttr.attrText36`)">
							<el-option v-for="item in dateBaseType" :key="item.value" :label="item.label" :value="item.value"> </el-option>
						</el-select>
					</div> -->
				<!-- </div> -->
				<!-- 序列化 -->
				<template v-if="(nodeType == 'WinText' || nodeType == 'GeneralBarcode' || nodeType == 'QRCode') && attrObj.DataSourceList[attrObj.dataSourceIndex].DataSourceType == 2">
					<!-- 序列类型 -->
					<div class="p-text">
						<div class="p-step-title">{{ $t(`editAttr.attrText92`) }}</div>
						<el-select
							class="p-text-select"
							:popper-append-to-body="false"
							v-model="attrObj.DataSourceList[attrObj.dataSourceIndex].SequenceType"
							:placeholder="$t(`editAttr.attrText36`)"
							@change="sequenceTypeChange()">
							<el-option v-for="item in sequenceTypeList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
						</el-select>
					</div>
					<!-- 序列化文本 -->
					<div class="p-textarea">
						<div class="p-serialization">
							<!-- 递变数值 -->
							<div class="p-InitialValue">
								<div>{{ $t(`editAttr.attrText39`) }}</div>
								<el-input v-model="attrObj.DataSourceList[attrObj.dataSourceIndex].FirstValue" :formatter="dataFormatter" :parser="dataFormatter" @input="handleTextData()"></el-input>
							</div>
							<!-- 递变量 -->
							<div>
								<div>{{ $t(`editAttr.attrText40`) }}</div>
								<el-input-number
									v-model="attrObj.DataSourceList[attrObj.dataSourceIndex].AddedValue"
									:step="1"
									:precision="0"
									size="large"
									controls-position="right"
									:value-on-clear="node.attrs.parameter.DataSourceList[attrObj.dataSourceIndex].AddedValue"
									@change="handleAddedValue()"></el-input-number>
							</div>
							<!-- 数量 -->
							<div>
								<div>{{ $t(`editAttr.attrText41`) }}</div>
								<el-input-number
									v-model="attrObj.DataSourceList[attrObj.dataSourceIndex].Quantity"
									:step="1"
									:min="1"
									:precision="0"
									size="large"
									controls-position="right"
									:value-on-clear="node.attrs.parameter.DataSourceList[attrObj.dataSourceIndex].Quantity"
									@change="handleQuantity()">
								</el-input-number>
							</div>
						</div>
					</div>
				</template>
				<!-- 元素值选择 -->
				<div class="p-element" v-if="(nodeType == 'WinText' || nodeType == 'GeneralBarcode' || nodeType == 'QRCode') && attrObj.DataSourceList[attrObj.dataSourceIndex].DataSourceType == 3">
					<el-select
						class="p-text-select"
						:popper-append-to-body="false"
						v-model="attrObj.DataSourceList[attrObj.dataSourceIndex].ReferenceId"
						:placeholder="$t(`editAttr.attrText36`)"
						@change="handleBindElement">
						<el-option v-for="item in elementList" :key="item.Id" :label="item.Name" :value="item.Id"> </el-option>
					</el-select>
				</div>
				<!-- 组合数据列表 -->
				<div v-if="nodeType == 'WinText' || nodeType == 'GeneralBarcode' || nodeType == 'QRCode'">
					<!-- 数据列表 -->
					<div ref="combinationBox" class="p-combination" v-if="attrObj.DataSourceList.length > 1">
						<ul>
							<li
								v-for="(list, index) in attrObj.DataSourceList"
								:key="index"
								:style="{
									backgroundColor: attrObj.dataSourceIndex == index ? 'rgba(0, 53, 145, 0.1)' : ''
								}"
								@click="handleDataSourceList(index)">
								<div class="combination-text">{{ list.FirstValue }}</div>
								<div class="combination-icon" v-show="attrObj.dataSourceIndex == index">
									<span class="iconfont icon-plus" @click.stop="dealSourceData(0)"></span>
									<span class="iconfont icon-minus" @click.stop="dealSourceData(1)"></span>
									<span class="iconfont icon-up" :style="{ color: attrObj.dataSourceIndex == 0 ? 'rgba(123, 137, 161, 0.4)' : '' }" @click.stop="dealSourceData(2)"></span>
									<span
										class="iconfont icon-down"
										:style="{ color: attrObj.dataSourceIndex == attrObj.DataSourceList.length - 1 ? 'rgba(123, 137, 161, 0.4)' : '' }"
										@click.stop="dealSourceData(3)"></span>
								</div>
							</li>
						</ul>
					</div>
					<!-- 新增数据 -->
					<div v-else class="p-textarea">
						<el-button style="width: 100%" @click="dealSourceData(0)">{{ $t(`editAttr.attrText102`) }}</el-button>
					</div>
				</div>
				<!-- 前缀 -->
				<div class="p-fonts" v-if="nodeType == 'WinText'">
					<div style="width: 45%">
						<div class="p-step-title">{{ $t(`editAttr.attrText118`) }}</div>
						<el-input style="width: 100%" v-model="attrObj.TextPrefix" :placeholder="$t(`editAttr.attrText118`)" @input="handleTextFix"></el-input>
					</div>
					<div style="width: 45%">
						<div class="p-step-title">{{ $t(`editAttr.attrText119`) }}</div>
						<el-input style="width: 100%" v-model="attrObj.TextSuffix" :placeholder="$t(`editAttr.attrText119`)" @input="handleTextFix"></el-input>
					</div>
				</div>
				<!-- 时间类型 选择时间 时间格式 天数偏移 分钟偏移 -->
				<div v-if="nodeType == 'TimeText'">
					<div class="p-text" style="margin: 16px 0">
						<el-checkbox-group v-model="attrObj.DateFormatChecked" :min="1" @change="handleDateFormatChecked">
							<el-checkbox :label="$t(`editAttr.attrText16`)" value="date" />
							<el-checkbox :label="$t(`editAttr.attrText120`)" value="time" />
						</el-checkbox-group>
					</div>
					<!-- 选择日期 -->
					<div class="p-text" v-if="attrObj.DateFormatChecked.indexOf('date') > -1">
						<el-date-picker
							style="width: 100%"
							type="date"
							:disabled="attrObj.TimeType == 0 ? true : false"
							v-model="attrObj.SelectDate"
							:placeholder="$t(`editAttr.attrText36`)"
							format="YYYY/MM/DD"
							value-format="YYYY/M/D"
							@change="handlePickDateTime"></el-date-picker>
					</div>
					<!-- 选择时间 -->
					<div class="p-text" style="margin-top: 16px" v-if="attrObj.DateFormatChecked.indexOf('time') > -1">
						<el-time-picker
							style="width: 100%"
							:disabled="attrObj.TimeType == 0 ? true : false"
							v-model="attrObj.SelectTime"
							:placeholder="$t(`editAttr.attrText36`)"
							arrow-control
							format="HH:mm:ss"
							value-format="HH:m:s"
							@change="handlePickDateTime">
						</el-time-picker>
					</div>
					<!-- 时间类型 -->
					<div class="p-text">
						<div class="p-step-title">{{ $t(`editAttr.attrText18`) }}</div>
						<el-select class="p-text-select" :popper-append-to-body="false" v-model="attrObj.TimeType" @change="handleTimeType" :placeholder="$t(`editAttr.attrText36`)">
							<el-option v-for="item in dataTime" :key="item.value" :label="item.label" :value="item.value"> </el-option>
						</el-select>
					</div>
					<!-- 日期格式 -->
					<div class="p-text" v-if="attrObj.DateFormatChecked.indexOf('date') > -1">
						<div class="p-step-title">{{ $t(`editAttr.attrText43`) }}</div>
						<el-select class="p-text-select" :popper-append-to-body="false" v-model="attrObj.DataFormat" @change="handleDateTimeFormat" :placeholder="$t(`editAttr.attrText36`)">
							<el-option v-for="(item, index) in dateFormatList" :key="index" :label="item" :value="item"> </el-option>
						</el-select>
					</div>
					<!-- 时间格式  -->
					<div class="p-text" v-if="attrObj.DateFormatChecked.indexOf('time') > -1">
						<div class="p-step-title">{{ $t(`editAttr.attrText115`) }}</div>
						<el-select class="p-text-select" :popper-append-to-body="false" v-model="attrObj.TimeFormat" @change="handleDateTimeFormat" :placeholder="$t(`editAttr.attrText36`)">
							<el-option v-for="(item, index) in timeFormatList" :key="index" :label="item" :value="item"> </el-option>
						</el-select>
					</div>

					<!-- 天数偏移 分钟偏移 -->
					<div class="p-fonts">
						<!-- 天数偏移 -->
						<div style="width: 45%">
							<div class="p-step-title">{{ $t(`editAttr.attrText95`) }}</div>
							<el-input-number
								class="p-text-select"
								v-model="attrObj.DayOffset"
								controls-position="right"
								:step="1"
								:precision="0"
								:value-on-clear="node.attrs.parameter.DayOffset"
								@change="handleTimeOffset">
							</el-input-number>
						</div>
						<!-- 分钟偏移 -->
						<div style="width: 45%">
							<div class="p-step-title">{{ $t(`editAttr.attrText96`) }}</div>
							<el-input-number
								class="p-text-select"
								v-model="attrObj.MinuteOffset"
								controls-position="right"
								:step="1"
								:precision="0"
								:value-on-clear="node.attrs.parameter.MinuteOffset"
								@change="handleTimeOffset">
							</el-input-number>
						</div>
					</div>
				</div>
				<!-- 条码类型 -->
				<div class="p-text" v-if="nodeType == 'GeneralBarcode' || nodeType == 'QRCode'">
					<div class="p-step-title">{{ $t(`editAttr.attrText21`) }}</div>
					<el-select class="p-text-select" :popper-append-to-body="false" v-model="attrObj.BarcodeType" :placeholder="$t(`editAttr.attrText36`)" @change="handleCodeType">
						<el-option v-for="item in CodeTypeList" :key="item.bcid" :label="item.label" :value="item.value"> </el-option>
					</el-select>
				</div>
				<!-- 纠错率 -->
				<div class="p-text" v-if="nodeType == 'QRCode' && (attrObj.BarcodeType == 'QR_CODE' || attrObj.BarcodeType == 'PDF_417')">
					<div class="p-step-title">{{ $t(`editAttr.attrText29`) }}</div>
					<el-select class="p-text-select" :popper-append-to-body="false" v-model="attrObj.ECLevelBits" :placeholder="$t(`editAttr.attrText36`)" @change="handleLevelBits">
						<el-option v-for="item in eclevelList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
					</el-select>
				</div>
				<!-- 条形码文本位置 -->
				<div class="p-text" v-if="nodeType == 'GeneralBarcode'">
					<div class="p-step-title">{{ $t(`editAttr.attrText22`) }}</div>
					<el-select class="p-text-select" :popper-append-to-body="false" v-model="attrObj.BarcodeNumPosition" :placeholder="$t(`editAttr.attrText36`)" @change="handleSetBarcodeNumPosition">
						<el-option v-for="item in dataLocation" :key="item.value" :label="item.label" :value="item.value"> </el-option>
					</el-select>
				</div>
				<!-- 字体 -->
				<div class="p-text" v-if="nodeType == 'WinText' || nodeType == 'TimeText'">
					<div class="p-step-title">{{ $t(`editAttr.attrText6`) }}</div>
					<el-select class="p-text-select" v-model="attrObj.FontFamily" @change="handleFontFamily" :placeholder="$t(`editAttr.attrText36`)">
						<el-option v-for="item in familyList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
					</el-select>
				</div>
				<!-- 字号 -->
				<div class="p-text" v-if="nodeType == 'WinText' || nodeType == 'GeneralBarcode' || nodeType == 'TimeText'">
					<div class="p-step-title">{{ $t(`editAttr.attrText7`) }}</div>
					<el-input-number
						style="width: 100%"
						v-model="attrObj.FontSize"
						controls-position="right"
						:step="0.5"
						:precision="1"
						:max="100"
						:min="1"
						:value-on-clear="node.attrs.parameter.FontSize"
						@change="handleFontSize">
					</el-input-number>
				</div>
				<!-- 添加图片 -->
				<div class="p-text" v-if="nodeType == 'Image'">
					<div class="p-img-btn" style="margin-top: 24px">
						<el-upload action="" :on-change="getLocalImg" list-type="picture" :auto-upload="false" accept=".jpeg,.jpg,.png">
							<el-button>{{ $t(`editAttr.attrText30`) }}</el-button>
						</el-upload>
						<div class="p-cloud-btn" style="margin-top: 16px">
							<el-button @click.stop="openCloudImg()">{{ $t(`editAttr.attrText31`) }}</el-button>
						</div>
					</div>
				</div>
				<!-- 旋转 -->
				<div class="p-textarea" v-if="nodeType == 'WinText' || nodeType == 'GeneralBarcode' || nodeType == 'Image' || nodeType == 'TimeText'">
					<div class="p-step-title">{{ $t(`editAttr.attrText9`) }}</div>
					<div class="b-rotate">
						<div class="rotate" @click="handleRotate(0)" :style="{ color: attrObj.Rotate == 0 ? '#003591' : '#7b89a1' }">0°</div>
						<div class="rotate" :style="{ color: attrObj.Rotate == 90 ? '#003591' : '#7b89a1' }" @click="handleRotate(90)">90°</div>
						<div class="rotate" :style="{ color: attrObj.Rotate == 180 ? '#003591' : '#7b89a1' }" @click="handleRotate(180)">180°</div>
						<div class="rotate" :style="{ color: attrObj.Rotate == -90 ? '#003591' : '#7b89a1' }" @click="handleRotate(-90)">270°</div>
					</div>
				</div>
				<!-- 等比缩放 适应画布 -->
				<div class="p-text" v-if="nodeType == 'Image'">
					<div class="p-img-check">
						<el-checkbox v-model="attrObj.ISParticipating" @change="handleISParticipating()">{{ $t(`editAttr.attrText32`) }}</el-checkbox>
						<el-button class="p-img-btn" @click="handleAdaptCanvas()">{{ $t(`editAttr.attrText46`) }}</el-button>
					</div>
				</div>
				<!-- 字间距  行间距 -->
				<div class="p-fonts" v-if="nodeType == 'WinText'">
					<!-- 字间距 -->
					<div style="width: 45%">
						<div class="p-step-title">{{ $t(`editAttr.attrText10`) }}</div>
						<el-input-number
							class="p-text-select"
							style="width: 100%"
							v-model="attrObj.LetterSpacing"
							controls-position="right"
							:step="0.1"
							:min="-1.2"
							:max="12.5"
							:precision="2"
							:value-on-clear="node.attrs.parameter.LetterSpacing"
							@change="handleLetterSpacing">
						</el-input-number>
					</div>
					<!-- 行间距 -->
					<div style="width: 45%">
						<div class="p-step-title">{{ $t(`editAttr.attrText11`) }}</div>
						<el-input-number
							class="p-text-select"
							style="width: 100%"
							v-model="attrObj.LineHeight"
							controls-position="right"
							:step="0.5"
							:min="0"
							:max="12.5"
							:precision="2"
							:value-on-clear="node.attrs.parameter.LineHeight"
							@change="handleLineHeight">
						</el-input-number>
					</div>
				</div>
				<!-- 自动换行 -->
				<div class="p-fonts" style="margin-top: 16px" v-if="nodeType == 'WinText' || nodeType == 'TimeText'">
					<div>
						<el-checkbox v-model="attrObj.AutoWrap" @change="handleAutoWrap">{{ $t(`editAttr.attrText12`) }}</el-checkbox>
					</div>
					<div v-if="attrObj.AutoWrap" style="width: 45%">
						<el-input-number
							v-model="attrObj.BoxWidth"
							controls-position="right"
							:step="1"
							:precision="2"
							:min="1"
							:max="1200"
							:value-on-clear="node.attrs.parameter.BoxWidth"
							@change="handleAutoWrapWidth()">
						</el-input-number>
					</div>
				</div>
				<!-- 镜像  黑反 -->
				<div class="p-textarea" style="display: flex; align-items: center; justify-content: space-between" v-if="nodeType == 'WinText' || nodeType == 'TimeText'">
					<el-button class="p-textarea-half-btn" :style="{ borderColor: attrObj.MirrorImage ? '#003591 !important' : '' }" @click="handleMirrorImage()">
						<span class="iconfont icon-jingxiang btn-icon" :style="{ color: attrObj.MirrorImage ? '#003591' : '' }"></span>
						<span class="btn-text" :style="{ color: attrObj.MirrorImage ? '#003591' : '' }">{{ $t(`editAttr.attrText13`) }}</span>
					</el-button>
					<el-button class="p-textarea-half-btn" :style="{ borderColor: attrObj.AntiBlack ? '#003591 !important' : '' }" @click="handleAntiBlack()">
						<span class="iconfont icon-heifan btn-icon" :style="{ color: attrObj.AntiBlack ? '#003591' : '' }"></span>
						<span class="btn-text" :style="{ color: attrObj.AntiBlack ? '#003591' : '' }">{{ $t(`editAttr.attrText14`) }}</span>
					</el-button>
				</div>
				<!-- 图形线条类型 粗细 -->
				<div
					class="p-text"
					v-if="
						nodeType == 'LineVertical' ||
						nodeType == 'LineHorizontal' ||
						nodeType == 'Line' ||
						nodeType == 'Rectangle' ||
						nodeType == 'RoundRectangle' ||
						nodeType == 'Diamond' ||
						nodeType == 'Ellipse' ||
						nodeType == 'Triangle'
					">
					<!-- 斜线方向 -->
					<div class="p-textarea" v-if="nodeType == 'Line'">
						<div class="p-step-title">{{ $t(`editAttr.attrText121`) }}</div>
						<div class="attr-style">
							<div class="attr-style-item">
								<span
									class="iconfont icon-zuoxiexian"
									:style="{ color: attrObj.LineDirection == 'LeftLine' ? '#003591' : '', background: attrObj.LineDirection == 'LeftLine' ? '#fff' : '' }"
									@click="handleLineDirection('LeftLine')"></span>
							</div>
							<div class="attr-style-item">
								<span
									class="iconfont icon-youxiexian"
									:style="{ color: attrObj.LineDirection == 'RightLine' ? '#003591' : '', background: attrObj.LineDirection == 'RightLine' ? '#fff' : '' }"
									@click="handleLineDirection('RightLine')"></span>
							</div>
						</div>
					</div>
					<!-- 线条类型 -->
					<div>
						<div class="p-step-title">{{ $t(`editAttr.attrText33`) }}</div>
						<div class="attr-style">
							<div class="attr-style-item">
								<span class="iconfont icon-shixian" :style="{ color: attrObj.DashStyle == 0 ? '#003591' : '', background: attrObj.DashStyle == 0 ? '#fff' : '' }" @click="handleLineStyle(0)"></span>
							</div>
							<div class="attr-style-item">
								<span class="iconfont icon-xuxian" :style="{ color: attrObj.DashStyle == 1 ? '#003591' : '', background: attrObj.DashStyle == 1 ? '#fff' : '' }" @click="handleLineStyle(1)"></span>
							</div>
						</div>
					</div>
					<!-- 线条粗细 -->
					<div>
						<div class="p-step-title">{{ $t(`editAttr.attrText34`) }}</div>
						<el-input-number
							style="width: 100%"
							controls-position="right"
							v-model="attrObj.PenWidth"
							:step="0.1"
							:min="0.3"
							:max="10"
							:precision="1"
							:value-on-clear="node.attrs.parameter.PenWidth"
							@change="handlePenWidth()"></el-input-number>
					</div>
					<!-- 圆角半径 -->
					<div v-if="nodeType == 'RoundRectangle'">
						<div class="p-step-title">{{ $t(`editAttr.attrText45`) }}</div>
						<el-input-number
							style="width: 100%"
							controls-position="right"
							v-model="attrObj.CornerRadius"
							:step="0.5"
							:min="0"
							:max="10"
							:precision="1"
							:value-on-clear="node.attrs.parameter.CornerRadius"
							@change="handleCornerRadius()">
						</el-input-number>
					</div>
					<!-- 填充 -->
					<div class="p-font" style="margin-top: 16px" v-if="nodeType != 'LineVertical' && nodeType != 'LineHorizontal' && nodeType != 'Line'">
						<el-checkbox v-model="attrObj.Fill" @change="handleFillShape">{{ $t(`editAttr.attrText117`) }}</el-checkbox>
					</div>
				</div>
				<!-- 表格 -->
				<div v-if="nodeType == 'Table'">
					<!-- 表格操作 -->
					<div class="p-textarea">
						<div class="b-location">
							<div class="b-icon table-tool">
								<div class="iconfont icon-multiple-choice b-icon-font" :style="{ color: attrObj.cellMultiple ? '#003591' : '' }" @click="handleTableOperation('mutiple')"></div>
								<div class="align-icon-hover">
									<div class="triangle_border_up"></div>
									<div class="align-icon-text">
										{{ $t(`editAttr.attrText82`) }}
									</div>
								</div>
							</div>
							<div class="b-icon table-tool">
								<div class="iconfont icon-insert-row b-icon-font" @click="handleTableOperation('insert_row')"></div>
								<div class="align-icon-hover">
									<div class="triangle_border_up"></div>
									<div class="align-icon-text">
										{{ $t(`editAttr.attrText109`) }}
									</div>
								</div>
							</div>
							<div class="b-icon table-tool">
								<div class="iconfont icon-insert-column b-icon-font" @click="handleTableOperation('insert_column')"></div>
								<div class="align-icon-hover">
									<div class="triangle_border_up"></div>
									<div class="align-icon-text">
										{{ $t(`editAttr.attrText110`) }}
									</div>
								</div>
							</div>
							<div class="b-icon table-tool">
								<div class="iconfont icon-delete-row b-icon-font" @click="handleTableOperation('delete_row')"></div>
								<div class="align-icon-hover">
									<div class="triangle_border_up"></div>
									<div class="align-icon-text">
										{{ $t(`editAttr.attrText111`) }}
									</div>
								</div>
							</div>
							<div class="b-icon table-tool">
								<div class="iconfont icon-delete-column b-icon-font" @click="handleTableOperation('delete_column')"></div>
								<div class="align-icon-hover">
									<div class="triangle_border_up"></div>
									<div class="align-icon-text">
										{{ $t(`editAttr.attrText112`) }}
									</div>
								</div>
							</div>
							<div class="b-icon table-tool" v-if="showTableSplit">
								<div
									class="iconfont icon-split b-icon-font"
									:style="{
										color: showTableSplit ? '#003591' : ''
									}"
									@click="handleTableOperation('split')"></div>
								<div class="align-icon-hover">
									<div class="triangle_border_up"></div>
									<div class="align-icon-text">
										{{ $t(`editAttr.attrText114`) }}
									</div>
								</div>
							</div>
							<div class="b-icon table-tool" v-else>
								<div
									class="iconfont icon-merge b-icon-font"
									:style="{
										color: attrObj.cellMultiple && attrObj.mulCellPos.length > 1 ? '#535d6c' : 'rgba(123, 137, 161, 0.4)'
									}"
									@click="handleTableOperation('merge')"></div>
								<div class="align-icon-hover">
									<div class="triangle_border_up"></div>
									<div class="align-icon-text">
										{{ $t(`editAttr.attrText113`) }}
									</div>
								</div>
							</div>
						</div>
					</div>
					<template v-if="!attrObj.cellMultiple">
						<!-- 文本、条形码、二维码数据类型 -->
						<div class="p-text">
							<div class="p-step-title">{{ $t(`editAttr.attrText2`) }}</div>
							<el-select
								class="p-text-select"
								:popper-append-to-body="false"
								v-model="attrObj.DataSourceList[attrObj.dataSourceIndex].DataSourceType"
								:placeholder="$t(`editAttr.attrText36`)"
								@change="handleDataSourceType()">
								<el-option v-for="item in dataSourceTypeList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
							</el-select>
						</div>
						<!-- 普通文本输入框 -->
						<div class="p-textarea" v-if="attrObj.DataSourceList[attrObj.dataSourceIndex].DataSourceType == 0">
							<el-input type="textarea" resize="none" :rows="3" :placeholder="$t(`editAttr.attrText47`)" v-model="attrObj.DataSourceList[attrObj.dataSourceIndex].FirstValue" @input="handleTextData()">
							</el-input>
						</div>
						<!-- 数据库文本 -->
						<div class="p-textarea" v-if="attrObj.DataSourceList[attrObj.dataSourceIndex].DataSourceType == 1">
							<!-- 连接数据库 -->
							<div style="display: flex; align-items: center; justify-content: space-between">
								<!-- 本地数据 -->
								<el-button class="p-textarea-half-btn" style="border: 1px solid #003591 !important" @click="getFileUpload()">
									<span class="iconfont icon-lianjie btn-icon" style="color: #003591"></span>
									<span class="btn-text" style="color: #003591">{{ $t(`editAttr.attrText68`) }}</span>
								</el-button>
								<!-- 云端数据 -->
								<el-button class="p-textarea-half-btn" style="border: 1px solid #003591 !important" @click="$refs.excelLinkRef.show()">
									<span class="iconfont icon-lianjie btn-icon" style="color: #003591"></span>
									<span class="btn-text" style="color: #003591">{{ $t(`editAttr.attrText67`) }}</span>
								</el-button>

								<input style="display: none" type="file" name="cover" accept=".xls,.xlsx" @change="getLocalData" id="selectBtn" />
							</div>
							<!-- 显示已连接的数据表表名 -->
							<div style="margin-top: 16px; width: 100%" v-if="attrObj.DataSourceList[attrObj.dataSourceIndex].ConnectionStr">
                <el-input
                :value="attrObj.DataSourceList[attrObj.dataSourceIndex].ConnectionStr.substr(attrObj.DataSourceList[attrObj.dataSourceIndex].ConnectionStr.lastIndexOf('/') + 1).length > 25 ? `${attrObj.DataSourceList[attrObj.dataSourceIndex].ConnectionStr.substr(attrObj.DataSourceList[attrObj.dataSourceIndex].ConnectionStr.lastIndexOf('/') + 1).substr(0, 10)}...${attrObj.DataSourceList[attrObj.dataSourceIndex].ConnectionStr.substr(attrObj.DataSourceList[attrObj.dataSourceIndex].ConnectionStr.lastIndexOf('/') + 1).substr(-12)}`: `${attrObj.DataSourceList[attrObj.dataSourceIndex].ConnectionStr.substr(attrObj.DataSourceList[attrObj.dataSourceIndex].ConnectionStr.lastIndexOf('/') + 1)}`"
                disabled
                style="width: 100%" />
							</div>
							<!-- 插入数据 -->
							<el-cascader
								style="margin-top: 16px; width: 100%"
								:popper-append-to-body="false"
								:options="attrObj.DataSourceList[attrObj.dataSourceIndex].DataBase"
								v-model="attrObj.DataSourceList[attrObj.dataSourceIndex].SheetIndex"
								:props="{
									label: 'text',
									value: 'index',
									children: 'children'
								}"
								:placeholder="$t(`editAttr.attrText38`)"
								@change="handleInsertSheet" />
						</div>
						<!-- 数据库文本的数据类型 -->
						<!-- <div v-if="attrObj.DataSourceList[attrObj.dataSourceIndex].DataSourceType == 1">
							<div class="p-text">
								<div class="p-step-title">{{ $t(`editAttr.attrText35`) }}</div>
								<el-select
									class="p-text-select"
									:popper-append-to-body="false"
									v-model="attrObj.DataSourceList[attrObj.dataSourceIndex].DataType"
									@change="dateBaseTypeChange($event)"
									:placeholder="$t(`editAttr.attrText36`)">
									<el-option v-for="item in dateBaseType" :key="item.value" :label="item.label" :value="item.value"> </el-option>
								</el-select>
							</div>
						</div> -->
						<!-- 序列化 -->
						<template v-if="attrObj.DataSourceList[attrObj.dataSourceIndex].DataSourceType == 2">
							<!-- 序列类型 -->
							<div class="p-text">
								<div class="p-step-title">{{ $t(`editAttr.attrText92`) }}</div>
								<el-select
									class="p-text-select"
									:popper-append-to-body="false"
									v-model="attrObj.DataSourceList[attrObj.dataSourceIndex].SequenceType"
									:placeholder="$t(`editAttr.attrText36`)"
									@change="sequenceTypeChange()">
									<el-option v-for="item in sequenceTypeList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
								</el-select>
							</div>
							<!-- 序列化文本 -->
							<div class="p-textarea">
								<div class="p-serialization">
									<!-- 递变数值 -->
									<div class="p-InitialValue">
										<div>{{ $t(`editAttr.attrText39`) }}</div>
										<el-input v-model="attrObj.DataSourceList[attrObj.dataSourceIndex].FirstValue" :formatter="dataFormatter" :parser="dataFormatter" @input="handleTextData()"></el-input>
									</div>
									<!-- 递变量 -->
									<div>
										<div>{{ $t(`editAttr.attrText40`) }}</div>
										<el-input-number
											v-model="attrObj.DataSourceList[attrObj.dataSourceIndex].AddedValue"
											:step="1"
											:precision="0"
											size="large"
											controls-position="right"
											:value-on-clear="node.attrs.parameter.DataSourceList[attrObj.dataSourceIndex].AddedValue"
											@change="handleAddedValue()"></el-input-number>
									</div>
									<!-- 数量 -->
									<div>
										<div>{{ $t(`editAttr.attrText41`) }}</div>
										<el-input-number
											v-model="attrObj.DataSourceList[attrObj.dataSourceIndex].Quantity"
											:step="1"
											:min="1"
											:precision="0"
											size="large"
											controls-position="right"
											:value-on-clear="node.attrs.parameter.DataSourceList[attrObj.dataSourceIndex].Quantity"
											@change="handleQuantity()">
										</el-input-number>
									</div>
								</div>
							</div>
						</template>
					</template>
					<!-- 表格线条粗细 -->
					<div class="p-text">
						<div class="p-step-title">{{ $t(`editAttr.attrText34`) }}(mm)</div>
						<el-input-number
							style="width: 100%"
							controls-position="right"
							v-model="attrObj.PenWidth"
							:step="0.1"
							:min="0.3"
							:max="5"
							:precision="1"
							:value-on-clear="node.attrs.parameter.PenWidth"
							@change="handleTableBorder">
						</el-input-number>
					</div>
					<!-- 表格行高-->
					<div class="p-text" v-if="!attrObj.cellMultiple">
						<div class="p-step-title">{{ $t(`editAttr.attrText106`) }}(mm)</div>
						<el-input-number
							style="width: 100%"
							controls-position="right"
							v-model="attrObj.DataSourceList[attrObj.dataSourceIndex].Height"
							:step="0.1"
							:min="1"
							:precision="1"
							:value-on-clear="node.attrs.parameter.DataSourceList[attrObj.dataSourceIndex].Height"
							@change="handleTableLineHeight">
						</el-input-number>
					</div>
					<!-- 表格列宽 -->
					<div class="p-text" v-if="!attrObj.cellMultiple">
						<div class="p-step-title">{{ $t(`editAttr.attrText107`) }}(mm)</div>
						<el-input-number
							style="width: 100%"
							controls-position="right"
							v-model="attrObj.DataSourceList[attrObj.dataSourceIndex].Width"
							:step="0.1"
							:min="1"
							:precision="1"
							:value-on-clear="node.attrs.parameter.DataSourceList[attrObj.dataSourceIndex].Width"
							@change="handleColumnWidth">
						</el-input-number>
					</div>
					<!-- 字体 -->
					<div class="p-text">
						<div class="p-step-title">{{ $t(`editAttr.attrText6`) }}</div>
						<el-select class="p-text-select" v-model="attrObj.DataSourceList[attrObj.dataSourceIndex].FontFamily" @change="handleFontFamily" :placeholder="$t(`editAttr.attrText36`)">
							<el-option v-for="item in familyList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
						</el-select>
					</div>
					<!-- 字号 -->
					<div class="p-text">
						<div class="p-step-title">{{ $t(`editAttr.attrText7`) }}</div>
						<el-input-number
							style="width: 100%"
							v-model="attrObj.DataSourceList[attrObj.dataSourceIndex].FontSize"
							controls-position="right"
							:step="1"
							:max="100"
							:precision="0"
							:min="1"
							:value-on-clear="node.attrs.parameter.DataSourceList[attrObj.dataSourceIndex].FontSize"
							@change="handleFontSize">
						</el-input-number>
					</div>
					<!-- 旋转 -->
					<div class="p-textarea">
						<div class="p-step-title">{{ $t(`editAttr.attrText9`) }}</div>
						<div class="b-rotate">
							<div class="rotate" @click="handleRotate(0)" :style="{ color: attrObj.Rotate == 0 ? '#003591' : '#7b89a1' }">0°</div>
							<div class="rotate" :style="{ color: attrObj.Rotate == 90 ? '#003591' : '#7b89a1' }" @click="handleRotate(90)">90°</div>
							<div class="rotate" :style="{ color: attrObj.Rotate == 180 ? '#003591' : '#7b89a1' }" @click="handleRotate(180)">180°</div>
							<div class="rotate" :style="{ color: attrObj.Rotate == -90 ? '#003591' : '#7b89a1' }" @click="handleRotate(-90)">270°</div>
						</div>
					</div>
					<!-- 字间距 行间距 -->
					<div class="p-fonts">
						<!-- 字间距 -->
						<div style="width: 45%">
							<div class="p-step-title">{{ $t(`editAttr.attrText10`) }}</div>
							<el-input-number
								class="p-text-select"
								style="width: 100%"
								v-model="attrObj.DataSourceList[attrObj.dataSourceIndex].LetterSpacing"
								controls-position="right"
								:step="0.1"
								:min="-1.2"
								:max="12.5"
								:precision="2"
								:value-on-clear="node.attrs.parameter.DataSourceList[attrObj.dataSourceIndex].LetterSpacing"
								@change="handleLetterSpacing">
							</el-input-number>
						</div>
						<!-- 行间距 -->
						<div style="width: 45%">
							<div class="p-step-title">{{ $t(`editAttr.attrText11`) }}</div>
							<el-input-number
								class="p-text-select"
								style="width: 100%"
								v-model="attrObj.DataSourceList[attrObj.dataSourceIndex].LineHeight"
								controls-position="right"
								:step="0.5"
								:min="0"
								:max="12.5"
								:precision="2"
								:value-on-clear="node.attrs.parameter.DataSourceList[attrObj.dataSourceIndex].LineHeight"
								@change="handleLineHeight">
							</el-input-number>
						</div>
					</div>
					<!-- 镜像  黑反 -->
					<div class="p-textarea" style="display: flex; align-items: center; justify-content: space-between">
						<el-button class="p-textarea-half-btn" :style="{ borderColor: attrObj.DataSourceList[attrObj.dataSourceIndex].MirrorImage ? '#003591 !important' : '' }" @click="handleMirrorImage()">
							<span class="iconfont icon-jingxiang btn-icon" :style="{ color: attrObj.DataSourceList[attrObj.dataSourceIndex].MirrorImage ? '#003591' : '' }"></span>
							<span class="btn-text" :style="{ color: attrObj.DataSourceList[attrObj.dataSourceIndex].MirrorImage ? '#003591' : '' }">{{ $t(`editAttr.attrText13`) }}</span>
						</el-button>
						<el-button class="p-textarea-half-btn" :style="{ borderColor: attrObj.DataSourceList[attrObj.dataSourceIndex].AntiBlack ? '#003591 !important' : '' }" @click="handleAntiBlack()">
							<span class="iconfont icon-heifan btn-icon" :style="{ color: attrObj.DataSourceList[attrObj.dataSourceIndex].AntiBlack ? '#003591' : '' }"></span>
							<span class="btn-text" :style="{ color: attrObj.DataSourceList[attrObj.dataSourceIndex].AntiBlack ? '#003591' : '' }">{{ $t(`editAttr.attrText14`) }}</span>
						</el-button>
					</div>
				</div>
				<!-- 位置 -->
				<div class="p-step" style="height: 250px">
					<div class="p-step-title">{{ $t(`editAttr.attrText15`) }}</div>
					<div class="p-step-item">
						<div class="p-step-li">
							<div class="p-step-li-title">X：</div>
							<el-input-number
								v-model="attrObj.StartX"
								:precision="2"
								:step="1"
								size="large"
								controls-position="right"
								:value-on-clear="node ? node.attrs.parameter.StartX : 0"
								@change="handleMoveStartX()"></el-input-number>
						</div>
						<div class="p-step-li">
							<div class="p-step-li-title">Y：</div>
							<el-input-number
								v-model="attrObj.StartY"
								:precision="2"
								:step="1"
								size="large"
								controls-position="right"
								:value-on-clear="node ? node.attrs.parameter.StartY : 0"
								@change="handleMoveStartY()"></el-input-number>
						</div>
					</div>
					<div class="p-step-item">
						<div class="p-step-li">
							<div class="p-step-li-title">W：</div>
							<el-input-number
								v-model="attrObj.Width"
								:precision="2"
								:step="1"
								disabled
								size="large"
								:min="1"
								controls-position="right"
								:value-on-clear="node ? node.attrs.parameter.Width : 0"></el-input-number>
						</div>
						<div class="p-step-li">
							<div class="p-step-li-title">H：</div>
							<el-input-number
								v-model="attrObj.Height"
								:precision="2"
								:step="1"
								disabled
								size="large"
								:min="1"
								controls-position="right"
								:value-on-clear="node ? node.attrs.parameter.Height : 0"></el-input-number>
						</div>
					</div>
				</div>
			</div>
		</div>
		<ExcelLink ref="excelLinkRef" @link="handleLinkExcel" />
	</div>
</template>
<script>
	import { Link } from '@element-plus/icons-vue';
	import { message } from 'ant-design-vue';
	import ExcelLink from './ExcelLink.vue';
	import {
		getTimeFormatList,
		getQrCodeTypeList,
		getBarCodeTypeList,
		getEclevelList,
		getFontFamily,
		resetNodeAttr,
		mmToPx,
		pxToMm,
		getTextAlignValue,
		formatDate,
		combinationData,
		dataPrecision,
		localToBase64,
		dealTimeOffset,
		getNowDateOrTime,
		getGenerateCodeImg,
		dealSort,
		getCellRang,
		getAssociate,
		setNodeSize,
		drawVerticalText,
		drawHorizontalText,
		urlToBase64
	} from '../js/common.js';
	import { getNodeAttrs, recordHistory } from '../js/history.js';
	import { getEnabledAnchors, KonvaTransformer, updateTransformer, TransformerListening, changeOriginToCenter, changeOriginToTopleft } from '../js/transformerStyle';
	import XLSX from 'xlsx';
	import '../css/edit.css';
	import '../css/edit-small.css';
	import { context } from 'ant-design-vue/lib/vc-image/src/PreviewGroup';
	export default {
		components: {
			ExcelLink
		},
		props: {
			elementAttr: {
				type: [Object, String],
				default: () => {}
			},
			designArea: {
				type: Object,
				default: () => {}
			},
			designLayer: {
				type: Object,
				default: () => {}
			}
		},
		data() {
			return {
				Link,
				dataSource: [
					{
						value: 0,
						label: this.$t(`editAttr.attrText3`)
					},
					{
						value: 1,
						label: this.$t(`editAttr.attrText4`)
					},
					{
						value: 2,
						label: this.$t(`editAttr.attrText5`)
					},
					{
						value: 3,
						label: this.$t(`editAttr.attrText101`)
					}
				],
				sequenceTypeList: [
					{
						value: 0,
						label: this.$t(`editAttr.attrText93`) + ' (0-9)'
					},
					{
						value: 1,
						label: this.$t(`editAttr.attrText103`) + ' (0~9,A~F,a~f)'
					},
					{
						value: 2,
						label: this.$t(`editAttr.attrText104`) + ' (A~Z,a~z)'
					},
					{
						value: 3,
						label: this.$t(`editAttr.attrText105`) + ' (0~9,A~Z,a~z)'
					}
				],
				dateBaseType: [
					{
						value: 2,
						label: this.$t(`module.editText1`)
					},
					{
						value: 0,
						label: this.$t(`editAttr.attrText16`)
					}
				],
				dataTime: [
					{
						value: 0,
						label: this.$t(`editAttr.attrText19`)
					},
					{
						value: 1,
						label: this.$t(`editAttr.attrText20`)
					}
				],
				textAlignList: [
					{
						label: 'icon-left',
						value: 0,
						text: this.$t(`editAttr.attrText26`)
					},
					{
						label: 'icon-center',
						value: 1,
						text: this.$t(`editAttr.attrText27`)
					},
					{
						label: 'icon-right',
						value: 2,
						text: this.$t(`editAttr.attrText28`)
					},
					{
						label: 'icon-pingpu',
						value: 3,
						text: this.$t(`editAttr.attrText123`)
					},
					{
						label: 'icon-lashen',
						value: 4,
						text: this.$t(`editAttr.attrText124`)
					}
				],
				dataLocation: [
					{
						value: 0,
						label: this.$t(`editAttr.attrText23`) //不显示
					},
					{
						value: 2,
						label: this.$t(`editAttr.attrText116`) //上方
					},
					{
						value: 1,
						label: this.$t(`editAttr.attrText24`) //下方
					}
				],
				dateFormatList: [],
				timeFormatList: [],
				magnification: 3.78, //1mm 约等于3.78px（96dpi）
				mmToPoint: 3.96, // 1mm约等于3.96点 (31.7/8)  (203dpi(pc端采用203dpi进行计算)) 用于与pc端的数据转换
				node: '',
				attrObj: {},
				nodeType: '',
				CodeTypeList: [],
				dataSourceTypeList: [],
				elementList: [], //可绑定的元素值列表
				eclevelList: [],
				familyList: [],
				numberRegex: /^[0-9]+$/,
				database: [],
				connectionStr: '',
				cellMultiple: false,
				showTableSplit: false
			};
		},
		watch: {
			elementAttr() {
				if (JSON.stringify(this.elementAttr) === '{}') return;
				this.node = this.designLayer.find('#' + this.elementAttr.Id)[0];
				this.nodeType = this.node.attrs.type;
				this.attrObj = JSON.parse(JSON.stringify(this.elementAttr));
				this.dataSourceTypeList = JSON.parse(JSON.stringify(this.dataSource));
				if (this.nodeType == 'GeneralBarcode') {
					this.CodeTypeList = getBarCodeTypeList();
				} else if (this.nodeType == 'QRCode') {
					this.CodeTypeList = getQrCodeTypeList();
					this.eclevelList = getEclevelList(this.node.attrs.parameter.BarcodeType);
				} else if (this.nodeType == 'TimeText') {
					this.elementAttr.SelectTime = this.node.attrs.parameter.SelectTime;
					this.attrObj.SelectTime = this.node.attrs.parameter.SelectTime;
					let { dateFormatList, timeFormatList } = getTimeFormatList();
					this.dateFormatList = dateFormatList;
					this.timeFormatList = timeFormatList;
				} else if (this.nodeType == 'Table') {
					this.dataSourceTypeList = this.dataSource.slice(0, 3);
				}
				this.elementList = this.getElementList();
			}
		},
		mounted() {
			this.familyList = getFontFamily();
			if (JSON.stringify(this.elementAttr) === '{}') return;
			this.node = this.designLayer.find('#' + this.elementAttr.Id)[0];
			this.nodeType = this.node.attrs.type;
			this.attrObj = JSON.parse(JSON.stringify(this.elementAttr));
			this.dataSourceTypeList = JSON.parse(JSON.stringify(this.dataSource));
			if (this.nodeType == 'GeneralBarcode') {
				this.CodeTypeList = getBarCodeTypeList();
			} else if (this.nodeType == 'QRCode') {
				this.CodeTypeList = getQrCodeTypeList();
				this.eclevelList = getEclevelList(this.node.attrs.parameter.BarcodeType);
			} else if (this.nodeType == 'TimeText') {
				this.elementAttr.SelectTime = this.node.attrs.parameter.SelectTime;
				this.attrObj.SelectTime = this.node.attrs.parameter.SelectTime;
				let { dateFormatList, timeFormatList } = getTimeFormatList();
				this.dateFormatList = dateFormatList;
				this.timeFormatList = timeFormatList;
			} else if (this.nodeType == 'Table') {
				this.dataSourceTypeList = this.dataSource.slice(0, 3);
			}
			this.elementList = this.getElementList();
		},
		methods: {
			//数据源切换
			handleDataSourceType() {
				let oldAttrs = getNodeAttrs(this.node);
				let DataSourceType = this.attrObj.DataSourceList[this.attrObj.dataSourceIndex].DataSourceType;
				this.node.attrs.parameter.DataSourceList[this.attrObj.dataSourceIndex].DataSourceType = DataSourceType;

        if(this.nodeType == 'GeneralBarcode'){
          this.node.attrs.parameter.isEdit = true;
          this.attrObj.isEdit = true;
        }

				if (DataSourceType == 1) {
          this.node.attrs.parameter.DataSourceList[this.attrObj.dataSourceIndex].ReferenceId = '';
				  this.attrObj.DataSourceList[this.attrObj.dataSourceIndex].ReferenceId = '';

          let dataSourceIndex = this.attrObj.dataSourceIndex;
          let DataBase = this.node.attrs.parameter.DataSourceList[dataSourceIndex].DataBase
          //如果存在已连接的数据库，直接赋值
					if (DataBase.length == 0 && this.database.length > 0) {
						//连接数据库
						this.node.attrs.parameter.DataSourceList[dataSourceIndex].DataBase = this.database;
            this.node.attrs.parameter.DataSourceList[dataSourceIndex].ConnectionStr = this.connectionStr;
            this.node.attrs.parameter.DataSourceList[dataSourceIndex].SheetIndex = [0, 0];
            this.attrObj.DataSourceList[dataSourceIndex] = JSON.parse(JSON.stringify(this.node.attrs.parameter.DataSourceList[dataSourceIndex]));
					}
          if(this.node.attrs.parameter.DataSourceList[dataSourceIndex].DataBase.length > 0){
						//默认插入第一条数据
						this.handleInsertSheet(oldAttrs);
          }
				} else if (DataSourceType == 2) {
					//重置递变数据
          this.node.attrs.parameter.DataSourceList[this.attrObj.dataSourceIndex].ReferenceId = '';
				  this.attrObj.DataSourceList[this.attrObj.dataSourceIndex].ReferenceId = '';

					let AddedValue = this.attrObj.DataSourceList[this.attrObj.dataSourceIndex].AddedValue;
					this.attrObj.DataSourceList[this.attrObj.dataSourceIndex].AddedValue = AddedValue ? AddedValue : 1;
					this.node.attrs.parameter.DataSourceList[this.attrObj.dataSourceIndex].AddedValue = AddedValue ? AddedValue : 1;

					let Quantity = this.attrObj.DataSourceList[this.attrObj.dataSourceIndex].Quantity;
					this.attrObj.DataSourceList[this.attrObj.dataSourceIndex].Quantity = Quantity ? Quantity : 1;
					this.node.attrs.parameter.DataSourceList[this.attrObj.dataSourceIndex].Quantity = Quantity ? Quantity : 1;

					let data = this.attrObj.DataSourceList[this.attrObj.dataSourceIndex].InitialValue;
					data = this.dataFormatter(data);
					this.attrObj.DataSourceList[this.attrObj.dataSourceIndex].FirstValue = data ? data : '1';
					this.node.attrs.parameter.DataSourceList[this.attrObj.dataSourceIndex].FirstValue = data ? data : '1';
					this.handleSetTextContent(oldAttrs);
				} else if (DataSourceType == 3) {
					//元素值
          let Id = this.node.id();
          let isRefer = false;
          let length = this.$parent.elementGroup.children.length;
          for(let i = 0; i < length; i++){
            let node = this.$parent.elementGroup.children[i]
            let type = node.attrs.type
            if (type == "WinText" || type == "GeneralBarcode" || type == "QRCode") {
              isRefer = node.attrs.parameter.DataSourceList.some((val) => val.DataSourceType == 3 && val.ReferenceId == Id);
              if (isRefer) {
                break;
              }
            }
          }
          if (isRefer) {
            message.error(this.$t(`tips.tip72`));
				    this.node.attrs.parameter.DataSourceList[this.attrObj.dataSourceIndex].ReferenceId = '';
				    this.attrObj.DataSourceList[this.attrObj.dataSourceIndex].ReferenceId = '';

            this.attrObj.DataSourceList[this.attrObj.dataSourceIndex].DataSourceType = 0;
				    this.node.attrs.parameter.DataSourceList[this.attrObj.dataSourceIndex].DataSourceType = 0;

            let NormalText = this.attrObj.DataSourceList[this.attrObj.dataSourceIndex].NormalText;
            this.attrObj.DataSourceList[this.attrObj.dataSourceIndex].FirstValue = NormalText ? NormalText : 'Label designer';
            this.node.attrs.parameter.DataSourceList[this.attrObj.dataSourceIndex].FirstValue = NormalText ? NormalText : 'Label designer';
            this.handleSetTextContent(oldAttrs);
          } else {
            this.elementList = this.getElementList();
            let newAttrs = getNodeAttrs(this.node);
					  recordHistory(this.$parent, oldAttrs, newAttrs, 'edit');
          }
				} else {
          this.node.attrs.parameter.DataSourceList[this.attrObj.dataSourceIndex].ReferenceId = '';
				  this.attrObj.DataSourceList[this.attrObj.dataSourceIndex].ReferenceId = '';

					let NormalText = this.attrObj.DataSourceList[this.attrObj.dataSourceIndex].NormalText;
					this.attrObj.DataSourceList[this.attrObj.dataSourceIndex].FirstValue = NormalText ? NormalText : 'Label designer';
          this.node.attrs.parameter.DataSourceList[this.attrObj.dataSourceIndex].FirstValue = NormalText ? NormalText : 'Label designer';
					this.handleSetTextContent(oldAttrs);
				}
        this.$parent.isDraw = true
			},
			//获取可选元素值
			getElementList() {
				let list = [];
				this.$parent.elementGroup.children.forEach(node => {
					if (node.attrs.type == 'WinText' || node.attrs.type == 'GeneralBarcode' || node.attrs.type == 'QRCode') {
						//查询元素是否绑定其他元素
						let isRefer = node.attrs.parameter.DataSourceList.some(data => data.DataSourceType == 3 && data.ReferenceId != '');
						//过滤出非当前选中元素且未绑定的元素
						if (!isRefer && node.id() != this.node.id()) {
							list.push({
								Id: node.id(),
								Name: node.attrs.parameter.Name
							});
						}
					}
				});
				return list;
			},
			// 序列类型选择
			sequenceTypeChange() {
				let oldAttrs = getNodeAttrs(this.node);
				//处理默认值
				const getData = (data, SequenceType) => {
					data = String(data);
					if (SequenceType == 1) {
						data = data.replace(/[^0-9a-fA-F]/g, '');
						data = data ? data : '1';
					} else if (SequenceType == 2) {
						data = data.replace(/[^a-zA-Z]/g, '');
						data = data ? data : 'a';
					} else if (SequenceType == 3) {
						data = data.replace(/[^0-9a-zA-Z]/g, '');
						data = data ? data : '1';
					} else {
						data = data.replace(/[^0-9]/g, '');
						data = data ? data : '1';
					}
					return data;
				};

				let SequenceType = this.attrObj.DataSourceList[this.attrObj.dataSourceIndex].SequenceType;
				this.node.attrs.parameter.DataSourceList[this.attrObj.dataSourceIndex].SequenceType = SequenceType;
				let Data = this.attrObj.DataSourceList[this.attrObj.dataSourceIndex].FirstValue;
				this.attrObj.DataSourceList[this.attrObj.dataSourceIndex].FirstValue = getData(Data, SequenceType);
				this.node.attrs.parameter.DataSourceList[this.attrObj.dataSourceIndex].FirstValue = getData(Data, SequenceType);
				if (this.nodeType == 'Table') {
					let cell = this.node.find(`.cell_${this.attrObj.currentPos[0]}_${this.attrObj.currentPos[1]}`)[0];
					if (cell) {
						cell.attrs.parameter.SequenceType = SequenceType;
					}
				}
				this.handleSetTextContent(oldAttrs);
			},
			//文本内容格式化
			dataFormatter(value) {
				if (this.nodeType == 'WinText' || this.nodeType == 'GeneralBarcode' || this.nodeType == 'QRCode' || this.nodeType == 'Table') {
					let DataSourceType = this.attrObj.DataSourceList[this.attrObj.dataSourceIndex].DataSourceType;
					let SequenceType = this.attrObj.DataSourceList[this.attrObj.dataSourceIndex].SequenceType;
					if (DataSourceType == 2) {
						if (SequenceType == 1) {
							return value.replace(/[^0-9a-fA-F]/g, '');
						} else if (SequenceType == 2) {
							return value.replace(/[^a-zA-Z]/g, '');
						} else if (SequenceType == 3) {
							return value.replace(/[^0-9a-zA-Z]/g, '');
						} else {
							return value.replace(/[^0-9]/g, '');
						}
					}
				}
				return value;
			},
			//文本内容编辑
			handleTextData() {
				let oldAttrs = getNodeAttrs(this.node);
				let FirstValue = this.attrObj.DataSourceList[this.attrObj.dataSourceIndex].FirstValue;
				this.node.attrs.parameter.DataSourceList[this.attrObj.dataSourceIndex].FirstValue = FirstValue;
        let DataSourceType = this.attrObj.DataSourceList[this.attrObj.dataSourceIndex].DataSourceType
        if(DataSourceType == 0){
          this.attrObj.DataSourceList[this.attrObj.dataSourceIndex].NormalText = FirstValue;
          this.node.attrs.parameter.DataSourceList[this.attrObj.dataSourceIndex].NormalText = FirstValue;
        }else if(DataSourceType == 2){
          this.attrObj.DataSourceList[this.attrObj.dataSourceIndex].InitialValue = FirstValue;
          this.node.attrs.parameter.DataSourceList[this.attrObj.dataSourceIndex].InitialValue = FirstValue;
        }
				this.handleSetTextContent(oldAttrs);
			},
			//前后缀编辑
			handleTextFix() {
				let oldAttrs = getNodeAttrs(this.node);
				this.node.attrs.parameter.TextPrefix = this.attrObj.TextPrefix;
				this.node.attrs.parameter.TextSuffix = this.attrObj.TextSuffix;
				this.handleSetTextContent(oldAttrs);
			},
			//绘制文本内容
			async handleSetTextContent(prevOldAttrs) {
				let oldAttrs = prevOldAttrs ? prevOldAttrs : getNodeAttrs(this.node);
				let newAttrs = '';
				let data = '';

				switch (this.nodeType) {
					case 'WinText':
						//将组合数据的文本内容拼接起来
						data = combinationData(this.node);
						this.attrObj.Data = data;
						this.node.attrs.parameter.Data = data;
						if (this.node.attrs.parameter.TextArrange == 1) {
							drawVerticalText(this.node, this.designLayer);
						} else {
							data = this.attrObj.TextPrefix + data + this.attrObj.TextSuffix;
							this.node.getText().text(data);
							resetNodeAttr(this.node);
						}

						updateTransformer(this.designLayer);
						this.designLayer.draw();
						newAttrs = getNodeAttrs(this.node);
						recordHistory(this.$parent, oldAttrs, newAttrs, 'edit');
						getAssociate(this.designLayer, this.$parent.elementGroup, this.node);
						this.attrObj = JSON.parse(JSON.stringify(this.node.attrs.parameter));
						break;
					case 'GeneralBarcode':
					case 'QRCode':
						//将组合数据的文本内容拼接起来
						data = combinationData(this.node);
						this.attrObj.Data = data;
						this.node.attrs.parameter.Data = data;
						this.node.attrs.options.text = data;
            this.node.attrs.parameter.isEdit = true;
            this.attrObj.isEdit = true;
						try {
							let codeImg = await getGenerateCodeImg(this.node);
							this.node.setAttrs({
								image: codeImg,
								imageSrc: codeImg.src,
								width: codeImg.width,
								height: codeImg.height
							});
							let { width, height } = setNodeSize(this.node);
							this.attrObj.Width = width;
							this.attrObj.Height = height;
							updateTransformer(this.designLayer);
							this.designLayer.draw();
							newAttrs = getNodeAttrs(this.node);
							recordHistory(this.$parent, oldAttrs, newAttrs, 'edit');
							getAssociate(this.designLayer, this.$parent.elementGroup, this.node);
						} catch (err) {
							if (!this.attrObj.Data) {
								message.error(this.$t(`tips.tip61`));
							} else {
								let errData = err.split(':');
								message.error(this.$t(`tips.tip111`) + '，' + errData[1]);
							}
						}
						break;
					case 'Table':
						let cell = this.node.find(`.cell_${this.attrObj.currentPos[0]}_${this.attrObj.currentPos[1]}`)[0];

						if (cell) {
							let cellText = cell.find('.cellText')[0];
							let FirstValue = this.attrObj.DataSourceList[this.attrObj.dataSourceIndex].FirstValue;
							cellText.text(FirstValue);
							this.node.attrs.parameter.DataSourceList[this.attrObj.dataSourceIndex].FirstValue = FirstValue;
							cell.attrs.parameter.FirstValue = FirstValue;
						}
						this.designLayer.draw();
						newAttrs = getNodeAttrs(this.node);
						recordHistory(this.$parent, oldAttrs, newAttrs, 'edit');
						break;
				}
        this.$parent.isDraw = true
			},
			//选择本地数据库
			getFileUpload() {
				$('#selectBtn').trigger('click');
			},
			//连接本地数据库
			getLocalData(e) {
				var files = e.target.files;
				if (files.length == 0) {
					message.error(this.$t(`tips.tip65`));
					return;
				} else {
					let reader = new FileReader();
					reader.readAsBinaryString(files[0]);
					reader.onload = e => {
						let workFile = '';
						try {
							workFile = XLSX.read(e.target.result, {
								type: 'binary',
								cellNF: true,
								dateNF: true,
								cellDates: true,
								bookDeps: true,
								raw: true
							});
						} catch (err) {
							message.error(this.$t(`tips.tip65`));
							return;
						}
						let sheetList = [];
						workFile.SheetNames.forEach(sheetName => {
							sheetList.push({
								sheetName: sheetName,
								sheet: XLSX.utils.sheet_to_json(workFile.Sheets[sheetName], {
									raw: true,
									header: 1,
									defval: ''
								})
							});
						});
						// console.log(workFile);
						// console.log(sheetList);
						if (sheetList.length == 0) {
							message.error(this.$t(`tips.tip65`));
							return;
						} else {
							message.success(this.$t(`tips.tip64`));
						}

						let database = [];
						sheetList.forEach((item, index) => {
							let obj = {
								text: item.sheetName,
								index,
								children: []
							};
							if (item.sheet.length > 0) {
								let firstRowLength = item.sheet[0].length;
								for (let i = 0; i < firstRowLength; i++) {
									obj.children.push({
										text: item.sheet[0][i],
										index: i,
										column: []
									}); // 为每一列初始化一个数组
								}

								for (let i = 1; i < item.sheet.length; i++) {
									for (let j = 0; j < firstRowLength; j++) {
										// 将原始数组中的元素按列放入结果数组
										obj.children[j].column.push(item.sheet[i][j]);
									}
								}
							} else {
								obj.children.push({
									text: this.$t(`tips.tip107`),
									index: 0,
									column: []
								});
							}
							database.push(obj);
						});

						this.database = database;
						this.ConnectionStr = files[0].name;
						// console.log('本地数据库', this.database);

						let oldAttrs = getNodeAttrs(this.node);
						//连接数据库
						let dataSourceIndex = this.attrObj.dataSourceIndex;
						this.node.attrs.parameter.DataSourceList[dataSourceIndex].DataBase = this.database;
						this.node.attrs.parameter.DataSourceList[dataSourceIndex].ConnectionStr = this.ConnectionStr;
						this.node.attrs.parameter.DataSourceList[dataSourceIndex].SheetIndex = [0, 0];

						this.attrObj.DataSourceList[dataSourceIndex] = JSON.parse(JSON.stringify(this.node.attrs.parameter.DataSourceList[dataSourceIndex]));
						//默认插入第一条数据
						this.handleInsertSheet(oldAttrs);
					};
				}
			},
			//连接云端数据库
			async handleLinkExcel(file) {
				let sheetList = [];
				await this.getSheet(file.url).then(res => {
					res.forEach((item, index) => {
						let obj = {
							text: item.sheetName,
							index,
							children: []
						};
						if (item.data.length > 0) {
							item.data.forEach((column, c_index) => {
								obj.children.push({
									text: column[0],
									index: c_index,
									column: column.slice(1)
								});
							});
						} else {
							obj.children.push({
								text: this.$t(`tips.tip107`),
								index: 0,
								column: []
							});
						}
						sheetList.push(obj);
					});

					this.database = sheetList;
					this.connectionStr = file.url;
					// console.log('云端数据库', this.database);

					let oldAttrs = getNodeAttrs(this.node);
					//连接数据库
					let dataSourceIndex = this.attrObj.dataSourceIndex;
					this.node.attrs.parameter.DataSourceList[dataSourceIndex].DataBase = this.database;
					this.node.attrs.parameter.DataSourceList[dataSourceIndex].ConnectionStr = this.connectionStr;
					this.node.attrs.parameter.DataSourceList[dataSourceIndex].SheetIndex = [0, 0];

					this.attrObj.DataSourceList[dataSourceIndex] = JSON.parse(JSON.stringify(this.node.attrs.parameter.DataSourceList[dataSourceIndex]));
					//默认插入第一条数据
					this.handleInsertSheet(oldAttrs);
				});
			},
			//获取云端excel
			getSheet(url) {
				return new Promise((resolve, reject) => {
					this.$axioshanma('/api/tem.new_class/import', {
						FileUrl: url,
						RowIndex: 0,
						TableIndex: -1 //传-1可拿到所有的表数据
					})
						.then(res => {
							resolve(res);
						})
						.catch(err => {
							reject();
						});
				});
			},
			//插入excel数据
			handleInsertSheet(prevOldAttrs) {
				let oldAttrs = prevOldAttrs ? prevOldAttrs : getNodeAttrs(this.node);
				let sheetIndex = this.attrObj.DataSourceList[this.attrObj.dataSourceIndex].SheetIndex;
				let columnData = this.attrObj.DataSourceList[this.attrObj.dataSourceIndex].DataBase[sheetIndex[0]].children[sheetIndex[1]].column;

				this.attrObj.DataSourceList[this.attrObj.dataSourceIndex].FirstValue = columnData[0];

				//列名
				let ColumnName = this.attrObj.DataSourceList[this.attrObj.dataSourceIndex].DataBase[sheetIndex[0]].children[sheetIndex[1]].text;
				this.attrObj.DataSourceList[this.attrObj.dataSourceIndex].ColumnName = ColumnName;
				//表名
				this.attrObj.DataSourceList[this.attrObj.dataSourceIndex].TableName = this.attrObj.DataSourceList[this.attrObj.dataSourceIndex].DataBase[sheetIndex[0]].text;

				this.attrObj.DataSourceList[this.attrObj.dataSourceIndex].QuerySql =
					this.attrObj.DataSourceList[this.attrObj.dataSourceIndex].ConnectionStr + ',' + this.attrObj.DataSourceList[this.attrObj.dataSourceIndex].ColumnName;
				//记录列数据
				this.attrObj.DataSourceList[this.attrObj.dataSourceIndex].ConnectionData = columnData;

				if (this.nodeType == 'Table') {
					let cell = this.node.find(`.cell_${this.attrObj.currentPos[0]}_${this.attrObj.currentPos[1]}`)[0];
					if (cell) {
						cell.attrs.parameter = JSON.parse(JSON.stringify(this.attrObj.DataSourceList[this.attrObj.dataSourceIndex]));
					}
				}
				this.node.attrs.parameter = JSON.parse(JSON.stringify(this.attrObj));
				this.handleSetTextContent(oldAttrs);
			},
			//增变量
			handleAddedValue() {
				let oldAttrs = getNodeAttrs(this.node);
				let oldVal = '';
				let newVal = '';
				newVal = this.attrObj.DataSourceList[this.attrObj.dataSourceIndex].AddedValue;
				oldVal = this.node.attrs.parameter.DataSourceList[this.attrObj.dataSourceIndex].AddedValue;
				this.node.attrs.parameter.DataSourceList[this.attrObj.dataSourceIndex].AddedValue = newVal;
				if (newVal != oldVal) {
					//防止选中元素时触发
					let newAttrs = getNodeAttrs(this.node);
					recordHistory(this.$parent, oldAttrs, newAttrs, 'edit');
          this.$parent.isDraw = true
				}
			},
			//递变份数
			handleQuantity() {
				let oldAttrs = getNodeAttrs(this.node);
				let oldVal = '';
				let newVal = '';
				newVal = this.attrObj.DataSourceList[this.attrObj.dataSourceIndex].Quantity;
				oldVal = this.node.attrs.parameter.DataSourceList[this.attrObj.dataSourceIndex].Quantity;
				this.node.attrs.parameter.DataSourceList[this.attrObj.dataSourceIndex].Quantity = newVal;
				if (newVal != oldVal) {
					//防止选中元素时触发
					let newAttrs = getNodeAttrs(this.node);
					recordHistory(this.$parent, oldAttrs, newAttrs, 'edit');
          this.$parent.isDraw = true
				}
			},
			//元素值绑定
			handleBindElement() {
				let oldAttrs = getNodeAttrs(this.node);

				let ReferenceId = this.attrObj.DataSourceList[this.attrObj.dataSourceIndex].ReferenceId;
				// 查找被绑定的元素
				let isBoundNode = this.designArea.find(`#${ReferenceId}`)[0];

				if (isBoundNode) {
					this.node.attrs.parameter.DataSourceList[this.attrObj.dataSourceIndex].ReferenceId = ReferenceId;
					// 获取被绑定元素的值
					let isBoundNode_data = isBoundNode.attrs.parameter.Data;
					//赋值给当前元素
					this.attrObj.DataSourceList[this.attrObj.dataSourceIndex].FirstValue = isBoundNode_data;
					this.node.attrs.parameter.DataSourceList[this.attrObj.dataSourceIndex].FirstValue = isBoundNode_data;
					this.handleSetTextContent(oldAttrs);
				}
			},
			// 组合数据操作
			dealSourceData(val) {
				let oldAttrs = getNodeAttrs(this.node);
				let DataSourceList = this.node.attrs.parameter.DataSourceList;
				let firstValue = '';
				if (this.nodeType == 'WinText') {
					firstValue = 'Label Designer';
				} else if (this.nodeType == 'GeneralBarcode' || this.nodeType == 'QRCode') {
					firstValue = '0123456789';
				}

				switch (val) {
					// 新增数据
					case 0:
						let obj = {
							DataSourceType: 0, //数据源 0普通文本 1数据库文本 2 序列化文本 3元素值
							FirstValue: firstValue, //显示文本值，连接数据库的时候，会获取绑定列的第一个值，用来显示
							ColumnName: '', //数据库列名
							QuerySql: '', //选择的数据库
							ConnectionStr: '', //插入字段名
							DataType: 2, //数据类型 0为日期 1为数字 2文本
							DataFormat: 'yyyy-MM-dd HH:mm:ss', //日期格式
							SequenceType: 0, //序列类型
							InitialValue: '1', //初始递变数值
							AddedValue: 1, //递变量
							Quantity: 1, //递变数量
							ReferenceId: '', //上一级引用元素值的Id
							SheetIndex: [-1, -1],
							DataBase: []
						};
						DataSourceList.push(obj);
						this.node.attrs.parameter.DataSourceList = DataSourceList;
						this.node.attrs.parameter.dataSourceIndex = DataSourceList.length - 1;

						break;
					// 删除数据
					case 1:
						DataSourceList.splice(this.attrObj.dataSourceIndex, 1);
						this.node.attrs.parameter.DataSourceList = DataSourceList;
						if (this.attrObj.dataSourceIndex == DataSourceList.length) {
							this.node.attrs.parameter.dataSourceIndex = this.attrObj.dataSourceIndex - 1;
						}
						break;
					// 上移
					case 2:
						if (this.attrObj.dataSourceIndex > 0) {
							let data = DataSourceList[this.attrObj.dataSourceIndex];
							DataSourceList.splice(this.attrObj.dataSourceIndex, 1);
							DataSourceList.splice(this.attrObj.dataSourceIndex - 1, 0, data);
							this.node.attrs.parameter.DataSourceList = DataSourceList;
							this.node.attrs.parameter.dataSourceIndex = this.attrObj.dataSourceIndex - 1;
						}
						break;
					// 下移
					case 3:
						if (this.attrObj.dataSourceIndex < DataSourceList.length - 1) {
							let data = DataSourceList[this.attrObj.dataSourceIndex];
							DataSourceList.splice(this.attrObj.dataSourceIndex, 1);
							DataSourceList.splice(this.attrObj.dataSourceIndex + 1, 0, data);
							this.node.attrs.parameter.DataSourceList = DataSourceList;
							this.node.attrs.parameter.dataSourceIndex = this.attrObj.dataSourceIndex + 1;
						}
						break;
				}

				this.handleSetTextContent(oldAttrs);

				this.attrObj = JSON.parse(JSON.stringify(this.node.attrs.parameter));

				this.$nextTick(() => {
					let combinationBox = this.$refs.combinationBox;
					if (combinationBox) {
						combinationBox.scrollTop = combinationBox.scrollHeight;
					}
				});
			},
			//点击组合数据
			handleDataSourceList(index) {
				this.attrObj.dataSourceIndex = index;
				this.node.attrs.parameter.dataSourceIndex = index;
			},
			//字体设置
			handleFontFamily() {
				let oldAttrs = getNodeAttrs(this.node);
				switch (this.nodeType) {
					case 'WinText':
					case 'TimeText':
						this.node.attrs.parameter.FontFamily = this.attrObj.FontFamily;
						if (this.node.attrs.parameter.TextArrange == 1) {
							let textGroup = this.node.find('.textGroup')[0];
							if (textGroup) {
								textGroup.children.forEach(text => {
									text.fontFamily(this.attrObj.FontFamily);
								});
							}
						} else {
							let text = this.node.getText();
							text.fontFamily(this.attrObj.FontFamily);
							resetNodeAttr(this.node);
						}
						updateTransformer(this.designLayer);
						break;
					case 'Table':
						let FontFamily = this.attrObj.DataSourceList[this.attrObj.dataSourceIndex].FontFamily;
						let cell = this.node.find(`.cell_${this.attrObj.currentPos[0]}_${this.attrObj.currentPos[1]}`)[0];
						if (cell) {
							let cellText = cell.find('.cellText')[0];
							cellText.fontFamily(FontFamily);
							cell.attrs.parameter.FontFamily = FontFamily;
							this.node.attrs.parameter.DataSourceList[this.attrObj.dataSourceIndex].FontFamily = FontFamily;
						}
						break;
				}
				this.designLayer.draw();
				let newAttrs = getNodeAttrs(this.node);
				recordHistory(this.$parent, oldAttrs, newAttrs, 'edit');
				this.attrObj = JSON.parse(JSON.stringify(this.node.attrs.parameter));
        this.$parent.isDraw = true
			},
			// 字号大小设置 保留两位小数
			async handleFontSize() {
				let oldAttrs = getNodeAttrs(this.node);
				let newAttrs = '';
				//pt转px
				let fontSize = 0;

				switch (this.nodeType) {
					case 'WinText':
					case 'TimeText':
						fontSize = Math.round((this.attrObj.FontSize / this.mmToPoint) * this.magnification * 100) / 100;
						//重置元素的宽度，防止字体增大后超出元素宽度
						this.node.attrs.parameter.FontSize = this.attrObj.FontSize;
						if (this.node.attrs.parameter.TextArrange == 1) {
							drawVerticalText(this.node, this.designLayer);
							this.attrObj = JSON.parse(JSON.stringify(this.node.attrs.parameter));
						} else {
							this.node.getText().fontSize(fontSize);
							if (!this.attrObj.AutoWrap) {
								this.node.getText().width(null);
								let width = this.node.getText().width();
								this.node.getText().width(width);
								this.node.width(width);
							}

							let MIN_TEXT_WIDTH = 2 * this.node.getText().padding() + this.node.getText().fontSize() * 1.1;
							this.node.width(Math.max(this.node.width(), MIN_TEXT_WIDTH));
							this.node.getText().width(Math.max(this.node.width(), MIN_TEXT_WIDTH));
							resetNodeAttr(this.node);
						}

						updateTransformer(this.designLayer);
						this.designLayer.draw();
						newAttrs = getNodeAttrs(this.node);
						recordHistory(this.$parent, oldAttrs, newAttrs, 'edit');
						break;
					case 'Table':
						fontSize = Math.round((this.attrObj.DataSourceList[this.attrObj.dataSourceIndex].FontSize / this.mmToPoint) * this.magnification * 100) / 100;
						let cell = this.node.find(`.cell_${this.attrObj.currentPos[0]}_${this.attrObj.currentPos[1]}`)[0];
						if (cell) {
							let cellText = cell.find('.cellText')[0];
							cellText.fontSize(fontSize);
							cell.attrs.parameter.FontSize = fontSize;
							this.node.attrs.parameter.DataSourceList[this.attrObj.dataSourceIndex].FontSize = this.attrObj.DataSourceList[this.attrObj.dataSourceIndex].FontSize;
						}
						this.designLayer.draw();
						newAttrs = getNodeAttrs(this.node);
						recordHistory(this.$parent, oldAttrs, newAttrs, 'edit');
						break;
					case 'GeneralBarcode':
						if (this.attrObj.BarcodeType == 'ean8' || this.attrObj.BarcodeType == 'ean13' || this.attrObj.BarcodeType == 'UPCA') {
							if (this.attrObj.FontSize > 13.5) {
								this.attrObj.FontSize = 13.5;
							}
						}
						fontSize = Math.round((this.attrObj.FontSize / this.mmToPoint) * this.magnification * 100) / 100;
						this.node.attrs.options.textsize = fontSize;
						this.node.attrs.parameter.FontSize = this.attrObj.FontSize;

						try {
							let codeImg = await getGenerateCodeImg(this.node);
							this.node.setAttrs({
								image: codeImg,
								imageSrc: codeImg.src
							});
							updateTransformer(this.designLayer);
							this.designLayer.draw();
							newAttrs = getNodeAttrs(this.node);
							recordHistory(this.$parent, oldAttrs, newAttrs, 'edit');
						} catch (err) {
							message.error(this.$t(`tips.tip111`));
						}
						break;
				}
				this.attrObj = JSON.parse(JSON.stringify(this.node.attrs.parameter));
        this.$parent.isDraw = true
			},
			//设置文字对齐方式
			async handleSetTextAlign(value) {
				if (this.attrObj.TextAlign == value) return;
				let oldAttrs = '';
				let newAttrs = '';
				switch (this.nodeType) {
					case 'WinText':
					case 'TimeText':
						oldAttrs = getNodeAttrs(this.node);
						this.attrObj.TextAlign = value;
						this.node.attrs.parameter.TextAlign = value;
						if (this.node.attrs.parameter.TextArrange == 1) {
							let tempText = this.node.find('.tempText')[0];
							if (value == 4) {
								let text = this.node.attrs.parameter.Data;
								if (this.node.attrs.type == 'WinText') {
									text = this.node.attrs.parameter.TextPrefix + this.node.attrs.parameter.Data + this.node.attrs.parameter.TextSuffix;
								}
								let textArr = text.split(/\r?\n/);
								let maxWordNum = 0;
								textArr.forEach(item => {
									maxWordNum = Math.max(maxWordNum, item.length);
								});
								let fontSize = Math.round((this.node.attrs.parameter.FontSize / this.mmToPoint) * this.magnification * 100) / 100;
								let letterSpacing = mmToPx(this.node.attrs.parameter.LetterSpacing, 2);
								let actualHeight = maxWordNum * fontSize + letterSpacing * maxWordNum + 1;
								let scale = this.node.height() / actualHeight;
								this.node.height(actualHeight);
								this.node.scaleY(scale);
								tempText.height(actualHeight);
								this.node.getTag().height(actualHeight);
								this.node.attrs.parameter.BoxWidth = pxToMm(actualHeight, 2);
								this.designLayer.draw();
							} else {
								let height = this.node.height() * this.node.scaleY();
								let fontSize = Math.round((this.node.attrs.parameter.FontSize / this.mmToPoint) * this.magnification * 100) / 100;
								let h = Math.max(height, fontSize);
								this.node.height(h);
								tempText.height(h);
								this.node.getTag().height(h);
								this.node.attrs.parameter.BoxWidth = pxToMm(h, 2);
								this.node.scaleX(1);
								this.node.scaleY(1);
								this.designLayer.batchDraw();
							}
							drawVerticalText(this.node, this.designLayer);
							this.attrObj = JSON.parse(JSON.stringify(this.node.attrs.parameter));
						} else {
							this.node.getText().align(getTextAlignValue(value));
							if (value == 4) {
								let width = this.node.width();
								this.node.getText().width(null);
								let textWidth = this.node.getText().width();
								let scale = width / textWidth;
								this.node.scaleX(scale);
								this.node.width(textWidth);
								this.node.getText().width(textWidth);
								this.node.getTag().width(textWidth);
							} else {
								let scale = this.node.scaleX();
								let width = this.node.width();
								this.node.scaleX(1);
								this.node.width(width * scale);
								this.node.getText().width(width * scale);
								this.node.getTag().width(width * scale);
							}
							resetNodeAttr(this.node);
						}
						updateTransformer(this.designLayer);
						this.designLayer.draw();
						newAttrs = getNodeAttrs(this.node);
						recordHistory(this.$parent, oldAttrs, newAttrs, 'edit');
						break;
					case 'GeneralBarcode':
						oldAttrs = getNodeAttrs(this.node);
						this.attrObj.TextAlign = value;
						this.node.attrs.options.textxalign = getTextAlignValue(value);
						this.node.attrs.parameter.TextAlign = value;
						try {
							let codeImg = await getGenerateCodeImg(this.node);
							this.node.setAttrs({
								image: codeImg,
								imageSrc: codeImg.src
							});
							updateTransformer(this.designLayer);
							this.designLayer.draw();
							newAttrs = getNodeAttrs(this.node);
							recordHistory(this.$parent, oldAttrs, newAttrs, 'edit');
						} catch (err) {
							message.error(this.$t(`tips.tip111`));
						}
						break;
					case 'Table':
						oldAttrs = getNodeAttrs(this.node);
						let cell = this.node.find(`.cell_${this.attrObj.currentPos[0]}_${this.attrObj.currentPos[1]}`)[0];
						if (cell) {
							let cellText = cell.find('.cellText')[0];
							cellText.align(getTextAlignValue(value));
							cell.attrs.parameter.TextAlign = value;
							this.attrObj.DataSourceList[this.attrObj.dataSourceIndex].TextAlign = value;
							this.node.attrs.parameter.DataSourceList[this.attrObj.dataSourceIndex].TextAlign = value;
						}
						this.designLayer.draw();
						newAttrs = getNodeAttrs(this.node);
						recordHistory(this.$parent, oldAttrs, newAttrs, 'edit');
						break;
				}
        this.$parent.isDraw = true
			},
			//文本方向
			handleTextDirection(value) {
				if (value == 'horizontal' && this.node.attrs.parameter.TextArrange == 0) return;
				if (value == 'vertical' && this.node.attrs.parameter.TextArrange == 1) return;
				let oldAttrs = getNodeAttrs(this.node);
				switch (value) {
					case 'horizontal':
						this.attrObj.TextArrange = 0;
						this.node.attrs.parameter.TextArrange = 0;
						drawHorizontalText(this.node, this.designLayer);
						this.attrObj = JSON.parse(JSON.stringify(this.node.attrs.parameter));
						break;
					case 'vertical':
						this.attrObj.TextArrange = 1;
						this.node.attrs.parameter.TextArrange = 1;
						drawVerticalText(this.node, this.designLayer, 'create');
						this.attrObj = JSON.parse(JSON.stringify(this.node.attrs.parameter));
						break;
					case 'arc':
						break;
				}
				let transformer = this.designLayer.find('.' + this.node.id())[0];
				transformer.enabledAnchors(getEnabledAnchors(this.node));
				updateTransformer(this.designLayer);
				let newAttrs = getNodeAttrs(this.node);
				recordHistory(this.$parent, oldAttrs, newAttrs, 'edit');
				this.designLayer.draw();
        this.$parent.isDraw = true
			},
			//字体风格
			handleFontStyle(value) {
				let oldAttrs = getNodeAttrs(this.node);

				const getFontStyle = node => {
					let parameter = node.attrs.parameter;
					let fontStyle = [];
					parameter[value] = !parameter[value];
					if (parameter.Bold) {
						fontStyle.push('bold');
					}
					if (parameter.Italic) {
						fontStyle.push('italic');
					}
					fontStyle = fontStyle.join(' ');
					let valueBoolean = parameter[value];
					return { fontStyle, valueBoolean };
				};

				switch (this.nodeType) {
					case 'WinText':
					case 'TimeText':
						const { fontStyle, valueBoolean } = getFontStyle(this.node);
						this.node.attrs.parameter[value] = valueBoolean;
						this.attrObj[value] = valueBoolean;
						if (this.node.attrs.parameter.TextArrange == 1) {
							let textGroup = this.node.find('.textGroup')[0];
							if (textGroup) {
								textGroup.children.forEach(text => {
									text.fontStyle(fontStyle);
								});
							}
						} else {
							this.node.getText().fontStyle(fontStyle);
							resetNodeAttr(this.node);
						}
						break;
					case 'Table':
						const cell = this.node.find(`.cell_${this.attrObj.currentPos[0]}_${this.attrObj.currentPos[1]}`)[0];
						if (cell) {
							let cellText = cell.find('.cellText')[0];
							let { fontStyle, valueBoolean } = getFontStyle(cell);
							cellText.fontStyle(fontStyle);
							cell.attrs.parameter[value] = valueBoolean;
							this.node.attrs.parameter.DataSourceList[this.attrObj.dataSourceIndex][value] = valueBoolean;
							this.attrObj.DataSourceList[this.attrObj.dataSourceIndex][value] = valueBoolean;
						}
						break;
				}

				this.designLayer.draw();
				let newAttrs = getNodeAttrs(this.node);
				recordHistory(this.$parent, oldAttrs, newAttrs, 'edit');
				this.attrObj = JSON.parse(JSON.stringify(this.node.attrs.parameter));
        this.$parent.isDraw = true
			},
			//字体风格  下划线删除线
			handleTextDecoration(value) {
				let oldAttrs = getNodeAttrs(this.node);

				const getDecoration = node => {
					let parameter = node.attrs.parameter;
					let textDecoration = [];
					parameter[value] = !parameter[value];
					if (parameter.UnderLine) {
						textDecoration.push('underline');
					}
					if (parameter.DeleteLine) {
						textDecoration.push('line-through');
					}
					textDecoration = textDecoration.join(' ');
					let valueBoolean = parameter[value];
					return { textDecoration, valueBoolean };
				};

				switch (this.nodeType) {
					case 'WinText':
					case 'TimeText':
						const { textDecoration, valueBoolean } = getDecoration(this.node);
						this.node.attrs.parameter[value] = valueBoolean;
						this.attrObj[value] = valueBoolean;
						if (this.node.attrs.parameter.TextArrange == 1) {
							let textGroup = this.node.find('.textGroup')[0];
							if (textGroup) {
								textGroup.children.forEach(text => {
									text.textDecoration(textDecoration);
								});
							}
						} else {
							this.node.getText().textDecoration(textDecoration);
							resetNodeAttr(this.node);
						}
						break;
					case 'Table':
						const cell = this.node.find(`.cell_${this.attrObj.currentPos[0]}_${this.attrObj.currentPos[1]}`)[0];
						if (cell) {
							let cellText = cell.find('.cellText')[0];
							let { textDecoration, valueBoolean } = getDecoration(cell);
							cellText.textDecoration(textDecoration);
							cell.attrs.parameter[value] = valueBoolean;
							this.node.attrs.parameter.DataSourceList[this.attrObj.dataSourceIndex][value] = valueBoolean;
							this.attrObj.DataSourceList[this.attrObj.dataSourceIndex][value] = valueBoolean;
						}
						break;
				}

				this.designLayer.draw();
				let newAttrs = getNodeAttrs(this.node);
				recordHistory(this.$parent, oldAttrs, newAttrs, 'edit');
				this.attrObj = JSON.parse(JSON.stringify(this.node.attrs.parameter));
        this.$parent.isDraw = true
			},
			//旋转
			handleRotate(rotate) {
				let oldAttrs = getNodeAttrs(this.node);
				changeOriginToCenter(this.node);
				this.node.rotation(rotate);
				this.attrObj.Rotate = rotate;
				this.node.attrs.parameter.Rotate = rotate;
				changeOriginToTopleft(this.node);
				//计算左上角坐标
				let x = this.node.x();
				let y = this.node.y();
				let width = this.node.width() * this.node.scaleX();
				let height = this.node.height() * this.node.scaleY();
				let StartX = rotate == 90 ? x - height : rotate == 180 ? x - width : x;
				let StartY = rotate == 180 ? y - height : rotate == -90 ? y - width : y;
				this.node.attrs.parameter.StartX = pxToMm(StartX, 2);
				this.node.attrs.parameter.StartY = pxToMm(StartY, 2);
				this.designLayer.draw();
				let newAttrs = getNodeAttrs(this.node);
				recordHistory(this.$parent, oldAttrs, newAttrs, 'edit');
				this.attrObj = JSON.parse(JSON.stringify(this.node.attrs.parameter));
        this.$parent.isDraw = true
			},
			//字间距设置
			handleLetterSpacing() {
				let oldAttrs = getNodeAttrs(this.node);
				let letterSpacing = 0;
				//mm转px
				switch (this.nodeType) {
					case 'WinText':
					case 'TimeText':
						this.node.attrs.parameter.LetterSpacing = this.attrObj.LetterSpacing;
						if (this.node.attrs.parameter.TextArrange == 1) {
							drawVerticalText(this.node, this.designLayer);
						} else {
							letterSpacing = mmToPx(this.attrObj.LetterSpacing, 2);
							this.node.getText().letterSpacing(letterSpacing);
							resetNodeAttr(this.node);
						}
						updateTransformer(this.designLayer);
						break;
					case 'Table':
						letterSpacing = mmToPx(this.attrObj.DataSourceList[this.attrObj.dataSourceIndex].LetterSpacing);
						let cell = this.node.find(`.cell_${this.attrObj.currentPos[0]}_${this.attrObj.currentPos[1]}`)[0];
						if (cell) {
							const cellText = cell.find('.cellText')[0];
							cellText.letterSpacing(letterSpacing);
							cell.attrs.parameter.letterSpacing = letterSpacing;
							this.node.attrs.parameter.DataSourceList[this.attrObj.dataSourceIndex].LetterSpacing = this.attrObj.DataSourceList[this.attrObj.dataSourceIndex].LetterSpacing;
						}

						break;
				}
				this.designLayer.draw();
				let newAttrs = getNodeAttrs(this.node);
				recordHistory(this.$parent, oldAttrs, newAttrs, 'edit');
				this.attrObj = JSON.parse(JSON.stringify(this.node.attrs.parameter));
        this.$parent.isDraw = true
			},
			//行间距设置
			handleLineHeight() {
				let oldAttrs = getNodeAttrs(this.node);
				let lineHeight = 0;
				let value = 0;
				//mm转px
				switch (this.nodeType) {
					case 'WinText':
					case 'TimeText':
						this.node.attrs.parameter.LineHeight = this.attrObj.LineHeight;
						if (this.node.attrs.parameter.TextArrange == 1) {
							drawVerticalText(this.node, this.designLayer);
						} else {
							lineHeight = mmToPx(this.attrObj.LineHeight);
							value = lineHeight / this.node.getText().fontSize() + 1;
							this.node.getText().lineHeight(value);
							resetNodeAttr(this.node);
						}
						updateTransformer(this.designLayer);
						break;
					case 'Table':
						lineHeight = mmToPx(this.attrObj.DataSourceList[this.attrObj.dataSourceIndex].LineHeight);
						const cell = this.node.find(`.cell_${this.attrObj.currentPos[0]}_${this.attrObj.currentPos[1]}`)[0];
						if (cell) {
							let cellText = cell.find('.cellText')[0];
							value = lineHeight / cellText.fontSize() + 1;
							cellText.lineHeight(value);
							cell.attrs.parameter.LineHeight = this.attrObj.DataSourceList[this.attrObj.dataSourceIndex].LineHeight;
							this.node.attrs.parameter.DataSourceList[this.attrObj.dataSourceIndex].LineHeight = this.attrObj.DataSourceList[this.attrObj.dataSourceIndex].LineHeight;
						}
						break;
				}
				this.designLayer.draw();
				let newAttrs = getNodeAttrs(this.node);
				recordHistory(this.$parent, oldAttrs, newAttrs, 'edit');
				this.attrObj = JSON.parse(JSON.stringify(this.node.attrs.parameter));
        this.$parent.isDraw = true
			},
			//镜像
			handleMirrorImage() {
				let oldAttrs = getNodeAttrs(this.node);
				switch (this.nodeType) {
					case 'WinText':
					case 'TimeText':
						this.attrObj.MirrorImage = !this.attrObj.MirrorImage;
						this.node.attrs.parameter.MirrorImage = this.attrObj.MirrorImage;
						if (this.node.attrs.parameter.TextArrange == 1) {
							let textGroup = this.node.find('.textGroup')[0];
							if (textGroup) {
								let scaleX = textGroup.scaleX();
								textGroup.scaleX(-scaleX);
								let width = textGroup.width();
								if (this.node.attrs.parameter.MirrorImage) {
									textGroup.x(width);
								} else {
									textGroup.x(0);
								}
							}
						} else {
							let scaleX = this.node.getText().scaleX();
							this.node.getText().scaleX(-scaleX);
							if (this.node.attrs.parameter.MirrorImage) {
								let width = this.node.getText().width();
								this.node.getText().x(width);
							} else {
								this.node.getText().x(0);
							}
						}
						break;
					case 'Table':
						let cell = this.node.find(`.cell_${this.attrObj.currentPos[0]}_${this.attrObj.currentPos[1]}`)[0];
						if (cell) {
							let cellText = cell.find('.cellText')[0];
							let mirror = !this.attrObj.DataSourceList[this.attrObj.dataSourceIndex].MirrorImage;
							this.attrObj.DataSourceList[this.attrObj.dataSourceIndex].MirrorImage = mirror;
							cell.attrs.parameter.MirrorImage = mirror;
							this.node.attrs.parameter.DataSourceList[this.attrObj.dataSourceIndex].MirrorImage = mirror;
							let scaleX = cellText.scaleX();
							cellText.scaleX(-scaleX);
							if (mirror) {
								let width = cellText.width();
								cellText.x(width);
							} else {
								cellText.x(0);
							}
						}
						break;
				}

				this.designLayer.draw();
				let newAttrs = getNodeAttrs(this.node);
				recordHistory(this.$parent, oldAttrs, newAttrs, 'edit');
        this.$parent.isDraw = true
			},
			//黑反
			handleAntiBlack() {
				let oldAttrs = getNodeAttrs(this.node);
				switch (this.nodeType) {
					case 'WinText':
					case 'TimeText':
						let tag = this.node.getTag();
						this.attrObj.AntiBlack = !this.attrObj.AntiBlack;
						this.node.attrs.parameter.AntiBlack = this.attrObj.AntiBlack;
						if (!this.attrObj.AntiBlack) {
							tag.fill('');
							if (this.node.attrs.parameter.TextArrange == 1) {
								let textGroup = this.node.find('.textGroup')[0];
								if (textGroup) {
									textGroup.children.forEach(text => {
										text.fill('#000000');
									});
								}
							} else {
								this.node.getText().fill('#000000');
							}
						} else {
							tag.fill('#000000');
							if (this.node.attrs.parameter.TextArrange == 1) {
								let textGroup = this.node.find('.textGroup')[0];
								if (textGroup) {
									textGroup.children.forEach(text => {
										text.fill('#FFFFFF');
									});
								}
							} else {
								this.node.getText().fill('#FFFFFF');
							}
						}
						break;
					case 'Table':
						let cell = this.node.find(`.cell_${this.attrObj.currentPos[0]}_${this.attrObj.currentPos[1]}`)[0];
						if (cell) {
							cell.attrs.parameter.AntiBlack = !cell.attrs.parameter.AntiBlack;

							let cellBg = cell.find('.cellBg')[0];
							let cellText = cell.find('.cellText')[0];
							cellBg.attrs.antiBlack = cell.attrs.parameter.AntiBlack;
							cellBg.fill(cellBg.attrs.antiBlack ? '#000000' : '#FFFFFF');
							cellText.fill(cellBg.attrs.antiBlack ? '#FFFFFF' : '#000000');

							this.attrObj.DataSourceList[this.attrObj.dataSourceIndex].AntiBlack = cellBg.attrs.antiBlack;

							this.node.attrs.parameter.DataSourceList[this.attrObj.dataSourceIndex].AntiBlack = cellBg.attrs.antiBlack;
						}
						break;
				}

				this.designLayer.draw();
				let newAttrs = getNodeAttrs(this.node);
				recordHistory(this.$parent, oldAttrs, newAttrs, 'edit');
        this.$parent.isDraw = true
			},
			//自动换行
			handleAutoWrap() {
				let oldAttrs = getNodeAttrs(this.node);
				this.node.attrs.parameter.AutoWrap = this.attrObj.AutoWrap;
				if (this.node.attrs.parameter.TextArrange == 1) {
					drawVerticalText(this.node, this.designLayer);
				} else {
					let fontsize = this.node.getText().fontSize() * 1.1;
					if (this.attrObj.AutoWrap && this.attrObj.TextAlign != 4) {
						let width = this.node.getText().width();
						this.node.getText().width(width + fontsize);
						this.node.width(width + fontsize);
					} else {
						this.node.getText().width(null);
						let width = this.node.getText().width();
						this.node.getText().width(width);
						this.node.width(width);
					}
					resetNodeAttr(this.node);
				}

				let transformer = this.designLayer.find('Transformer')[0];
				transformer.enabledAnchors(getEnabledAnchors(this.node));
				transformer.forceUpdate();
				this.designLayer.draw();
				let newAttrs = getNodeAttrs(this.node);
				recordHistory(this.$parent, oldAttrs, newAttrs, 'edit');
				this.attrObj = JSON.parse(JSON.stringify(this.node.attrs.parameter));
			},
			//自动换行的宽度
			handleAutoWrapWidth() {
				let oldAttrs = getNodeAttrs(this.node);
				let BoxWidth = mmToPx(this.attrObj.BoxWidth);
				if (this.node.attrs.parameter.TextArrange == 1) {
					this.node.attrs.parameter.BoxWidth = this.attrObj.BoxWidth;
					drawVerticalText(this.node, this.designLayer);
				} else {
          if (this.node.attrs.parameter.TextAlign != 4) {
						this.node.width(BoxWidth);
						this.node.getText().width(BoxWidth);
						this.node.getTag().width(BoxWidth);
						if (this.node.getText().scaleX() < 0) {
							//如果文本内容镜像，需处理x坐标
							this.node.getText().x(this.node.getText().width());
						}
					} else {
						let scale = BoxWidth / this.node.width();
					  this.node.scaleX(scale);
					}
					setNodeSize(this.node);
				}

				let transformer = this.designLayer.find('Transformer')[0];
				transformer.forceUpdate();
				this.designLayer.draw();
				let newAttrs = getNodeAttrs(this.node);
				recordHistory(this.$parent, oldAttrs, newAttrs, 'edit');
				this.attrObj = JSON.parse(JSON.stringify(this.node.attrs.parameter));
        this.$parent.isDraw = true
			},
			//斜线方向
			handleLineDirection(value) {
				if (value == this.node.attrs.parameter.LineDirection) return;
				let oldAttrs = getNodeAttrs(this.node);
				let StartX = this.node.x();
				let StartY = this.node.y();
				let EndX = this.node.y();
				let EndY = this.node.x();
				let scaleX = this.node.scaleX();
				let scaleY = this.node.scaleY();
				let width = Math.abs((this.node.points()[2] - this.node.points()[0]) * scaleX);
				let height = Math.abs((this.node.points()[3] - this.node.points()[1]) * scaleY);
				let points = [];
				if (value == 'LeftLine' && this.node.attrs.parameter.LineDirection == 'RightLine') {
					//右斜线 ==》 左斜线
					points = [0, height, width, 0];
					EndX = StartX + width;
				} else if (value == 'RightLine' && this.node.attrs.parameter.LineDirection == 'LeftLine') {
					//左斜线 ==》 右斜线
					points = [0, 0, width, height];
					EndY = StartY + height;
				}
				this.node.points(points);
				this.node.attrs.parameter.LineDirection = value;
				this.node.attrs.parameter.EndX = pxToMm(EndX, 2);
				this.node.attrs.parameter.EndY = pxToMm(EndY, 2);
				updateTransformer(this.designLayer);
				this.designLayer.draw();
				let newAttrs = getNodeAttrs(this.node);
				recordHistory(this.$parent, oldAttrs, newAttrs, 'edit');
				this.attrObj = JSON.parse(JSON.stringify(this.node.attrs.parameter));
        this.$parent.isDraw = true
			},
			// 线条样式
			handleLineStyle(value) {
				let oldAttrs = getNodeAttrs(this.node);
				this.attrObj.DashStyle = value;
				this.node.attrs.parameter.DashStyle = value;
				this.attrObj.Dash = value == 1 ? true : false;
				this.node.attrs.parameter.Dash = value == 1 ? true : false;
				switch (this.nodeType) {
					case 'Rectangle':
					case 'RoundRectangle':
					case 'Ellipse':
					case 'Diamond':
					case 'Triangle':
						let dashEnabled = this.attrObj.Dash && !this.attrObj.Fill;
						this.node.dashEnabled(dashEnabled);
						break;
					default:
						this.node.dashEnabled(this.attrObj.Dash);
						break;
				}
				updateTransformer(this.designLayer);
				this.designLayer.draw();
				let newAttrs = getNodeAttrs(this.node);
				recordHistory(this.$parent, oldAttrs, newAttrs, 'edit');
        this.$parent.isDraw = true
			},
			//线条粗线
			handlePenWidth() {
				let oldAttrs = getNodeAttrs(this.node);
				let penWidth = Math.round(this.attrObj.PenWidth * this.magnification * 10) / 10;
				this.node.strokeWidth(penWidth);
				this.node.attrs.parameter.PenWidth = this.attrObj.PenWidth;
				updateTransformer(this.designLayer);
				this.designLayer.draw();
				let newAttrs = getNodeAttrs(this.node);
				recordHistory(this.$parent, oldAttrs, newAttrs, 'edit');
        this.$parent.isDraw = true
			},
			//圆角大小
			handleCornerRadius() {
				let oldAttrs = getNodeAttrs(this.node);
				let cornerRadius = Math.round(this.attrObj.CornerRadius * this.magnification * 10) / 10;
				this.node.cornerRadius(cornerRadius);
				this.node.attrs.parameter.CornerRadius = this.attrObj.CornerRadius;
				updateTransformer(this.designLayer);
				this.designLayer.draw();
				let newAttrs = getNodeAttrs(this.node);
				recordHistory(this.$parent, oldAttrs, newAttrs, 'edit');
        this.$parent.isDraw = true
			},
			//填充图像
			handleFillShape() {
				let oldAttrs = getNodeAttrs(this.node);
				let color = this.attrObj.Fill ? '#000000' : '';
				this.node.attrs.parameter.Fill = this.attrObj.Fill;
				this.node.fill(color);
				this.node.dashEnabled(this.attrObj.Dash && !this.attrObj.Fill);
				this.designLayer.draw();
				let newAttrs = getNodeAttrs(this.node);
				recordHistory(this.$parent, oldAttrs, newAttrs, 'edit');
        this.$parent.isDraw = true
			},
			//获取本地添加的图片
			getLocalImg(file, fileList) {
				// console.log(file);
				// console.log(file.raw.type);
				var testmsg = /^image\/(jpeg|png|jpg)$/.test(file.raw.type);
				if (!testmsg) {
					message.error(this.$t(`tips.tip39`));
					return;
				}
				let configuration = this.$parent.configuration;
				if (file.raw.size / 1024 > configuration.image_size) {
					message.error(this.$t(`tips.tip30`) + configuration.image_size + this.$t(`tips.tip31`));
					return;
				}
				localToBase64(file.raw).then(res => {
					let image = new Image();
					image.setAttribute('crossOrigin', 'Anonymous');
					image.onload = () => {
						let oldAttrs = getNodeAttrs(this.node);
						//保持宽度不变
						let scale = this.node.width() / image.width;
						this.node.setAttrs({
							image,
							height: image.height,
							scaleY: scale,
							imageSrc: res
						});
						let { width, height } = setNodeSize(this.node);
						this.attrObj.Width = width;
						this.attrObj.Height = height;

						updateTransformer(this.designLayer);
						this.designLayer.draw();
						let newAttrs = getNodeAttrs(this.node);
						recordHistory(this.$parent, oldAttrs, newAttrs, 'edit');
            this.$parent.isDraw = true
					};
					image.src = res;
				});
			},
			//打开云端图片选择框
			openCloudImg() {
				this.$parent.$refs.cloudImgDiaRef.show();
			},
			//设置图片
			handleUpdateImg(imgUrl) {
				urlToBase64(imgUrl, dataURL => {
					let image = new Image();
					image.setAttribute('crossOrigin', 'Anonymous');
					image.onload = () => {
						let oldAttrs = getNodeAttrs(this.node);
						//保持宽度不变
						let scale = this.node.width() / image.width;
						this.node.setAttrs({
							image,
							height: image.height,
							scaleY: scale,
							imageSrc: dataURL
						});
						let { width, height } = setNodeSize(this.node);
						this.attrObj.Width = width;
						this.attrObj.Height = height;

						updateTransformer(this.designLayer);
						this.designLayer.draw();
						let newAttrs = getNodeAttrs(this.node);
						recordHistory(this.$parent, oldAttrs, newAttrs, 'edit');
            this.$parent.isDraw = true
					};
					image.src = dataURL;
				});
			},
			//图片等比缩放
			handleISParticipating() {
				let oldAttrs = getNodeAttrs(this.node);
				let transformer = this.designLayer.find('Transformer')[0];
				let enabledAnchors = ['middle-right', 'bottom-center', 'bottom-right'];
				if (this.attrObj.ISParticipating) {
					enabledAnchors = ['bottom-right'];
				}
				this.node.attrs.parameter.ISParticipating = this.attrObj.ISParticipating;
				transformer.enabledAnchors(enabledAnchors);
				this.designLayer.draw();
				let newAttrs = getNodeAttrs(this.node);
				recordHistory(this.$parent, oldAttrs, newAttrs, 'edit');
        this.$parent.isDraw = true
			},
			//适应画布
			handleAdaptCanvas() {
				let oldAttrs = getNodeAttrs(this.node);
				let width = this.designArea.width();
				let height = this.designArea.height();
				let x = 0;
				let y = 0;
				let w = width;
				let h = height;
				let rotation = this.node.rotation();
				switch (rotation) {
					case 90:
						w = height;
						h = width;
						x = h;
						break;
					case 180:
						x = w;
						y = h;
						break;
					case -90:
						w = height;
						h = width;
						y = w;
						break;
				}
				this.node.setAttrs({
					x,
					y,
					width: w,
					height: h,
					scaleX: 1,
					scaleY: 1
				});
				this.node.attrs.parameter.StartX = 0;
				this.node.attrs.parameter.StartY = 0;
				this.node.attrs.parameter.Width = pxToMm(w, 2);
				this.node.attrs.parameter.Height = pxToMm(h, 2);

				updateTransformer(this.designLayer);
				this.designLayer.draw();
				let newAttrs = getNodeAttrs(this.node);
				recordHistory(this.$parent, oldAttrs, newAttrs, 'edit');
				this.attrObj = JSON.parse(JSON.stringify(this.node.attrs.parameter));
        this.$parent.isDraw = true
			},
			//是否显示时间或日期
			handleDateFormatChecked() {
				let oldAttrs = getNodeAttrs(this.node);
				//判断是否有勾选日期
				if (this.attrObj.DateFormatChecked.indexOf('date') == -1) {
					this.attrObj.DataFormat = '';
				} else {
					this.attrObj.DataFormat = this.attrObj.DataFormat ? this.attrObj.DataFormat : 'yyyy/MM/dd';
				}

				//判断是否有勾选时间
				if (this.attrObj.DateFormatChecked.indexOf('time') == -1) {
					this.attrObj.TimeFormat = '';
				} else {
					this.attrObj.TimeFormat = this.attrObj.TimeFormat ? this.attrObj.TimeFormat : 'HH:mm:ss';
				}
				this.node.attrs.parameter.DateFormatChecked = this.attrObj.DateFormatChecked;
				let date = dealTimeOffset(this.attrObj.SelectDate, this.attrObj.SelectTime, this.attrObj.DayOffset, this.attrObj.MinuteOffset);
				this.attrObj.Data = formatDate(date, this.attrObj.DataFormat, this.attrObj.TimeFormat);
				this.node.attrs.parameter.Data = this.attrObj.Data;
				this.node.attrs.parameter.DataFormat = this.attrObj.DataFormat;
				this.node.attrs.parameter.TimeFormat = this.attrObj.TimeFormat;

				if (this.node.attrs.parameter.TextArrange == 1) {
					drawVerticalText(this.node, this.designLayer);
					this.attrObj = JSON.parse(JSON.stringify(this.node.attrs.parameter));
				} else {
					this.node.getText().text(this.attrObj.Data);
					resetNodeAttr(this.node);
				}

				let { width, height } = setNodeSize(this.node);
				this.attrObj.Width = width;
				this.attrObj.Height = height;
				updateTransformer(this.designLayer);
				this.designLayer.draw();

				let newAttrs = getNodeAttrs(this.node);
				recordHistory(this.$parent, oldAttrs, newAttrs, 'edit');
        this.$parent.isDraw = true
			},
			//选择日期、时间
			handlePickDateTime() {
				let oldAttrs = getNodeAttrs(this.node);
				//先计算时间偏移
				let date = dealTimeOffset(this.attrObj.SelectDate, this.attrObj.SelectTime, this.attrObj.DayOffset, this.attrObj.MinuteOffset);
        console.log(date)
				//转换成对应的时间类型
				this.attrObj.Data = formatDate(date, this.attrObj.DataFormat, this.attrObj.TimeFormat);
				this.node.attrs.parameter.Data = this.attrObj.Data;
				this.node.attrs.parameter.SelectDate = this.attrObj.SelectDate;
				this.node.attrs.parameter.SelectTime = this.attrObj.SelectTime;
				if (this.node.attrs.parameter.TextArrange == 1) {
					drawVerticalText(this.node, this.designLayer);
					this.attrObj = JSON.parse(JSON.stringify(this.node.attrs.parameter));
				} else {
					this.node.getText().text(this.attrObj.Data);
					resetNodeAttr(this.node);
				}

				let { width, height } = setNodeSize(this.node);
				this.attrObj.Width = width;
				this.attrObj.Height = height;
				updateTransformer(this.designLayer);
				this.designLayer.draw();
				let newAttrs = getNodeAttrs(this.node);
				recordHistory(this.$parent, oldAttrs, newAttrs, 'edit');
        this.$parent.isDraw = true
			},
			// 日期格式 时间格式
			handleDateTimeFormat() {
				let oldAttrs = getNodeAttrs(this.node);
				let date = dealTimeOffset(this.attrObj.SelectDate, this.attrObj.SelectTime, this.attrObj.DayOffset, this.attrObj.MinuteOffset);

				this.node.attrs.parameter.DataFormat = this.attrObj.DataFormat;
				this.node.attrs.parameter.TimeFormat = this.attrObj.TimeFormat;
				this.attrObj.Data = formatDate(date, this.attrObj.DataFormat, this.attrObj.TimeFormat);
				this.node.attrs.parameter.Data = this.attrObj.Data;
				if (this.node.attrs.parameter.TextArrange == 1) {
					drawVerticalText(this.node, this.designLayer);
					this.attrObj = JSON.parse(JSON.stringify(this.node.attrs.parameter));
				} else {
					this.node.getText().text(this.attrObj.Data);
					resetNodeAttr(this.node);
				}

				let { width, height } = setNodeSize(this.node);
				this.attrObj.Width = width;
				this.attrObj.Height = height;
				updateTransformer(this.designLayer);
				this.designLayer.draw();

				let newAttrs = getNodeAttrs(this.node);
				recordHistory(this.$parent, oldAttrs, newAttrs, 'edit');
        this.$parent.isDraw = true
			},
			// 时间类型
			handleTimeType() {
				let oldAttrs = getNodeAttrs(this.node);
				this.node.attrs.parameter.TimeType = this.attrObj.TimeType;
				if (this.attrObj.TimeType == 0) {
					this.attrObj.SelectDate = getNowDateOrTime('date');
					this.attrObj.SelectTime = getNowDateOrTime('time');
					let date = dealTimeOffset(this.attrObj.SelectDate, this.attrObj.SelectTime, this.attrObj.DayOffset, this.attrObj.MinuteOffset);

					this.attrObj.Data = formatDate(date, this.attrObj.DataFormat, this.attrObj.TimeFormat);
					this.node.attrs.parameter.Data = this.attrObj.Data;
					this.node.attrs.parameter.SelectDate = this.attrObj.SelectDate;
					this.node.attrs.parameter.SelectTime = this.attrObj.SelectTime;
					if (this.node.attrs.parameter.TextArrange == 1) {
						drawVerticalText(this.node, this.designLayer);
						this.attrObj = JSON.parse(JSON.stringify(this.node.attrs.parameter));
					} else {
						this.node.getText().text(this.attrObj.Data);
						resetNodeAttr(this.node);
					}
				}

				let { width, height } = setNodeSize(this.node);
				this.attrObj.Width = width;
				this.attrObj.Height = height;
				updateTransformer(this.designLayer);
				this.designLayer.draw();

				let newAttrs = getNodeAttrs(this.node);
				recordHistory(this.$parent, oldAttrs, newAttrs, 'edit');
        this.$parent.isDraw = true
			},
			//天数偏移 时间偏移
			handleTimeOffset() {
				let oldAttrs = getNodeAttrs(this.node);
				let date = dealTimeOffset(this.attrObj.SelectDate, this.attrObj.SelectTime, this.attrObj.DayOffset, this.attrObj.MinuteOffset);
				this.attrObj.Data = formatDate(date, this.attrObj.DataFormat, this.attrObj.TimeFormat);
				this.node.attrs.parameter.Data = this.attrObj.Data;
				this.node.attrs.parameter.DayOffset = this.attrObj.DayOffset;
				this.node.attrs.parameter.MinuteOffset = this.attrObj.MinuteOffset;
				if (this.node.attrs.parameter.TextArrange == 1) {
					drawVerticalText(this.node, this.designLayer);
					this.attrObj = JSON.parse(JSON.stringify(this.node.attrs.parameter));
				} else {
					this.node.getText().text(this.attrObj.Data);
					resetNodeAttr(this.node);
				}

				let { width, height } = setNodeSize(this.node);
				this.attrObj.Width = width;
				this.attrObj.Height = height;
				updateTransformer(this.designLayer);
				this.designLayer.draw();
				let newAttrs = getNodeAttrs(this.node);
				recordHistory(this.$parent, oldAttrs, newAttrs, 'edit');
        this.$parent.isDraw = true
			},
			//编码类型
			async handleCodeType() {
				let oldAttrs = getNodeAttrs(this.node);
				let data = this.CodeTypeList.find(item => item.value == this.attrObj.BarcodeType);
				this.node.attrs.options.bcid = data.bcid;
				this.node.attrs.parameter.BarcodeType = this.attrObj.BarcodeType;
				if (this.nodeType == 'QRCode') {
					this.eclevelList = getEclevelList(this.node.attrs.parameter.BarcodeType);
					if (this.attrObj.BarcodeType == 'QR_CODE') {
						this.node.attrs.options.eclevel = 'M';
						this.node.attrs.parameter.ECLevelBits = 'M';
						this.attrObj.ECLevelBits = 'M';
					} else if (this.attrObj.BarcodeType == 'PDF_417') {
						this.node.attrs.options.eclevel = 1;
						this.node.attrs.parameter.ECLevelBits = 1;
						this.attrObj.ECLevelBits = 1;
					} else {
						this.node.attrs.options.eclevel = '';
					}
				} else {
          if(!this.node.attrs.parameter.isEdit){
            this.attrObj.Data = data.defaultValue;
						this.node.attrs.parameter.Data = data.defaultValue;
						this.node.attrs.parameter.DataSourceList[0].FirstValue = data.defaultValue;
						this.node.attrs.options.text = data.defaultValue;
          }
					this.node.attrs.options.eclevel = '';
				}
				try {
					let codeImg = await getGenerateCodeImg(this.node);
					this.node.setAttrs({
						image: codeImg,
						imageSrc: codeImg.src,
						width: codeImg.width,
						height: codeImg.height
					});
					let { width, height } = setNodeSize(this.node);
					this.attrObj.Width = width;
					this.attrObj.Height = height;
					updateTransformer(this.designLayer);
					this.designLayer.draw();
					this.attrObj = JSON.parse(JSON.stringify(this.node.attrs.parameter));
					this.$forceUpdate();
					let newAttrs = getNodeAttrs(this.node);
					recordHistory(this.$parent, oldAttrs, newAttrs, 'edit');
          this.$parent.isDraw = true
				} catch (err) {
					message.error(this.$t(`tips.tip111`));
				}
			},
			//条码文本位置
			async handleSetBarcodeNumPosition() {
				let oldAttrs = getNodeAttrs(this.node);
				let BarcodeNumPosition = this.attrObj.BarcodeNumPosition;
				switch (BarcodeNumPosition) {
					case 0:
						this.node.attrs.options.includetext = false;
						break;
					case 1:
						this.node.attrs.options.includetext = true;
						this.node.attrs.options.textyalign = 'bottom';
						break;
					case 2:
						this.node.attrs.options.includetext = true;
						this.node.attrs.options.textyalign = 'above';
						break;
				}
				this.node.attrs.parameter.BarcodeNumPosition = BarcodeNumPosition;

				try {
					let codeImg = await getGenerateCodeImg(this.node);
					this.node.setAttrs({
						image: codeImg,
						imageSrc: codeImg.src,
						width: codeImg.width,
						height: BarcodeNumPosition == 0 ? this.node.height() : codeImg.height
					});
					let { width, height } = setNodeSize(this.node);
					this.attrObj.Width = width;
					this.attrObj.Height = height;
					updateTransformer(this.designLayer);
					this.designLayer.draw();
					let newAttrs = getNodeAttrs(this.node);
					recordHistory(this.$parent, oldAttrs, newAttrs, 'edit');
          this.$parent.isDraw = true
				} catch (err) {
					message.error(this.$t(`tips.tip111`));
				}
			},
			// 二维码纠错率
			async handleLevelBits() {
				let oldAttrs = getNodeAttrs(this.node);
				this.node.attrs.parameter.ECLevelBits = this.attrObj.ECLevelBits;
				this.node.attrs.options.eclevel = this.node.attrs.parameter.ECLevelBits;
				try {
					let codeImg = await getGenerateCodeImg(this.node);
					this.node.setAttrs({
						image: codeImg,
						imageSrc: codeImg.src,
						width: codeImg.width,
						height: codeImg.height
					});
					let { width, height } = setNodeSize(this.node);
					this.attrObj.Width = width;
					this.attrObj.Height = height;
					updateTransformer(this.designLayer);
					this.designLayer.draw();
					this.attrObj = JSON.parse(JSON.stringify(this.node.attrs.parameter));
					this.$forceUpdate();
					let newAttrs = getNodeAttrs(this.node);
					recordHistory(this.$parent, oldAttrs, newAttrs, 'edit');
          this.$parent.isDraw = true
				} catch (err) {
					message.error(this.$t(`tips.tip111`));
				}
			},
			//表格操作
			handleTableOperation(type) {
				switch (type) {
					case 'mutiple':
						let val = !this.attrObj.cellMultiple;
						this.attrObj.cellMultiple = val;
						this.node.attrs.parameter.cellMultiple = val;
						if (val) {
							//多选
							let arr = [];
							arr.push(this.node.attrs.parameter.DataSourceList[this.node.attrs.parameter.dataSourceIndex].Key);
							this.node.attrs.parameter.mulCellPos = arr;
						} else {
							//单选
							this.node.attrs.parameter.mulCellPos = [];
							//隐藏选中框
							let currentPos = this.node.attrs.parameter.currentPos;
							let cell = this.node.find(`.cell_${currentPos[0]}_${currentPos[1]}`)[0];
							if (cell.attrs.parameter.AllKeys.length > 1) {
								this.showTableSplit = true;
							} else {
								this.showTableSplit = false;
							}
							let cellSelectArr = this.node.find('.cellSelect');
							cellSelectArr.map(item => {
								if (item.attrs.pos[0] == currentPos[0] && item.attrs.pos[1] == currentPos[1]) {
									item.show();
								} else {
									item.hide();
								}
							});
						}
						this.designLayer.draw();
						break;
					case 'insert_row':
						this.tableInsertRow();
						break;
					case 'insert_column':
						this.tableInsertColumn();
						break;
					case 'delete_row':
						this.tableDeleteRow();
						break;
					case 'delete_column':
						this.tableDeleteColumn();
						break;
					case 'merge':
						//合并单元格
						if (!this.node.attrs.parameter.cellMultiple) return;
						if (this.node.attrs.parameter.mulCellPos.length < 2) return;
						this.tableMerge();
						break;
					case 'split':
						//拆分合并单元格
						if (!this.showTableSplit) return;
						this.tableSplit();
				}
				updateTransformer(this.designLayer);
				this.designLayer.draw();
			},
			//增加行
			tableInsertRow() {
				let oldAttrs = getNodeAttrs(this.node);

				let strokeWidth = Math.round(this.node.attrs.parameter.PenWidth * this.magnification * 10) / 10;
				let row = this.node.attrs.parameter.Row;
				let column = this.node.attrs.parameter.Column;
				let defaultHeight = this.designArea.width() * 0.7 * 0.4;
				let defaultCellHeight = (defaultHeight - strokeWidth * 3) / 2;
				let tableWidth = this.node.width();
				let tableHeight = this.node.height();
				let DataSourceList = this.node.attrs.parameter.DataSourceList;
				let CellWidth = this.node.attrs.parameter.CellWidth;
				let CellHeight = this.node.attrs.parameter.CellHeight;

				row += 1;
				this.node.attrs.parameter.Row = row; //记录总行数

				let tableRowLine = this.node.find('.tableRowLine')[0];
				let y = tableHeight + defaultCellHeight;
				//绘制新增行线
				let rowLine = new Konva.Rect({
					name: `rowLine_${row}`,
					x: 0,
					y,
					width: tableWidth,
					height: strokeWidth,
					fill: '#000000',
					hitFunc: context => {
						context.beginPath();
						context.rect(0, y, tableWidth, strokeWidth);
						context.closePath();
					}
				});
				tableRowLine.add(rowLine);

				//更新列线的高度
				let tableColumnLine = this.node.find('.tableColumnLine')[0];
				tableColumnLine.children.map(item => {
					item.setAttrs({
						height: y + strokeWidth,
						hitFunc: context => {
							context.beginPath();
							context.rect(item.x(), 0, item.width(), y + strokeWidth);
							context.closePath();
						}
					});
				});

				this.node.height(y + strokeWidth);

				CellHeight.push(defaultCellHeight); //存储新增行的高度
				this.node.attrs.parameter.DicLineLocH.push(y); // 存储新的行线

				let DicLineLocH = this.node.attrs.parameter.DicLineLocH; //起始点为整个表格的左上角
				let DicLineLocW = this.node.attrs.parameter.DicLineLocW; //起始点为整个表格的左上角

				let cellGroup = this.node.find('.cellGroup')[0]; //起始点为表格内的左上角(扣除外围边线粗细)
				//绘制新增行的单元格
				for (let i = 0; i < column; i++) {
					let cell_x = DicLineLocW[i];
					let cell_y = DicLineLocH[row - 1];
					let cell = new Konva.Group({
						name: `cell_${row - 1}_${i}`,
						x: cell_x,
						y: cell_y,
						width: CellWidth[i],
						height: defaultCellHeight,
						pos: [row - 1, i],
						allPos: [[row - 1, i]], //用来记录合并单元格情况
						clipWidth: CellWidth[i],
						clipHeight: defaultCellHeight
					});
					let cellBg = new Konva.Rect({
						name: 'cellBg',
						pos: [row - 1, i],
						x: 0,
						y: 0,
						width: CellWidth[i],
						height: defaultCellHeight,
						fill: '#FFFFFF',
						antiBlack: false
					});
					cell.add(cellBg);

					let cellText = new Konva.Text({
						name: 'cellText',
						pos: [row - 1, i],
						x: 0,
						y: 0,
						width: CellWidth[i],
						height: defaultCellHeight,
						text: '',
						fontFamily: '阿里巴巴普惠体',
						fontSize: 18 / this.$parent.multiple,
						fontStyle: '',
						textDecoration: '',
						align: getTextAlignValue(0),
						verticalAlign: 'middle',
						lineHeight: 1,
						letterSpacing: 0,
						fill: '#000000',
						scaleX: 1,
						scaleY: 1,
						wrap: 'char'
					});
					cell.setAttr('parameter', {
						AllKeys: [[row - 1, i]],
						Key: [row - 1, i],
						StartX: Math.round((cell.x() / this.magnification) * 100) / 100,
						StartY: Math.round((cell.y() / this.magnification) * 100) / 100,
						Width: Math.round((cell.width() / this.magnification) * 100) / 100,
						Height: Math.round((cell.height() / this.magnification) * 100) / 100,
						FirstValue: cellText.text(),
            NormalText: cellText.text() ? cellText.text() : '',
						SequenceType: 0,
						InitialValue: '1',
						AddedValue: '',
						Quantity: '',
						ColumnName: '',
						QuerySql: '',
						TableName: '',
						ConnectionStr: '',
						DataSourceType: 0,
						AntiBlack: cellBg.attrs.antiBlack,
						MirrorImage: cellText.scaleX() < 0 ? true : false,
						FontFamily: cellText.fontFamily(),
						FontSize: dataPrecision(cellText.fontSize(), 1),
						Bold: cellText.fontStyle().indexOf('bold') > -1 ? true : false,
						Italic: cellText.fontStyle().indexOf('italic') > -1 ? true : false,
						UnderLine: cellText.textDecoration().indexOf('underline') > -1 ? true : false,
						DeleteLine: cellText.textDecoration().indexOf('line-through') > -1 ? true : false,
						TextAlign: 0,
						LineHeight: 0,
						LetterSpacing: 0,
						DataType: '2', //数据类型 0为日期 1为数字 2文本
						DataFormat: 'yyyy-MM-dd HH:mm:ss', //日期格式
						NumberFormat: '123456789', //数字形式
						SheetIndex: [0, 0],
            DataBase: [],
					});
					cell.add(cellText);

					let cellSelect = new Konva.Rect({
						name: 'cellSelect',
						pos: [row - 1, i],
						x: 0,
						y: 0,
						width: CellWidth[i],
						height: defaultCellHeight,
						fill: '#3F80F5',
						opacity: 0.1
					});
					cellSelect.hide();
					cell.add(cellSelect);
					cellGroup.add(cell);
					DataSourceList.push(cell.attrs.parameter);
				}
				this.node.attrs.parameter.CellHeight = CellHeight;
				this.node.attrs.parameter.DataSourceList = DataSourceList;
				setNodeSize(this.node);
				this.attrObj = JSON.parse(JSON.stringify(this.node.attrs.parameter));

				let newAttrs = getNodeAttrs(this.node);
				recordHistory(this.$parent, oldAttrs, newAttrs, 'edit');
        this.$parent.isDraw = true
			},
			//增加列
			tableInsertColumn() {
				let oldAttrs = getNodeAttrs(this.node);

				let strokeWidth = Math.round(this.node.attrs.parameter.PenWidth * this.magnification * 100) / 100;
				let row = this.node.attrs.parameter.Row;
				let column = this.node.attrs.parameter.Column;
				let defaultWidth = this.designArea.width() * 0.7;
				let defaultCellWidth = (defaultWidth - strokeWidth * 3) / 2;
				let tableWidth = this.node.width();
				let tableHeight = this.node.height();
				let DataSourceList = this.node.attrs.parameter.DataSourceList;
				let CellWidth = this.node.attrs.parameter.CellWidth;
				let CellHeight = this.node.attrs.parameter.CellHeight;

				column += 1;
				this.node.attrs.parameter.Column = column; //记录总列数

				let tableColumnLine = this.node.find('.tableColumnLine')[0];
				let x = tableWidth + defaultCellWidth;
				//绘制新增列线
				let columnLine = new Konva.Rect({
					name: `columnLine_${column}`,
					x,
					y: 0,
					width: strokeWidth,
					height: tableHeight,
					fill: '#000000',
					hitFunc: context => {
						context.beginPath();
						context.rect(x, 0, strokeWidth, tableHeight);
						context.closePath();
					}
				});
				tableColumnLine.add(columnLine);

				//更新行线的宽度
				let tableRowLine = this.node.find('.tableRowLine')[0];
				tableRowLine.children.map(item => {
					item.setAttrs({
						width: x + strokeWidth,
						hitFunc: context => {
							context.beginPath();
							context.rect(0, item.y(), x + strokeWidth, item.height());
							context.closePath();
						}
					});
				});

				this.node.width(x + strokeWidth);

				CellWidth.push(defaultCellWidth); //存储新增列的宽度

				this.node.attrs.parameter.DicLineLocW.push(x); // 存储新的列线

				let DicLineLocH = this.node.attrs.parameter.DicLineLocH; //起始点为整个表格的左上角
				let DicLineLocW = this.node.attrs.parameter.DicLineLocW; //起始点为整个表格的左上角

				let cellGroup = this.node.find('.cellGroup')[0]; //起始点为表格内的左上角(扣除外围边线粗细)
				//绘制新增列的单元格
				for (let i = 0; i < row; i++) {
					let cell_x = DicLineLocW[column - 1];
					let cell_y = DicLineLocH[i];
					let cell = new Konva.Group({
						name: `cell_${i}_${column - 1}`,
						x: cell_x,
						y: cell_y,
						width: defaultCellWidth,
						height: CellHeight[i],
						pos: [i, column - 1],
						allPos: [[i, column - 1]], //用来记录合并单元格情况
						clipWidth: defaultCellWidth,
						clipHeight: CellHeight[i]
					});
					let cellBg = new Konva.Rect({
						name: 'cellBg',
						pos: [i, column - 1],
						x: 0,
						y: 0,
						width: defaultCellWidth,
						height: CellHeight[i],
						fill: '#FFFFFF',
						antiBlack: false
					});
					cell.add(cellBg);

					let cellText = new Konva.Text({
						name: 'cellText',
						pos: [i, column - 1],
						x: 0,
						y: 0,
						width: defaultCellWidth,
						height: CellHeight[i],
						text: '',
						fontFamily: '阿里巴巴普惠体',
						fontSize: 18 / this.$parent.multiple,
						fontStyle: '',
						textDecoration: '',
						align: getTextAlignValue(0),
						verticalAlign: 'middle',
						lineHeight: 1,
						letterSpacing: 0,
						fill: '#000000',
						scaleX: 1,
						scaleY: 1,
						wrap: 'char'
					});
					cell.setAttr('parameter', {
						AllKeys: [[i, column - 1]],
						Key: [i, column - 1],
						StartX: Math.round((cell.x() / this.magnification) * 100) / 100,
						StartY: Math.round((cell.y() / this.magnification) * 100) / 100,
						Width: Math.round((cell.width() / this.magnification) * 100) / 100,
						Height: Math.round((cell.height() / this.magnification) * 100) / 100,
						FirstValue: cellText.text(),
            NormalText: cellText.text() ? cellText.text() : '',
						SequenceType: 0,
						InitialValue: '1',
						AddedValue: '',
						Quantity: '',
						ColumnName: '',
						QuerySql: '',
						TableName: '',
						ConnectionStr: '',
						DataSourceType: 0,
						AntiBlack: cellBg.attrs.antiBlack,
						MirrorImage: cellText.scaleX() < 0 ? true : false,
						FontFamily: cellText.fontFamily(),
						FontSize: dataPrecision(cellText.fontSize(), 1),
						Bold: cellText.fontStyle().indexOf('bold') > -1 ? true : false,
						Italic: cellText.fontStyle().indexOf('italic') > -1 ? true : false,
						UnderLine: cellText.textDecoration().indexOf('underline') > -1 ? true : false,
						DeleteLine: cellText.textDecoration().indexOf('line-through') > -1 ? true : false,
						TextAlign: 0,
						LineHeight: 0,
						LetterSpacing: 0,
						DataType: '2', //数据类型 0为日期 1为数字 2文本
						DataFormat: 'yyyy-MM-dd HH:mm:ss', //日期格式
						NumberFormat: '123456789', //数字形式
						SheetIndex: [0, 0],
             DataBase: [],
					});
					cell.add(cellText);

					let cellSelect = new Konva.Rect({
						name: 'cellSelect',
						pos: [i, column - 1],
						x: 0,
						y: 0,
						width: defaultCellWidth,
						height: CellHeight[i],
						fill: '#3F80F5',
						opacity: 0.1
					});
					cellSelect.hide();
					cell.add(cellSelect);
					cellGroup.add(cell);
					DataSourceList.push(cell.attrs.parameter);
				}
				this.node.attrs.parameter.CellWidth = CellWidth;
				this.node.attrs.parameter.DataSourceList = DataSourceList;
				setNodeSize(this.node);
				this.attrObj = JSON.parse(JSON.stringify(this.node.attrs.parameter));

				let newAttrs = getNodeAttrs(this.node);
				recordHistory(this.$parent, oldAttrs, newAttrs, 'edit');
        this.$parent.isDraw = true
			},
			//删除行
			tableDeleteRow() {
				let row = this.node.attrs.parameter.Row;
				if (row == 1) return;
				let oldAttrs = getNodeAttrs(this.node);

				let column = this.node.attrs.parameter.Column;
				let strokeWidth = Math.round(this.node.attrs.parameter.PenWidth * this.magnification * 100) / 100;

				//移除行线
				let rowLine = this.node.find(`.rowLine_${row}`)[0];
				rowLine.remove();

				let DicLineLocH = this.node.attrs.parameter.DicLineLocH;
				DicLineLocH = DicLineLocH.slice(0, DicLineLocH.length - 1);
				this.node.attrs.parameter.DicLineLocH = DicLineLocH;

				let height = DicLineLocH[DicLineLocH.length - 1] + strokeWidth;
				this.node.height(height);

				//更新列线的高度
				let tableColumnLine = this.node.find('.tableColumnLine')[0];
				tableColumnLine.children.map(item => {
					item.setAttrs({
						height,
						hitFunc: context => {
							context.beginPath();
							context.rect(item.x(), 0, item.width(), height);
							context.closePath();
						}
					});
				});

				let CellHeight = this.node.attrs.parameter.CellHeight;

				let cellGroup = this.node.find('.cellGroup')[0];
				for (let i = 0; i < column; i++) {
					let cell = cellGroup.find(`.cell_${row - 1}_${i}`)[0];
					if (cell) {
						cell.remove();
					}
				}

				let DataSourceList = [];
				cellGroup.children.map(cell => {
					let pos = cell.attrs.pos;
					let allPos = cell.attrs.allPos;
					let index = allPos.findIndex(pos => pos[0] == row - 1);
					if (index > -1) {
						cell.attrs.allPos.splice(index, 1);
						let height = cell.height() - CellHeight[CellHeight.length - 1] - strokeWidth;
						cell.setAttrs({
							height,
							clipHeight: height
						});
						cell.attrs.parameter.Height = Math.round((height / this.magnification) * 100) / 100;
						cell.attrs.parameter.AllKeys = cell.attrs.allPos;

						let cellBg = cell.find('.cellBg')[0];
						let cellText = cell.find('.cellText')[0];
						let cellSelect = cell.find('.cellSelect')[0];

						cellBg.height(height);
						cellText.height(height);
						cellSelect.height(height);
					}
					DataSourceList.push(cell.attrs.parameter);
				});
				this.node.attrs.parameter.DataSourceList = DataSourceList;

				CellHeight = CellHeight.slice(0, CellHeight.length - 1);
				this.node.attrs.parameter.CellHeight = CellHeight;

				this.node.attrs.parameter.Row -= 1;

				//选中单元格在删除列或删除行，则重置选中单元格
				let currentPos = this.node.attrs.parameter.currentPos;
				if (currentPos[0] >= this.node.attrs.parameter.Row || currentPos[1] >= this.node.attrs.parameter.Column) {
					this.node.attrs.parameter.currentPos = [0, 0];
					let cell = this.node.find('.cell_0_0')[0];
					let cellSelect = cell.find('.cellSelect')[0];
					cellSelect.show();
          this.node.attrs.parameter.dataSourceIndex = 0
				}
				setNodeSize(this.node);
				this.attrObj = JSON.parse(JSON.stringify(this.node.attrs.parameter));
				let newAttrs = getNodeAttrs(this.node);
				recordHistory(this.$parent, oldAttrs, newAttrs, 'edit');
        this.$parent.isDraw = true
			},
			//删除列
			tableDeleteColumn() {
				let column = this.node.attrs.parameter.Column;
				if (column == 1) return;
				let oldAttrs = getNodeAttrs(this.node);

				let row = this.node.attrs.parameter.Row;
				let strokeWidth = Math.round(this.node.attrs.parameter.PenWidth * this.magnification * 100) / 100;

				//移除列线
				let columnLine_ = this.node.find(`.columnLine_${column}`)[0];
				columnLine_.remove();

				let DicLineLocW = this.node.attrs.parameter.DicLineLocW;
				DicLineLocW = DicLineLocW.slice(0, DicLineLocW.length - 1);
				this.node.attrs.parameter.DicLineLocW = DicLineLocW;

				let width = DicLineLocW[DicLineLocW.length - 1] + strokeWidth;
				this.node.width(width);

				//更新行线的宽度
				let tableRowLine = this.node.find('.tableRowLine')[0];
				tableRowLine.children.map(item => {
					item.setAttrs({
						width,
						hitFunc: context => {
							context.beginPath();
							context.rect(0, item.y(), width, item.height());
							context.closePath();
						}
					});
				});

				let CellWidth = this.node.attrs.parameter.CellWidth;

				let cellGroup = this.node.find('.cellGroup')[0];
				for (let i = 0; i < row; i++) {
					let cell = cellGroup.find(`.cell_${i}_${column - 1}`)[0];
					if (cell) {
						cell.remove();
					}
				}

				let DataSourceList = [];
				cellGroup.children.map(cell => {
					let pos = cell.attrs.pos;
					let allPos = cell.attrs.allPos;
					let index = allPos.findIndex(pos => pos[1] == column - 1);
					if (index > -1) {
						cell.attrs.allPos.splice(index, 1);
						let width = cell.width() - CellWidth[CellWidth.length - 1] - strokeWidth;
						cell.setAttrs({
							width,
							clipWidth: width
						});
						cell.attrs.parameter.Width = Math.round((width / this.magnification) * 100) / 100;
						cell.attrs.parameter.AllKeys = cell.attrs.allPos;

						let cellBg = cell.find('.cellBg')[0];
						let cellText = cell.find('.cellText')[0];
						let cellSelect = cell.find('.cellSelect')[0];

						cellBg.width(width);
						cellText.width(width);
						cellSelect.width(width);
					}
					DataSourceList.push(cell.attrs.parameter);
				});
				this.node.attrs.parameter.DataSourceList = DataSourceList;

				CellWidth = CellWidth.slice(0, CellWidth.length - 1);
				this.node.attrs.parameter.CellWidth = CellWidth;

				this.node.attrs.parameter.Column -= 1;

				//选中单元格在删除列或删除行，则重置选中单元格
				let currentPos = this.node.attrs.parameter.currentPos;
				if (currentPos[0] >= this.node.attrs.parameter.Row || currentPos[1] >= this.node.attrs.parameter.Column) {
					this.node.attrs.parameter.currentPos = [0, 0];
					let cell = this.node.find('.cell_0_0')[0];
					let cellSelect = cell.find('.cellSelect')[0];
					cellSelect.show();
          this.node.attrs.parameter.dataSourceIndex = 0
				}
				setNodeSize(this.node);
				this.attrObj = JSON.parse(JSON.stringify(this.node.attrs.parameter));
				let newAttrs = getNodeAttrs(this.node);
				recordHistory(this.$parent, oldAttrs, newAttrs, 'edit');
        this.$parent.isDraw = true
			},
			//合并单元格
			tableMerge() {
				let oldAttrs = getNodeAttrs(this.node);

				let mulCellPos = this.node.attrs.parameter.mulCellPos; //多选的单元格
				mulCellPos.sort(dealSort);

				//处理多选单元格中的合并单元格
				let allMulCellPos = [];
				mulCellPos.map(item => {
					this.attrObj.DataSourceList.map(data => {
						if (data.Key[0] == item[0] && data.Key[1] == item[1]) {
							let AllKeys = data.AllKeys;
							allMulCellPos.push(...AllKeys);
						}
					});
				});

				// 过滤出选中单元格所包含行列范围内的所有单元格
				const { minRow, maxRow, minColumn, maxColumn } = getCellRang(allMulCellPos);

				let cellGroup = this.node.find('.cellGroup')[0];
				let containCellPos = []; //范围内所有的单元格
				let allContainCellPos = []; //范围内所有的单元格(包含合并单元格内的坐标)
				let firstNode = '';
				cellGroup.children.map(child => {
					let pos = child.attrs.pos;
					let allPos = child.attrs.allPos;
					if (pos[0] >= minRow && pos[0] <= maxRow && pos[1] >= minColumn && pos[1] <= maxColumn) {
						containCellPos.push(pos);
						allContainCellPos.push(...allPos);
					}
				});
				// 排序
				containCellPos.sort(dealSort);
				allContainCellPos.sort(dealSort);

				let allWidth = 0;
				let allHeight = 0;
				let strokeWidth = Math.round(this.node.attrs.parameter.PenWidth * this.magnification * 100) / 100;
				containCellPos.map((item, index) => {
					let cell = cellGroup.find(`.cell_${item[0]}_${item[1]}`)[0];
					if (cell) {
						if (index == 0) {
							firstNode = cell;
						} else {
							cell.remove();
						}
					}
					if (item[0] == containCellPos[0][0]) {
						allWidth = allWidth + cell.width() + strokeWidth;
					}
					if (item[1] == containCellPos[0][1]) {
						allHeight = allHeight + cell.height() + strokeWidth;
					}
				});
				allWidth -= strokeWidth;
				allHeight -= strokeWidth;
				firstNode.attrs.allPos = containCellPos;
				firstNode.setAttrs({
					width: allWidth,
					height: allHeight,
					clipWidth: allWidth,
					clipHeight: allHeight,
					allPos: allContainCellPos
				});
				let cellBg = firstNode.find('.cellBg')[0];
				let cellText = firstNode.find('.cellText')[0];
				let cellSelect = firstNode.find('.cellSelect')[0];

				cellBg.setAttrs({
					width: allWidth,
					height: allHeight
				});
				cellText.setAttrs({
					x: firstNode.attrs.parameter.MirrorImage ? allWidth : 0,
					width: allWidth,
					height: allHeight
				});
				cellSelect.setAttrs({
					width: allWidth,
					height: allHeight
				});
				firstNode.attrs.parameter.Width = Math.round((allWidth / this.magnification) * 100) / 100;
				firstNode.attrs.parameter.Height = Math.round((allHeight / this.magnification) * 100) / 100;
				firstNode.attrs.parameter.AllKeys = containCellPos;

				let DataSourceList = [];
				cellGroup.children.map(node => {
					let cellText = node.find('.cellText')[0];
					DataSourceList.push(node.attrs.parameter);
				});
				this.node.attrs.parameter.DataSourceList = DataSourceList;
				let currentPos = firstNode.attrs.pos;
				this.node.attrs.parameter.currentPos = currentPos;
				this.node.attrs.parameter.dataSourceIndex = DataSourceList.findIndex(cell => cell.Key[0] == currentPos[0] && cell.Key[1] == currentPos[1]);
				this.node.attrs.parameter.mulCellPos = [currentPos];
				this.attrObj = JSON.parse(JSON.stringify(this.node.attrs.parameter));
				this.showTableSplit = true;
				updateTransformer(this.designLayer);
				this.designLayer.draw();
				let newAttrs = getNodeAttrs(this.node);
				recordHistory(this.$parent, oldAttrs, newAttrs, 'edit');
        this.$parent.isDraw = true
			},
			//拆分单元格
			tableSplit() {
				let oldAttrs = getNodeAttrs(this.node);

				const cellGroup = this.node.find('.cellGroup')[0];
				const CellWidth = this.node.attrs.parameter.CellWidth;
				const CellHeight = this.node.attrs.parameter.CellHeight;
				let PenWidth = Math.round(this.node.attrs.parameter.PenWidth * this.magnification * 100) / 100; //修改后
				let dataSourceIndex = this.node.attrs.parameter.dataSourceIndex;
				let celldData = this.node.attrs.parameter.DataSourceList[dataSourceIndex];
				let currentPos = this.node.attrs.parameter.currentPos;
				let cellNode = cellGroup.find(`.cell_${currentPos[0]}_${currentPos[1]}`)[0];
				let DicLineLocW = this.node.attrs.parameter.DicLineLocW;
				let DicLineLocH = this.node.attrs.parameter.DicLineLocH;
				let allPos = cellNode.attrs.allPos;
				allPos.sort(dealSort);
				let mulCellPos = [];
				let cellMultiple = this.node.attrs.parameter.cellMultiple; //是否多选状态
				for (let i = 1; i < allPos.length; i++) {
					let pos = allPos[i];

					let width = CellWidth[pos[1]];
					let height = CellHeight[pos[0]];
					//创建除第一个单元格之后的其他单元格
					//x,y无需加上线条的粗细
					// 因为cellgroup的起点为(pendWidth, pendWidth), rowLine、columnLine的起点为(0, 0), 两者正好相差一个pendWidth
					let cell = new Konva.Group({
						name: `cell_${pos[0]}_${pos[1]}`,
						x: DicLineLocW[pos[1]],
						y: DicLineLocH[pos[0]],
						width,
						height,
						pos,
						allPos: [pos], //用来记录合并单元格情况
						clipWidth: width, //可见范围
						clipHeight: 100 //可见范围
					});

					let cellBg = new Konva.Rect({
						name: 'cellBg',
						pos,
						x: 0,
						y: 0,
						width,
						height,
						fill: '#FFFFFF',
						antiBlack: false
					});
					cell.add(cellBg);

					let cellText = new Konva.Text({
						name: 'cellText',
						pos,
						x: 0,
						y: 0,
						width,
						height,
						text: '',
						fontFamily: '阿里巴巴普惠体',
						fontSize: 18 / this.$parent.multiple,
						fontStyle: '',
						textDecoration: '',
						align: getTextAlignValue(0),
						verticalAlign: 'middle',
						lineHeight: 1,
						letterSpacing: 0,
						fill: '#000000',
						scaleX: 1,
						scaleY: 1,
						wrap: 'char'
					});
					cell.setAttr('parameter', {
						AllKeys: cell.attrs.allPos, //存放合并的单元格坐标
						Key: cell.attrs.pos,
						StartX: Math.round((cell.x() / this.magnification) * 100) / 100,
						StartY: Math.round((cell.y() / this.magnification) * 100) / 100,
						Width: Math.round((cell.width() / this.magnification) * 100) / 100,
						Height: Math.round((cell.height() / this.magnification) * 100) / 100,
						FirstValue: cellText.text(),
            NormalText: cellText.text() ? cellText.text() : '',
						SequenceType: 0,
						InitialValue: '1',
						AddedValue: '',
						Quantity: '',
						ColumnName: '',
						QuerySql: '',
						TableName: '',
						ConnectionStr: '',
						DataSourceType: 0,
						AntiBlack: cellBg.attrs.antiBlack,
						MirrorImage: cellText.scaleX() < 0 ? true : false,
						FontFamily: cellText.fontFamily(),
						FontSize: dataPrecision(cellText.fontSize(), 1),
						Bold: cellText.fontStyle().indexOf('bold') > -1 ? true : false,
						Italic: cellText.fontStyle().indexOf('italic') > -1 ? true : false,
						UnderLine: cellText.textDecoration().indexOf('underline') > -1 ? true : false,
						DeleteLine: cellText.textDecoration().indexOf('line-through') > -1 ? true : false,
						TextAlign: 0,
						LineHeight: 0,
						LetterSpacing: 0,
						DataType: '2', //数据类型 0为日期 1为数字 2文本
						DataFormat: 'yyyy-MM-dd HH:mm:ss', //日期格式
						NumberFormat: '123456789', //数字形式
						SheetIndex: [0, 0],
            DataBase: [],
					});
					cell.add(cellText);

					let cellSelect = new Konva.Rect({
						name: 'cellSelect',
						pos,
						x: 0,
						y: 0,
						width,
						height,
						fill: '#3F80F5',
						opacity: 0.1
					});
					cellSelect.hide();
					if (cellMultiple) {
						mulCellPos.push(pos);
						cellSelect.show();
					}
					cell.add(cellSelect);
					cellGroup.add(cell);
				}

				let cellBg = cellNode.find('.cellBg')[0];
				let cellText = cellNode.find('.cellText')[0];
				let cellSelect = cellNode.find('.cellSelect')[0];
				let width = CellWidth[currentPos[1]];
				let height = CellHeight[currentPos[0]];
				if (cellMultiple) {
					mulCellPos.unshift(currentPos);
				}

				cellNode.setAttrs({
					width,
					height,
					allPos: [currentPos],
					clipWidth: width,
					clipHeight: height
				});
				cellNode.attrs.parameter.Width = Math.round((width / this.magnification) * 100) / 100;
				cellNode.attrs.parameter.Height = Math.round((height / this.magnification) * 100) / 100;
				cellNode.attrs.parameter.AllKeys = [currentPos];

				cellBg.setAttrs({
					width,
					height
				});
				cellText.setAttrs({
					x: cellNode.attrs.parameter.MirrorImage ? width : 0,
					width,
					height
				});
				cellSelect.setAttrs({
					width,
					height
				});
				let DataSourceList = cellGroup.children.map(cell => {
					return cell.attrs.parameter;
				});
				this.node.attrs.parameter.DataSourceList = DataSourceList;
				this.node.attrs.parameter.mulCellPos = mulCellPos;

				this.node.attrs.parameter.dataSourceIndex = DataSourceList.findIndex(cell => cell.Key[0] == currentPos[0] && cell.Key[1] == currentPos[1]);
				this.attrObj = JSON.parse(JSON.stringify(this.node.attrs.parameter));
				this.showTableSplit = false;
				updateTransformer(this.designLayer);
				this.designLayer.draw();
				let newAttrs = getNodeAttrs(this.node);
				recordHistory(this.$parent, oldAttrs, newAttrs, 'edit');
        this.$parent.isDraw = true
			},
			//表格边框粗细
			async handleTableBorder() {
				let oldPenWidth = Math.round(this.node.attrs.parameter.PenWidth * this.magnification * 100) / 100; //修改前
				let penWidth = Math.round(this.attrObj.PenWidth * this.magnification * 100) / 100; //修改后
				//防止选中元素时触发
				if (oldPenWidth == penWidth) return;
				let oldAttrs = getNodeAttrs(this.node);

				const row = this.node.attrs.parameter.Row;
				const column = this.node.attrs.parameter.Column;
				const tableRowLine = this.node.find('.tableRowLine')[0];
				const tableColumnLine = this.node.find('.tableColumnLine')[0];
				const cellGroup = this.node.find('.cellGroup')[0];

				//计算表格宽高
				let tableWidth = this.node.width() + (penWidth - oldPenWidth) * (column + 1);
				let tableHeight = this.node.height() + (penWidth - oldPenWidth) * (row + 1);
				//重置表格宽高
				this.node.setAttrs({
					width: tableWidth,
					height: tableHeight
				});

				//重置行线
				this.node.attrs.parameter.DicLineLocH = [];
				let tempY = 0;
				for (let i = 0; i <= row; i++) {
					let data = tableRowLine.children[i];
					let y = data.y() + i * (penWidth - oldPenWidth);
					data.setAttrs({
						y,
						width: tableWidth,
						height: penWidth,
						hitFunc: context => {
							context.beginPath();
							context.rect(0, y, tableWidth, penWidth);
							context.closePath();
						}
					});
					this.node.attrs.parameter.DicLineLocH.push(y);
				}
				//重置列线
				this.node.attrs.parameter.DicLineLocW = [];
				let tempX = 0;
				for (let i = 0; i <= column; i++) {
					let data = tableColumnLine.children[i];
					let x = data.x() + i * (penWidth - oldPenWidth);
					data.setAttrs({
						x,
						width: penWidth,
						height: tableHeight,
						hitFunc: context => {
							context.beginPath();
							context.rect(x, 0, penWidth, tableHeight);
							context.closePath();
						}
					});
					this.node.attrs.parameter.DicLineLocW.push(x);
				}
				//重置单元格
				cellGroup.setAttrs({
					x: penWidth,
					y: penWidth
				});

				let DataSourceList = cellGroup.children.map(cell => {
					let key = cell.attrs.pos;
					let cellBg = cell.find('.cellBg')[0];
					let cellText = cell.find('.cellText')[0];
					let cellSelect = cell.find('.cellSelect')[0];

					let x = cell.x() + key[1] * (penWidth - oldPenWidth);
					let y = cell.y() + key[0] * (penWidth - oldPenWidth);
					cell.x(x);
					cell.y(y);
					cell.attrs.parameter.StartX = Math.round((cell.x() / this.magnification) * 100) / 100;
					cell.attrs.parameter.StartY = Math.round((cell.y() / this.magnification) * 100) / 100;

					if (cell.attrs.allPos.length > 1) {
						//存在合并单元格时需调整单元格的宽高
						const { minRow, maxRow, minColumn, maxColumn } = getCellRang(cell.attrs.allPos);
						let w = cell.width() + (penWidth - oldPenWidth) * (maxColumn - minColumn);
						let h = cell.height() + (penWidth - oldPenWidth) * (maxRow - minRow);
						cell.setAttrs({
							width: w,
							height: h,
							clipWidth: w, //可见范围
							clipHeight: h //可见范围
						});
						cellBg.setAttrs({
							width: w,
							height: h
						});
						cellText.setAttrs({
							x: cell.attrs.parameter.MirrorImage ? w : 0,
							width: w,
							height: h
						});
						cellSelect.setAttrs({
							width: w,
							height: h
						});
						cell.attrs.parameter.Width = Math.round((w / this.magnification) * 100) / 100;
						cell.attrs.parameter.Height = Math.round((h / this.magnification) * 100) / 100;
					}

					return cell.attrs.parameter;
				});

				this.node.attrs.parameter.DataSourceList = DataSourceList;
				this.node.attrs.parameter.PenWidth = this.attrObj.PenWidth;
				setNodeSize(this.node);
				this.attrObj = JSON.parse(JSON.stringify(this.node.attrs.parameter));
				updateTransformer(this.designLayer);
				this.designLayer.draw();

				let newAttrs = getNodeAttrs(this.node);
				recordHistory(this.$parent, oldAttrs, newAttrs, 'edit');
        this.$parent.isDraw = true
			},
			//表格行高
			async handleTableLineHeight() {
				let dataSourceIndex = this.node.attrs.parameter.dataSourceIndex;
				let oldHeight = Math.round(this.node.attrs.parameter.DataSourceList[dataSourceIndex].Height * this.magnification * 100) / 100; //修改前行高
				let height = Math.round(this.attrObj.DataSourceList[dataSourceIndex].Height * this.magnification * 100) / 100; //修改后行高
				//防止选中元素时触发
				if (oldHeight == height) return;
				let oldAttrs = getNodeAttrs(this.node);

				const tableRowLine = this.node.find('.tableRowLine')[0];
				const tableColumnLine = this.node.find('.tableColumnLine')[0];
				const cellGroup = this.node.find('.cellGroup')[0];
				let currentPos = this.node.attrs.parameter.currentPos;

				//判断高度是否小于最小值
				let minH = Math.round(0.5 * this.magnification * 100) / 100;
				let cellHeight = this.node.attrs.parameter.CellHeight[currentPos[0]] + (height - oldHeight);
				if (cellHeight < minH) {
					this.attrObj.DataSourceList[dataSourceIndex].Height = this.node.attrs.parameter.DataSourceList[dataSourceIndex].Height;
					this.$forceUpdate();
					return;
				}
				this.node.attrs.parameter.CellHeight[currentPos[0]] = cellHeight;

				//计算表格高
				let tableHeight = this.node.height() + (height - oldHeight);
				//重置表格高
				this.node.height(tableHeight);

				//处理行线
				this.node.attrs.parameter.DicLineLocH = [];
				tableRowLine.children.map(rowLine => {
					let nameArr = rowLine.name().split('_');
					let y = rowLine.y();
					if (nameArr[1] > Number(currentPos[0])) {
						y = rowLine.y() + (height - oldHeight);
						rowLine.setAttrs({
							y,
							hitFunc: context => {
								context.beginPath();
								context.rect(0, y, rowLine.width(), rowLine.height());
								context.closePath();
							}
						});
					}
					this.node.attrs.parameter.DicLineLocH.push(y);
				});

				//更新列线高度
				tableColumnLine.children.map(columnLine => {
					columnLine.setAttrs({
						height: tableHeight,
						hitFunc: context => {
							context.beginPath();
							context.rect(columnLine.x(), 0, columnLine.width(), tableHeight);
							context.closePath();
						}
					});
				});

				let DataSourceList = cellGroup.children.map(cell => {
					let cellBg = cell.find('.cellBg')[0];
					let cellText = cell.find('.cellText')[0];
					let cellSelect = cell.find('.cellSelect')[0];
					if (cell.attrs.pos[0] > currentPos[0]) {
						//修改当前选中单元格之后每一行的y坐标
						let y = cell.y() + (height - oldHeight);
						cell.y(y);
						cell.attrs.parameter.StartY = Math.round((y / this.magnification) * 100) / 100;
					} else if (cell.attrs.pos[0] == currentPos[0]) {
						//当前选中单元格的所在行  修改单元格高度
						let h = cell.height() + (height - oldHeight);
						cell.setAttrs({
							height: h,
							clipHeight: h
						});
						cellBg.height(h);
						cellText.height(h);
						cellSelect.height(h);
						cell.attrs.parameter.Height = Math.round((h / this.magnification) * 100) / 100;
					} else {
						//判断当前选中单元格之前是否有存在包含当前行的合并单元格，修改合并单元格高度
						if (cell.attrs.allPos.length > 1) {
							let allPos = cell.attrs.allPos;
							let included = allPos.find(item => item[0] == currentPos[0]);
							if (included) {
								let h = cell.height() + (height - oldHeight);
								cell.setAttrs({
									height: h,
									clipHeight: h
								});
								cellBg.height(h);
								cellText.height(h);
								cellSelect.height(h);
								cell.attrs.parameter.Height = Math.round((h / this.magnification) * 100) / 100;
							}
						}
					}
					return cell.attrs.parameter;
				});
				this.node.attrs.parameter.DataSourceList = DataSourceList;
				setNodeSize(this.node);
				this.attrObj = JSON.parse(JSON.stringify(this.node.attrs.parameter));

				updateTransformer(this.designLayer);
				this.designLayer.draw();

				let newAttrs = getNodeAttrs(this.node);
				recordHistory(this.$parent, oldAttrs, newAttrs, 'edit');
        this.$parent.isDraw = true
			},
			//表格列宽
			async handleColumnWidth() {
				let dataSourceIndex = this.node.attrs.parameter.dataSourceIndex;
				let oldWidth = Math.round(this.node.attrs.parameter.DataSourceList[dataSourceIndex].Width * this.magnification * 100) / 100; //修改前列宽
				let width = Math.round(this.attrObj.DataSourceList[dataSourceIndex].Width * this.magnification * 100) / 100; //修改后列宽
				//防止选中元素时触发
				if (oldWidth == width) return;
				let oldAttrs = getNodeAttrs(this.node);

				const tableRowLine = this.node.find('.tableRowLine')[0];
				const tableColumnLine = this.node.find('.tableColumnLine')[0];
				const cellGroup = this.node.find('.cellGroup')[0];
				let currentPos = this.node.attrs.parameter.currentPos;

				//判断宽度是否小于最小值
				let minW = Math.round(0.5 * this.magnification * 100) / 100;
				let cellWidth = this.node.attrs.parameter.CellWidth[currentPos[1]] + (width - oldWidth);
				if (cellWidth < minW) {
					this.attrObj.DataSourceList[dataSourceIndex].Width = this.node.attrs.parameter.DataSourceList[dataSourceIndex].Width;
					this.$forceUpdate();
					return;
				}
				this.node.attrs.parameter.CellWidth[currentPos[1]] = cellWidth;

				//计算表格宽
				let tableWidth = this.node.width() + (width - oldWidth);
				//重置表格高
				this.node.width(tableWidth);

				//更新行线宽度
				tableRowLine.children.map(rowLine => {
					rowLine.setAttrs({
						width: tableWidth,
						hitFunc: context => {
							context.beginPath();
							context.rect(0, rowLine.y(), tableWidth, rowLine.height());
							context.closePath();
						}
					});
				});

				//处理列线
				this.node.attrs.parameter.DicLineLocW = [];
				tableColumnLine.children.map(columnLine => {
					let nameArr = columnLine.name().split('_');
					let x = columnLine.x();
					if (nameArr[1] > Number(currentPos[1])) {
						x = columnLine.x() + (width - oldWidth);
						columnLine.setAttrs({
							x,
							hitFunc: context => {
								context.beginPath();
								context.rect(x, 0, columnLine.width(), columnLine.height());
								context.closePath();
							}
						});
					}
					this.node.attrs.parameter.DicLineLocW.push(x);
				});

				let DataSourceList = cellGroup.children.map(cell => {
					let cellBg = cell.find('.cellBg')[0];
					let cellText = cell.find('.cellText')[0];
					let cellSelect = cell.find('.cellSelect')[0];
					if (cell.attrs.pos[1] > currentPos[1]) {
						//修改当前选中单元格之后每一列的x坐标
						let x = cell.x() + (width - oldWidth);
						cell.x(x);
						cell.attrs.parameter.StartX = Math.round((x / this.magnification) * 100) / 100;
					} else if (cell.attrs.pos[1] == currentPos[1]) {
						//当前选中单元格的所在列  修改单元格宽度
						let w = cell.width() + (width - oldWidth);
						cell.setAttrs({
							width: w,
							clipWidth: w
						});
						cellBg.width(w);
						cellText.width(w);
						cellText.x(cell.attrs.parameter.MirrorImage ? w : 0);
						cellSelect.width(w);
						cell.attrs.parameter.Width = Math.round((w / this.magnification) * 100) / 100;
					} else {
						//判断当前选中单元格之前是否有存在包含当前行的合并单元格，修改合并单元格宽度
						if (cell.attrs.allPos.length > 1) {
							let allPos = cell.attrs.allPos;
							let included = allPos.find(item => item[1] == currentPos[1]);
							if (included) {
								let w = cell.width() + (width - oldWidth);
								cell.setAttrs({
									width: w,
									clipWidth: w
								});
								cellBg.width(w);
								cellText.width(w);
								cellText.x(cell.attrs.parameter.MirrorImage ? w : 0);
								cellSelect.width(w);
								cell.attrs.parameter.Width = Math.round((w / this.magnification) * 100) / 100;
							}
						}
					}
					return cell.attrs.parameter;
				});
				this.node.attrs.parameter.DataSourceList = DataSourceList;
				setNodeSize(this.node);
				this.attrObj = JSON.parse(JSON.stringify(this.node.attrs.parameter));

				updateTransformer(this.designLayer);
				this.designLayer.draw();

				let newAttrs = getNodeAttrs(this.node);
				recordHistory(this.$parent, oldAttrs, newAttrs, 'edit');
        this.$parent.isDraw = true
			},

			//移动x坐标  StartX为左上角坐标
			handleMoveStartX() {
				let oldAttrs = getNodeAttrs(this.node);
				let startX = mmToPx(this.attrObj.StartX, 2);
				let rotation = this.node.rotation();
				let width = this.node.width() * this.node.scaleX();
				let height = this.node.height() * this.node.scaleY();
				if (this.nodeType == 'Ellipse') {
					startX = rotation == 90 || rotation == -90 ? startX + height / 2 : startX + width / 2;
				} else {
					startX = rotation == 90 ? startX + height : rotation == 180 ? startX + width : startX;
				}
				this.node.x(startX);

				this.node.attrs.parameter.StartX = this.attrObj.StartX;
				this.designLayer.draw();
				let newAttrs = getNodeAttrs(this.node);
				recordHistory(this.$parent, oldAttrs, newAttrs, 'edit');
        this.$parent.isDraw = true
			},
			//移动y坐标  StartY为左上角坐标
			handleMoveStartY() {
				let oldAttrs = getNodeAttrs(this.node);
				let startY = mmToPx(this.attrObj.StartY, 2);
				let rotation = this.node.rotation();
				let width = this.node.width() * this.node.scaleX();
				let height = this.node.height() * this.node.scaleY();
				if (this.nodeType == 'Ellipse') {
					startY = rotation == 90 || rotation == -90 ? startY + width / 2 : startY + height / 2;
				} else {
					startY = rotation == 180 ? startY + height : rotation == -90 ? startY + width : startY;
				}
				this.node.y(startY);
				this.node.attrs.parameter.StartY = this.attrObj.StartY;
				this.designLayer.draw();
				let newAttrs = getNodeAttrs(this.node);
				recordHistory(this.$parent, oldAttrs, newAttrs, 'edit');
        this.$parent.isDraw = true
			}
		}
	};
</script>
