import { message } from 'ant-design-vue';
import { drawVerticalText, getCellRang, mmToPx, pxToMm, setNodePos, setNodeSize, sumData } from '../js/common';
import { getNodeAttrs, recordHistory } from '../js/history';
import { drawCurrentNodeGUides, drawGuides, getGuides, getLineGuideStops, getObjectSnappingEdges } from '../js/objectSnapping';
var MIN_WIDTH = 20;
var MIN_HEIGHT = 20;
var MIN_LINE_WIDTH = 1;
var MIN_LINE_HEIGHT = 1;

const magnification = 3.78; //1mm 约等于3.78px（96dpi）   像素 = 毫米 * ( 分辨率 / 25.4)
const mmToPoint = 3.96; // 1mm约等于3.96点 (31.7/8)  (203dpi(pc端采用203dpi进行计算)) 用于与pc端的数据转换

//创建变换组件
const KonvaTransformer = (enabledAnchors, node) => {
	let parameter = node.attrs.parameter;
	let Transformer = new Konva.Transformer({
		id: 'transformer',
		name: parameter.Id,
		nodes: [node],
		centeredScaling: false,
		rotateEnabled: false,
		borderStroke: parameter.Lock ? '#7B89A1' : '#003591',
		borderStrokeWidth: 1,
		anchorStroke: '#003591',
		anchorFill: 'rgba(0, 53, 154, 0.8)',
		anchorSize: 20,
		anchorCornerRadius: 10,
		enabledAnchors,
		padding: parameter.Type == 'WinText' || parameter.Type == 'TimeText' ? 3 : 0,
		flipEnabled: false,
		boundBoxFunc: (oldBoundBox, newBoundBox) => {
			// console.log(oldBoundBox,newBoundBox)
			return newBoundBox;
		},
		anchorDragBoundFunc: (oldAbsPos, newAbsPos, movingAnchorName, event) => {
			parameter = node.attrs.parameter;
			let nodePos = node.getAbsolutePosition();

			if (parameter.Type != 'Line' && parameter.Type != 'LineHorizontal' && parameter.Type != 'LineVertical') {
				let rotation = node.rotation();
				let ratio = ((node.height() * node.scaleY()) / node.width()) * node.scaleX();
				MIN_HEIGHT = ratio * MIN_WIDTH < MIN_WIDTH ? MIN_WIDTH : ratio * MIN_WIDTH;
				if (movingAnchorName == 'middle-right') {
          switch (rotation) {
						case 90:
							if (newAbsPos.y - nodePos.y < MIN_WIDTH) {
								return {
									x: newAbsPos.x,
									y: oldAbsPos.y
								};
							}
							break;
						case 180:
							if (nodePos.x - newAbsPos.x  < MIN_WIDTH) {
                return {
                  x: oldAbsPos.x,
                  y: newAbsPos.y
                };
              }
							break;
						case -90:
							if (nodePos.y - newAbsPos.y < MIN_WIDTH) {
								return {
									x: newAbsPos.x,
									y: oldAbsPos.y
								};
							}
							break;
						default:
							if (newAbsPos.x - nodePos.x < MIN_WIDTH) {
                return {
                  x: oldAbsPos.x,
                  y: newAbsPos.y
                };
              }
							break;
          }
				} else if (movingAnchorName == 'bottom-center') {
					let min_h = MIN_HEIGHT;
					if (parameter.Type == 'WinText' || parameter.Type == 'TimeDate') {
						let fontSize = Math.round((parameter.FontSize / mmToPoint) * magnification * 100) / 100;
						fontSize *= 1.1;
						min_h = fontSize;
					}
					switch (rotation) {
						case 90:
							if (nodePos.x - newAbsPos.x < min_h) {
								return {
									x: oldAbsPos.x,
									y: newAbsPos.y
								};
							}
							break;
						case 180:
							if (nodePos.y - newAbsPos.y < min_h) {
								return {
									x: newAbsPos.x,
									y: oldAbsPos.y
								};
							}
							break;
						case -90:
							if (newAbsPos.x - nodePos.x < min_h) {
								return {
									x: oldAbsPos.x,
									y: newAbsPos.y
								};
							}
							break;
						default:
							if (newAbsPos.y - nodePos.y < min_h) {
								return {
									x: newAbsPos.x,
									y: oldAbsPos.y
								};
							}
							break;
					}
				} else if (movingAnchorName == 'bottom-right') {
					let x = newAbsPos.x;
					let y = newAbsPos.y;
					switch (rotation) {
						case 90:
							if (nodePos.x - newAbsPos.x < MIN_WIDTH) {
								x = nodePos.x - MIN_WIDTH;
							}
							if (newAbsPos.y - nodePos.y < MIN_HEIGHT) {
								y = nodePos.y + MIN_HEIGHT;
							}
							break;
						case 180:
							if (nodePos.x - newAbsPos.x < MIN_WIDTH) {
								x = nodePos.x - MIN_WIDTH;
							}
							if (nodePos.y - newAbsPos.y < MIN_HEIGHT) {
								y = nodePos.y - MIN_HEIGHT;
							}
							break;
						case -90:
							if (newAbsPos.x - nodePos.x < MIN_WIDTH) {
								x = nodePos.x + MIN_WIDTH;
							}
							if (nodePos.y - newAbsPos.y < MIN_HEIGHT) {
								y = nodePos.y - MIN_HEIGHT;
							}
							break;
						default:
							if (newAbsPos.x - nodePos.x < MIN_WIDTH) {
								x = nodePos.x + MIN_WIDTH;
							}
							if (newAbsPos.y - nodePos.y < MIN_HEIGHT) {
								y = nodePos.y + MIN_HEIGHT;
							}
							break;
					}
					return { x, y };
				}
				return newAbsPos;
			} else {
				if (parameter.Type == 'LineHorizontal' && newAbsPos.x - nodePos.x < MIN_LINE_WIDTH) {
					return {
						x: oldAbsPos.x,
						y: newAbsPos.y
					};
				}
				if (parameter.Type == 'LineVertical' && newAbsPos.y - nodePos.y < MIN_LINE_HEIGHT) {
					return {
						x: newAbsPos.x,
						y: oldAbsPos.y
					};
				}
				if (parameter.Type == 'Line' && (parameter.LineDirection == 'LeftLine' || parameter.LineDirection == 'RightLine')) {
					let x = newAbsPos.x;
					let y = newAbsPos.y;
					if (newAbsPos.x - nodePos.x < MIN_LINE_WIDTH) {
						x = nodePos.x + MIN_WIDTH;
					}
					if (newAbsPos.y - nodePos.y < MIN_LINE_HEIGHT) {
						y = nodePos.y + MIN_HEIGHT;
					}
					return { x, y };
				}
				return newAbsPos;
			}
		},
		anchorStyleFunc: anchor => {
			// console.log(anchor,123)
			anchor.cornerRadius(10);
			let offset = parameter.Type == 'WinText' || parameter.Type == 'TimeText' ? 0 : 3;
			if (parameter.Type != 'Line' && parameter.Type != 'LineHorizontal' && parameter.Type != 'LineVertical') {
				if (anchor.hasName('bottom-center')) {
					anchor.height(6);
					anchor.offsetY(offset);
					anchor.width(24);
					anchor.offsetX(12);
				}
				if (anchor.hasName('middle-right')) {
					anchor.height(24);
					anchor.offsetY(12);
					anchor.width(6);
					anchor.offsetX(offset);
				}
			}
		}
	});
	return Transformer;
};

const getEnabledAnchors = node => {
	let enabledAnchors = [];
	if (node.attrs.parameter && node.attrs.parameter.Lock) {
		return enabledAnchors;
	}
	switch (node.attrs.type) {
		case 'WinText':
		case 'TimeText':
			if (node.attrs.parameter.AutoWrap) {
				if (node.attrs.parameter.TextArrange == 1) {
					enabledAnchors = ['bottom-center'];
				} else {
					enabledAnchors = ['middle-right'];
				}
			} else {
				enabledAnchors = [];
			}
			break;
		case 'GeneralBarcode':
		case 'QRCode':
			enabledAnchors = ['bottom-right'];
			break;
		case 'LineHorizontal':
			enabledAnchors = ['middle-right'];
			break;
		case 'LineVertical':
			enabledAnchors = ['bottom-center'];
			break;
		case 'Line':
			enabledAnchors = ['middle-right', 'bottom-center'];
			break;
		case 'Image':
			if (node.attrs.parameter.ISParticipating) {
				enabledAnchors = ['bottom-right'];
			} else {
				enabledAnchors = ['middle-right', 'bottom-center', 'bottom-right'];
			}
			break;
		case 'Rectangle':
		case 'RoundRectangle':
		case 'Ellipse':
		case 'Diamond':
		case 'Triangle':
			enabledAnchors = ['middle-right', 'bottom-center', 'bottom-right'];
			break;
		case 'Table':
			enabledAnchors = ['middle-right', 'bottom-center'];
			break;
		default:
			enabledAnchors = ['bottom-right'];
			break;
	}
	return enabledAnchors;
};

//销毁变换组件
const destroyTransformer = designLayer => {
	const transformers = designLayer.find('Transformer');
	transformers.forEach(transformer => {
		// let node = transformer.nodes()
		// node[0].draggable(false)
		transformer.destroy();
	});
};

//更新变换组件
const updateTransformer = designLayer => {
	const transformers = designLayer.find('Transformer');
	transformers.forEach(transformer => {
		transformer.forceUpdate();
	});
};

//控件事件监听处理
const TransformerListening = (event, node) => {
	var _this = event;
	let oldAttrs = '';
	let newAttrs = '';
	let isTouchMove = false;
	node.on('touchstart mousedown', ev => {
		// console.log('mousedown');
		let buttons = ev.evt.buttons;
		if (buttons == 2) return;

		// console.log("touchstart", ev);
		console.log(node);
		_this.designArea.draggable(false);
		isTouchMove = false;
		//多选状态下，不执行
		if (ev.evt.ctrlKey) return;
		if (_this.$refs.toolAreaRef.multipleSelection) return;

		//单选状态下
		if (_this.selectNode && _this.selectNode.id() != node.id()) {
			let oldNode = _this.designLayer.find('#' + _this.selectNode.id())[0];
			if (oldNode) {
				oldNode.draggable(false);
			}
			_this.selectNode = '';
			_this.elementAttr = {};
			let cellSelectArr = _this.designLayer.find('.cellSelect');
			cellSelectArr.map(item => {
				item.hide();
			});
		}

		if (node.attrs.parameter.Lock) {
			node.draggable(false);
			message.error(_this.$t(`tips.tip112`));
			_this.$forceUpdate();
		} else {
			node.draggable(true);
		}

		destroyTransformer(_this.designLayer);
		_this.$refs.toolAreaRef.cancelMultipleSelection();

		_this.selectNode = node;
		if (node.attrs.type == 'Table') {
			let parent = ev.target.getParent();
			let DataSourceList = node.attrs.parameter.DataSourceList;
			if (node.attrs.parameter.cellMultiple) {
				//表格单元格多选
        let currentPos = parent.attrs.pos;
				node.attrs.parameter.currentPos = currentPos;
				node.attrs.parameter.dataSourceIndex = DataSourceList.findIndex(cell => cell.Key[0] == currentPos[0] && cell.Key[1] == currentPos[1]);
				let cell = node.find(`.cell_${currentPos[0]}_${currentPos[1]}`)[0];
				let cellSelect = cell.find('.cellSelect')[0];
				let data = DataSourceList[node.attrs.parameter.dataSourceIndex];
				let isPushIndex = node.attrs.parameter.mulCellPos.findIndex(pos => pos[0] == data.Key[0] && pos[1] == data.Key[1]);
				if (isPushIndex == -1) {
					//选中
					node.attrs.parameter.mulCellPos.push(data.Key);
					cellSelect.show();
          _this.$nextTick(() => {
            if (_this.$refs.attrsDiaRef) { 
              if (node.attrs.parameter.mulCellPos.length == 1 && cell.attrs.parameter.AllKeys.length > 1) {
                _this.$refs.attrsDiaRef.showTableSplit = true;
              } else {
                _this.$refs.attrsDiaRef.showTableSplit = false;
              }
            }
					});
				} else {
					//取消选中
					cellSelect.hide();
					node.attrs.parameter.mulCellPos.splice(isPushIndex, 1);
          if (cell.attrs.parameter.AllKeys.length > 1) {
            if (_this.$refs.attrsDiaRef) { 
              _this.$refs.attrsDiaRef.showTableSplit = false;
            }
					}
					//剩下一个选中的单元格
					if (node.attrs.parameter.mulCellPos.length == 1) {
						let currentPos = node.attrs.parameter.mulCellPos[0];
						let cell = node.find(`.cell_${currentPos[0]}_${currentPos[1]}`)[0];
						if (cell.attrs.parameter.AllKeys.length > 1) {
							//存在合并单元格
							node.attrs.parameter.currentPos = currentPos;
							node.attrs.parameter.cellIndex = DataSourceList.findIndex(cell => cell.Key[0] == currentPos[0] && cell.Key[1] == currentPos[1]);
              _this.$nextTick(() => {
                if (_this.$refs.attrsDiaRef) { 
                  _this.$refs.attrsDiaRef.showTableSplit = true;
                }
								
							});
						} else {
              _this.$nextTick(() => {
                if (_this.$refs.attrsDiaRef) {
                  _this.$refs.attrsDiaRef.showTableSplit = false;
                }
								
							});
						}
					}
				}
			} else {
				//表格单元格单选
				//取消之前的选中单元格
				let oldCurrentPos = node.attrs.parameter.currentPos;
				let oldCell = node.find(`.cell_${oldCurrentPos[0]}_${oldCurrentPos[1]}`)[0];
				let oldSelect = oldCell.find('.cellSelect')[0];
				oldSelect.hide();
				//显示选中单元格
				let currentPos = parent.attrs.pos;
				node.attrs.parameter.currentPos = currentPos;
				node.attrs.parameter.dataSourceIndex = DataSourceList.findIndex(cell => cell.Key[0] == currentPos[0] && cell.Key[1] == currentPos[1]);
				let cell = node.find(`.cell_${currentPos[0]}_${currentPos[1]}`)[0];
				let cellSelect = cell.find('.cellSelect')[0];
				cellSelect.show();
				_this.$nextTick(() => {
					if (cell.attrs.parameter.AllKeys.length > 1) {
						_this.$refs.attrsDiaRef.showTableSplit = true;
					} else {
						_this.$refs.attrsDiaRef.showTableSplit = false;
					}
				});
			}
		}

		setNodePos(_this.selectNode);
		setNodeSize(_this.selectNode);
		_this.elementAttr = JSON.parse(JSON.stringify(_this.selectNode.attrs.parameter));
		let transformer = KonvaTransformer(getEnabledAnchors(node), _this.selectNode);
		_this.elementGroup.add(transformer);

		_this.designLayer.draw();
	});

	node.on('touchmove mousemove', ev => {
		// console.log("touchmove", ev);
		isTouchMove = true;
	});

	node.on('touchend mouseup', ev => {
		// console.log("touchend", ev);
		isTouchMove = false;
	});

	node.on('tap click', ev => {
		// console.log("tap", ev);
		//移动过程中不执行
		if (isTouchMove) return;
		//多选状态下
		if (ev.evt.ctrlKey) {
			//按住ctrl （多选状态）
			_this.$refs.toolAreaRef.multipleSelection = true;
			//判断元素是否已经被选中
			let nodeIndex = _this.$refs.toolAreaRef.selectionElement.findIndex(item => item.id() == node.id());

			if (_this.selectNode && _this.selectNode.id() != node.id()) {
				//如果已经存在选中的元素，将该元素加入到多选数组中
				let transformer = _this.designLayer.find('.' + _this.selectNode.id())[0];
				if (transformer) {
					transformer.enabledAnchors([]);
				}
				_this.$refs.toolAreaRef.selectionElement.push(_this.selectNode);
			}

			if (nodeIndex == -1) {
				//选中
				if (node.attrs.parameter.Lock) {
					message.error(_this.$t(`tips.tip112`));
					node.draggable(false);
				} else {
					node.draggable(true);
				}

				_this.$refs.toolAreaRef.selectionElement.push(node);
				let transformer = KonvaTransformer([], node);
				_this.elementGroup.add(transformer);
			} else {
				//取消选中
				node.draggable(false);
				let transformer = _this.elementGroup.children.find(item => {
					if (item instanceof Konva.Transformer) {
						return item.name() == node.id();
					}
				});
				if (transformer) {
					transformer.destroy();
				}
				_this.$refs.toolAreaRef.selectionElement.splice(nodeIndex, 1);
			}

			_this.selectNode = '';
			_this.elementAttr = {};
			// console.log('多选数组', _this.$refs.toolAreaRef.selectionElement);
		}
	});

	let isDrag = false;
	let attrs = [];
	let clientX = 0;
	let clientY = 0;
	let multipleSelection = false;
	let selectionElement = [];

	node.on('dragstart', ev => {
		// console.log('dragstart', ev);
		multipleSelection = _this.$refs.toolAreaRef.multipleSelection;
		selectionElement = _this.$refs.toolAreaRef.selectionElement;
		if (multipleSelection) {
			//记录初始位置
			selectionElement.map(item => {
				attrs.push({
					x: item.x(),
					y: item.y()
				});
			});
			clientX = ev.evt.clientX || ev.evt.touches[0].clientX;
			clientY = ev.evt.clientY || ev.evt.touches[0].clientY;
		}

		drawCurrentNodeGUides(node, _this);

		isDrag = false;
		oldAttrs = getNodeAttrs(node);
	});

	node.on('dragmove', ev => {
		// console.log("dragmove", ev);
		if (multipleSelection) {
			let newClientX = ev.evt.clientX || ev.evt.touches[0].clientX;
			let newClientY = ev.evt.clientY || ev.evt.touches[0].clientY;
			let distX = (newClientX - clientX) / _this.multiple;
			let distY = (newClientY - clientY) / _this.multiple;
			selectionElement.forEach((item, index) => {
				if (item.id() != node.id() && !item.attrs.parameter.Lock) {
					item.x(attrs[index].x + distX);
					item.y(attrs[index].y + distY);
				}
			});
		}
		if (!multipleSelection && !_this.selectNode) {
			return;
		}

		//控件移动过程中处理并绘制辅助线
		let x = node.x();
		let y = node.y();
		let width = node.width() * node.scaleX();
		let height = node.height() * node.scaleY();
		let x1, x2, y1, y2;
		x1 = node.rotation() == 90 ? x - height : node.rotation() == 180 ? x - width : x;
		y1 = node.rotation() == 180 ? y - height : node.rotation() == -90 ? y - width : y;

		x2 = node.rotation() == 90 || node.rotation() == -90 ? x1 + height : x1 + width;
		y2 = node.rotation() == 90 || node.rotation() == -90 ? y1 + width : y1 + height;

		let type = node.attrs.type;
		if (type == 'Ellipse') {
			x1 = node.rotation() == 90 || node.rotation() == -90 ? x - height / 2 : x - width / 2;
			y1 = node.rotation() == 90 || node.rotation() == -90 ? y - width / 2 : y - height / 2;

			x2 = node.rotation() == 90 || node.rotation() == -90 ? x + height / 2 : x + width / 2;
			y2 = node.rotation() == 90 || node.rotation() == -90 ? y + width / 2 : y + height / 2;
		}

		const node_line_left = _this.designArea.find('.node-line-left')[0];
		const node_line_right = _this.designArea.find('.node-line-right')[0];
		const node_line_top = _this.designArea.find('.node-line-top')[0];
		const node_line_bottom = _this.designArea.find('.node-line-bottom')[0];
		node_line_left.points([x1, -6000, x1, 6000]);
		node_line_right.points([x2, -6000, x2, 6000]);
		node_line_top.points([-6000, y1, 6000, y1]);
		node_line_bottom.points([-6000, y2, 6000, y2]);

		// 清除所有的线
		const guid_line = _this.designLayer.find('.guid-line');
		guid_line.forEach(item => {
			item.destroy();
		});

		// 查找可能的捕捉线
		var lineGuideStops = getLineGuideStops(node, _this);
		// 查找当前对象的捕捉点
		var itemBounds = getObjectSnappingEdges(node, _this);
		// 查找可以捕捉当前对象的范围
		var guides = getGuides(lineGuideStops, itemBounds, _this);
		if (!guides.length) {
			return;
		}
		drawGuides(guides, _this);
		isDrag = true;

		setNodePos(node);
		if (_this.selectNode) {
			_this.elementAttr = JSON.parse(JSON.stringify(node.attrs.parameter));
    }
    _this.isDraw = true
		_this.$forceUpdate();
	});

	node.on('dragend', ev => {
		// console.log("dragend", ev);
		attrs = [];
		clientX = 0;
		clientY = 0;

		//控件移动结束删除辅助线
		const node_line_left = _this.designArea.find('.node-line-left');
		const node_line_right = _this.designArea.find('.node-line-right');
		const node_line_top = _this.designArea.find('.node-line-top');
		const node_line_bottom = _this.designArea.find('.node-line-bottom');
		const guid_line = _this.designLayer.find('.guid-line');
		node_line_left.forEach(item => {
			item.destroy();
		});
		node_line_right.forEach(item => {
			item.destroy();
		});
		node_line_top.forEach(item => {
			item.destroy();
		});
		node_line_bottom.forEach(item => {
			item.destroy();
		});
		guid_line.forEach(item => {
			item.destroy();
		});

		_this.designLayer.draw();

		if (isDrag) {
			newAttrs = getNodeAttrs(node);
			recordHistory(_this, oldAttrs, newAttrs, 'edit');
		}
		isDrag = false;
		oldAttrs = '';
		newAttrs = '';
	});

	let isTransform = false;
	node.on('transformstart', ev => {
		// console.log("transformstart", ev);
		isTransform = false;
		oldAttrs = getNodeAttrs(node);
	});

	let startX = '';
	let startY = '';
	let points = '';
	node.on('transform', async ev => {
		// console.log('transform', ev);
		switch (node.attrs.type) {
			case 'WinText':
			case 'TimeText':
				if (node.attrs.parameter.TextArrange == 1) {
					if (node.attrs.parameter.TextAlign != 4) {
						let tempText = node.find('.tempText')[0];
						let height = node.height() * node.scaleY();
						let fontSize = Math.round((node.attrs.parameter.FontSize / mmToPoint) * magnification * 100) / 100;
						let h = Math.max(height, fontSize * 1.1);
						node.height(h);
						tempText.height(h);
						node.getTag().height(h);
						node.attrs.parameter.BoxWidth = pxToMm(h, 2);
						node.scaleX(1);
						node.scaleY(1);
						_this.designLayer.draw();
						drawVerticalText(node, _this.designLayer);
					} else {
						setNodeSize(node);
					}
				} else {
					if (node.attrs.parameter.TextAlign != 4) {
						let MIN_TEXT_WIDTH = 2 * node.getText().padding() + node.getText().fontSize() * 1.1;
						let width = Math.max(node.width() * node.scaleX(), MIN_TEXT_WIDTH);
						node.width(width);
						node.getText().width(width);
						node.getTag().width(width);
						node.attrs.parameter.BoxWidth = pxToMm(width, 2);
						node.scaleX(1);
						node.scaleY(1);
						if (node.getText().scaleX() < 0) {
							//如果文本内容镜像，需处理x坐标
							node.getText().x(node.getText().width());
						}
					} else {
						setNodeSize(node);
					}
				}
				break;
			case 'LineHorizontal':
			case 'LineVertical':
			case 'Line':
				points = node.points();
				if (node.attrs.type == 'LineHorizontal') {
					points[2] = node.width() * node.scaleX();
				} else if (node.attrs.type == 'LineVertical') {
					points[3] = node.height() * node.scaleY();
				} else {
					if (node.attrs.parameter.LineDirection == 'RightLine') {
						points[2] = node.width() * node.scaleX();
						points[3] = node.height() * node.scaleY();
					} else {
						points[1] = node.height() * node.scaleY();
						points[2] = node.width() * node.scaleX();
					}
				}
				node.points(points);
				node.scaleX(1);
				node.scaleY(1);
				startX = mmToPx(node.attrs.parameter.StartX, 2);
				startY = mmToPx(node.attrs.parameter.StartY, 2);
				node.x(startX);
				node.y(startY);
				break;
			case 'Rectangle':
			case 'RoundRectangle':
				node.width(node.width() * node.scaleX());
				node.height(node.height() * node.scaleY());
				startX = mmToPx(node.attrs.parameter.StartX, 2);
				startY = mmToPx(node.attrs.parameter.StartY, 2);
				node.x(startX);
				node.y(startY);
				node.scaleX(1);
				node.scaleY(1);
				break;
			case 'Ellipse':
				node.width(node.width() * node.scaleX());
				node.height(node.height() * node.scaleY());
				node.radiusX(node.radiusX() * node.scaleX());
				node.radiusY(node.radiusY() * node.scaleY());
				node.scaleX(1);
				node.scaleY(1);
				break;
			case 'Diamond':
				points = node.points();
				points[1] = (node.height() * node.scaleY()) / 2;
				points[2] = (node.width() * node.scaleX()) / 2;
				points[4] = node.width() * node.scaleX();
				points[5] = (node.height() * node.scaleY()) / 2;
				points[6] = (node.width() * node.scaleX()) / 2;
				points[7] = node.height() * node.scaleY();

				node.width(node.width() * node.scaleX());
				node.height(node.height() * node.scaleY());
				startX = mmToPx(node.attrs.parameter.StartX, 2);
				startY = mmToPx(node.attrs.parameter.StartY, 2);
				node.x(startX);
				node.y(startY);
				node.scaleX(1);
				node.scaleY(1);
				break;
			case 'Triangle':
				points = node.points();
				points[1] = node.height() * node.scaleY();
				points[2] = (node.width() * node.scaleX()) / 2;
				points[4] = node.width() * node.scaleX();
				points[5] = node.height() * node.scaleY();

				node.width(node.width() * node.scaleX());
				node.height(node.height() * node.scaleY());
				startX = mmToPx(node.attrs.parameter.StartX, 2);
				startY = mmToPx(node.attrs.parameter.StartY, 2);
				node.x(startX);
				node.y(startY);
				node.scaleX(1);
				node.scaleY(1);
				break;
			case 'Table':
				scaleTable(_this, node);
				break;
		}
		setNodeSize(node);
		if (_this.selectNode) {
			_this.elementAttr = JSON.parse(JSON.stringify(node.attrs.parameter));
    }
    _this.designLayer.draw();
    _this.isDraw = true
		isTransform = true;
	});

	node.on('transformend', ev => {
		// console.log("transformend", ev);
		if (isTransform) {
			switch (node.attrs.type) {
				case 'Table':
					_this.elementAttr = JSON.parse(JSON.stringify(node.attrs.parameter));
					_this.$forceUpdate();
					break;
			}
			newAttrs = getNodeAttrs(node);
			recordHistory(_this, oldAttrs, newAttrs, 'edit');
		}
		isTransform = false;
		oldAttrs = '';
		newAttrs = '';
	});
};

//缩放表格
const scaleTable = (_this, node) => {
	let strokeWidth = Math.round(node.attrs.parameter.PenWidth * magnification * 100) / 100;
	let row = node.attrs.parameter.Row;
	let column = node.attrs.parameter.Column;
	let old_tableWidth = node.width();
	let old_tableHeight = node.height();
	let new_tableWidth = node.width() * node.scaleX();
	let new_tableHeight = node.height() * node.scaleY();
	//限制最小宽高
	let min_cellSize = 2; //最小单元格尺寸
	let min_tableWidth = column * min_cellSize + strokeWidth * (column + 1);
	let min_tableHeight = row * min_cellSize + strokeWidth * (row + 1);
	if (new_tableWidth < min_tableWidth) {
		new_tableWidth = min_tableWidth;
	}
	if (new_tableHeight < min_tableHeight) {
		new_tableHeight = min_tableHeight;
	}

	let dist_width = new_tableWidth - old_tableWidth;
	let dist_height = new_tableHeight - old_tableHeight;

	//计算除边线以外的单元格总宽高
	let allCellWidth = old_tableWidth - strokeWidth * (column + 1);
	let allCellHeight = old_tableHeight - strokeWidth * (row + 1);

	//按照单元格比例计算出每列的缩放值
	let distXArr = [];
	for (let i = 0; i < node.attrs.parameter.CellWidth.length; i++) {
		let w = node.attrs.parameter.CellWidth[i];
		let val = (w / allCellWidth) * dist_width;
		distXArr.push(val);
		node.attrs.parameter.CellWidth[i] = w + val;
	}
	//按照单元格比例计算出每行的缩放值
	let distYArr = [];
	for (let i = 0; i < node.attrs.parameter.CellHeight.length; i++) {
		let h = node.attrs.parameter.CellHeight[i];
		let val = (h / allCellHeight) * dist_height;
		distYArr.push(val);
		node.attrs.parameter.CellHeight[i] = h + val;
	}

	let DicLineLocW = []; //列线的坐标（线的左上角）
	let DicLineLocH = []; //行线的坐标（线的左上角）

	//重置行线的参数
	let tableRowLine = node.find('.tableRowLine')[0];
	let sunDistY = 0;
	tableRowLine.children.forEach((item, index) => {
		let y = item.y() + sunDistY;
		sunDistY += distYArr[index];
		item.setAttrs({
			y,
			width: new_tableWidth,
			hitFunc: context => {
				context.beginPath();
				context.rect(0, y, new_tableWidth, item.height());
				context.closePath();
			}
		});
		DicLineLocH.push(y);
	});

	//重置列线的参数
	let tableColumnLine = node.find('.tableColumnLine')[0];
	let sunDistX = 0;
	tableColumnLine.children.forEach((item, index) => {
		let x = item.x() + sunDistX;
		sunDistX += distXArr[index];
		item.setAttrs({
			x,
			height: new_tableHeight,
			hitFunc: context => {
				context.beginPath();
				context.rect(x, 0, item.width(), new_tableHeight);
				context.closePath();
			}
		});
		DicLineLocW.push(x);
	});
	node.attrs.parameter.DicLineLocW = JSON.parse(JSON.stringify(DicLineLocW));
	node.attrs.parameter.DicLineLocH = JSON.parse(JSON.stringify(DicLineLocH));

	//重置单元格位置
	let cellGroup = node.find('.cellGroup')[0];
	let DataSourceList = cellGroup.children.map(cell => {
		let cellBg = cell.find('.cellBg')[0];
		let cellText = cell.find('.cellText')[0];
		let cellSelect = cell.find('.cellSelect')[0];
		let nameArr = cell.name().split('_');
		//nameArr[1] 行坐标   nameArr[2]列坐标
		let allPos = cell.attrs.allPos;
		let distW = distXArr[nameArr[2]];
		let distH = distYArr[nameArr[1]];
		if (allPos.length > 0) {
			//合并的单元格
			const { minRow, maxRow, minColumn, maxColumn } = getCellRang(allPos);
			distW = sumData(distXArr.slice(minColumn, maxColumn + 1));
			distH = sumData(distYArr.slice(minRow, maxRow + 1));
		}
		let rowLine = node.find(`.rowLine_${nameArr[1]}`)[0];
		let columnLine = node.find(`.columnLine_${nameArr[2]}`)[0];
		//无需加上线条的粗细
		// 因为cellgroup的起点为(pendWidth, pendWidth), rowLine、columnLine的起点为(0, 0), 两者正好相差一个pendWidth
		let x = columnLine.x();
		let y = rowLine.y();

		let width = cell.width() + distW;
		let height = cell.height() + distH;

		cell.setAttrs({
			x,
			y,
			width,
			height,
			clipWidth: width,
			clipHeight: height
		});
		cellBg.setAttrs({
			width,
			height
		});
		cellText.setAttrs({
			x: cell.attrs.parameter.MirrorImage ? width : 0,
			width,
			height
		});
		cellSelect.setAttrs({
			width,
			height
		});

		cell.attrs.parameter.StartX = Math.round((cell.x() / magnification) * 100) / 100;
		cell.attrs.parameter.StartY = Math.round((cell.y() / magnification) * 100) / 100;
		cell.attrs.parameter.Width = Math.round((cell.width() / magnification) * 100) / 100;
		cell.attrs.parameter.Height = Math.round((cell.height() / magnification) * 100) / 100;

		return cell.attrs.parameter;
	});

	node.attrs.parameter.DataSourceList = DataSourceList;

	node.width(new_tableWidth);
	node.height(new_tableHeight);
	node.scaleX(1);
	node.scaleY(1);
	_this.designLayer.batchDraw();
};

//修改原点为中心
const changeOriginToCenter = node => {
	if (node.attrs.type == 'Ellipse') {
		return;
  }
	node.setAttrs({
		offsetX: node.width() / 2,
		offsetY: node.height() / 2
	});
	let x1 = node.x() + (node.offsetX() * node.scaleX() * Math.cos((node.rotation() * Math.PI) / 180) - node.offsetY() * node.scaleY() * Math.sin((node.rotation() * Math.PI) / 180));
	let y1 = node.y() + (node.offsetY() * node.scaleY() * Math.cos((node.rotation() * Math.PI) / 180) + node.offsetX() * node.scaleX() * Math.sin((node.rotation() * Math.PI) / 180));
	node.x(x1);
  node.y(y1);
};

//修改原点为左上角
const changeOriginToTopleft = node => {
	if (node.attrs.type == 'Ellipse') {
		return;
  }
	let x1 = node.x() - (node.offsetX() * node.scaleX() * Math.cos((node.rotation() * Math.PI) / 180) - node.offsetY() * node.scaleY() * Math.sin((node.rotation() * Math.PI) / 180));
	let y1 = node.y() - (node.offsetY() * node.scaleY() * Math.cos((node.rotation() * Math.PI) / 180) + node.offsetX() * node.scaleX() * Math.sin((node.rotation() * Math.PI) / 180));
	node.offsetX(0);
	node.offsetY(0);
	node.x(x1);
  node.y(y1);
};
export { KonvaTransformer, TransformerListening, changeOriginToCenter, changeOriginToTopleft, destroyTransformer, getEnabledAnchors, updateTransformer };
