import { BASE_URL, HAMA_URL, NET_URL } from "@/services/api";
import { request } from "@/utils/request";
import { hmSignature } from "@/utils/signature";

export async function axios(url, params, config, method = "post") {
  return request(BASE_URL + url, params, method, config);
}

export async function axioshanma(url, params, config, method = "post") {
  let time_stamp = Date.parse(new Date());
  let obj = {
    appid: "764e02x4-d252-11eb-af05-6cdc5827",
    time_stamp,
    signature: hmSignature(time_stamp),
  };
  let newParmas;
  if (params) {
    newParmas = Object.assign(params, obj);
  } else {
    newParmas = obj;
  }
  return request(HAMA_URL + url, newParmas, method, config);
}

export async function axiosnet (url, params, config, method = "post") {
  return request(NET_URL + url, params, method, config);
}
export async function qiniuApi(url, params, config, method = "post") {
  return request(url, params, method, config);
}
export default {
  axios,
  axioshanma,
  axiosnet,
  qiniuApi,
};
