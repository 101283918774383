export default {
	navbar: {
		login: "Login",
		home: "Home",
		register: "Register",
		shareTemplate: "Industry Template",
		cloudTemplate: "Cloud templates",
		cloudData: "Cloud data",
		printSetting: "Print settings",
		moreSetting: "More settings",
		user: "Personal center",
		unit: "Unit",
		logout: "Sign out",
		error404: "Error404",
		renamePassword: "Change Password",
		editLabel: "Edit label",
		member: "Member Center",
		suggest: "Feedback",
		download: "Download Software",
		help: "Help Center",
		bind: "Bind the account",
    onlinetool: 'Utilities',
    expansion: 'Expand the cloud space',
    pdfPrint: 'Document print',
	},
	button: {
		login: "Login",
		register: "Register",
		getCode: "Get the verification code",
		print: "Print now",
		editLabel: "Edit the label",
		copyLabel: "Copy the label",
		rename: "Rename",
		delete: "Delete",
		createLabel: "Create a new label",
		move: "Move",
		createFile: "Create a new folder",
		upload: "Upload",
		cancel: "Cancel",
		confirm: "Confirm",
		save: "Save",
		create: "Create",
		saveToCloudTem: "Save to the cloud template",
		download: "Download",
		updateAvatar: "Modify",
		forgetPass: "Forget your password",
		set: "Set up",
		goRegister: "Register now",
		goLogin: "Go to sign in",
		backLogin: "Return to sign in",
		reset: "Reset",
		useEmail: "Register by email",
		usePhone: "Register with your phone number",
		labelset: "Label settings",
		printLabel: "Print",
		exitEdit: "Exit",
		choose: "Choose",
		help: "Help",
		downloadPC: "Download Software",
		Renewal: "Renewal",
		Upgrade: "Upgrade membership",
		bingLogin: "Bind and login",
		upgradeMember: "Upgrade membership",
		exchange: "Redemption code",
    exchangeNow: 'Redeem now',
    expansion: 'Expansion',
    abandon: 'Abandon',
    logout: 'Account cancellation',
	},
	lang: {
		chinese: "Chinese Simplified",
		english: "English",
		portuguese: "Portuguesee",
		Spanish: "Spanish",
		Russian: "Russian",
	},
	tips: {
		tip1: "Login successful",
		tip2: "Are you sure you want to delete all the data in the folder and folder?",
		tip3: "Are you sure you want to delete this data?",
		tip4: "Are you sure you want to remove this label?",
		tip5: "Please enter the label name/size",
		tip6: "Please enter the industry",
		tip7: "Please enter your mobile phone number/email address",
		tip8: "Please enter the verification code",
		tip9: "Please set a new password",
		tip10: "Please confirm the new password",
		tip11: "Please enter your password",
		tip12: "Please enter your username",
		tip13: "Please enter your phone number",
		tip14: "Please set your password",
		tip15: "Please confirm your password",
		tip16: "Please select the industry",
		tip17: "Please enter the mailbox",
		tip18: "Please enter the correct mobile phone number/email address",
		tip19: "Please check and read agreement",
		tip20: "Please enter the correct phone number",
		tip21: "Please enter the correct mailbox",
		tip22: "The password is inconsistent",
		tip23: "No results, please try something else",
		tip24: "There is no content at this time",
		tip25: "Come and create your tag",
		tip26: "Please select the tprt/tprts format file!",
		tip27: "Upload failed",
		tip28: "The deletion was successful",
		tip29: "Choose png, jpg, jpeg, xlsx, xls, csv format files!",
		tip30: "The file is too large to upload, please upload less than",
		tip31: "K's file!",
		tip32: "You don't have an account yet?",
		tip33: "Have an account,",
		tip34: "Successfully modified",
		tip35: "The replication was successful",
		tip36: "Select a layer",
		tip37: "The paste was successful",
		tip38: "Bar code can not have Chinese characters!",
		tip39: "Please choose pictures in JPG, JPEG, PNG format!",
		tip40: "No cloud image",
		tip41: "Name is required",
		tip42: "The file name cannot contain any of the following characters:",
		tip43: "Nickname cannot be empty",
		tip44: "The modification is successful and you will be redirected to the login page",
		tip45: "Whether to overwrite the file already exists",
		tip46: "Network Error!",
		tip47: "The file is too large and can be opened using a PC client!",
		tip48: "The modification was successful",
		tip49: "Modification failed",
		tip50: "Template content cannot be empty",
		tip51: "Please select a single layer",
		tip52: "Select at least one piece of data",
		tip53: "Please enter keywords",
		tip54: "The verification code has been sent successfully",
		tip55: "Registered successfully",
		tip56: "Added successfully",
		tip57: "Moved successfully",
		tip58: "Saved successfully",
		tip59: "Submitted successfully",
		tip60: "Please upload images less than 2M",
		tip61: "The content cannot be empty",
		tip62: "The current folder has no data uploaded",
		tip63: "For the security of your account, please bind your mobile phone number/email address!",
		tip64: "Connected successfully!",
		tip65: "Connection failed!",
		tip66: "Binding succeeded!",
		tip67: "Please use the permission to modify nickname after upgrading the membership!",
		tip68: "Authorized successfully!",
		tip69: "The font size is abnormal, please adjust the minimum font size of the browser!",
		tip70: "How to modify?",
		tip71: "Tips",
		tip72: "It has been associated with other elements, and it cannot be associated with an element!",
		tip73: "Please enter a decimal number of 0~9",
		tip74: "Please enter a hexadecimal number consisting of 0 to 9, A to F",
		tip75: "Please enter a base-26 number composed of A to Z.",
		tip76: "Please enter a base-36 number composed of 0 to 9 and A to Z",
		tip77: "The current data is referenced by another element, is it certain to delete?",
		tip78: "The current data exists that is referenced by other elements, is it certain to delete?",
		tip79: "It is recommended to use Chrome and Microsoft Edge browsers for label editing or printing!",
		tip80: "The encoding format is incorrect!",
		tip81: "Security verification",
		tip82: "Can't see clearly? Change one",
		tip83: "Prints cannot be empty!",
		tip84: "Please select a printer",
		tip85: "Click ",
		tip86: "Download to install and launch",
		tip87: " the Print Assistant",
		tip88: "If it is installed and started, click here to ",
		tip89: "connect",
		tip90: "Failed to get printer, please reconnect Print Assistant!",
		tip91: "Successfully delivered!",
		tip92: "Delivery failed, please try again!",
		tip93: "Please install and start the Print Assistant!",
		tip94: 'Payment successful',
		tip95: 'Payment timeout',
		tip96: 'Please enter the redemption code',
		tip97: 'Instructions for use',
		tip98: 'Redemption codes can be obtained by participating in the official operation activities held by Hanma;',
		tip99: 'Redemption process: verify the account information, fill in the redemption code, and click Redeem Now;',
		tip100: 'If you are a member, the validity period of the membership will be extended after redemption;',
		tip101: 'Please pay attention to the redemption time and redeem within the redemption validity period.',
		tip102: 'Redemption successful',
		tip103: 'Template parsing error',
		tip104: 'The table function is a member-only privilege, please use it after upgrading your membership!',
    tip105: 'Please open it with a computer browser!',
    tip106: 'Please select the folder to delete',
    tip107: 'No data',
    tip108: 'Please select the control to be operated on',
    tip109: 'Please unlock the locked control first',
    tip110: 'Please select data',
    tip111: 'Generation failed',
    tip112: 'This control is locked',
    tip113: 'Please select an image',
    tip114: 'Please select a model',
    tip115: 'Are you sure you want to delete the selected folder and all the data in the folder?',
    tip116: 'Have at least one folder!',
    tip117: 'Do you want to save the current editing content',
    tip118: 'Smart tags are divided into exclusive privileges for members. Please upgrade to a member before using them!',
    tip119: 'Customize cropping as a member exclusive privilege, please upgrade to a member before using!',
    tip120: 'Cancellation successful!',
    tip121: 'After logging out, the data will be cleaned up. Please operate with caution! If you wish to continue using Hancode after cancellation, you need to register your account again!',
	},
	label: {
		label1: "Folder",
		label2: "Recently used labels",
		label3: "Name",
		label4: "Edit the folder name",
		label5: "Edit the file name",
		label6: "Edit the label name",
		label7: "Modify the nickname",
		label8: "Edit the nickname",
		label9: "Change the password",
		label10: "Nickname",
		label11: "Account",
		label12: "Password",
		label13: "Industry",
		label14: "Select the industry",
		label15: "Revise",
		label16: "The label name",
		label17: "Save the label to",
		label18: "Move the label to",
		label19: "Print preview",
		label20: "The size of the label",
		label21: "The template type",
		label23: "Width",
		label24: "Height",
		label25: "Multi-row multi-column",
		label26: "Columns",
		label27: "Rows",
		label28: "Column spacing",
		label29: "Line spacing",
		label30: "Margin",
		label31: "Left",
		label32: "Right",
		label33: "Top",
		label34: "Bottom",
		label35: "My creation",
		label36: "DataName",
		label37: "AddDate",
		label38: "Default",
		label39: "Name A-Z",
		label40: "Name Z-A",
		label41: "Date ASC",
		label42: "Date DESC",
		label43: "Size ASC",
		label44: "Size DESC",
		label45: "Account login",
		label46: "Reset the password",
		label47: "I have read and agree ",
		label48: "Service agreement ",
		label49: "And ",
		label50: "Privacy Policy",
		label51: "New folder 1",
		label52: "Upgrade membership to enjoy exclusive privileges",
		label53: "WeChat Pay",
		label54: "WeChat",
		label55: "Upgrade members to enjoy ",
		label63: " member privileges",
		label56: "Recommend",
		label57: "Expires in ",
		label58: " days",
		label59: "",
		label60: "Expires today",
		label61: " months",
		label62: "Third-party login",
		label64: "Printer",
		label65: "Copies",
    label66: "Preferential",
    label67: "All folders",
    label68: "<span style='color: #003591'>{ total }</span> folders have been selected",
    label69: 'Manage',
    label70: 'Year',
	},
	privilege: {
		privilege1: "Increase cloud space",
		privilege2: "Scan code new",
		privilege3: "Unlimited folders",
		privilege4: "Scan Print",
		privilege5: "Share Templates",
		privilege6: "Fast Printing",
		privilege7: "Variable Templates",
		privilege8: "Exclusive Fonts",
		privilege9: "Table Function",
		privilege10: "Print Record",
		privilege11: "Modify Nickname",
    privilege12: "Honorable Status",
    privilege13: "Cloud space",
	},
	editAttr: {
		attrText1: "Component properties",
		attrText2: "The data source",
		attrText3: "Normal text",
		attrText4: "Database text",
		attrText5: "Serialized text",
		attrText6: "Font",
		attrText7: "FontSize",
		attrText8: "FontStyle",
		attrText9: "Revolve",
		attrText10: "Word spacing",
		attrText11: "Line spacing",
		attrText12: "Word wrap",
		attrText13: "Mirror",
		attrText14: "AntiBlack",
		attrText15: "Location",
		attrText16: "Data",
		attrText17: "Digit",
		attrText18: "The type of time",
		attrText19: "System time",
		attrText20: "The specified time",
		attrText21: "The type of bar code",
		attrText22: "The location of the data",
		attrText23: "Hide",
		attrText24: "Below",
		attrText25: "Zoom magnity",
		attrText26: "Left",
		attrText27: "Center",
		attrText28: "Right",
		attrText29: "Error correction rate",
		attrText30: "Add a local picture",
		attrText31: "Add a picture in the cloud",
		attrText32: "Zoom",
		attrText33: "The line type",
		attrText34: "The thickness of the line",
		attrText35: "Data type",
		attrText36: "Please select",
		attrText37: "Connect",
		attrText38: "Insert data",
		attrText39: "Start value",
		attrText40: "Increment",
		attrText41: "Quantity",
		attrText42: "Number format",
		attrText43: "Date format",
		attrText44: "Select the time",
		attrText45: "Fillet radius",
		attrText46: "Adapt canvas",
		attrText47: "Please enter something",
		attrText48: "Solid",
		attrText49: "Dashed",
		attrText50: "Copy",
		attrText51: "Paste",
		attrText52: "Locking",
		attrText53: "Unlock",
		attrText54: "Align",
		attrText55: "Align left",
		attrText56: "Vertically centered",
		attrText57: "Align right",
		attrText58: "Horizontally centered",
		attrText59: "Top alignment",
		attrText60: "Bottom Aligned",
		attrText61: "Horizontal distribution",
		attrText62: "Vertical distribution",
		attrText63: "Layer order",
		attrText64: "Move To Top",
		attrText65: "Move To Bottom",
		attrText66: "Delete",
		attrText67: "Cloud data",
		attrText68: "Local data",
		attrText69: "Revoke",
		attrText70: "Recover",
		attrText71: "Save",
		attrText72: "Save As",
		attrText73: "Delete",
		attrText74: "Move up",
		attrText75: "Move down",
		attrText76: "Shift left",
		attrText77: "Shift right",
		attrText78: "Select all",
		attrText79: "Print",
		attrText80: "Canvas zooms",
		attrText81: "Canvas zoom out",
		attrText82: "Multiple selection",
		attrText83: "Scroll wheel up",
		attrText84: "Scroll wheel down",
		attrText85: "Left click",
		attrText86: "Novice guide",
		attrText87: "View shortcut keys",
		attrText88: "Shortcut key descriptions",
		attrText89: "Adaptive window",
		attrText90: "Zoom out",
		attrText91: "Zoom in",
		attrText92: "Serialization type",
		attrText93: "Decimal",
		attrText94: "Align",
		attrText95: "Offset(d)",
		attrText96: "Offset(m)",
		attrText97: "Background image",
		attrText98: "Data",
		attrText99: "Prints background image",
		attrText100: "Select data",
		attrText101: "Object value",
		attrText102: "Add data",
		attrText103: "Hexadecimal",
		attrText104: "Letters",
		attrText105: "Letters and numbers",
		attrText106: "Line height",
		attrText107: "Column width",
		attrText108: "Content",
		attrText109: "Insert rows",
		attrText110: "Insert a column",
		attrText111: "Delete the row",
		attrText112: "Delete the column",
		attrText113: "Merge",
    attrText114: "Split",
    attrText115: "Time format",
    attrText116: "Below",
    attrText117: "Fill",
    attrText118: "Prefix",
    attrText119: "Suffix",
    attrText120: 'Time',
    attrText121: 'Line direction',
    attrText122: 'Cloud based images',
    attrText123: 'Tile',
    attrText124: 'Stretch',
    attrText125: 'Horizontal Text',
    attrText126: 'Vertical Text',
    attrText127: 'Curved Text',
    attrText128: 'Bold',
    attrText129: 'Italic',
    attrText130: 'Underline',
    attrText131: 'Strikethrough',
	},
	module: {
		editText1: "Text",
		editText2: "Time",
		editText3: "Barcode",
		editText4: "QRCode",
		editText5: "Image",
		editText6: "Shape",
		editText7: "Rectangle",
		editText8: "Fillet rectangle",
		editText9: "Lozenge",
		editText10: "Elliptical",
		editText11: "Triangle",
		editText12: "Solid rectangle",
		editText13: "Icon",
		editText14: "Slash",
		editText15: "Horizon",
		editText16: "Vertical",
		editText17: "Table",
	},
	guide: {
		guideText1: "Welcome to ",
		guideText2: "Simple and efficient",
		guideText3: "Scene-oriented",
		guideText4: "Handy resources",
		guideText5: "Multi-terminal interoperability",
		guideText6: "The HereLabel label design software enables label design to achieve what you see is what you get. It can be completed by simply dragging and dropping. Label design has never been so simple and efficient. Come and experience it!",
		guideText7: "Skip",
		guideText8: "Start learning",
		guideText9: "Guide: Industry Template",
		guideText10: "Scenario-based label templates provide common labels in various industries such as retail, clothing, and barcodes.",
		guideText11: "Guide: Cloud Template",
		guideText12: "Personal tags are automatically stored in the cloud, folder management tags can be created, and tags can be used on PC and mobile terminals.",
		guideText13: "Guide: Cloud Data",
		guideText14: "Files such as pictures and tables can be permanently stored in the cloud and can be used anytime, anywhere on the Web, PC, and mobile terminals.",
		guideText15: "Guide: Personal Center",
		guideText16: "Click to modify the language, switch the unit or enter the personal center page to modify the information.",
		guideText17: "Guide: New label",
		guideText18: "Set the label name, size, number of rows, and number of columns, and start creating your own label!",
		guideText19: "Got it",
		guideText20: "Start creating labels",
		guideText21: "Welcome to the edit page",
		guideText22: "Just a few simple steps to complete the label editing, come and learn together!",
		guideText23: "Component library",
		guideText24: "Provides a variety of components such as text, time, barcode, etc., and the corresponding components can be added by clicking or dragging with the mouse.",
		guideText25: "keep studing",
		guideText26: "Function area",
		guideText27: "The component functions include lock, undo, restore, alignment, layer on top, layer on bottom, copy, paste, and delete.",
		guideText28: "You can also right-click the component in the canvas to open the right-click menu for operation.",
		guideText29: "Attribute area",
		guideText30: "Select the component to open the attribute area, modify the data source, style, size, position and other attributes of the corresponding component.",
		guideText31: "Save Print",
		guideText32: "Click label settings to modify the current label size. <br />After finishing editing the label, you can try to print the current label!",
		guideText33: "I have learned",
		guideText34: "Next",
    guideText35: "The HereLabel label design software enables label design to achieve what you see is what you get. It can be completed by simply dragging and dropping. Label design has never been so simple and efficient. Come and experience it!",
    guideText36: "Guide: Document printing",
    guideText37: "Support Word Excel、PPT、PDF、 Multiple formats including images"
	},
	suggest: {
		suggest1: "Question type",
		suggest2: "Abnormal function",
		suggest3: "Product advice",
		suggest4: "Problem description",
		suggest5: "Please describe your problem in detail and we will solve it as soon as possible!",
		suggest6: "Contact information",
		suggest7: "Please enter your contact information！",
		suggest8: "Screenshot of the problem",
		suggest9: "Submit",
		suggest10: 'Submission successful, thank you for your feedback!',
		suggest11: 'Helpful',
		suggest12: 'Not helpful',
		suggest13: 'Sorry, what went wrong?',
		suggest14: 'The description is not clear/incomprehensible',
		suggest15: 'The content described is inaccurate/topical',
		suggest16: 'This feature does not work well',
		suggest17: 'Other issues',
		suggest18: 'Please select the question type',
		suggest19: 'Thanks for the feedback, do you have any other suggestions?',
		suggest20: 'Please enter your suggestion',
	},
	download: {
		download1: "中文",
		download2: "English",
		download3: "download",
		download4: "Mobile App",
		download5: "Multi-terminal interoperability",
		download6: "PC, WEB, iOS, Android four-terminal data are synchronized in real time, so that label storage is no longer limited by time and space.",
		download7: "Simple and efficient",
		download8: "Make the label design to achieve what you see is what you get, just drag and drop to complete, label design has never been so simple and efficient.",
		download9: "Scene-oriented",
		download10: "For different industry applications, it provides a wealth of scene-based label templates, such as address labels, text labels, barcode labels, transportation labels, retail labels, etc., and you can design your favorite labels with just one click.",
		download11: "Handy resources",
		download12: "We hope that high-quality resources are at your fingertips, and a large number of commonly used scene signs make label design more convenient and efficient.",
		download13: "(only available online)",
	},
	help: {
		help1: "Help Center",
		help2: "All",
		help3: "Catalog",
		help4: "Update time",
		help5: "Search results",
  },
  document: {
    text1: 'Open document',
    text2: 'Click or drag and drop the file directly into this area to upload it',
    text3: 'Auto Crop',
    text4: 'Intelligent label clipping',
    text5: 'Custom Crop',
    text6: 'Direct printing',
    text7: 'Document',
    text8: 'One click cropping of excess blank space in documents',
    text9: 'Intelligent label segmentation',
    text10: 'Crop into multiple label files',
    text11: 'Rows',
    text12: 'Columns',
    text13: 'Example',
    text14: 'For example: 2 * 2',
    text15: 'Next step',
    text16: 'Paper size',
    text17: 'Custom',
    text18: 'Width',
    text19: 'Height',
    text20: 'Printing effect',
    text21: 'Document',
    text22: 'Image',
    text23: 'Resolution',
    text24: 'Arrangement',
    text25: 'Stretch',
    text26: 'Fill',
    text27: 'Equal ratio',
    text28: 'Number of copies printed',
    text29: 'Page Range',
    text30: 'All',
    text31: 'Current page',
    text32: 'Page Range',
    text33: 'Page range(e.g. 1-3, 5)',
    text34: 'Back',
    text35: 'Cropping',
    text36: 'Please upload txt, pptx, ppt, pdf, doc, docx, xls, xlsx, bp, jpg, png files',
    text37: 'The document content is empty',
    text38: 'Page range input error',
    text39: 'Parsing failed',
    text40: 'Apply the entire document',
  },
  expandCloud: {
    text1: 'Used already',
    text2: 'Total space',
    text3: 'Purchase Record',
    text4: 'Purchase space',
    text5: 'Purchasing date',
    text6: 'Valid to',
    text7: 'No purchase records available at the moment',
    text8: 'Expansion Package',
    text9: 'The space package will take effect within 5 minutes after successful purchase; The purchased space will be reclaimed after expiration, but the uploaded files will be retained.'
  },
  industryTemplate: {
    text1: 'Select model',
    text2: 'More',
    text3: 'Search For',
    text4: 'Filter Criteria',
    text5: 'Template size',
    text6: 'Select template width',
    text7: 'Select template height',
    text8: '{tempCount} templates',
    text9: 'Switch models',
    text10: 'Filter the content',
	text11: 'Select Series',
  }
};